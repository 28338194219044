import React, { Component } from 'react';
import { connect } from "react-redux";
import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import * as actions from "../../store/actions/index";
import { obterUsuarioLogado } from '../../store/userSession';
import Button from '../../components/UI/Button/Button'
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';

const useStyles = (theme) => ({
  textField: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  button: {
    display: 'flex',
    justifyContent: 'center'
  }
});

class CreatePostForm extends Component {
  classes = this.props;

  guardAgainstUndefined = (field, prop) => {
    if (field === 'city' || field === 'realStates') {
      return prop ? prop : []
    }
    return prop ? prop : ''
  }

  state = {
    id: this.guardAgainstUndefined('id', this.props.user.id),
    name: this.guardAgainstUndefined('name', this.props.user.name),
    city: this.guardAgainstUndefined('city', this.props.user.city),
    email: this.guardAgainstUndefined('email', this.props.user.email),
    phone: this.guardAgainstUndefined('phone', this.props.user.phone),
    realState: this.guardAgainstUndefined('realState', this.props.user.realState),
    role: this.guardAgainstUndefined('role', this.props.user.role),
    realStates: this.guardAgainstUndefined('realStates', this.props.user.realStates),
    password: '',
    cities: [
      'bauru',
      'piratininga',
      'agudos',
      'pederneiras',
      'lençóis paulista',
      'londrina',
      'jaú',
      'santos',
      'praia grande'
    ],
    userCity: obterUsuarioLogado().city,
    loaded: false,
    realStatesLoaded: false,
    }

  componentDidMount() {
    var id = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);

    const typeUser = window.location.pathname;
    if (typeUser.includes('create-user')) {
      this.props.onFetchUser(id);
      this.setState({ role: 'User' })
    } else if (typeUser.includes('create-master')) {
      this.props.onFetchAdmin(id);
      this.setState({ role: 'Admin' })
    }
  }

  componentWillUnmount() {
    this.props.resetProps();
  }

  handleSavePost = (post) => {
    this.props.handleSavePost(post);
    this.props.history.push('/admin/panel');
  }

  onChangeField = (field, string) => {
    if (field === 'Nome') {
      this.setState({ name: string })
    } else if (field === 'Cidade') {
      this.setState({ city: string })
    } else if (field === 'Imobiliária') {
      this.setState({ realState: string })
    } else if (field === 'Email') {
      this.setState({ email: string })
    } else if (field === 'Telefone') {
      this.setState({ phone: string })
    } else {
      this.setState({ password: string })
    }
  }

  buildTextField = (field, value, onChange) => (
    <FormControl fullWidth>
      <InputLabel>{field}</InputLabel>
      <Input
        id={field}
        name={field}
        label={field}
        value={value}
        className={this.classes.textField}
        onChange={e => onChange(field, e.target.value)}
      />
      <br />
    </FormControl>
  )

  buildMultiSelectLabel = (field, value, options, onChange) => (
    <FormControl fullWidth>
      <InputLabel htmlFor="outlined-age-native-simple">{field}</InputLabel>
      <Select
        MenuProps={{
          getContentAnchorEl: () => null,
        }}
        id={field}
        // className={this.inputClasses.join(' ')}
        multiple
        value={value}
        label={field}
        onChange={e => onChange(field, e.target.value)}
        renderValue={selected => selected.map(s => s).join(', ')}
      >
        {options && options.map(option => (
          <MenuItem key={option} value={option}>
            <Checkbox checked={value.map(city => city).includes(option)} />
            <ListItemText primary={option} />
            {/* {city.label || city} */}
          </MenuItem>
        ))}
      </Select>
      <br />
    </FormControl>
  )

  buildSelectLabel = (field, value, options, onChange) => (
    <FormControl fullWidth>
      <InputLabel htmlFor="outlined-age-native-simple">{field}</InputLabel>
      <Select
        id={field}
        value={value}
        label={field}
        onChange={e => onChange(field, e.target.value)}
        renderValue={selected => {
          const option = options.find( item => item.value === selected)
          return option && option.label;
        }}
      >
        <MenuItem key={'Nenhum'} value={''}>
            <Checkbox checked={value === ''} />
            <ListItemText primary={'Nenhum'} />
        </MenuItem>
        {options && options.map(option => (
          <MenuItem key={option.label} value={option.value}>
            <Checkbox checked={value === option.value} />
            <ListItemText primary={option.label} />
          </MenuItem>
        ))}
      </Select>
      <br />
    </FormControl>
  )

  submitHandler = () => {
    const userdata = {
      _id: this.state.id,
      name: this.state.name,
      city: (this.props.role === 'Master' || this.state.city === '') ? [this.state.userCity] : this.state.city,
      email: this.state.email,
      password: this.state.password,
      phone: this.state.phone,
      role: this.state.role,
      realState: this.state.realState,
      checked: true
    }

    if (userdata._id === '') {
      delete userdata._id;
      this.props.onSignup(userdata);
    } else {
      if (userdata.role === 'User') {
        this.props.onUpdateUser(userdata);
      }
      if (userdata.role === 'Master') {
        this.props.onUpdateAdmin(userdata);
      }
    }

    this.props.history.push('/admin/panel');
  }

  render() {
    const { classes } = this.props;

    if (this.props.user.realStates !== undefined && this.state.realStatesLoaded === false) {
      this.setState({
        realStates: this.guardAgainstUndefined('realStates', this.props.user.realStates),
        realStatesLoaded: true
      })
    }

    if (this.props.user.id !== undefined && this.props.user.id !== this.state.id && this.state.loaded === false) {
      this.setState({
        loaded: true,
        id: this.guardAgainstUndefined('id', this.props.user.id),
        name: this.guardAgainstUndefined('name', this.props.user.name),
        city: this.guardAgainstUndefined('city', this.props.user.city),
        email: this.guardAgainstUndefined('email', this.props.user.email),
        phone: this.guardAgainstUndefined('phone', this.props.user.phone),
        realState: this.guardAgainstUndefined('realState', this.props.user.realState),
        role: this.guardAgainstUndefined('role', this.props.user.role),
        password: '',
        cities: [
          'bauru',
          'piratininga',
          'agudos',
          'pederneiras',
          'lençóis paulista',
          'londrina',
          'jaú',
          'santos',
          'praia grande'
        ],
        userCity: obterUsuarioLogado().city    
      })
    }

    return (
      <React.Fragment>
        <CssBaseline />
        <main className={classes.layout}>
          <Paper className={classes.paper}>
            <Grid>
              {
                this.state.role === "User" &&
                <Typography component="h1" variant="body1" align="center" className={classes.textField}>
                  Cadastrar Novo corretor
                </Typography>
              }
              {
                this.state.role === "Admin" &&
                <Typography component="h1" variant="body1" align="center" className={classes.textField}>
                  Cadastrar Novo Admin
                </Typography>
              }
              {this.buildTextField('Nome', this.state.name, this.onChangeField)}
              {
                this.props.role === "Admin" &&
                this.buildMultiSelectLabel('Cidade', this.state.city, this.state.cities, this.onChangeField)
              }
              {this.buildSelectLabel('Imobiliária', this.state.realState, this.state.realStates, this.onChangeField)} 
              {this.buildTextField('Email', this.state.email, this.onChangeField)}
              {this.buildTextField('Telefone', this.state.phone, this.onChangeField)}
              {this.state.id === '' && this.buildTextField('Senha', this.state.password, this.onChangeField)}
            </Grid>
            <br />
            <div className={classes.button}>
              <Button variant="contained" color="primary" clicked={this.submitHandler}>
                Criar Conta
              </Button>
            </div>
          </Paper>
        </main>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    loading: state.auth.loading,
    role: state.auth.role,
    user: state.users.user
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFetchUser: (id) => dispatch(actions.fetchUser(id)),
    onFetchAdmin: (id) => dispatch(actions.fetchAdmin(id)),
    onSignup: (formdata) => dispatch(actions.signup(formdata)),
    onUpdateUser: (formdata) => dispatch(actions.updateUserByAdmin(formdata)),
    onUpdateAdmin: (formdata) => dispatch(actions.updateAdmin(formdata)),
    resetProps: () => dispatch(actions.resetProps())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(useStyles)(CreatePostForm));