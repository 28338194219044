import React, { Component } from 'react';
import { connect } from "react-redux";
import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import AnnouncementMessageInformation from '../../components/AnnouncementMessagesInformation/Message/AnnouncementMessageInformation';

import * as actions from "../../store/actions/index";

const useStyles = (theme) => ({
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(800 + theme.spacing(2) * 2)]: {
      width: 800,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(800 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
});

class AdminPostForm extends Component {
  componentDidMount() {
    var id = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);
    this.props.onFetchReport(id);
  }

  render () {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <CssBaseline />
        <main className={classes.layout}>
          <Paper className={classes.paper}>
            <AnnouncementMessageInformation
              history={this.props.history}
              defaultAnnouncementMessage={this.props.report}
              columns={[
                'name',
                'email',
                'phone',
                'role',
                'realState'
              ]}
            />
          </Paper>
        </main>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    report: state.reports.report
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFetchReport: (id) => dispatch(actions.fetchReport(id)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(useStyles)(AdminPostForm));
