import React, { Component } from 'react';
import { connect } from "react-redux";
import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Button from '../../components/UI/Button/Button'
import AdvertiseInformationComponent from '../../components/AdvertiseInformationComponent/AdvertiseInformationComponent';

import { createFormDataImages } from '../../utils/images';

import * as actions from "../../store/actions/index";

const useStyles = (theme) => ({
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(800 + theme.spacing(2) * 2)]: {
      width: 800,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(800 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
});

class AdvertiseInformation extends Component {
  state = {
    tmpImage: undefined
  }

  componentDidMount() {
    var id = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);
    this.props.onFetchAdvertise(id);
  }

  componentWillUnmount() {
    this.props.onResetProps();
  }

  onChangeInput = (key, value) => {
    this.props.onUpdateAdvertise(key, value);
  }

  onUploadFile = (value) => {
    this.setState({
        tmpImage: value
    })
  }

  submitAdvertise = () => {
    const id = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);

    let formData = new FormData();
    formData = createFormDataImages('image', formData, this.state.tmpImage);
    formData.append('advertise', JSON.stringify({
        _id: id,
        ...this.props.advertise
    }));

    if (this.props.advertise.name
    && this.props.advertise.page
    && this.props.advertise.position) {
      this.props.onSendAdvertise(formData);
    }
  }

  render () {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <CssBaseline />
        <main className={classes.layout}>
          <Paper className={classes.paper}>
            <AdvertiseInformationComponent
              history={this.props.history}
              defaultAdvertise={this.props.advertise}
              columns={[
                'name',
                'page',
                'position',
                'link',
                'image'
              ]}
              disabled={false}
              onChangeInput={this.onChangeInput}
              onUploadFile={this.onUploadFile}
            />
          </Paper>
          <Button
            clicked={this.submitAdvertise}
          >
            Finalizar
          </Button>
        </main>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    advertise: state.advertise.advertise,
    name: state.advertise.advertise.name,
    page: state.advertise.advertise.page,
    position: state.advertise.advertise.position,
    link: state.advertise.advertise.link,
    image: state.advertise.advertise.image
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFetchAdvertise: (id) => dispatch(actions.fetchAdvertise(id)),
    onResetProps: () => dispatch(actions.fetchAdvertiseStart()),
    onUpdateAdvertise: (key, value) => dispatch(actions.updateAdvertise(key, value)),
    onSendAdvertise: (data) => dispatch(actions.sendAdvertise(data))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(useStyles)(AdvertiseInformation));
