import React from "react";
import { NavLink } from "react-router-dom";

import "./NavigationItem.scss";

const updateLinkActive = function (props) {
  let url = window.location.pathname;
  let searchUrl = window.location.search;

  if (url.indexOf("imobiliaria") !== -1 && props.children === "Imobiliárias") {
    return true;
  } else if (
    url.indexOf("pesquisa") !== -1 &&
    searchUrl.indexOf("imobiliaria") !== -1 &&
    props.children === "Imobiliárias"
  ) {
    return true;
  } else if (url === "/" && props.children === "Venda e Locação") {
    return true;
  } else if (
    url.indexOf("pesquisa") !== -1 &&
    searchUrl.indexOf("imobiliaria") === -1 &&
    props.children === "Venda e Locação"
  ) {
    return true;
  } else if (
    url.indexOf("busca") !== -1 &&
    props.children === "Venda e Locação"
  ) {
    return true;
  } else if (url.indexOf("contato") !== -1 && props.children === "Contato") {
    return true;
  } else if (url.indexOf("auth") !== -1 && props.children === "Entrar") {
    return true;
  } else if (props.clicked) {
    return true;
  } else
    return url.indexOf("meusdados") !== -1 && props.children === "Meus dados";
};

const navigationItem = (props) => (
  <li className="NavigationItem">
    <NavLink
      to={props.link}
      exact={props.exact}
      isActive={() => updateLinkActive(props)}
      className={props.className}
      onClick={props.click}
      activeClassName="active"
    >
      {props.children}
    </NavLink>
  </li>
);

export default navigationItem;
