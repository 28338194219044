import styled from '@emotion/styled'

export const FloatButtons = styled.div`
  position: fixed;
  bottom: -2px;
`
export const ButtonHolder = styled.div`
  padding: 10px 20px;
`

export const MapElement = styled.div`
  display: flex;
  flex-direction: column-reverse;
  flex: 1;
  width: 100%;
  height: 100%;
`
