import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Helmet } from 'react-helmet';

import Footer from '../../components/UI/Footer';
// import AdvertisementImages from '../../components/AdvertisementImages/AdvertisementImages';
import PartnerBanner from '../../components/PartnerBanner/PartnerBanner';

import * as actions from '../../store/actions/index';

const useStyles = (theme) => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center'
  },
  root2: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
  paper: {
    textAlign: 'center',
    color: theme.palette.text.secondary
  },
  partnersTitle: {
    display: 'flex',
    justifyContent: 'center',
    color: '#003a70',
    fontSize: '30px'
  },
  partnerTitle: {
    display: 'flex',
    justifyContent: 'center',
    color: '#003a70',
    fontSize: '30px'
  },
  backgroundPartners: {
    backgroundcolor: '#ededed',
    padding: '30px'
  }
});

class Partner extends Component {
  state = {
    showInfoWindow: [],
    showInfoWindowUser: true,
    showWindowGroup: false,
    myCoordinates: {
      latitude: sessionStorage.getItem('latitude') !== undefined ? Number(sessionStorage.getItem('latitude')) : 0,
      longitude: sessionStorage.getItem('longitude') !== undefined ? Number(sessionStorage.getItem('longitude')) : 0
    },
    favoritou: false,
    address: '',
    loadingAddress: false,
    position: null,
    id: null,
    imovel: null,
    quadrinha: null,
    mostrarPainel: false,
    mostrarQuadrinha: false,
    loadingFavorito: false,
    scroll: false,
    city: '',
    type: '',
    elevator: '',
    lazer: '',
    find: false
  };

  componentDidMount () {
    this.props.onFetchAdvertisements('/parceiros');
  }

  render() {
    const { classes } = this.props;

    return (
      <>
        <Helmet>
          <title> Parceiros — Plano Cidade</title>
        </Helmet>
        <br />
        {/* <AdvertisementImages
          advertisements={this.props.advertisements['top']}
          width='728px'
          height='90px'
        /> */}
        <br />
        <div className={classes.backgroundPartners}>
          <span className={classes.partnersTitle}>
            Parceiros
          </span>
          <br />
          <PartnerBanner
            partnerTitle={'Imobiliárias'}
            partnerLink={'/parceiros/imobiliaria'}
            advertisement={this.props.advertisements['imobiliaria']}
          />
          <PartnerBanner
            partnerTitle={'Construtoras'}
            partnerLink={'/parceiros/construtora'}
            advertisement={this.props.advertisements['construtora']}
          />
          <PartnerBanner
            partnerTitle={'Administradoras'}
            partnerLink={'/parceiros/administradora'}
            advertisement={this.props.advertisements['administradora']}
          />
          <PartnerBanner
            partnerTitle={'Corretoras de Seguros'}
            partnerLink={'/parceiros/corretora_de_seguro'}
            advertisement={this.props.advertisements['corretora_de_seguro']}
          />
          <PartnerBanner
            partnerTitle={'Prestadoras de Serviço'}
            partnerLink={'/parceiros/prestadora_de_servico'}
            advertisement={this.props.advertisements['prestadora_de_servico']}
          />
        </div>
        {/* <AdvertisementImages
          advertisements={this.props.advertisements['bottom']}
          width='728px'
          height='90px'
        /> */}
        <br />
        <Footer />
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    advertisements: state.advertise.advertisements
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFetchAdvertisements: (page) => dispatch(actions.fetchAdvertisements(page))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(useStyles)(Partner));
