import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Input from '../UI/Input/Input';
import IconDelete from '@material-ui/icons/Delete';
import ImageUploading from 'react-images-uploading';

const useStyles = makeStyles((theme) => ({
  textField: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  margin: {
    margin: theme.spacing(1),
  },
  button: {
    display: 'flex',
    justifyContent: 'center'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  images: {
    maxWidth: '200px'
  },
  field: {
    marginBottom: '32px'
  }
}));

export default function AdvertiseInformationComponent({ columns, defaultAdvertise, disabled, onChangeInput, onUploadFile }) {
  const classes = useStyles();
  const [textFields, setTextFields] = useState({});

  useEffect(() => {
    if (defaultAdvertise) {
      Object.entries(defaultAdvertise).map(([key, _value]) => {
        if (textFields[key] !== defaultAdvertise[key]) {
          setTextFields(defaultAdvertise);
          return undefined;
        }
        return undefined;
      });  
    }
  }, [textFields, defaultAdvertise]);

  const buildTextField = (meta, value, disabled = true, onChange) => (
    <Input
      id={meta}
      key={meta}
      changed={onChange}
      name={meta}
      label={meta + ' *'}
      touched={true}
      elementConfig={value.config}
      elementType={value.inputType}
      shouldValidate={value.validation}
      value={value.value}
    />
  )

  const buildImage = (image) => {
    const img = <img src={ image.url } alt="Imagem" className={classes.images} />;
    return img;
  }

  const onInputEvent = (meta, event) => {
    return onChangeInput(meta, event.target.value);
  }

  const buildField = (meta, textFields, disabled = true) => {
    const value = textFields[meta];
    const positions = {
      '/': [
        {
          value: 'top',
          displayValue: 'top'
        },
        {
          value: 'middle',
          displayValue: 'middle'
        },
      ],
      '/lancamentos': [
        {
          value: 'top',
          displayValue: 'top'
        },
        {
          value: 'bottom',
          displayValue: 'bottom'
        },
      ],
      '/imoveis-a-venda': [
        {
          value: 'top',
          displayValue: 'top'
        },
        {
          value: 'bottom',
          displayValue: 'bottom'
        },
      ],
      '/parceiros': [
        {
          value: 'top',
          displayValue: 'top'
        },
        {
          value: 'bottom',
          displayValue: 'bottom'
        },
        {
          value: 'construtora',
          displayValue: 'construtora'
        },
        {
          value: 'administradora',
          displayValue: 'administradora'
        },
        {
          value: 'corretora_de_seguro',
          displayValue: 'corretora_de_seguro'
        },
        {
          value: 'imobiliaria',
          displayValue: 'imobiliaria'
        },
        {
          value: 'prestadora_de_servico',
          displayValue: 'prestadora_de_servico'
        }
      ],
      '/parceiros/construtora': [
        {
          value: 'top',
          displayValue: 'top'
        },
        {
          value: 'bottom',
          displayValue: 'bottom'
        },
      ],
      '/parceiros/administradora': [
        {
          value: 'top',
          displayValue: 'top'
        },
        {
          value: 'bottom',
          displayValue: 'bottom'
        },
      ],
      '/parceiros/corretora_de_seguro': [
        {
          value: 'top',
          displayValue: 'top'
        },
        {
          value: 'bottom',
          displayValue: 'bottom'
        },
      ],
      '/parceiros/imobiliaria': [
        {
          value: 'top',
          displayValue: 'top'
        },
        {
          value: 'bottom',
          displayValue: 'bottom'
        },
      ],
      '/parceiros/prestadora_de_servico': [
        {
          value: 'top',
          displayValue: 'top'
        },
        {
          value: 'bottom',
          displayValue: 'bottom'
        },
      ],
    }

    if (meta === 'image') {
      return (
        <div className={classes.field}>
        <label>imagens *</label>
        <div className="ImagensList">
          {value && buildImage(value)}
        </div>

        <ImageUploading multiple={false} onChange={ (tmpImages) => onUploadFile(tmpImages) } maxNumber={ 1 }>
          {
            ({ imageList, onImageUpload, onImageRemoveAll }) => (
              <>
                <div className="ImagesUpload">
                  {
                    (value === undefined) && (
                      <h4>Sem fotos</h4>
                    )
                  }
                  <br />
                  <button onClick={onImageUpload} type="button">Adicionar Fotos</button>
                </div>
                {
                  (imageList.length > 0) && (
                    <div className="ImagensList">
                      {
                        imageList.map(image => (
                          <div key={ image.key }>
                            <img src={ image.dataURL } alt="Imagem" className={classes.images} />
                            <button onClick={image.onRemove} type="button">
                              <IconDelete alt="Deletar foto" />
                            </button>
                          </div>
                        ))
                      }

                      <div className="Actions">
                        <button onClick={onImageRemoveAll} type="button">Remover todas as Fotos</button>
                      </div>
                    </div>
                  )
                }
              </>
            )}
        </ImageUploading>
      </div>
      )
    }
    if (meta === 'page') {
      return buildTextField(meta, {
        config: {
          options: [
            {
              value: '/',
              displayValue: '/'
            },
            {
              value: '/lancamentos',
              displayValue: '/lancamentos'
            },
            {
              value: '/parceiros',
              displayValue: '/parceiros'
            },
            {
              value: '/parceiros/construtora',
              displayValue: '/parceiros/construtora'
            },
            {
              value: '/parceiros/administradora',
              displayValue: '/parceiros/administradora'
            },
            {
              value: '/parceiros/construtora',
              displayValue: '/parceiros/construtora'
            },
            {
              value: '/parceiros/corretora_de_seguro',
              displayValue: '/parceiros/corretora_de_seguro'
            },
            {
              value: '/parceiros/imobiliaria',
              displayValue: '/parceiros/imobiliaria'
            },
            {
              value: '/parceiros/prestadora_de_servico',
              displayValue: '/parceiros/prestadora_de_servico'
            },
            {
              value: '/imoveis-a-venda',
              displayValue: '/imoveis-a-venda'
            },
          ]
        },
        inputType: 'select',
        validation: false,
        value: value
      }, disabled, (event) => onInputEvent(meta, event))
    }
    if (meta === 'position') {
      return buildTextField(meta, {
        config: {
          options: positions[textFields['page']]
        },
        inputType: 'select',
        validation: false,
        value: value
      }, disabled, (event) => onInputEvent(meta, event))
    }
    return buildTextField(meta, {
      config: {},
      inputType: 'input',
      validation: false,
      value: value
    }, disabled, (event) => onInputEvent(meta, event));
  }

  return (
    <React.Fragment>
      {
        defaultAdvertise && (
          <Grid>
            <Typography component="h1" variant="body1" align="center" className={classes.textField}>
              Informações Básicas
            </Typography>
            <Typography component="h1" variant="caption" align="center" className={classes.textField}>
              top/middle: 728 x 90
            </Typography>
            <Typography component="h1" variant="caption" align="center" className={classes.textField}>
              bottom: 728 x 300
            </Typography>
            <Typography component="h1" variant="caption" align="center" className={classes.textField}>
              parceiros: 1140 x 256
            </Typography>
            {
              columns && columns.map(column => (
                buildField(column, defaultAdvertise, disabled)
              ))
            }
          </Grid>
        )
      }
    </React.Fragment>
  );
}
