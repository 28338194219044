import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  iconButton: {
    padding: 10,
  },
  formControl: {
    margin: "2px 3px",
    minWidth: '100%',
    // maxWidth: 300,
  },
  MuiOutlinedInput:{
    padding: "11px"
  }
}));

const Asynchronous = (props) => {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [value, setValue] = React.useState("");
  const loading = open && options.length === 0;
  const classes = useStyles();

  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      var properties = await props.clickAuto(value);

      // console.log(e);
      // console.log(active);
      console.log(properties)
      if (active && properties.length > 0) {
        setOptions(properties.map((el) => el));
      }
    })();    


    return () => {
      active = false;
    };
  // eslint-disable-next-line
  }, [loading]);

  // React.useEffect(() => {
  //   if (!open) {
  //     setOptions([]);
  //   }
  // }, [open]);

  const handleSubmit = React.useCallback(
    async (e) => {
      let active = true;

      if (e) {    
        (async () => {
          // setOpen(true);
          console.log(active)
          await props.clickSubmit(e);
          // console.log(properties)
          // if (active && properties.length > 0) {
          //   setOptions(properties.map((el) => el));
          //   setOpen(false);

          // }
        })();
      }


      return () => {
        active = false;
      };
    // eslint-disable-next-line
    }, [loading]);

  return (
    <div className={classes.root}>
    <FormControl className={classes.formControl} variant="outlined">
    {/* <InputLabel >Age</InputLabel> */}
    <Select
    id="asynchronous-demo"
    style={{
      justifyContent: 'center',
      fontSize: "12px",
    }}
    onOpen={() => {
      setOpen(true);
     }
    }
    onClose={() => {
      setOpen(false);
    }}
    open={open}
    value={value.label}
    onChange={(event, newValue) => {
      // setOptions([]);
      // setOpen(true);
      setValue({
        label: newValue.props.value
      })
      console.log(newValue)

      if (typeof newValue === 'string') {
        handleSubmit(newValue);
      } else if (newValue && newValue.inputValue) {
        handleSubmit(newValue.inputValue);
      } else {
        if (newValue && newValue.props) handleSubmit(newValue.props.value);
      }
    }}
    classes={{
      "outlined": classes.MuiOutlinedInput
    }}
  >
    {options && options.map((e) => (
      <MenuItem key={e.label} value={e.label}><Typography style={{ fontSize: "12px" }}>{e.label}</Typography></MenuItem>
  ))}
  </Select>
  </FormControl>
  </div>
  );
}

export default Asynchronous
