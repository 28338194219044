import React from "react";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

const ErrorLogs = ({uploadError, open, handleClose}) => {
    const uploadId = uploadError[0].upload_id;
    const errors = uploadError[0].upload_errors

    return (
        
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth
        >
            <Box sx={{
                maxWidth: 600,
                maxHeight: 600,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'left',
                padding: '10px',                
            }}
            >   
                <DialogTitle>
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Typography variant="h4">Erros no upload do arquivo</Typography>
                        <IconButton onClick={handleClose}>
                            <CloseIcon/>
                        </IconButton>
                    </Grid>
                </DialogTitle>

                <Typography variant="h6"sx={{ml: "20px"}}>
                    {`Upload ID: ${uploadId}`}
                </Typography>
                <Typography variant="h6"sx={{ml: "20px"}}>
                    Erros:
                </Typography>
                <List sx={{ 
                    width: '100%',
                    maxWidth: 600,
                    bgcolor: 'background.paper',
                    maxHeight: 500,
                    overflowY: 'auto'
                    }}>
                    {errors.map((erro) => (
                         <>
                            <ListItem>
                                <Typography variant="h6"sx={{ml: "20px"}}>
                                    {`Código do Imóvel: ${erro.codigo_imovel}`}
                                </Typography>
                            </ListItem>
                            <ListItem>
                                <Typography variant="h6"sx={{ml: "20px"}}>
                                    {`Erro: ${erro.error_description}`}
                                </Typography>
                            </ListItem>
                            <Divider/>
                        </>
                    ))}
                </List>                        
            </Box>
        </Dialog>
    )
}

export default ErrorLogs;