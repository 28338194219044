import React from "react";
import {
  Typography,
  Grid,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import FilterNoneIcon from "@material-ui/icons/FilterNone";
import KingBedIcon from "@material-ui/icons/KingBed";
import DriveEtaIcon from "@material-ui/icons/DriveEta";
import BathtubIcon from "@material-ui/icons/Bathtub";

const useStyles = makeStyles({
  text: {
    color: "black",
  },
  icon: {
    fontSize: "26px",
    marginRight: "4px",
    color: "#003a70",
  },
  areaIcon: {
    marginRight: "4px",
    color: "#003a70",
  },
});

function IconsPost({
  area = undefined,
  quartos = undefined,
  garagens = undefined,
  banheiros = undefined,
}) {
  const classes = useStyles();

  const showIf = (param) => param && param !== "0";

  return (
    <Grid
      xs={12}
      md={7}
      style={{
        marginTop: "24px",
        display: "flex",
        alignItems: "center",
      }}
    >
      {showIf(area) ? (
        <Typography
          variant="body2"
          color="textSecondary"
          component="div"
          className={classes.text}
          style={{ marginRight: "5px" }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <FilterNoneIcon className={classes.areaIcon} />
            <span style={{ fontSize: "14px", marginRight: "5px" }}>
              {area}m²
            </span>
          </div>
        </Typography>
      ) : null}
      {showIf(quartos) ? (
        <Typography
          variant="body2"
          color="textSecondary"
          component="div"
          className={classes.text}
          style={{ marginRight: "10px" }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <KingBedIcon className={classes.icon} />
            <span style={{ fontSize: "14px", marginRight: "5px" }}>
              {quartos}
            </span>
          </div>
        </Typography>
      ) : null}
      {showIf(garagens) ? (
        <Typography
          variant="body2"
          color="textSecondary"
          component="div"
          className={classes.text}
          style={{ marginRight: "10px" }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <DriveEtaIcon className={classes.icon} />
            <span style={{ fontSize: "14px", marginRight: "5px" }}>
              {garagens}
            </span>
          </div>
        </Typography>
      ) : null}
      {showIf(banheiros) ? (
        <Typography
          variant="body2"
          color="textSecondary"
          component="div"
          className={classes.text}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <BathtubIcon className={classes.icon} />
            <span style={{ fontSize: "14px" }}>{banheiros}</span>
          </div>
        </Typography>
      ) : null}
    </Grid>
  );
}

export default IconsPost;
