import React from 'react';

const changeEvent = (e, currentvalue, changeMethodUpdateState) => {
    let newvalue = currentvalue === 'Não' ? "Sim" : "Não";
    e.target.value = newvalue;
    changeMethodUpdateState(e);
}

const simnao = (props) => (
    <input type="checkbox"
           name={props.value}
           id={props.id}
           className={props.classes}
           {...props.elementConfig}
           onChange={(e) => changeEvent(e, props.value, props.changed)}
           checked={(props.value === 'Sim')}/>);

export default simnao;
