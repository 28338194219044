import * as actionsNames from '../actions/actionsNames';
import { updateObject } from '../../utils/utility';

const initialState = {
    loading: false,
    advertises: null,
    advertise: {},
    advertisements: {}
};

const sendAdvertiseStart = (state, action) => {
    return updateObject(state, { loading: true });
};

const sendAdvertiseSuccess = (state, action) => {
    return updateObject(state, {
        loading: false,
        advertises: null,
        advertise: {}
    });
};

const sendAdvertiseFail = (state, action) => {
    return updateObject(state, {
        advertise: {}
    });
};

const actionFail = (state, action) => {
    return updateObject(state, {
        loading: false,
        error: action.error
    });
};

const actionStart = (state, action) => {
    return updateObject(state, { loading: true });
};

const fetchAdvertiseStart = (state, action) => {
    return updateObject(state, {
        loading: false,
        advertises: null,
        advertise: {}
    });
};

const fetchAdvertisesSuccess = (state, action) => {
    return updateObject(state, { 
        loading: false,
        advertises: action.data
    });
};

const fetchAdvertiseSuccess = (state, action) => {
    return updateObject(state, { 
        loading: false,
        advertise: action.data
    });
};

const updateAdvertise = (state, action) => {
    const updatedAdvertise = state.advertise;
    updatedAdvertise[action.data.key] = action.data.value
    return updateObject(state, {
        loading: false,
        advertise: updatedAdvertise
    });
};

const fetchAdvertisementsSuccess = (state, action) => {
    return updateObject(state, {
        loading: false,
        advertisements: action.data
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionsNames.SEND_ADVERTISE_START: return sendAdvertiseStart(state, action);
        case actionsNames.SEND_ADVERTISE_SUCCESS: return sendAdvertiseSuccess(state, action)
        case actionsNames.SEND_ADVERTISE_FAIL: return sendAdvertiseFail(state, action);
        case actionsNames.FETCH_ADVERTISES_START: return actionStart(state, action);
        case actionsNames.FETCH_ADVERTISES_SUCCESS: return fetchAdvertisesSuccess(state, action);
        case actionsNames.FETCH_ADVERTISES_FAIL: return actionFail(state, action);
        case actionsNames.FETCH_ADVERTISE_START: return fetchAdvertiseStart(state, action);
        case actionsNames.FETCH_ADVERTISE_SUCCESS: return fetchAdvertiseSuccess(state, action);
        case actionsNames.FETCH_ADVERTISE_FAIL: return actionFail(state, action);
        case actionsNames.UPDATE_ADVERTISE: return updateAdvertise(state, action);
        case actionsNames.FETCH_ADVERTISEMENTS_START: return actionStart(state, action);
        case actionsNames.FETCH_ADVERTISEMENTS_SUCCESS: return fetchAdvertisementsSuccess(state, action);
        case actionsNames.FETCH_ADVERTISEMENTS_FAIL: return actionFail(state, action);
        default: return state;
    }
};

export default reducer;
