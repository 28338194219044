import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { NavLink } from "react-router-dom";
import ContactButtons from "../../ContactButtons/ContactButtons";

const useStyles = makeStyles({
  root: {
    width: "100%",
    maxWidth: 310,
  },
  media: {
    height: 180,
  },
  card: {
    display: "flex",
    justifyContent: "center",
  },
  navlink: {
    color: "inherit",
    "&:hover, &:focus, &:active": {
      "&, & .text": {
        color: "inherit",
        textDecoration: "none",
      },
    },
  },
});

const MediaCard = ({
  id,
  post_title,
  src,
  tipo,
  area = undefined,
  valor_inicial = undefined,
  valor_do_condomnio = undefined,
  bairro = undefined,
  cidade = undefined,
  pathurl = "/imoveis/",
  numberPhone = undefined,
  emailProprietary,
  created_from = "",
  creciJuridico = undefined,
  mdSize = 4,
  smSize = 6,
}) => {
  const classes = useStyles();

  const showIf = (param) => param && param !== "0";

  const toTitleCase = (str) => {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  return (
    <Grid className={classes.card} item xs={12} sm={smSize} md={mdSize}>
      <Card className={classes.root}>
        <CardActionArea>
          <NavLink to={pathurl + id} className={classes.navlink}>
            <CardMedia className={classes.media} image={src} />
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                {post_title && post_title}
              </Typography>
              <Typography
                gutterBottom
                variant="h5"
                component="h2"
                style={{ color: "#a0c500" }}
              >
                {tipo && tipo.name ? tipo.name : tipo}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                {showIf(valor_inicial) ? (
                  <span style={{ fontSize: "12px" }}>
                    {"Preço: R$ " + valor_inicial}
                    <br />
                  </span>
                ) : (
                  ""
                )}
                {showIf(valor_do_condomnio) ? (
                  <span style={{ fontSize: "12px" }}>
                    {"Condomínio: R$ " + valor_do_condomnio}
                    <br />
                  </span>
                ) : (
                  ""
                )}
                {showIf(area) ? (
                  <span style={{ fontSize: "12px" }}>
                    {area + " m²"}
                    <br />
                  </span>
                ) : (
                  ""
                )}
                <span style={{ fontSize: "12px" }}>
                  {cidade && cidade.name && toTitleCase(cidade.name)}
                </span>
                <br />
                {numberPhone ? (
                  <span style={{ fontSize: "12px" }}>
                    {numberPhone}
                    <br />
                  </span>
                ) : null}
                {creciJuridico ? (
                  <span style={{ fontSize: "12px" }}>
                    CRECI: {creciJuridico}
                    <br />
                  </span>
                ) : null}
              </Typography>
              {created_from === "xml" ? (
                <div
                  style={{
                    display: "flex",
                    marginTop: "7px",
                    justifyContent: "center",
                  }}
                >
                  <ContactButtons
                    numberPhone={numberPhone}
                    emailProprietary={emailProprietary}
                    pageId={id}
                  />
                </div>
              ) : (
                ""
              )}
            </CardContent>
          </NavLink>
        </CardActionArea>
      </Card>
    </Grid>
  );
};

export default MediaCard;
