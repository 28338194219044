import * as actionsNames from '../actions/actionsNames';
import { updateObject } from '../../utils/utility';
// import { getItem } from '../../utils/cacheControl';

const initialState = {
    coordenadas: null,
    loading: true,
    centraliza: 0,
    error_geo: false,
    favoritos: [],
    favoritosPredios: [],
    center: {
        lat: Number(window.sessionStorage.getItem('latitude')),
        lng: Number(window.sessionStorage.getItem('longitude'))
    }
}
const setLocalizacaoSuccess = ( state, action ) => {
    return updateObject( state, 
        { loading: false, 
        coordenadas: action.data.coordenadas,
        center: { lat: action.data.coordenadas.latitude, lng: action.data.coordenadas.longitude },
        centraliza: false,
        erro_geo: false,
         } 
    );
};
    
const centralizar = ( state, action ) => {
    return updateObject( state, { centraliza: state.centraliza+1 } );
};
    
const setCenter = ( state, action ) => {
    return updateObject( state, { center: action.center, centraliza: false } );
};

const setErrorGetLocalizacao = ( state, action ) => {
    return updateObject( state, { loading: false, error_geo: true } );
};

// const setLocalizacao = ( state, action ) => {
//     return updateObject( state, { coordenadas: action.coordenadas } );
// };

const actionStart = ( state, action ) => {
    return updateObject( state, { loading: true } );
};

const initSuccess = ( state, action ) => {
    action.data = {coordenadas: action.coordenadas};
    let stateLocalizacaoSetted = setLocalizacaoSuccess(state, action);
    return updateObject( stateLocalizacaoSetted, 
        {favoritos: action.favoritos, favoritosPredios: action.favoritosPredios, loading: false}
    );
};

const initFail = ( state, action ) => {
    return updateObject( state, { loading: false,
        favoritos: null,
        favoritosPredios: null,
        error: action.error } );
};

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        
        case actionsNames.SET_LOCALIZACAO_SUCCESS_FAV: return setLocalizacaoSuccess( state, action );
        case actionsNames.MAP_CENTER_FAV: return centralizar( state, action );
        case actionsNames.SET_CENTER_FAV: return setCenter( state, action );
        case actionsNames.ERROR_GET_LOCALIZACAO_FAV: return setErrorGetLocalizacao( state, action );
        case actionsNames.INIT_MAPA_START_FAV: return actionStart( state, action );
        case actionsNames.INIT_MAPA_SUCCESS_FAV: return initSuccess(state, action);
        case actionsNames.INIT_MAPA_FAIL_FAV: return initFail( state, action );
        default: return state;
    }
};

export default reducer;
