import React, { useLayoutEffect, useState } from 'react';

import { withGoogleMap } from 'react-google-maps'
import { MapElement } from './styles/Map.style'

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);

  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);

    updateSize();

    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return size;
}

function ShowWindowDimensions(props) {
  const [width, height] = useWindowSize();
  return {
    width,
    height
  };
}

const withGoogleMaps = Component => props => {
  return(
    <div className={`MapFull ${ (props.location && (props.location.pathname === '/busca/mapa' || props.location.pathname === '/meusimoveis/mapa') ? 'MapInternal' : 'MapHome') }`}>
      <Component
        {...props}
        containerElement={<MapElement className='map-container' style={{ height: `${ShowWindowDimensions(props).height - 90}px` }} />}
        mapElement={<MapElement className='map-element' />}
      />
    </div>
  )
}

export default Component => withGoogleMaps(withGoogleMap(Component))
