import * as actionsNames from './actionsNames';
import api from '../../services/api';
import configToken from '../../utils/configToken';

const sendAdvertiseStart = () => {
  return {
    type: actionsNames.SEND_ADVERTISE_START
  };
};

const sendAdvertiseSuccess = data => {
  return {
    type: actionsNames.SEND_ADVERTISE_SUCCESS
  };
};

const sendAdvertiseFail = error => {
  return {
    type: actionsNames.SEND_ADVERTISE_FAIL,
    data: error
  };
};

export const sendAdvertise = data => {
	return dispatch => {
		dispatch(sendAdvertiseStart());
		api
		.patch(`/advertisements/update`, data, {
			headers: {
				...configToken().headers,
				"Content-Type": "application/x-www-form-urlencoded"
			}
		})
		.then(res => {
			dispatch(sendAdvertiseSuccess(res.data));
			window.location.reload();
		})
		.catch(err => {
			dispatch(sendAdvertiseFail(err));
		});	
	};
};

const fetchAdvertisesSuccess = data => {
	return {
	  type: actionsNames.FETCH_ADVERTISES_SUCCESS,
	  data: data
	};
};
  
const fetchAdvertisesFail = error => {
	return {
	  type: actionsNames.FETCH_ADVERTISES_FAIL,
	  error: error
	};
};
  
const fetchAdvertisesStart = () => {
	return {
	  type: actionsNames.FETCH_ADVERTISES_START
	};
};
  
export const fetchAdvertises = () => {
	return dispatch => {
	  dispatch(fetchAdvertisesStart());
	  api
		.get(`/advertisements/all`, configToken())
		.then(res => {
		  dispatch(fetchAdvertisesSuccess(res.data));
		})
		.catch(res => {
		  dispatch(fetchAdvertisesFail(res.data));
		})
	};
};

export const fetchAdvertiseStart = () => {
	return {
	  type: actionsNames.FETCH_ADVERTISE_START
	};
  };

const fetchAdvertiseSuccess = data => {
	return {
		type: actionsNames.FETCH_ADVERTISE_SUCCESS,
		data: data
	};
};

const fetchAdvertiseFail = error => {
	return {
		type: actionsNames.FETCH_ADVERTISE_FAIL,
		error: error
	};
};

export const fetchAdvertise = (id) => {
	return dispatch => {
	  dispatch(fetchAdvertiseStart());
	  api
		.get(`/advertisements/advertise?id=${id}`, configToken())
		.then(res => {
		  dispatch(fetchAdvertiseSuccess(res.data));
		})
		.catch(res => {
		  dispatch(fetchAdvertiseFail(res.data));
		})
	}
};

export const updateAdvertise = (key, value) => {
	return {
	  type: actionsNames.UPDATE_ADVERTISE,
	  data: {
          key: key,
          value: value
      }
	};
};

const fetchAdvertisementsSuccess = data => {
	return {
	  type: actionsNames.FETCH_ADVERTISEMENTS_SUCCESS,
	  data: data
	};
};
  
const fetchAdvertisementsFail = error => {
	return {
	  type: actionsNames.FETCH_ADVERTISEMENTS_FAIL,
	  error: error
	};
};
  
const fetchAdvertisementsStart = () => {
	return {
	  type: actionsNames.FETCH_ADVERTISEMENTS_START
	};
};

export const fetchAdvertisements = (page) => {
	return dispatch => {
		dispatch(fetchAdvertisementsStart());
		api
		  .get(`/advertisements/page?page=${page}`, configToken())
		  .then(res => {
			dispatch(fetchAdvertisementsSuccess(res.data));
		  })
		  .catch(res => {
			dispatch(fetchAdvertisementsFail(res.data));
		  })
	  }
  }