const inputTypes = {
  Map: "map",
  File: "file",
  Files: "files",
  Basic: "basic",
  Input: "input",
  Select: "select",
  Search: "search",
  Address: "address",
  Checkbox: "checkbox",
  Linkfile: "linkfile",
  Multiselect: "multiselect",
  Singleselect: "singleselect",
  Attachedfile: "attachedfile",
};

const configTypes = {
  Url: "url",
  Text: "text",
  List: "list",
  Number: "number",
};

const section = {
  Basic: "Básico",
  Owner: "Proprietário",
  Property: "Propriedade",
  Address: "Endereço",
  Advertise: "Anúncio",
  Parter: "Parceiro",
};

const buildPostFieldSchema = ({
  config = {},
  validation = {},
  type = undefined,
  value = undefined,
  label = undefined,
  valid = undefined,
  section = undefined,
  touched = undefined,
  dropdown = undefined,
  showWhen = undefined,
  inputType = undefined,
  elementSchema = undefined,
  secondarySection = undefined,
} = {}) => ({
  config: {
    type: config.type,
    readOnly: config.readOnly,
    placeholder: config.placeholder,
  },
  validation: {
    required: validation.required,
    maxLength: validation.maxLength,
    isNumeric: validation.isNumeric,
  },
  type,
  value,
  label,
  valid,
  section,
  touched,
  dropdown,
  showWhen,
  inputType,
  elementSchema,
  secondarySection,
});

const postFieldSchemas = {
  post_title: buildPostFieldSchema({
    label: "Título",
    inputType: inputTypes.Input,
    config: {
      type: configTypes.Text,
    },
    value: undefined,
    validation: {
      required: false,
    },
    valid: false,
    touched: false,
    section: section.Basic,
    secondarySection: section.Basic,
  }),
  post_type: buildPostFieldSchema({
    label: "Tipo do Post",
    inputType: inputTypes.Select,
    config: {
      type: configTypes.Text,
    },
    value: undefined,
    validation: {
      required: false,
    },
    valid: false,
    touched: false,
    section: section.Basic,
    secondarySection: section.Basic,
  }),
};

const postMetasFieldSchemas = {
  tipo_de_servico: buildPostFieldSchema({
    label: "Tipo de serviço",
    inputType: inputTypes.Multiselect,
    config: {
      type: configTypes.List,
    },
    value: undefined,
    validation: {
      required: false,
    },
    valid: false,
    showWhen: [
      {
        field: "post_type",
        value: "prestadora_de_servico",
      },
    ],
    touched: false,
    secondarySection: section.Parter,
    elementSchema: buildPostFieldSchema({
      label: "Tipo de serviço",
      inputType: inputTypes.Checkbox,
      showWhen: [
        {
          field: "post_type",
          value: "prestadora_de_servico",
        },
      ],
      config: {
        type: configTypes.Text,
      },
      value: undefined,
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
      secondarySection: section.Parter,
    }),
  }),
  lancamento: buildPostFieldSchema({
    label: "Lançamento",
    inputType: inputTypes.Checkbox,
    config: {
      type: configTypes.Text,
    },
    value: undefined,
    validation: {
      required: false,
    },
    valid: false,
    touched: false,
    section: section.Property,
  }),
  codigo_imovel: buildPostFieldSchema({
    label: "Código do Imóvel",
    inputType: inputTypes.Input,
    config: {
      type: configTypes.Text,
    },
    value: undefined,
    validation: {
      required: false,
    },
    showWhen: [
      {
        field: "post_type",
        value: "empreendimento",
      },
    ],
    valid: false,
    touched: false,
    section: section.Property,
  }),
  tipo: buildPostFieldSchema({
    label: "Tipo",
    inputType: inputTypes.Select,
    config: {
      type: configTypes.Text,
    },
    value: undefined,
    validation: {
      required: false,
    },
    valid: false,
    touched: false,
    section: section.Property,
  }),
  data_de_entrega: buildPostFieldSchema({
    label: "Data de Entrega",
    inputType: inputTypes.Input,
    config: {
      type: configTypes.Text,
    },
    value: undefined,
    validation: {
      required: false,
    },
    valid: false,
    touched: false,
    section: section.Property,
  }),
  site_propriedade: buildPostFieldSchema({
    label: "Site",
    inputType: inputTypes.Input,
    config: {
      type: configTypes.Text,
    },
    value: undefined,
    validation: {
      required: false,
    },
    valid: false,
    touched: false,
    section: section.Property,
  }),
  prestadora_de_servico: buildPostFieldSchema({
    label: "Prestadora de serviço",
    inputType: inputTypes.Multiselect,
    config: {
      type: configTypes.List,
    },
    value: undefined,
    validation: {
      required: false,
    },
    valid: false,
    touched: false,
    section: section.Property,
    elementSchema: buildPostFieldSchema({
      label: "Prestadora de serviço",
      inputType: inputTypes.Checkbox,
      config: {
        type: configTypes.Text,
      },
      value: undefined,
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
      section: section.Property,
    }),
  }),
  construtora: buildPostFieldSchema({
    label: "Construtora",
    inputType: inputTypes.Select,
    config: {
      type: configTypes.Text,
    },
    value: undefined,
    validation: {
      required: false,
    },
    valid: false,
    touched: false,
    section: section.Property,
  }),
  administradora_condominio: buildPostFieldSchema({
    label: "Administradora",
    inputType: inputTypes.Select,
    config: {
      type: configTypes.Text,
    },
    value: undefined,
    validation: {
      required: false,
    },
    valid: false,
    touched: false,
    section: section.Property,
  }),
  data_administradora_atualizacao: buildPostFieldSchema({
    label: "Data de atualização da administradora",
    inputType: inputTypes.Input,
    config: {
      type: configTypes.Text,
    },
    value: undefined,
    validation: {
      required: false,
    },
    valid: false,
    touched: false,
    section: section.Property,
  }),
  total_unidades: buildPostFieldSchema({
    label: "Total de Unidades",
    inputType: inputTypes.Input,
    config: {
      type: configTypes.Number,
    },
    value: undefined,
    validation: {
      required: false,
    },
    showWhen: [
      {
        field: "tipo",
        value: "Edifícios Residenciais",
      },
    ],
    valid: false,
    touched: false,
    section: section.Property,
  }),
  total_salas: buildPostFieldSchema({
    label: "Total de Salas",
    inputType: inputTypes.Input,
    config: {
      type: configTypes.Number,
    },
    value: undefined,
    validation: {
      required: false,
    },
    showWhen: [
      {
        field: "tipo",
        value: "Edifícios Comerciais",
      },
    ],
    valid: false,
    touched: false,
    section: section.Property,
  }),
  total_de_lotes: buildPostFieldSchema({
    label: "Total de Lotes",
    inputType: inputTypes.Input,
    config: {
      type: configTypes.Text,
    },
    value: undefined,
    validation: {
      required: false,
    },
    showWhen: [
      {
        field: "tipo",
        value: "Loteamentos Comerciais / Industriais",
      },
      {
        field: "tipo",
        value: "Loteamentos Fechados",
      },
    ],
    valid: false,
    touched: false,
    section: section.Property,
  }),
  total_andares: buildPostFieldSchema({
    label: "Total de Andares",
    inputType: inputTypes.Input,
    config: {
      type: configTypes.Number,
    },
    value: undefined,
    validation: {
      required: false,
    },
    showWhen: [
      {
        field: "tipo",
        value: "Edifícios Residenciais",
      },
      {
        field: "tipo",
        value: "Edifícios Comerciais",
      },
    ],
    valid: false,
    touched: false,
    section: section.Property,
  }),
  total_imoveis: buildPostFieldSchema({
    label: "Total de Aptos.",
    inputType: inputTypes.Input,
    config: {
      type: configTypes.Number,
    },
    value: undefined,
    validation: {
      required: false,
    },
    showWhen: [
      {
        field: "tipo",
        value: "Edifícios Residenciais",
      },
      {
        field: "tipo",
        value: "Edifícios Comerciais",
      },
    ],
    valid: false,
    touched: false,
    section: section.Property,
  }),
  total_elevadores: buildPostFieldSchema({
    label: "Total de Elevadores",
    inputType: inputTypes.Input,
    config: {
      type: configTypes.Number,
    },
    value: undefined,
    validation: {
      required: false,
    },
    showWhen: [
      {
        field: "tipo",
        value: "Edifícios Residenciais",
      },
      {
        field: "tipo",
        value: "Edifícios Comerciais",
      },
    ],
    valid: false,
    touched: false,
    section: section.Property,
  }),
  total_torres: buildPostFieldSchema({
    label: "Total de Torres",
    inputType: inputTypes.Input,
    config: {
      type: configTypes.Number,
    },
    value: undefined,
    validation: {
      required: false,
    },
    showWhen: [
      {
        field: "tipo",
        value: "Edifícios Residenciais",
      },
      {
        field: "tipo",
        value: "Edifícios Comerciais",
      },
    ],
    valid: false,
    touched: false,
    section: section.Property,
  }),
  total_blocos: buildPostFieldSchema({
    label: "Total de Blocos",
    inputType: inputTypes.Input,
    config: {
      type: configTypes.Number,
    },
    value: undefined,
    validation: {
      required: false,
    },
    showWhen: [
      {
        field: "tipo",
        value: "Edifícios Residenciais",
      },
      {
        field: "tipo",
        value: "Edifícios Comerciais",
      },
    ],
    valid: false,
    touched: false,
    section: section.Property,
  }),
  portaria: buildPostFieldSchema({
    label: "Portaria",
    inputType: inputTypes.Select,
    config: {
      type: configTypes.List,
    },
    value: undefined,
    validation: {
      required: false,
    },
    showWhen: [
      {
        field: "tipo",
        value: "Edifícios Residenciais",
      },
      {
        field: "tipo",
        value: "Edifícios Comerciais",
      },
    ],
    valid: false,
    touched: false,
    section: section.Property,
    elementSchema: buildPostFieldSchema({
      label: "Portaria",
      inputType: inputTypes.Checkbox,
      config: {
        type: configTypes.Text,
      },
      value: undefined,
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
      section: section.Property,
    }),
  }),
  total_padroes_de_unidade: buildPostFieldSchema({
    label: "Total de Padrões de Unidades",
    inputType: inputTypes.Input,
    config: {
      type: configTypes.Number,
    },
    value: undefined,
    validation: {
      required: false,
    },
    showWhen: [
      {
        field: "tipo",
        value: "Edifícios Residenciais",
      },
    ],
    valid: false,
    touched: false,
    section: section.Property,
  }),
  total_padroes_de_sala: buildPostFieldSchema({
    label: "Total de Padrões de Salas",
    inputType: inputTypes.Input,
    config: {
      type: configTypes.Number,
    },
    value: undefined,
    validation: {
      required: false,
    },
    showWhen: [
      {
        field: "tipo",
        value: "Edifícios Comerciais",
      },
    ],
    valid: false,
    touched: false,
    section: section.Property,
  }),
  tipo_apartamento: buildPostFieldSchema({
    label: "Tipo do apartamento/cobertura",
    inputType: inputTypes.Select,
    config: {
      type: configTypes.Text,
    },
    value: undefined,
    validation: {
      required: false,
    },
    showWhen: [
      {
        field: "tipo",
        value: "Edifícios Residenciais",
      },
    ],
    valid: false,
    touched: false,
    section: section.Property,
  }),
  area: buildPostFieldSchema({
    label: "Área",
    inputType: inputTypes.Input,
    config: {
      type: configTypes.Number,
    },
    value: undefined,
    validation: {
      required: false,
    },
    showWhen: [
      {
        field: "tipo",
        value: "Edifícios Residenciais",
      },
      {
        field: "tipo",
        value: "Edifícios Comerciais",
      },
    ],
    valid: false,
    touched: false,
    section: section.Property,
  }),
  quantidade_dormitorios: buildPostFieldSchema({
    label: "Quantidade de Dormitórios",
    inputType: inputTypes.Input,
    config: {
      type: configTypes.Number,
    },
    value: undefined,
    validation: {
      required: false,
    },
    showWhen: [
      {
        field: "tipo",
        value: "Edifícios Residenciais",
      },
    ],
    valid: false,
    touched: false,
    section: section.Property,
  }),
  quantidade_salas: buildPostFieldSchema({
    label: "Quantidade de Salas",
    inputType: inputTypes.Input,
    config: {
      type: configTypes.Number,
    },
    value: undefined,
    validation: {
      required: false,
    },
    showWhen: [
      {
        field: "tipo",
        value: "Edifícios Comerciais",
      },
    ],
    valid: false,
    touched: false,
    section: section.Property,
  }),
  quantidade_suites: buildPostFieldSchema({
    label: "Quantidade de Suítes",
    inputType: inputTypes.Input,
    config: {
      type: configTypes.Number,
    },
    value: undefined,
    validation: {
      required: false,
    },
    showWhen: [
      {
        field: "tipo",
        value: "Edifícios Residenciais",
      },
    ],
    valid: false,
    touched: false,
    section: section.Property,
  }),
  quantidade_banheiros: buildPostFieldSchema({
    label: "Quantidade de Banheiros",
    inputType: inputTypes.Input,
    config: {
      type: configTypes.Number,
    },
    value: undefined,
    validation: {
      required: false,
    },
    showWhen: [
      {
        field: "tipo",
        value: "Edifícios Residenciais",
      },
      {
        field: "tipo",
        value: "Edifícios Comerciais",
      },
    ],
    valid: false,
    touched: false,
    section: section.Property,
  }),
  vagas_de_garagem: buildPostFieldSchema({
    label: "Vagas de Garagem",
    inputType: inputTypes.Input,
    config: {
      type: configTypes.Number,
    },
    value: undefined,
    validation: {
      required: false,
    },
    showWhen: [
      {
        field: "tipo",
        value: "Edifícios Residenciais",
      },
      {
        field: "tipo",
        value: "Edifícios Comerciais",
      },
    ],
    valid: false,
    touched: false,
    section: section.Property,
  }),
  banheiro_serviço: buildPostFieldSchema({
    label: "Banheiro de serviço",
    inputType: inputTypes.Select,
    config: {
      type: configTypes.Text,
    },
    value: undefined,
    validation: {
      required: false,
    },
    showWhen: [
      {
        field: "tipo",
        value: "Edifícios Residenciais",
      },
    ],
    valid: false,
    touched: false,
    section: section.Property,
  }),
  quarto_serviço: buildPostFieldSchema({
    label: "Quarto de serviço",
    inputType: inputTypes.Select,
    config: {
      type: configTypes.Text,
    },
    value: undefined,
    validation: {
      required: false,
    },
    showWhen: [
      {
        field: "tipo",
        value: "Edifícios Residenciais",
      },
    ],
    valid: false,
    touched: false,
    section: section.Property,
  }),
  vagas_cobertas_garagem: buildPostFieldSchema({
    label: "Vagas Cobertas na Garagem",
    inputType: inputTypes.Input,
    config: {
      type: configTypes.Text,
    },
    value: undefined,
    validation: {
      required: false,
    },
    showWhen: [
      {
        field: "tipo",
        value: "Edifícios Residenciais",
      },
      {
        field: "tipo",
        value: "Edifícios Comerciais",
      },
    ],
    valid: false,
    touched: false,
    section: section.Property,
  }),
  vagas_descobertas_garagem: buildPostFieldSchema({
    label: "Vagas Descobertas na Garagem",
    inputType: inputTypes.Input,
    config: {
      type: configTypes.Text,
    },
    value: undefined,
    validation: {
      required: false,
    },
    showWhen: [
      {
        field: "tipo",
        value: "Edifícios Residenciais",
      },
      {
        field: "tipo",
        value: "Edifícios Comerciais",
      },
    ],
    valid: false,
    touched: false,
    section: section.Property,
  }),
  valor_condominio: buildPostFieldSchema({
    label: "Valor do Condomínio",
    inputType: inputTypes.Input,
    config: {
      type: configTypes.Number,
    },
    showWhen: [
      {
        field: "tipo",
        value: "Edifícios Residenciais",
      },
      {
        field: "tipo",
        value: "Edifícios Comerciais",
      },
    ],
    value: undefined,
    validation: {
      required: false,
    },
    valid: false,
    touched: false,
    section: section.Property,
  }),
  condominio_atualizacao: buildPostFieldSchema({
    label: "Data de Atualização do Condomínio",
    inputType: inputTypes.Input,
    config: {
      type: configTypes.Text,
    },
    value: undefined,
    validation: {
      required: false,
    },
    showWhen: [
      {
        field: "tipo",
        value: "Edifícios Residenciais",
      },
      {
        field: "tipo",
        value: "Edifícios Comerciais",
      },
    ],
    valid: false,
    touched: false,
    section: section.Property,
  }),
  area_lote: buildPostFieldSchema({
    label: "Área Lote",
    inputType: inputTypes.Input,
    config: {
      type: configTypes.Text,
    },
    value: undefined,
    validation: {
      required: false,
    },
    showWhen: [
      {
        field: "tipo",
        value: "Loteamentos Comerciais / Industriais",
      },
      {
        field: "tipo",
        value: "Loteamentos Fechados",
      },
    ],
    valid: false,
    touched: false,
    section: section.Property,
  }),
  area_loteamento: buildPostFieldSchema({
    label: "Área Loteamento",
    inputType: inputTypes.Input,
    config: {
      type: configTypes.Text,
    },
    value: undefined,
    validation: {
      required: false,
    },
    showWhen: [
      {
        field: "tipo",
        value: "Loteamentos Comerciais / Industriais",
      },
      {
        field: "tipo",
        value: "Loteamentos Fechados",
      },
    ],
    valid: false,
    touched: false,
    section: section.Property,
  }),
  area_construida: buildPostFieldSchema({
    label: "Área Construída",
    inputType: inputTypes.Input,
    config: {
      type: configTypes.Text,
    },
    value: undefined,
    validation: {
      required: false,
    },
    showWhen: [
      {
        field: "tipo",
        value: "Loteamentos Comerciais / Industriais",
      },
      {
        field: "tipo",
        value: "Loteamentos Fechados",
      },
    ],
    valid: false,
    touched: false,
    section: section.Property,
  }),
  areas_de_lazer: buildPostFieldSchema({
    label: "Áreas de Lazer",
    inputType: inputTypes.Multiselect,
    config: {
      type: configTypes.List,
    },
    value: undefined,
    validation: {
      required: false,
    },
    valid: false,
    touched: false,
    section: section.Property,
    elementSchema: buildPostFieldSchema({
      label: "Área de Lazer",
      inputType: inputTypes.Checkbox,
      config: {
        type: configTypes.Text,
      },
      value: undefined,
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
      section: section.Property,
    }),
  }),
  // 'hotel': buildPostFieldSchema({
  //   label: 'Hotel',
  //   inputType: inputTypes.Checkbox,
  //   config: {
  //     type: configTypes.Text
  //   },
  //   value: undefined,
  //   validation: {
  //     required: false
  //   },
  //   valid: false,
  //   touched: false,
  //   section: section.Property
  // }),
  // 'anunciante': buildPostFieldSchema({
  //   label: 'Anunciante',
  //   inputType: inputTypes.Checkbox,
  //   config: {
  //     type: configTypes.Text
  //   },
  //   value: undefined,
  //   validation: {
  //     required: false
  //   },
  //   valid: false,
  //   touched: false,
  //   section: section.Property
  // }),
  // 'aceita_minha_casa_minha_vida': buildPostFieldSchema({
  //   label: 'Aceita Minha Casa Minha Vida',
  //   inputType: inputTypes.Checkbox,
  //   config: {
  //     type: configTypes.Text
  //   },
  //   value: undefined,
  //   validation: {
  //     required: false
  //   },
  //   valid: false,
  //   touched: false,
  //   section: section.Property
  // }),
  // 'razao_social': buildPostFieldSchema({
  //   label: 'Razão Social',
  //   inputType: inputTypes.Input,
  //   config: {
  //     type: configTypes.Text
  //   },
  //   value: undefined,
  //   validation: {
  //     required: false
  //   },
  //   valid: false,
  //   touched: false,
  //   section: section.Property
  // }),
  cep: buildPostFieldSchema({
    label: "CEP",
    inputType: inputTypes.Input,
    config: {
      type: configTypes.Text,
    },
    value: undefined,
    validation: {
      required: false,
    },
    valid: false,
    touched: false,
    section: section.Address,
  }),
  logradouro: buildPostFieldSchema({
    label: "Logradouro",
    inputType: inputTypes.Input,
    config: {
      type: configTypes.Text,
    },
    value: undefined,
    validation: {
      required: false,
    },
    valid: false,
    touched: false,
    section: section.Address,
  }),
  nmero: buildPostFieldSchema({
    label: "Número",
    inputType: inputTypes.Input,
    config: {
      type: configTypes.Text,
    },
    value: undefined,
    validation: {
      required: false,
    },
    valid: false,
    touched: false,
    section: section.Address,
  }),
  complemento: buildPostFieldSchema({
    label: "Complemento",
    inputType: inputTypes.Input,
    config: {
      type: configTypes.Text,
    },
    value: undefined,
    validation: {
      required: false,
    },
    valid: false,
    touched: false,
    section: section.Address,
  }),
  bairro: buildPostFieldSchema({
    label: "Bairro",
    inputType: inputTypes.Input,
    config: {
      type: configTypes.Text,
    },
    value: undefined,
    validation: {
      required: false,
    },
    valid: false,
    touched: false,
    section: section.Address,
  }),
  telefone: buildPostFieldSchema({
    label: "Telefone",
    inputType: inputTypes.Input,
    config: {
      type: configTypes.Text,
    },
    value: undefined,
    validation: {
      required: false,
    },
    valid: false,
    touched: false,
    section: section.Owner,
  }),
  email: buildPostFieldSchema({
    label: "Email",
    inputType: inputTypes.Input,
    config: {
      type: configTypes.Text,
    },
    value: undefined,
    validation: {
      required: false,
    },
    valid: false,
    touched: false,
    section: section.Owner,
  }),
  nome_do_contato: buildPostFieldSchema({
    label: "Nome do Contato",
    inputType: inputTypes.Input,
    config: {
      type: configTypes.Text,
    },
    value: undefined,
    validation: {
      required: false,
    },
    valid: false,
    touched: false,
    section: section.Owner,
    secondarySection: section.Parter,
  }),
  email_do_contato: buildPostFieldSchema({
    label: "Email do Contato",
    inputType: inputTypes.Input,
    config: {
      type: configTypes.Text,
    },
    value: undefined,
    validation: {
      required: false,
    },
    valid: false,
    touched: false,
    section: section.Owner,
    secondarySection: section.Parter,
  }),
  celular_de_contato: buildPostFieldSchema({
    label: "Celular do Contato",
    inputType: inputTypes.Input,
    config: {
      type: configTypes.Text,
    },
    value: undefined,
    validation: {
      required: false,
    },
    valid: false,
    touched: false,
    section: section.Owner,
    secondarySection: section.Parter,
  }),
  creci_juridico: buildPostFieldSchema({
    label: "CRECI Jurídico",
    inputType: inputTypes.Input,
    config: {
      type: configTypes.Text,
    },
    value: undefined,
    validation: {
      required: false,
    },
    showWhen: [
      {
        field: "post_type",
        value: "imobiliaria",
      },
    ],
    valid: false,
    touched: false,
    section: section.Owner,
    secondarySection: section.Parter,
  }),
  site: buildPostFieldSchema({
    label: "Site",
    inputType: inputTypes.Input,
    config: {
      type: configTypes.Text,
    },
    value: undefined,
    validation: {
      required: false,
    },
    valid: false,
    touched: false,
    section: section.Owner,
    secondarySection: section.Parter,
  }),
  estado: buildPostFieldSchema({
    label: "Estado",
    inputType: inputTypes.Input,
    config: {
      type: configTypes.Text,
    },
    value: undefined,
    validation: {
      required: false,
    },
    valid: false,
    touched: false,
    section: section.Address,
  }),
  cidade: buildPostFieldSchema({
    label: "Cidade",
    inputType: inputTypes.Select,
    config: {
      type: configTypes.Text,
    },
    value: undefined,
    validation: {
      required: false,
    },
    valid: false,
    touched: false,
    section: section.Address,
    secondarySection: section.Parter,
  }),
  location_coordinates: buildPostFieldSchema({
    label: "Coordenadas",
    inputType: inputTypes.Map,
    config: {
      type: configTypes.List,
    },
    value: undefined,
    validation: {
      required: false,
    },
    valid: false,
    touched: false,
    section: section.Address,
  }),
  _pronamic_google_maps_map_type: buildPostFieldSchema({
    label: "Tipo do Mapa",
    inputType: inputTypes.Map,
    config: {
      type: configTypes.Text,
    },
    value: undefined,
    validation: {
      required: false,
    },
    valid: false,
    touched: false,
    section: section.Address,
  }),
  _pronamic_google_maps_zoom: buildPostFieldSchema({
    label: "Zoom do Mapa",
    inputType: inputTypes.Map,
    config: {
      type: configTypes.Text,
    },
    value: undefined,
    validation: {
      required: false,
    },
    valid: false,
    touched: false,
    section: section.Address,
  }),
  _pronamic_google_maps_address: buildPostFieldSchema({
    label: "Endereço",
    inputType: inputTypes.Address,
    config: {
      type: configTypes.Text,
    },
    value: undefined,
    validation: {
      required: false,
    },
    valid: false,
    touched: false,
    section: section.Address,
  }),
  pmb_sequencia: buildPostFieldSchema({
    label: "PMB Sequência",
    inputType: inputTypes.Input,
    config: {
      type: configTypes.Text,
    },
    value: undefined,
    validation: {
      required: false,
    },
    valid: false,
    touched: false,
    section: section.Property,
  }),
  valor_inicial: buildPostFieldSchema({
    label: "Valor de Venda",
    inputType: inputTypes.Input,
    config: {
      type: configTypes.Text,
    },
    value: undefined,
    validation: {
      required: false,
    },
    valid: false,
    touched: false,
    section: section.Property,
  }),
  valor_locacao: buildPostFieldSchema({
    label: "Valor de Locação",
    inputType: inputTypes.Input,
    config: {
      type: configTypes.Text,
    },
    value: undefined,
    validation: {
      required: false,
    },
    valid: false,
    touched: false,
    section: section.Property,
  }),
  imobiliaria: buildPostFieldSchema({
    label: "Imobiliária",
    inputType: inputTypes.Select,
    config: {
      type: configTypes.Text,
    },
    value: undefined,
    validation: {
      required: false,
    },
    valid: false,
    touched: false,
    section: section.Property,
  }),
  corretora_de_seguro: buildPostFieldSchema({
    label: "Corretora de Seguro",
    inputType: inputTypes.Select,
    config: {
      type: configTypes.Text,
    },
    value: undefined,
    validation: {
      required: false,
    },
    valid: false,
    touched: false,
    section: section.Property,
  }),
  banner_topo: buildPostFieldSchema({
    label: "Banner Topo",
    inputType: inputTypes.Linkfile,
    config: {
      type: configTypes.Text,
    },
    value: undefined,
    validation: {
      required: false,
    },
    valid: false,
    touched: false,
    section: section.Advertise,
  }),
  banner_lateral: buildPostFieldSchema({
    label: "Banner Lateral",
    inputType: inputTypes.Linkfile,
    config: {
      type: configTypes.List,
    },
    value: undefined,
    validation: {
      required: false,
    },
    valid: false,
    touched: false,
    section: section.Advertise,
    elementSchema: buildPostFieldSchema({
      label: "Banner Lateral",
      inputType: inputTypes.File,
      config: {
        type: configTypes.Url,
      },
      value: undefined,
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
      section: section.Advertise,
    }),
  }),
  fotos: buildPostFieldSchema({
    label: "Fotos",
    inputType: inputTypes.Files,
    config: {
      type: configTypes.List,
    },
    value: undefined,
    validation: {
      required: false,
    },
    valid: false,
    touched: false,
    section: section.Property,
    elementSchema: buildPostFieldSchema({
      label: "Foto",
      inputType: inputTypes.File,
      config: {
        type: configTypes.Url,
      },
      value: undefined,
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
      section: section.Property,
    }),
  }),
  planta: buildPostFieldSchema({
    label: "Plantas",
    inputType: inputTypes.Files,
    config: {
      type: configTypes.List,
    },
    value: undefined,
    validation: {
      required: false,
    },
    valid: false,
    touched: false,
    section: section.Property,
    elementSchema: buildPostFieldSchema({
      label: "Planta",
      inputType: inputTypes.File,
      config: {
        type: configTypes.Url,
      },
      value: undefined,
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
      section: section.Property,
    }),
  }),
  _pronamic_google_maps_title: buildPostFieldSchema({
    label: "Título do Mapa",
    inputType: inputTypes.Map,
    config: {
      type: configTypes.Text,
    },
    value: undefined,
    validation: {
      required: false,
    },
    valid: false,
    touched: false,
    section: section.Address,
  }),
  _pronamic_google_maps_description: buildPostFieldSchema({
    label: "Descrição do Mapa",
    inputType: inputTypes.Map,
    config: {
      type: configTypes.Text,
    },
    value: undefined,
    validation: {
      required: false,
    },
    valid: false,
    touched: false,
    section: section.Address,
  }),
  banner_principal: buildPostFieldSchema({
    label: "Banner Principal",
    inputType: inputTypes.Linkfile,
    config: {
      type: configTypes.Text,
    },
    value: undefined,
    validation: {
      required: false,
    },
    valid: false,
    touched: false,
    section: section.Advertise,
    secondarySection: section.Parter,
  }),
  arquivos: buildPostFieldSchema({
    label: "Normas e Regulamentos",
    inputType: inputTypes.Attachedfile,
    config: {
      type: configTypes.List,
    },
    value: undefined,
    validation: {
      required: false,
    },
    valid: false,
    touched: false,
    section: section.Property,
  }),
  banner_de_lancamento: buildPostFieldSchema({
    label: "Banner de Lançamento",
    inputType: inputTypes.File,
    config: {
      type: configTypes.Text,
    },
    value: undefined,
    validation: {
      required: false,
    },
    valid: false,
    touched: false,
    section: section.Advertise,
  }),
  logo_lancamento: buildPostFieldSchema({
    label: "Logo de Lançamento",
    inputType: inputTypes.File,
    config: {
      type: configTypes.Text,
    },
    value: undefined,
    validation: {
      required: false,
    },
    valid: false,
    touched: false,
    section: section.Advertise,
  }),
};

const googleTypes = {
  numero: "street_number",
  logradouro: "route",
  bairro: "sublocality_level_1",
  cidade: "administrative_area_level_2",
  estado: "administrative_area_level_1",
  cep: "postal_code",
};

module.exports = {
  googleTypes,
  postFieldSchemas,
  postMetasFieldSchemas,
  configTypes,
  inputTypes,
  section,
};
