import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { Map, Marker, GoogleApiWrapper } from "google-maps-react";
import { geocodeByAddress } from "react-places-autocomplete";
import Input from "../../UI/Input/Input";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import IconDelete from "@material-ui/icons/Delete";
import ImageUploading from "react-images-uploading";

import Button from "../../UI/Button/Button";

import {
  googleTypes,
  postFieldSchemas,
  postMetasFieldSchemas,
  inputTypes,
  section,
} from "../../../utils/fields";
import { createFormDataImages } from "../../../utils/images";
import { serveImage } from "../../../utils/serveImage";

import "../../../containers/MeusImoveis/Edicao/index.scss";

import AddressInput from "../../map/AddressInput";

const useStyles = makeStyles((theme) => ({
  textField: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  textLink: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  margin: {
    margin: theme.spacing(1),
  },
  button: {
    display: "flex",
    justifyContent: "center",
  },
  input: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "32px",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  buttons: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  images: {
    maxWidth: "200px",
  },
  field: {
    marginBottom: "32px",
  },
}));

function PostForm({
  defaultPost,
  handleSave,
  handleDeletePost,
  google,
  onChange,
  onBasicChange,
}) {
  const classes = useStyles();
  const [post, setPost] = useState(undefined);
  const [postBasic, setPostBasic] = useState(undefined);
  const [tmpImages, setTmpImages] = useState({});
  const [tmpLinks, setTmpLinks] = useState({});
  const [uploadedFiles, setUploadedFiles] = useState([]);

  const getValue = (value) => value.url || value;

  const guardAgainstUndefined = (value) =>
    value !== undefined ? getValue(value) : "";

  const dictToKeyValueArray = (dict) => Object.entries(dict);

  const createFieldWithRepetition = (
    initialLabel,
    finalLabel,
    controlLabel,
    post,
    fieldSchemas,
    showWhenValue
  ) => {
    let postField = {};
    let reading = false;
    let repeatedKeys = [];
    let postOriginal = {};

    dictToKeyValueArray(fieldSchemas).map(([key, value]) => {
      if (key === initialLabel) {
        reading = true;
      }
      if (
        reading &&
        value.showWhen &&
        value.showWhen.some((e) => e.value === showWhenValue)
      ) {
        let enumKey = key + "1";
        postField[enumKey] = { ...value };
        postField[enumKey].label = value.label + " (Tipo 1)";
        repeatedKeys.push(key);
        if (key === finalLabel) {
          reading = false;
          if (
            post[controlLabel] &&
            post[controlLabel] !== undefined &&
            Number(post[controlLabel]) > 0
          ) {
            for (let i = 2; i <= Number(post[controlLabel]); i++) {
              repeatedKeys.forEach((key) => {
                let enumKey2 = key + i.toString();
                postField[enumKey2] = { ...fieldSchemas[key] };
                postField[enumKey2].label =
                  fieldSchemas[key].label + ` (Tipo ${i})`;
              });
            }
          }
        }
      } else {
        postField[key] = { ...value };
        postOriginal[key] = { ...value };
      }
      return [key, value];
    });

    return post[controlLabel] &&
      post[controlLabel] !== undefined &&
      Number(post[controlLabel]) > 0
      ? postField
      : postOriginal;
  };

  const createPostFields = (post, fieldSchemas) => {
    let postFields = createFieldWithRepetition(
      "tipo_apartamento",
      "condominio_atualizacao",
      "total_padroes_de_unidade",
      post,
      { ...fieldSchemas },
      "Edifícios Residenciais"
    );

    for (const [key, value] of dictToKeyValueArray(post)) {
      if (key in postFields) {
        if (value || value === false || value === "") {
          if (value.value) {
            postFields[key].value = value.value;
          } else {
            if (value.options === undefined) {
              postFields[key].value = value;
            }
          }

          if (value.options) {
            postFields[key].config.options = value.options;
          }
        }
      } else {
        for (const adaptedKey of Object.keys(postFields)) {
          let generalKey = adaptedKey.slice(0, -1);
          if (generalKey === key && value.options) {
            postFields[adaptedKey].config.options = value.options;
          }
        }
        if (
          key === "padroes_de_unidade" &&
          Array.isArray(value) &&
          value.length > 0
        ) {
          let keyWithType = "";
          value.forEach((tipoUnidade, index) => {
            Object.entries(tipoUnidade).map(([keyy, valuee]) => {
              keyWithType = keyy + `${index + 1}`;
              postFields[keyWithType].value =
                typeof valuee === "object" && valuee !== null
                  ? { ...valuee }
                  : valuee;
              return key;
            });
          });
        }
      }
    }
    return postFields;
  };

  useEffect(() => {
    if (defaultPost) {
      const postFields = createPostFields(
        {
          ...defaultPost.metas,
        },
        postMetasFieldSchemas
      );

      setPostBasic(
        createPostFields(
          {
            post_title: defaultPost.post_title,
            post_type: defaultPost.post_type,
          },
          postFieldSchemas
        )
      );

      setPost(postFields);
    }
    // eslint-disable-next-line
  }, [defaultPost]);

  const onMapClick = (event, map) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();

    if (lat && lng) {
      onChange("location_coordinates", [lng, lat]);
    }
  };

  const onChangeAddress = (address) =>
    onChange("_pronamic_google_maps_address", address);

  const findComponentByType = (components, type) =>
    guardAgainstUndefined(
      components.find((component) => component.types.includes(type))
    );

  const onPlaceSelected = (fullAddress) => {
    geocodeByAddress(fullAddress).then((result) => {
      const place = result[0];
      const latitude = place.geometry.location.lat();
      const longitude = place.geometry.location.lng();
      const address = place.formatted_address;

      const selectedGoogleTypes = {};

      dictToKeyValueArray(googleTypes).forEach(([key, type]) => {
        selectedGoogleTypes[key] = findComponentByType(
          place.address_components,
          type
        );
      });

      const { numero, logradouro, bairro, cep, estado, cidade } =
        selectedGoogleTypes;

      onChange("complemento", undefined);
      onChange("cep", cep.long_name);
      onChange("logradouro", logradouro.long_name);
      onChange("nmero", numero.long_name);
      onChange("bairro", bairro.long_name);
      onChange("estado", estado.short_name);
      onChange("cidade", cidade.long_name.toLowerCase());
      onChange("location_coordinates", [longitude, latitude]);
      onChange("_pronamic_google_maps_address", address);
    });
  };

  const createMapInput = (fields) => {
    const coordinates = fields.location_coordinates;
    const zoom = fields._pronamic_google_maps_zoom;

    let map = undefined;

    if (
      coordinates &&
      zoom &&
      coordinates.value &&
      zoom.value &&
      coordinates.value.length > 0
    ) {
      map = {
        keys: {
          coodinates: "location_coordinates",
        },
        position: {
          lat: Number(coordinates.value[1]),
          lng: Number(coordinates.value[0]),
        },
        zoom: parseInt(zoom.value),
      };
    }

    if (map) {
      return (
        <div style={{ height: "600px" }} className={classes.field}>
          <Map
            containerStyle={{
              height: "583px",
              maxWidth: "700px",
              width: "96%",
            }}
            google={google}
            center={map.position}
            initialCenter={map.position}
            onClick={(_props, _marker, event) => onMapClick(event, map)}
            zoom={map.zoom}
          >
            <Marker name={"Current location"} position={map.position} />
          </Map>
        </div>
      );
    }

    return undefined;
  };

  const createAddressInput = (fields) => {
    const address = fields._pronamic_google_maps_address;
    const coordinates = fields.location_coordinates;
    const latitude =
      coordinates &&
      coordinates.value &&
      coordinates.value.length > 0 &&
      coordinates.value[1];
    const longitude =
      coordinates &&
      coordinates.value &&
      coordinates.value.length > 0 &&
      coordinates.value[0];
    const position = {
      lat: latitude,
      lng: longitude,
    };

    return (
      address && (
        <div className={classes.field}>
          <AddressInput
            onPlaceSelected={onPlaceSelected}
            onChange={onChangeAddress}
            address={address.value}
            position={position}
          />
        </div>
      )
    );
  };

  const onUploadFile = (key, images) =>
    setTmpImages({
      ...tmpImages,
      [key]: images,
    });

  const onChangeLink = (key, link) =>
    setTmpLinks({
      ...tmpLinks,
      [key]: link,
    });

  const createAttachedFile = (key, value) => {
    const MAX_COUNT = 5;
    console.log(value);
    const handleFileEvent = (e) => {
      const chosenFiles = Array.prototype.slice.call(e.target.files);
      console.log(uploadedFiles);
      handleUploadFiles(chosenFiles);
    };
    const handleUploadFiles = (files) => {
      const uploaded = [];
      let limitExceeded = false;

      files.some((file) => {
        let reader = new FileReader();
        const toUpload = {};

        if (uploaded.findIndex((f) => f.name === file.name) === -1) {
          toUpload.file = file;
          if (uploaded.length > MAX_COUNT) {
            alert(`O número maximo de upload é de ${MAX_COUNT} arquivos`);
            limitExceeded = true;
            return true;
          }
        }

        reader.onload = (file) => {
          toUpload.dataURL = file.target.result;
          uploaded.push(toUpload);
        };

        reader.readAsDataURL(file);

        return true;
      });
      if (!limitExceeded) setUploadedFiles(uploaded);
    };

    return (
      <div className={classes.input}>
        <label>{value.label}</label>
        {post.arquivos &&
          post.arquivos.value &&
          post.arquivos.value.length > 0 &&
          post.arquivos.value.map((file) => (
            <a href={file.url} target="_blank" rel="noopener noreferrer">
              {file.url}
            </a>
          ))}
        <input
          type="file"
          id="button-file"
          name="arquivos"
          multiple
          onChange={handleFileEvent}
        />
        {uploadedFiles && uploadedFiles.map((file) => <p>{file.name}</p>)}
      </div>
    );
  };

  const createFilesComponent = (
    key,
    value,
    multiple = true,
    maxNumber = 12
  ) => {
    let images = value.value;

    if (!images) {
      images = [];
    }

    if (!Array.isArray(images)) {
      images = [images];
    }

    return (
      <div className={classes.field}>
        <label>{value.label}</label>
        <div className="ImagensList">
          {images.map((image) => {
            const img = (
              <img
                src={serveImage(image.url)}
                alt="Imagem"
                className={classes.images}
              />
            );
            if (image.link) {
              return (
                <a href={image.link} target="_blank" rel="noopener noreferrer">
                  {img}
                </a>
              );
            }
            return img;
          })}
        </div>

        <ImageUploading
          multiple={multiple}
          onChange={(tmpImages) => onUploadFile(key, tmpImages)}
          maxNumber={maxNumber}
        >
          {({ imageList, onImageUpload, onImageRemoveAll }) => (
            <>
              <div className="ImagesUpload">
                {images.length === 0 && <h4>Sem fotos</h4>}
                <br />
                <button onClick={onImageUpload} type="button">
                  Adicionar Fotos
                </button>
              </div>
              {imageList.length > 0 && (
                <div className="ImagensList">
                  {imageList.map((image) => (
                    <div key={image.key}>
                      <img
                        src={image.dataURL}
                        alt="Imagem"
                        className={classes.images}
                      />
                      <button onClick={image.onRemove} type="button">
                        <IconDelete alt="Deletar foto" />
                      </button>
                      {createInputComponent(
                        key,
                        {
                          label: "Link da Imagem",
                          value: tmpLinks[key],
                        },
                        (event) => onChangeLink(key, event.target.value)
                      )}
                    </div>
                  ))}

                  <div className="Actions">
                    <button onClick={onImageRemoveAll} type="button">
                      Remover todas as Fotos
                    </button>
                  </div>
                </div>
              )}
            </>
          )}
        </ImageUploading>
      </div>
    );
  };

  const createInputComponent = (key, value, changed) => (
    <Input
      id={key}
      key={key}
      changed={changed}
      name={value.label}
      label={value.label}
      invalid={!value.valid}
      touched={value.touched}
      elementConfig={value.config}
      elementType={value.inputType}
      shouldValidate={value.validation}
      value={guardAgainstUndefined(value.value)}
    />
  );

  const buildFormatFunctions = () => ({
    checkbox: (key, value) => {
      const changed = (event) => onChange(key, event.target.checked);
      return createInputComponent(key, value, changed);
    },
    multiselect: (key, value) => {
      const changed = (event) =>
        onChange(event.target.name, event.target.value);
      return createInputComponent(key, value, changed);
    },
    text: (key, value) => {
      let changed = (event) => onChange(key, event.target.value);
      if (value.section === section.Basic) {
        changed = (event) => onBasicChange(key, event.target.value);
      }
      return createInputComponent(key, value, changed);
    },
    files: (key, value) => createFilesComponent(key, value),
    file: (key, value) => createFilesComponent(key, value, false, 1),
    linkFile: (key, value) => createFilesComponent(key, value, false, 1),
    attachedFile: (key, value) => createAttachedFile(key, value),
  });

  const fieldsToInputs = (fields) => {
    const formatFunctions = buildFormatFunctions();

    const fieldFormatter = {
      [inputTypes.Checkbox]: {
        function: formatFunctions.checkbox,
      },
      [inputTypes.Select]: {
        function: formatFunctions.text,
      },
      [inputTypes.Multiselect]: {
        function: formatFunctions.multiselect,
      },
      [inputTypes.Singleselect]: {
        function: formatFunctions.multiselect,
      },
      [inputTypes.Input]: {
        function: formatFunctions.text,
      },
      [inputTypes.Files]: {
        function: formatFunctions.files,
      },
      [inputTypes.File]: {
        function: formatFunctions.file,
      },
      [inputTypes.Linkfile]: {
        function: formatFunctions.linkFile,
      },
      [inputTypes.Attachedfile]: {
        function: formatFunctions.attachedFile,
      },
    };

    dictToKeyValueArray(fields).map(([_key, value]) => {
      if (value.inputType in fieldFormatter) {
        value["function"] = fieldFormatter[value.inputType].function;
      }
      return undefined;
    });

    const inputs = [createAddressInput(fields), createMapInput(fields)];
    console.log(inputs);
    return inputs.concat(
      dictToKeyValueArray(fields).map(
        ([key, value]) => value["function"] && value["function"](key, value)
      )
    );
  };

  const createSectionDropdown = (fields, label) =>
    fields && (
      <Accordion key={"dropdown_" + label}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          {label}
        </AccordionSummary>

        <AccordionDetails style={{ padding: "0px" }}>
          <div className="row Form-center">
            <div className="col-sm-12">{fieldsToInputs(fields)}</div>
          </div>
        </AccordionDetails>
      </Accordion>
    );

  const checkShowField = (showWhen) => {
    var show = false;
    showWhen.map((rule) => {
      if (
        (post[rule.field] &&
          post[rule.field].value &&
          post[rule.field].value.name === rule.value) ||
        (postBasic[rule.field] && postBasic[rule.field].value === rule.value)
      ) {
        show = true;
      }
      return undefined;
    });

    return show;
  };

  const addFieldInDropdownSection = (dropdownSections, type, key, value) => {
    if (
      value.showWhen === undefined ||
      checkShowField(value.showWhen) === true
    ) {
      dropdownSections[value[type]] = {
        ...dropdownSections[value[type]],
        [key]: value,
      };
    }
  };

  const checkPostType = () => {
    return (
      postBasic.post_type.value &&
      postBasic.post_type.value !== "empreendimento"
    );
  };

  const createDropdown = (fields) => {
    const dropdownSections = {};

    if (checkPostType()) {
      dictToKeyValueArray(fields).map(([key, value]) => {
        if (value.secondarySection) {
          addFieldInDropdownSection(
            dropdownSections,
            "secondarySection",
            key,
            value
          );
        }
        return undefined;
      });
    } else {
      dictToKeyValueArray(fields).map(([key, value]) => {
        if (value) {
          addFieldInDropdownSection(dropdownSections, "section", key, value);
        }
        return undefined;
      });
    }

    return dictToKeyValueArray(dropdownSections).map(([key, value]) =>
      createSectionDropdown(value, key)
    );
  };

  const saveButton = () => {
    const postWithImages = {
      post: {
        _id: defaultPost._id,
        post_title: postBasic.post_title.value,
        post_type: postBasic.post_type && postBasic.post_type.value,
        metas: post,
      },
    };

    dictToKeyValueArray({
      ...tmpImages,
      ...{
        arquivos: uploadedFiles,
      },
    }).map(([key, value]) => {
      postWithImages[key] = value;
      return undefined;
    });

    dictToKeyValueArray(postWithImages.post.metas).map(([key, value]) => {
      postWithImages.post.metas[key] = value ? value.value : value;
      return undefined;
    });

    let formData = new FormData();

    Object.entries(postWithImages).map(([key, value]) => {
      if (key === "arquivos") {
        formData = createFormDataImages(key, formData, value, "");
      } else if (key !== "post") {
        formData = createFormDataImages(key, formData, value);
      }
      return null;
    });

    formData.append("post", JSON.stringify(postWithImages.post));

    formData.append("links", JSON.stringify(tmpLinks));

    handleSave(postWithImages.post._id, formData);
  };

  return (
    <React.Fragment>
      {post && (
        <Grid>
          <Typography
            component="h1"
            variant="body1"
            align="center"
            className={classes.textField}
          >
            Informações
          </Typography>
          <div className="FormLeft">
            {createDropdown(postBasic)}
            {createDropdown(post)}
          </div>
        </Grid>
      )}
      <div className={classes.buttons}>
        {defaultPost && (
          <Button btnType="Excluir" clicked={() => handleDeletePost(defaultPost._id)}>
            Excluir
          </Button>
        )}
        <Button clicked={saveButton}>Salvar</Button>
      </div>
    </React.Fragment>
  );
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyCRrwsJQfUXOsVKnI3rg3cWe5bRjWXaEIA",
})(PostForm);
