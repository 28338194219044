/* eslint react/prop-types: 0 */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import styled from '@emotion/styled'
import { InputNumber, Button } from 'antd'

export const AddressInputContainer = styled.div`
  position: relative;
  .ant-list {
    background: rgba(255,255,255,.95);
    position: absolute;
    width: 100%;
    z-index: 1;
    .suggestion-item {
      &--active {
        cursor: pointer;
        color: #1890ff;
      }
    }
  }
`

const cnpjInputStyle = css`
  .ant-input-number-handler-wrap { display: none; }
`

const photoInputStyle = css`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  > * {
    flex: 1;
    text-align: center;
    padding: .25rem;
    .ant-btn,
    .ant-upload {
      width: 100%;
      min-width: 10rem;
    }
  }
`

const photoStyle = css`
  position: relative;
  width: 100%;
  background: #333;
  text-align: center;
  img {
    width: auto;
    height: auto;
    max-height: 15rem;
    max-width: 100%;
  }
  button {
    position: absolute;
    top: .5rem;
    right: .5rem;
  }
`

const pocketItemStyle = (filled, selected, color) => css`
  width: 100%;
  .ant-btn-group {
    width: 100%;
    display: flex;
    .ant-btn {
      ${filled && css`
        background-color: ${color};
        color: #fff;
      `}
      ${!selected && css`opacity: .5;`}
      transition: all .3s ease-out;
      text-align: left;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-right: 2em;
      :nth-of-type(2) { flex: 1; }
      &:active, &:focus, &:hover {
        border-color: ${color};
        ${!filled && css`color: ${color};`}
      }
    }
  }
`

const counterStyle = css`
  .ant-tabs-nav { width: 0; }
`

const counterItemStyle = (filled, selected, color) => css`
  display: flex;
  width: 100%;
  .ant-btn-group {
    width: 100%;
    display: flex;
    .ant-btn {
      ${filled && css`
        background-color: ${color};
        color: #fff;
      `}
      ${!selected && css`opacity: .5;`}
      transition: none;
      text-align: left;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-right: 2em;
      :nth-of-type(2) { flex: 1; }
      &:active, &:focus, &:hover {
        border-color: ${color};
        ${!filled && css`color: ${color};`}
      }
    }
  }
`

const counterLabelStyle = css`
  width: 100%;
`

const counterNumberStyle = css`
  position: absolute !important;
  padding: 0 .5rem;
  right: 0;
`

const addItemButtonStyle = css`
  width: 100%;
`

export const StyledCNPJInput = props => (
  <InputNumber {...props} css={cnpjInputStyle} />
)

export const StyledPhotoInput = props => (
  <div {...props} css={photoInputStyle} />
)

export const Photo = props => (
  <div css={photoStyle}>
    <img {...props} alt='capture' />
    {props.close}
  </div>
)

export const StyledPocketItemInput = props => (
  <div css={pocketItemStyle(props.filled, props.selected, props.color)}>
    {props.children}
  </div>
)

export const StyledCounterInput = props => (
  <div css={counterStyle} {...props} />
)

export const StyledCounterItemInput = props => (
  <div css={counterItemStyle(props.filled, props.selected, props.color)}>
    {props.children}
  </div>
)

export const CounterLabel = props => (
  <span css={counterLabelStyle} {...props} />
)

export const CounterNumber = props => (
  <span css={counterNumberStyle} {...props} />
)

export const InvisibleInput = styled.input`
  background: transparent;
  border: none;
  outline: none;
  width: 100%;
`

export const DynamicFieldItem = styled.div`
  display: flex;
  margin-bottom: 1rem;
  > div:first-of-type { flex: 1; }
  .ant-form-item { margin-bottom: 0; }
`

export const AddItemButton = props => (
  <Button
    css={addItemButtonStyle}
    type='dashed'
    icon='plus'
    {...props}
  >
    Adicionar item
  </Button>
)
