import React, { Component } from 'react';
import { connect } from "react-redux";
import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import PostForm from '../../components/PostForm/Forms/PostForm';

import * as actions from "../../store/actions/index";

const useStyles = (theme) => ({
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
});

class AdminCreatePostForm extends Component {
  componentDidMount() {
    this.props.onFetchPostAdmin('');
  }

  handleSavePost = (id, post) => {
    this.props.handleSavePost(undefined, post);
  }

  render () {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <CssBaseline />
        <main className={classes.layout}>
          <Paper className={classes.paper}>
            <PostForm
              {...this.props}
              handleSave={this.handleSavePost}
              history={this.props.history}
              onChange={this.props.onUpdatePost}
              defaultPost={this.props.post}
              onBasicChange={this.props.onBasicUpdatePost}
            />
          </Paper>
        </main>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    post: state.post
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFetchPostAdmin: (id) => dispatch(actions.fetchPostAdmin(id)),
    handleSavePost: (id, post) => dispatch(actions.savePost(id, post)),
    handleDeletePost: (id) => dispatch(actions.deletePost(id)),
    onUpdatePost: (key, value) => dispatch(actions.updatePost(key, value)),
    onBasicUpdatePost: (key, value) => dispatch(actions.updateBasicPost(key, value))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(useStyles)(AdminCreatePostForm));
