import React, { Component } from 'react';
import { connect } from "react-redux";
import FindButton from '../../components/UI/FindButton/FindButton';
import Footer from "../../components/UI/Footer";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Helmet } from 'react-helmet';

import * as actions from "../../store/actions/index";

const useStyles = (theme) => ({
  root: {
    flexGrow: 1
  },
  title: {
    display: 'flex',
    justifyContent: 'flex-start',
    color: '#003a70',
    fontSize: '30px' 
  },
  text: {
    display: 'flex',
    justifyContent: 'flex-start',
    color: '#57574d',
    fontSize: '20px'
  },
  formText: {
    display: 'flex',
    justifyContent: 'flex-start',
    color: '#000000',
    fontSize: '16px'
  },
  formBox: {
    width: '100%',
    height: '34px'
  },
  messageBox: {
    width: '100%'
  }
});

class Advertise extends Component {

  classes = makeStyles((theme) => ({
    root: {
      flexGrow: 1
    },
    root2: {
      maxWidth: 345,
    },
    media: {
      height: 140,
    },
    paper: {
      textAlign: "center",
      color: theme.palette.text.secondary
    }
  }));

  state = {
    city: window.localStorage.getItem('city') ? window.localStorage.getItem('city') : '',
    type: '',
    name: '',
    email: '',
    phone: '',
    message: ''
  };

  handleChangeCity = (event) => {
    const city = event.target.value;
    if (city) window.localStorage.setItem('city', city)
    this.setState({
      city: event.target.value,
    });
  }

  handleChangeType = (event) => {
    this.setState({
      type: event.target.value,
    });
  }

  handleChangeName = (event) => {
    this.setState({
      name: event.target.value,
    });
  }

  handleChangeEmail = (event) => {
    this.setState({
      email: event.target.value,
    });
  }

  handleChangePhone = (event) => {
    this.setState({
      phone: event.target.value,
    });
  }

  handleChangeMessage = (event) => {
    this.setState({
      message: event.target.value,
    });
  }

  sendMessage = () => {
    console.log({
      city: this.state.city,
      business_type: this.state.type,
      name: this.state.name,
      email: this.state.email,
      phone: this.state.phone,
      message: this.state.message
    })
    this.props.onSendAnnouncementMessage({
      city: this.state.city,
      business_type: this.state.type,
      name: this.state.name,
      email: this.state.email,
      phone: this.state.phone,
      message: this.state.message
    })
  }

  render() {
    const { classes } = this.props;

    return (
      <>
        <Helmet>
          <title>Contato — Imobilize</title>
        </Helmet>
        <div style={{ backgroundColor: '#ffffff', padding: '30px' }}>
          <div className={classes.root} style={{ display: 'flex', justifyContent: 'center' }}>
            <div>
              <span className={classes.title}>Contato</span><br /><br />
              <span className={classes.text}>Preencha seus dados no formulário abaixo que em breve entraremos em contato.</span><br /><br />
              <span className={classes.formText}><b>Nome<span style={{ color: 'red' }}>*</span>:</b></span>
              <input className={classes.formBox} value={this.state.name} onChange={this.handleChangeName}></input><br /><br />
              <span className={classes.formText}><b>Email<span style={{ color: 'red' }}>*</span>:</b></span>
              <input className={classes.formBox} value={this.state.email} onChange={this.handleChangeEmail}></input><br /><br />
              <span className={classes.formText}><b>Seu Celular (com DDD)<span style={{ color: 'red' }}>*</span>:</b></span>
              <input className={classes.formBox} value={this.state.phone} onChange={this.handleChangePhone}></input><br /><br />
              <span className={classes.formText}><b>Mensagem:</b></span>
              <textarea rows={10} className={classes.messageBox} value={this.state.message} onChange={this.handleChangeMessage}></textarea><br /><br />
              <FindButton clicked={this.sendMessage}>Enviar</FindButton>
            </div>
          </div>
        </div>
        {/* <br />
        <div style={{ width: '728px', height: '300px', borderStyle: 'solid', borderColor: '#dcdcdc', margin: 'auto' }}>anúncio</div>
        <br /> */}
        <Footer />
      </>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onSendAnnouncementMessage: (data) => dispatch(actions.sendAnnouncementMessage(data))
  };
};

export default connect(
  null,
  mapDispatchToProps
)(withStyles(useStyles)(Advertise));
