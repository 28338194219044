import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {compose} from 'redux'
import withScript from '../map/withScript'
import {Spin} from 'antd'
import Select from 'react-select';
import api from "../../services/api";
import NavigationItem from "../Navigation/NavigationItems/NavigationItem/NavigationItem";
import CustomizedInputBase from "./searchBar";
import VirtualizedList from "./searchList";
import SearchBarIm from "./searchBarIm";
import SearchBarCond from "./searchBarCond";

class AddressVenal extends Component {
    _isMounted = false

    static propTypes = {
        onPlaceSelected: PropTypes.func.isRequired,
        onQuadrinhaSelected: PropTypes.func.isRequired,
        onChange: PropTypes.func.isRequired,
        onReset: PropTypes.func.isRequired,
        resetImovel: PropTypes.func.isRequired,
        resetQuadrinha: PropTypes.func,
        loading: PropTypes.bool,
        address: PropTypes.object,
        position: PropTypes.any,
    }

    state = {
        options: [],
        optionsIm: [],
        optionsCondo: [],
        optionsBlock: [],
        optionsLot: [],
        optionsQuadSetor: [],
        optionsQuadQuad: [],
        show: false,
        showList: false,
        showBlock: false,
        showLot: false,
        showQuadQuad: false,
        end: window.localStorage.getItem('cityVenal') ? true : false,
        cond: false,
        quad: false,
        value: '',
        valueCondo: '',
        valueBlock: '',
        valueLot: '',
        valueQuadSetor: '',
        valueQuadQuad: '',
        selected: window.localStorage.getItem('cityVenal') ? window.localStorage.getItem('cityVenal') : null
    }

    componentDidMount() {
        this._isMounted = true
        console.log()
        // this.setState({selected: window.localStorage.getItem('city')})
        if (this._isMounted) {
            let addressStorage = window.localStorage.getItem('address')
            if (addressStorage !== null && addressStorage !== undefined) {
                this.setState({address: addressStorage})
                window.localStorage.removeItem('address')
            }
        }
    }

    componentWillUnmount() {
        this._isMounted = false
    }

    changeCity(e) {
        const city = e.value;
        if (city) window.localStorage.setItem('cityVenal', city)
        this.setState({selected: e.value})
    }

    resetState() {
        this.setState({
            options: [],
            optionsCondo: [],
            optionsBlock: [],
            optionsLot: [],
            show: false,
            showList: false,
            showBlock: false,
            showLot: false,
            value: '',
            valueCondo: '',
            valueBlock: '',
            valueLot: '',
            optionsQuadSetor: [],
            optionsQuadQuad: [],
            showQuadQuad: false,
            valueQuadSetor: '',
            valueQuadQuad: '',
        })
    }

    loadCondos() {
        let optionsCondo = []

        api.get('/properties/autocomplete/condominium?city=' + this.state.selected + '&name=&block=&lot=')
            .then(res => {
                    for (let i = 0; i < res.data.label.length; i++) {
                        optionsCondo.push({value: res.data.label[i], label: res.data.label[i], city: this.state.selected})
                    }
                this.setState({optionsCondo: optionsCondo})
            })
            .catch(err => {
                console.log(err)
            })
    }

    loadSetores() {
        let optionsQuadSetor = []

        api.get('/properties/autocomplete/quadrinhas?city=' + this.state.selected + '&block=&lot=')
            .then(res => {
                for (let i = 0; i < res.data.label.length; i++) {
                    optionsQuadSetor.push({value: res.data.label[i], label: res.data.label[i], city: this.state.selected})
                }
                this.setState({optionsQuadSetor: optionsQuadSetor})
            })
            .catch(err => {
                console.log(err)
            })
    }

    render() {
        const {
            onPlaceSelected
        } = this.props
        const {
            selected,
            show,
            showBlock,
            showLot,
            end,
            cond,
            quad,
        } = this.state

        // var endRef = React.useRef(null);

        return (
            <>
                <div className={'form-group FormSelect'}>
                    <label>Cidade</label>

                    <Select
                        label={'Cidade'}
                        isSearchable
                        noOptionsMessage={function noOptionsMessage() {
                            return 'Nenhuma Opção';
                        }}
                        loadingMessage={function loadingMessage() {
                            return 'Buscando...';
                        }}
                        defaultValue={this.state.selected ? {
                            value: this.state.selected,
                            label: this.state.selected
                        } : {
                            value: '',
                            label: '-- Selecione uma Cidade --'
                        }}
                        placeholder={'Escolha a cidade'}
                        options={[
                            {value: '', label: '-- Selecione uma Cidade --'},
                            {value: 'Bauru', label: 'Bauru'},
                            {value: 'Santos', label: 'Santos'},
                            {value: 'Araraquara', label: 'Araraquara'}
                        ]}
                        onChange={(event) => {this.changeCity(event); this.props.resetImovel(); this.resetState();
                        this.setState({end: true,
                            cond: false,
                            quad: false})}
                        }
                    />
                </div>
                <br/>
                {selected && selected === "Bauru" && <div>
                <>
                    <ul className="NavigationItems">
                        <NavigationItem className="menu-fx" link={window.location.pathname} click={() => {this.setState({end: true, cond: false, quad: false}); this.props.resetImovel(); this.resetState()}} clicked={end}>Endereço</NavigationItem>
                        <NavigationItem className="menu-fx" link={window.location.pathname} click={() => {this.setState({end: false, cond: true, quad: false}); this.props.resetImovel(); this.resetState(); this.loadCondos()}} clicked={cond}>Condomínio</NavigationItem>
                        <NavigationItem className="menu-fx" link={window.location.pathname} click={() => {this.setState({end: false, cond: false, quad: true}); this.props.resetImovel(); this.resetState(); this.loadSetores()}} clicked={quad}>Quadrinhas</NavigationItem>
                    </ul>
                </>

                <br/>
                    {
                        end && <div>
                            {
                                
                            <div className={'form-group FormSelect'}>
                                <label>Busca por Endereço </label><Spin spinning={show}>
                                {/* <AsyncSelect
                                    label={'Endereço'}
                                    noOptionsMessage={function noOptionsMessage() {
                                        return 'Nenhuma Opção';
                                    }}
                                    loadingMessage={function loadingMessage() {
                                        return 'Buscando...';
                                    }}
                                    // cacheOptions
                                    isSearchable
                                    placeholder={'Digite o endereço'}
                                    defaultOptions
                                    loadOptions={this.promiseOptions}
                                    // value={this.state.value}
                                    onInputChange={e => {this.setState({value: e}); this.onInputChange(e)}}
                                    // onChange={e => {this.setState({value: e}); this.inputClear(); this.onInputChange(e)}}
                                    // onInputChange={e => this.onInputChange(e)}
                                    // onChange={onPlaceSelected}
                                /> */}
                                <CustomizedInputBase selected={this.state.selected} clickAuto={this.onInputChange} clickSubmit={this.onInputSubmit} onPlaceSelected={onPlaceSelected} load={this.promiseOptions} />
                                {this.state.showList ? <VirtualizedList value={this.state.value} options={this.state.options} onPlaceSelected={onPlaceSelected} /> : ""}
                            </Spin></div>}
                            {/* this.setState({show: true, options: options, value: e}) */}
                            {<div className={'form-group FormSelect'}>
                                <label>Busca por Inscrição </label><Spin spinning={show}>
                                {/* <AsyncSelect
                                    label={'Inscrição Municipal'}
                                    noOptionsMessage={function noOptionsMessage() {
                                        return 'Nenhuma Opção';
                                    }}
                                    loadingMessage={function loadingMessage() {
                                        return 'Buscando...';
                                    }}
                                    isSearchable
                                    placeholder={'Digite a inscrição municipal'}
                                    defaultOptions
                                    loadOptions={this.promiseOptionsIm}
                                    onInputChange={e => this.onInputChangeIm(e)}
                                    onChange={onPlaceSelected}
                                /> */}
                                <SearchBarIm clickAuto={this.onInputChangeIm} clickSubmit={onPlaceSelected} load={this.promiseOptionsIm} placeholder={""} />
                            </Spin></div>}
                        </div>
                    }

                    {cond && <div>
                            {<div className={'form-group FormSelect'}>
                                <label>Selecionar Condomínio </label><Spin spinning={showBlock}>
                                {/* <AsyncSelect
                                    label={'Selecionar Condomínio'}
                                    noOptionsMessage={function noOptionsMessage() {
                                        return 'Nenhuma Opção';
                                    }}
                                    loadingMessage={function loadingMessage() {
                                        return 'Buscando...';
                                    }}
                                    isSearchable
                                    placeholder={'Digite o condomínio'}
                                    defaultOptions
                                    loadOptions={this.promiseOptionsCondo}
                                    // onInputChange={e => this.onInputChangeCondo(e)}
                                    onChange={(e) => {this.setState({valueCondo: e.value, valueBlock: '', valueLot: ''}); this.onInputChangeCondo(e)}}
                                /> */}
                                <SearchBarCond clickAuto={this.onInputChangeCondo} clickSubmit={(e) => {this.setState({valueCondo: e, valueBlock: '', valueLot: ''}); this.onInputSubmitCondo(e)}} load={this.promiseOptions} placeholder={"Selecione o condomínio"} />
                            </Spin></div>}

                            {this.state.optionsBlock.length > 0 && <div className={'form-group FormSelect'}>
                                <label>Selecionar Quadra </label><Spin spinning={showBlock}>
                                {/* <AsyncSelect
                                    label={'Selecionar Quadra'}
                                    noOptionsMessage={function noOptionsMessage() {
                                        return 'Nenhuma Opção';
                                    }}
                                    loadingMessage={function loadingMessage() {
                                        return 'Buscando...';
                                    }}
                                    isSearchable
                                    placeholder={'Selecione a quadra'}
                                    defaultOptions
                                    loadOptions={this.promiseOptionsBlock}
                                    // onInputChange={(e) => this.onInputChangeBlock(e)}
                                    onChange={(e) => {this.setState({showLot: true, valueBlock: e.value}); this.onInputChangeBlock(e)}}
                                /> */}
                                <SearchBarCond clickAuto={this.onInputChangeBlock} clickSubmit={(e) => {this.setState({showLot: true, valueBlock: e}); this.onInputSubmitBlock(e)}} load={this.promiseOptions} placeholder={"Selecione a quadra"} />
                            </Spin></div>}

                            {this.state.optionsLot.length > 0 && <div className={'form-group FormSelect'}>
                                <label>Selecionar Lote </label><Spin spinning={showLot}>
                                {/* <AsyncSelect
                                    label={'Selecionar Lote'}
                                    noOptionsMessage={function noOptionsMessage() {
                                        return 'Nenhuma Opção';
                                    }}
                                    loadingMessage={function loadingMessage() {
                                        return 'Buscando...';
                                    }}
                                    isSearchable
                                    placeholder={'Selecione o lote'}
                                    defaultOptions
                                    loadOptions={this.promiseOptionsLot}
                                    // onInputChange={(e) => this.onInputChangeLot(e)}
                                    onChange={(e) => {this.setState({valueLot: e.value}); this.onInputChangeLot(e)}}
                                /> */}
                                <SearchBarCond clickAuto={this.onInputChangeLot} clickSubmit={(e) => {this.setState({valueLot: e}); this.onInputSubmitLot(e)}} load={this.promiseOptions} placeholder={"Selecione o lote"} />
                            </Spin></div>}
                        </div>
                    }

                    {
                        quad && <div>
                            {<div className={'form-group FormSelect'}>
                                <label>Selecionar Setor </label><Spin spinning={show}>
                                {/* <AsyncSelect
                                    label={'Selecionar Setor'}
                                    noOptionsMessage={function noOptionsMessage() {
                                        return 'Nenhuma Opção';
                                    }}
                                    loadingMessage={function loadingMessage() {
                                        return 'Buscando...';
                                    }}
                                    isSearchable
                                    placeholder={'Selecione o setor'}
                                    defaultOptions
                                    loadOptions={this.promiseOptionsQuadSetor}
                                    // onInputChange={e => this.onInputChangeQuadSetor(e)}
                                    onChange={(e) => {this.setState({valueQuadSetor: e.value}); this.onInputChangeQuadSetor(e)}}
                                /> */}
                                <SearchBarCond clickAuto={this.onInputChangeQuadSetor} clickSubmit={(e) => {this.setState({valueQuadSetor: e}); this.onInputChangeQuadSetor(e)}} load={this.promiseOptions} placeholder={"Selecione o setor"} />
                            </Spin></div>}

                            {this.state.optionsQuadQuad.length > 0 && <div className={'form-group FormSelect'}>
                                <label>Selecionar Quadra </label><Spin spinning={show}>
                                {/* <AsyncSelect
                                    label={'Selecionar Quadra'}
                                    noOptionsMessage={function noOptionsMessage() {
                                        return 'Nenhuma Opção';
                                    }}
                                    loadingMessage={function loadingMessage() {
                                        return 'Buscando...';
                                    }}
                                    isSearchable
                                    placeholder={'Selecione a quadra'}
                                    defaultOptions
                                    loadOptions={this.promiseOptionsQuadQuad}
                                    // onInputChange={e => this.onInputChangeQuadQuad(e)}
                                    onChange={(e) => {this.setState({valueQuadQuad: e.value}); this.onInputChangeQuadQuad(e)}} */}
                                <SearchBarCond clickAuto={this.onInputChangeQuadQuad} clickSubmit={(e) => {this.setState({valueQuadQuad: e}); this.onInputChangeQuadQuad(e)}} load={this.promiseOptions} placeholder={"Selecione a quadra"} />
                            </Spin></div>}
                        </div>
                    }
                </div>
            }

            {
                selected && selected !== "Bauru" && <div>
                                        {
                        end && <div>
                            {
                                
                            <div className={'form-group FormSelect'}>
                                <label>Busca por Endereço </label><Spin spinning={show}>
                                {/* <AsyncSelect
                                    label={'Endereço'}
                                    noOptionsMessage={function noOptionsMessage() {
                                        return 'Nenhuma Opção';
                                    }}
                                    loadingMessage={function loadingMessage() {
                                        return 'Buscando...';
                                    }}
                                    // cacheOptions
                                    isSearchable
                                    placeholder={'Digite o endereço'}
                                    defaultOptions
                                    loadOptions={this.promiseOptions}
                                    // value={this.state.value}
                                    onInputChange={e => {this.setState({value: e}); this.onInputChange(e)}}
                                    // onChange={e => {this.setState({value: e}); this.inputClear(); this.onInputChange(e)}}
                                    // onInputChange={e => this.onInputChange(e)}
                                    // onChange={onPlaceSelected}
                                /> */}
                                {this.state.selected === "Santos" && <CustomizedInputBase selected={this.state.selected} clickAuto={this.onInputChange} clickSubmit={this.onInputSubmit} onPlaceSelected={onPlaceSelected} load={this.promiseOptions} />}
                                {this.state.selected === "Araraquara" && <CustomizedInputBase selected={this.state.selected} clickAuto={this.onInputChange} clickSubmit={this.onInputSubmit} onPlaceSelected={onPlaceSelected} load={this.promiseOptions} />}
                                {this.state.showList ? <VirtualizedList value={this.state.value} options={this.state.options} onPlaceSelected={onPlaceSelected} /> : ""}
                            </Spin></div>}
                            {/* this.setState({show: true, options: options, value: e}) */}
                            {<div className={'form-group FormSelect'}>
                                <label>Busca por Inscrição </label><Spin spinning={show}>
                                {/* <AsyncSelect
                                    label={'Inscrição Municipal'}
                                    noOptionsMessage={function noOptionsMessage() {
                                        return 'Nenhuma Opção';
                                    }}
                                    loadingMessage={function loadingMessage() {
                                        return 'Buscando...';
                                    }}
                                    isSearchable
                                    placeholder={'Digite a inscrição municipal'}
                                    defaultOptions
                                    loadOptions={this.promiseOptionsIm}
                                    onInputChange={e => this.onInputChangeIm(e)}
                                    onChange={onPlaceSelected}
                                /> */}
                                <SearchBarIm clickAuto={this.onInputChangeIm} clickSubmit={onPlaceSelected} load={this.promiseOptionsIm} placeholder={""} />
                            </Spin></div>}
                        </div>
                    }
                </div>
            }

            </>
        )
    }

    verifyString = (el) => typeof el === 'string';

    filterOptions = (options, value) => 
        options
            .filter(el => this.verifyString(el) && el.label.toLowerCase()
            .includes(this.verifyString(el) && value.toLowerCase()));

    promiseOptions = inputValue =>
        new Promise(resolve => {
            setTimeout(() => {
                resolve(this.state.options);
            }, 10000);
        });

    promiseOptionsIm = inputValue =>
        new Promise(resolve => {
            setTimeout(() => {
                resolve(this.state.optionsIm);
            }, 1000);
        });

    promiseOptionsCondo = inputValue =>
        new Promise(resolve => {
            setTimeout(() => {
                resolve(this.state.optionsCondo);
            }, 1000);
        });

    promiseOptionsBlock = inputValue =>
        new Promise(resolve => {
            setTimeout(() => {
                resolve(this.filterOptions(this.state.optionsBlock, inputValue));
            }, 1000);
        });

    promiseOptionsLot = inputValue =>
        new Promise(resolve => {
            setTimeout(() => {
                resolve(this.filterOptions(this.state.optionsLot, inputValue));
            }, 1000);
        });

    promiseOptionsQuadSetor = inputValue =>
        new Promise(resolve => {
            setTimeout(() => {
                resolve(this.state.optionsQuadSetor);
            }, 1000);
        });

    promiseOptionsQuadQuad = inputValue =>
        new Promise(resolve => {
            setTimeout(() => {
                resolve(this.state.optionsQuadQuad.filter(el => el.label.includes(inputValue)));
            }, 1000);
        });

    onInputChange = async (e) => {
        let options = []
        this.props.onReset()
        this.setState({show: false, options: []})
        if (e !== this.state.value && e) {
            await api.get('/properties/autocomplete?city=' + this.state.selected + '&address=' + e)
            .then(res => {
                for (let i = 0; i < res.data.length; i++) {
                    options.push({value: res.data[i], label: res.data[i].address, city: this.state.selected})
                }

                // if (options.length == 0) this.setState({showList: false})

                this.setState({show: true, options: options, value: e, showList: false})
            })
            .catch(err => {
                console.log(err)
            })
        }

        return options
    }

    onInputSubmit = async (e) => {
        console.log(e)
        console.log(this.state)
        let options = []
        this.props.onReset()
        this.setState({show: false, options: []})
        if (e) {
            await api.get('/properties/autocomplete/textsearch?city=' + this.state.selected + '&address=' + e)
            .then(res => {
                for (let i = 0; i < res.data.length; i++) {
                    options.push({value: res.data[i], label: res.data[i].address, city: this.state.selected})
                }

                if (options.length === 0) this.setState({showList: false})
                else this.setState({showList: true})

                this.setState({show: true, options: options, value: e, showList: true})
            })
            .catch(err => {
                console.log(err)
            })
        }

        return options
    }

    onInputChangeIm = async (e) => {
        this.props.onReset()
        this.setState({show: false, options: []})
        let options = []
        console.log(e)

        if (e) {
            await api.get('/properties/search/register?register=' + e.label)
                .then(res => {
                    if (Array.isArray(res.data)) {
                        for (let i = 0; i < res.data.length; i++) {
                            options.push({value: res.data[i], label: e.label, city: undefined})
                        }
                    } else {
                        options.push({value: res.data, label: e.label, city: undefined})
                    }
                    //this.setState({show: true, options: options})
                })
                .catch(err => {
                    localStorage.removeItem('request')
                })

            this.setState({show: true, optionsIm: options, value: e})
        }

        return options
    }

    onInputChangeCondo = async (e) => {
        this.props.onReset()
        this.setState({show: false, showBlock: false, options: [], optionsBlock: [], optionsLot: []})
        let optionsCondo = []
        let optionsBlock = []
        let optionsLot = []

        let condo = e === "" || e === this.state.valueCondo ? this.state.valueCondo : e

        await api.get('/properties/autocomplete/condominium?city=' + this.state.selected + '&name=' + condo + '&block=&lot=')
            .then(res => {
                if (res.data.search) {
                    optionsCondo.push({value: this.state.valueCondo, label: this.state.valueCondo, city: this.state.selected})
                    optionsBlock = res.data.search.block.map((e) => ({label: e, value: e}))
                    // optionsLot = res.data.search.lot.map((e) => ({label: e, value: e}))
                } else {
                    for (let i = 0; i < res.data.label.length; i++) {
                        optionsCondo.push({value: res.data.label[i], label: res.data.label[i], city: this.state.selected})
                    }
                }
                this.setState({optionsCondo: optionsCondo, optionsBlock: optionsBlock, optionsLot: optionsLot})
            })
            .catch(err => {
                console.log(err)
            })
        
        return optionsCondo
    }

    onInputSubmitCondo = async (e) => {
        this.props.onReset()
        this.setState({show: false, showBlock: false, options: [], optionsBlock: [], optionsLot: []})
        let optionsCondo = []
        let optionsBlock = []
        let optionsLot = []

        let condo = e === "" || e === this.state.valueCondo ? this.state.valueCondo : e

        await api.get('/properties/submit/condominium?city=' + this.state.selected + '&name=' + condo + '&block=&lot=')
            .then(res => {
                if (res.data.search) {
                    optionsCondo.push({value: this.state.valueCondo, label: this.state.valueCondo, city: this.state.selected})
                    optionsBlock = res.data.search.block.map((e) => ({label: e, value: e}))
                    // optionsLot = res.data.search.lot.map((e) => ({label: e, value: e}))
                } else {
                    for (let i = 0; i < res.data.label.length; i++) {
                        optionsCondo.push({value: res.data.label[i], label: res.data.label[i], city: this.state.selected})
                    }
                }
                this.setState({optionsCondo: optionsCondo, optionsBlock: optionsBlock, optionsLot: optionsLot})
            })
            .catch(err => {
                console.log(err)
            })
        
        return optionsCondo
    }

    onInputChangeBlock = async (e) => {
        this.props.onReset()
        this.props.resetImovel()
        this.setState({show: false, showLot: false, valueLot: '', optionsLot: []})
        let optionsCondo = []
        let optionsBlock = []
        let optionsLot = []

        let block = e === "" || e === this.state.valueBlock ? this.state.valueBlock : e

        await api.get('/properties/autocomplete/condominium?city=' + this.state.selected + '&name=' + this.state.valueCondo + '&block=' + block + '&lot=')
            .then(res => {
                if (res.data.search) {
                    optionsCondo.push({value: this.state.valueCondo, label: this.state.valueCondo, city: this.state.selected})
                    optionsBlock = res.data.search.block.map((e) => ({label: e, value: e}))
                    if (block !== "") optionsLot = res.data.search.lot.map((e) => ({label: e, value: e}))
                } else {
                    for (let i = 0; i < res.data.label.length; i++) {
                        optionsCondo.push({value: res.data.label[i], label: res.data.label[i], city: this.state.selected})
                    }
                }
                this.setState({optionsCondo: optionsCondo, optionsBlock: optionsBlock, optionsLot: optionsLot})
            })
            .catch(err => {
                console.log(err)
            })
        return optionsBlock
    }

    onInputSubmitBlock = async (e) => {
        this.props.onReset()
        this.props.resetImovel()
        this.setState({show: false, showLot: false, valueLot: '', optionsLot: []})
        let optionsCondo = []
        let optionsBlock = []
        let optionsLot = []

        let block = e === "" || e === this.state.valueBlock ? this.state.valueBlock : e

        await api.get('/properties/autocomplete/condominium?city=' + this.state.selected + '&name=' + this.state.valueCondo + '&block=' + block + '&lot=')
            .then(res => {
                if (res.data.search) {
                    optionsCondo.push({value: this.state.valueCondo, label: this.state.valueCondo, city: this.state.selected})
                    optionsBlock = res.data.search.block.map((e) => ({label: e, value: e}))
                    if (block !== "") optionsLot = res.data.search.lot.map((e) => ({label: e, value: e}))
                } else {
                    for (let i = 0; i < res.data.label.length; i++) {
                        optionsCondo.push({value: res.data.label[i], label: res.data.label[i], city: this.state.selected})
                    }
                }
                this.setState({optionsCondo: optionsCondo, optionsBlock: optionsBlock, optionsLot: optionsLot})
            })
            .catch(err => {
                console.log(err)
            })
        return optionsBlock
    }

    onInputChangeLot = async (e) => {
        this.props.onReset()
        this.setState({show: false, options: []})
        let options = []
        let lot = e === "" || e === this.state.valueLot ? this.state.valueLot : e

        await api.get('/properties/autocomplete/condominium?city=' + this.state.selected + '&name=' + this.state.valueCondo + '&block=' + this.state.valueBlock + '&lot=' + lot)
            .then(res => {
                options = [{value: res.data.search.properties, label: res.data.search.properties.address, city: this.state.selected}]
                this.props.onPlaceSelected(options[0])
                this.setState({show: true, options: options})
            })
            .catch(err => {
                console.log(err)
            })
        this.setState({show: true})

        return this.state.optionsLot
    }

    onInputSubmitLot = async (e) => {
        this.props.onReset()
        this.setState({show: false, options: []})
        let options = []
        let lot = e === "" || e === this.state.valueLot ? this.state.valueLot : e

        await api.get('/properties/autocomplete/condominium?city=' + this.state.selected + '&name=' + this.state.valueCondo + '&block=' + this.state.valueBlock + '&lot=' + lot)
            .then(res => {
                options = [{value: res.data.search.properties, label: res.data.search.properties.address, city: this.state.selected}]
                this.props.onPlaceSelected(options[0])
                this.setState({show: true, options: options})
            })
            .catch(err => {
                console.log(err)
            })
        this.setState({show: true})

        return this.state.optionsLot
    }

    onInputChangeQuadSetor = async (e) => {
        this.props.onReset()
        this.props.resetQuadrinha()
        this.setState({show: false, options: [], optionsQuadQuad: []})
        let optionsQuadQuad = []

        let setor = e === "" || e === this.state.valueQuadSetor ? this.state.valueQuadSetor : e

        await api.get('/properties/autocomplete/quadrinhas?city=' + this.state.selected + '&block=' + setor + '&lot=')
            .then(res => {
                if (setor !== "") optionsQuadQuad = res.data.label.map((e) => ({label: e, value: e}))
                this.setState({optionsQuadQuad: optionsQuadQuad})
            })
            .catch(err => {
                console.log(err)
            })
        
        return this.state.optionsQuadSetor 
    }

    onInputChangeQuadQuad = async (e) => {
        this.props.onReset()
        this.setState({show: false, options: []})
        let options = []
        let quad = e === "" || e === this.state.valueQuadQuad ? this.state.valueQuadQuad : e
        await api.get('/properties/autocomplete/quadrinhas?city=' + this.state.selected + '&block=' + this.state.valueQuadSetor + '&lot=' + quad)
            .then(res => {
                console.log(res)
                options = [{label: res.data.label, value: res.data.label}]
                this.props.onQuadrinhaSelected(options[0].label)
                this.setState({show: true, options: options})
            })
            .catch(err => {
                console.log(err)
            })
        return this.state.optionsQuadQuad
    }
}

const enhance = compose(
    withScript
)

export default enhance(AddressVenal)
