import * as actionsNames from '../actions/actionsNames';
import { updateObject } from '../../utils/utility';

const initialState = {
    loading: false,
    reports: null,
    report: null
};

const actionFail = (state, action) => {
    return updateObject(state, {
        loading: false,
        error: action.error
    });
};

const actionStart = (state, action) => {
    return updateObject(state, { loading: true });
};

const fetchReportsSuccess = (state, action) => {
    return updateObject(state, { 
        loading: false,
        reports: action.data
    });
};

const fetchReportSuccess = (state, action) => {
    return updateObject(state, { 
        loading: false,
        report: action.data
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionsNames.FETCH_REPORTS_START: return actionStart(state, action);
        case actionsNames.FETCH_REPORTS_SUCCESS: return fetchReportsSuccess(state, action);
        case actionsNames.FETCH_REPORTS_FAIL: return actionFail(state, action);
        case actionsNames.FETCH_REPORT_START: return actionStart(state, action);
        case actionsNames.FETCH_REPORT_SUCCESS: return fetchReportSuccess(state, action);
        case actionsNames.FETCH_REPORT_FAIL: return actionFail(state, action);
        default: return state;
    }
};

export default reducer;
