import React, {Component} from 'react';
import * as actions from '../../../store/actions';
import {connect} from 'react-redux';
import { Link } from 'react-scroll'
import Button from '../../../components/UI/Button/Button';
import {NavLink} from 'react-router-dom';
import { Helmet } from 'react-helmet';
import './index.scss';

import heartempty from '../../../assets/images/icon-heart-line.svg';
import heartfull from '../../../assets/images/icon-heart.svg';
import {isAssinaturaValida} from '../../../store/userSession';
import '../../../components/Navigation/NavigationItems/NavigationItems.scss';
import AddressVenal from "../../../components/map/AddressVenal";
import api from "../../../services/api";
import { obterUsuarioLogado } from '../../../store/userSession';
import Spinner from "../../../components/UI/Spinner/Spinner";

import HeaderNav from "../../../components/UI/HeaderNav";
import MenuCard from "../../../components/UI/MenuCard";
import CardButton from "../../../components/UI/MenuCard/components/ButtonCard";

import IconHome from '../../../assets/images/icon-home.svg';
import IconMaps from '../../../assets/images/icon-maps.svg';
import IconFavorites from '../../../assets/images/icon-favorites.svg';
import IconSignature from '../../../assets/images/icon-signature.svg';
import IconUser from '../../../assets/images/icon-user.svg';
import IconData from '../../../assets/images/icon-data.svg';
import IconMsg from '../../../assets/images/icon-msg.svg';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import IconSettings from '../../../assets/images/icon-setting.svg';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

console.log(obterUsuarioLogado())

class BuscaVenal extends Component {
  addressSearch = false;
  imovelSelecionado = undefined;
  state = {
    showInfoWindow: [],
    showInfoWindowUser: true,
    showWindowGroup: false,
    myCoordinates: {
      latitude: sessionStorage.getItem('latitude') !== undefined ? Number(sessionStorage.getItem('latitude')) : 0,
      longitude: sessionStorage.getItem('longitude') !== undefined ? Number(sessionStorage.getItem('longitude')) : 0
    },
    favoritou: false,
    address: '',
    loadingAddress: false,
    position: null,
    type: null,
    id: null,
    imovel: null,
    quadrinha: null,
    mostrarPainel: false,
    mostrarQuadrinha: false,
    loadingFavorito: false,
  };

  componentDidMount() {
    if (this.props.isAuthenticated)
      this.props.onInit({lat: 0, lng: 0})
  }

  handleReset = () => {
    this.setState({loadingAddress: false, position: null})
  }

  resetImovel = () => {
    this.imovelSelecionado = undefined;
    this.setState({loadingAddress: false, position: null, imovel: null, mostrarPainel: false, mostrarQuadrinha: false, address: ''})
  }

  resetQuadrinha = () => {
    this.setState({quadrinha: null, mostrarQuadrinha: false})
  }

  onChange = (address) => {
    this.setState({loadingAddress: false})
  }

  onPlaceSelected = (address) => {
    this.addressSearch = true

    let newAddress = address.value
    console.log(address)

    if(address.city !== undefined){
      api.get(`/properties/search/address?city=${ address.city }&address=${ address.label }`).then(res => {
        if (!Array.isArray(res.data)) {
          newAddress = res.data
          this.setState({loadingAddress: true, imovel: newAddress})
        } else {
          for(let i = 0; i < res.data.length; i++) {
            if(res.data[i]._id === address.value._id) {
              newAddress = res.data[i]
              break
            }
          }

          this.imovelSelecionado = newAddress
          this.setState({loadingAddress: true, imovel: newAddress, mostrarPainel: true})
        }
      })
      .catch(err => {
      })
    } else {
      this.imovelSelecionado = newAddress
    }

    if (this.props.isAuthenticated) {
      this.props.onInit({lat: 0, lng: 0})
    }

    this.setState({loadingAddress: true, imovel: newAddress, mostrarPainel: true, scroll: true})
  };

  onQuadrinhaSelected = (property) => {
    this.setState({quadrinha: property, mostrarQuadrinha: true})
  }

  redirectToSignUp = () => {
    window.location.href = '/signup/'
  }

  redirectToSignature = () => {
    window.location.href = '/signup/'
  }

  render() {
    let favorite = localStorage.getItem('favorite')
    let noRedirect = localStorage.getItem('no_redirect')

    if(noRedirect !== 'sim') {
      if (this.props.isAuthenticated && favorite) {
        localStorage.removeItem('favorite')
        window.location.href = "/meusimoveis/edicao/" + favorite
      }
    }

    const AlwaysScrollToBottom = () => {
      const elementRef = React.useRef();
      React.useEffect(() => elementRef.current.scrollIntoView());
      return <div ref={elementRef} />;
    };    

    let { isAuthenticated } = this.props;
    let {
      loadingAddress,
      loadingFavorito,
      mostrarPainel,
      mostrarQuadrinha,
      imovel
    } = this.state;
    let info = (<span/>)
    let aviso =
        (<div className="boxcenter">
          <div className="avisoimovel">
            <h5>Quer ver mais informações? Faça seu cadastro e comece agora mesmo a salvar seus imóveis.</h5>
          </div>

          {/* <NavLink
              style={{fontWeight: 'bold'}}
              to="/signup"
          >
            <Button>Cadastro</Button>
          </NavLink> */}
        </div>)

    if (imovel !== null && imovel.property_address !== undefined) {
      console.log('imovel.property_venal_value', imovel)

      if (isAuthenticated) {
        if (!isAssinaturaValida()) {
          info = (
            <React.Fragment>
              { info }

              <h5>
                Endereço: { imovel.address },
                { imovel.property_address.block ? `, Qd. ${imovel.property_address.block}` : '' }
                { imovel.property_address.lot ? `, Lt. ${imovel.property_address.lot}` : ''}
              </h5>

              <div className="boxcenter">
                <div className="avisoimovel">
                  <h5>Assinatura expirada!</h5>
                </div>

                <NavLink
                  style={{fontWeight: 'bold'}}
                  to="/assinatura"
                >
                  <Button>Ver Assinatura</Button>
                </NavLink>
              </div>
            </React.Fragment>
          );
        } else {
          let controlefavorito = (
            <Button clicked={() => this.adicionarFavorito(imovel._id, true, 0, 0)}>Adicionar e Editar</Button>
          );

          let favorito = this.isFavorito(imovel._id);
          let heart = null;

          if (favorito) {
            favorito = favorito.favorite;

            controlefavorito = (
              <NavLink
                style={{fontWeight: 'bold', marginBottom: '2px'}}
                to={"/meusimoveis/edicao/" + favorito._id}
              >
                <Button>Editar Favorito</Button>
              </NavLink>
            );

            heart =(
              <button className="ButtonFavorite">
                <img src={ heartfull } alt="Favorito"/>
              </button>
            );
          } else {
            heart = (
              <button className="ButtonFavorite"
                      onClick={() => this.adicionarFavorito(imovel._id, false, 0, 0)}
              >
                <img src={ heartempty } alt="Adicionar Favorito"/>
              </button>
            );
          }

          info = (
            <React.Fragment>
              <div className="CardInfo">
                {/* { info } */}
                <h2>Informações do Imóvel</h2>

                <div>
                  <p>
                    <strong>Endereço:</strong> <span>{ imovel.address }</span>
                    { imovel.property_address.block ? <span>{", Qd. " + imovel.property_address.block }</span>: ''}
                    <span>{ imovel.property_address.lot ? ", Lt. " + imovel.property_address.lot : '' }</span>
                  </p>

                  <ul>
                    <li><strong>Valor Venal Total:</strong> <span>{ imovel.total_venal_value ? " R$ " + imovel.total_venal_value : null }</span></li>
                    <li><strong>Inscrição Imobiliária:</strong> <span>{ imovel.municipal_register }</span></li>

                    { imovel.ground_area ? <li><strong>Área Territorial:</strong> <span>{ imovel.ground_area }</span></li> : null }
                    { imovel.building_area ? <li><strong>Área Construída:</strong> <span>{ imovel.building_area }</span></li> : null}
                    { imovel.territorial_venal_value ? <li><strong>Valor Venal Territorial:</strong> <span>{ " R$ " + imovel.territorial_venal_value }</span></li> : null}
                    { imovel.property_venal_value ? <li><strong>Valor Venal Predial:</strong> <span>{ " R$ " + imovel.property_venal_value }</span></li> : null}
                    { imovel.total_venal_value ? <li><strong>Valor Venal Total:</strong> <span>{ " R$ " + imovel.total_venal_value }</span></li> : null}
                    { imovel.iptu ? <li><strong>IPTU:</strong> <span>{ " R$ " + imovel.iptu }</span></li> : null}
                    { imovel.building_year ? <li><strong>Ano Construção:</strong> <span>{ imovel.building_year }</span></li> : null}
                  </ul>
                </div>

                <div className="boxcenter">
                  { controlefavorito }
                </div>

                { heart }
              </div>
            </React.Fragment>
          );
        }
      } else {
        info = (
          <React.Fragment>
            <div className="DefaultContent">
              <h3 className="SubTitle">Veja Seus Imóveis Favoritos</h3>
            </div>

            <div className="CardInfo">
              {/* { info } */}
              <h2>Informações do Imóvel</h2>

              <div>
                <p>
                  <strong>Endereço:</strong> <span>{ imovel.address }</span>
                  { imovel.property_address.block ? <span>{", Qd. " + imovel.property_address.block }</span>: ''}
                  <span>{ imovel.property_address.lot ? ", Lt. " + imovel.property_address.lot : '' }</span>
                </p>

                <p>
                  <strong>Valor Venal Total:</strong> { imovel.total_venal_value ? " R$ " + imovel.total_venal_value : null }
                </p>
              </div>

              {aviso}
            </div>
          </React.Fragment>
        );
      }
    }

    return (
      <>
        <Helmet>
          <title> Busca Venal — Plano Cidade</title>
        </Helmet>
        <HeaderNav padd={{"padding-top": "30px"}} atSearch>
          <MenuCard>
            {
              // eslint-disable-next-line no-restricted-globals
              (location.pathname) && (
                <>
                  {
                    // eslint-disable-next-line no-restricted-globals
                    (!this.props.isAuthenticated && location.pathname === '/') && (<CardButton to="/busca/venal" icon={ IconHome }>Buscar Imóveis</CardButton>)
                  }
                  {
                    // eslint-disable-next-line no-restricted-globals
                    (this.props.isAuthenticated || location.pathname === '/busca/venal') && (<CardButton to="/busca/mapa" className="DesktopLinks" icon={ IconMaps }>Buscar Mapa</CardButton>) }
                  {
                    // eslint-disable-next-line no-restricted-globals
                    (this.props.isAuthenticated && location.pathname === '/') && (<CardButton to="/busca/venal" className="MobileLinks" icon={ IconHome }>Buscar Imóveis</CardButton>)
                  }
                  {
                    // eslint-disable-next-line no-restricted-globals
                    (location.pathname === '/busca/venal') && (<CardButton to="/busca/mapa" className="MobileLinks" icon={ IconMaps }>Buscar Mapa</CardButton>)
                  }


                  {
                    // eslint-disable-next-line no-restricted-globals
                    (!this.props.isAuthenticated && location.pathname === '/') && (
                      <>
                        {/* <CardButton to="/signup" className="MobileLinks" icon={ IconFavorites }>Meus Imóveis</CardButton>
                        <CardButton to="/signup" className="MobileLinks" icon={ IconSignature }>Assinatura</CardButton>
                        <CardButton to="/signup" className="MobileLinks" icon={ IconUser }>Clientes</CardButton> */}

                        <Link className="ButtonCard DesktopLinks"
                              to="favoritos"
                              smooth={true}
                              offset={-10}
                              duration={700}
                        >
                          <img src={ IconFavorites } alt="Meus Imóveis" />
                          Meus Imóveis
                        </Link>

                        <Link className="ButtonCard DesktopLinks"
                              to="assinatura"
                              smooth={true}
                              offset={-10}
                              duration={700}
                        >
                          <img src={ IconSignature } alt="Assinatura" />
                          Assinatura
                        </Link>

                        <Link className="ButtonCard DesktopLinks"
                              to="clientes"
                              smooth={true}
                              offset={-10}
                              duration={700}
                        >
                          <img src={ IconUser } alt="Clientes" />
                          Clientes
                        </Link>
                      </>
                    )
                  }
                </>
              )
            }

            {
              // eslint-disable-next-line no-restricted-globals
              (this.props.isAuthenticated && location.pathname === '/') && (
                <>
                  <CardButton to="/meusimoveis/lista" className="MobileLinks" icon={ IconFavorites }>Meus Imóveis</CardButton>
                  {/* <CardButton to="/assinatura" className="MobileLinks" icon={ IconSignature }>Assinatura</CardButton> */}
                  {(this.props.role === 'Master' || this.props.role === 'Admin') && <CardButton to="/admin/panel" className="MobileLinks" icon={ IconSettings }>Admin</CardButton>}
                  <CardButton to="/meusclientes" className="MobileLinks" icon={ IconUser }>Clientes</CardButton>

                  <CardButton to="/meusdados" className="MobileLinks" icon={ IconData }>Meus Dados</CardButton>
                  <CardButton to="/contato" className="MobileLinks" icon={ IconMsg }>Sugestões</CardButton>
                </>
              )
            }
          </MenuCard>
        </HeaderNav>

        {
          // eslint-disable-next-line no-restricted-globals
          ((location.pathname === '/' && this.props.isAuthenticated) || location.pathname === '/busca/venal') && (
            // eslint-disable-next-line no-restricted-globals
            <div className={`SearchVenal WrapperDiv ${ location.pathname === '/busca/venal' ? '' : 'SearchVenalHome' }`}>
              {/* <div className="DefaultContent">
                <h3 className="Title">Buscar Imóveis</h3>
              </div> */}

              <div>
                <AddressVenal
                  onPlaceSelected={this.onPlaceSelected}
                  onQuadrinhaSelected={this.onQuadrinhaSelected}
                  resetQuadrinha={this.resetQuadrinha}
                  onChange={this.onChange}
                  onReset={this.handleReset}
                  resetImovel={this.resetImovel}
                  loading={loadingAddress}
                />
              </div>

              <div className="PanelFilter">
                <div className="PanelLoading">
                  { loadingFavorito && <Spinner/> }
                </div>

                {
                  (mostrarPainel && !loadingFavorito) && (
                    <div className="PanelPropertyDetails DefaultContent"  ref={(ref) => this.endRef = ref}>
                      <h3 className="Title">Detalhes do Imóvel</h3>

                      <div>
                        { info }
                      </div>
                      {this.state.scroll && <AlwaysScrollToBottom />}
                      {this.state.scroll && this.setState({scroll: false})}
                    </div>
                  )
                }

                {
                  (mostrarQuadrinha && this.state.quadrinha.image) && (
                      <div className="PanelPropertyDetails DefaultContent">
                        <h3 className="Title">Quadrinhas</h3>
                        <div style={{display: "flex", justifyContent: "center"}}>
                          {
                            isAuthenticated ?
                                (
                                    <TransformWrapper
                                        defaultScale={1}
                                        defaultPositionX={200}
                                        defaultPositionY={100}
                                    >
                                      {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                          <div style={{display: "flex", alignItems: "center", flexDirection: "column"}}>
                                            <div className="tools">
                                              <button onClick={zoomIn}><ZoomInIcon /></button>
                                              <button onClick={zoomOut}><ZoomOutIcon /></button>
                                            </div>
                                            <TransformComponent>
                                              <img src={this.state.quadrinha.image.url} style={{maxWidth: "100%"}} alt="Quadrinha"/>
                                            </TransformComponent>
                                          </div>
                                      )}
                                    </TransformWrapper>
                                  )
                                :
                                (<div>
                                  {aviso}
                                </div>)
                          }
                        </div>
                      </div>
                  )
                }

              </div>
            </div>
          )
        }

        {
          // eslint-disable-next-line no-restricted-globals
          (!this.props.isAuthenticated && location.pathname !== '/busca/venal') && (
            <div className="CheckLoginHome WrapperDiv">
              <p>
                <strong>Não tem cadastro?</strong><br />
                Faça seu cadastro e comece agora mesmo.
              </p>

              {/* <NavLink to="/signup"><Button>Cadastro</Button></NavLink> */}
            </div>
          )
        }
      </>
    )
  }

  adicionarFavorito = (id, bok, type, idPredio) => {
    this.setState({mostrarPainel: true, imovel: this.imovelSelecionado, loadingFavorito: true})
    this.props.criarFavorito(id, false);
    if(!bok){
      localStorage.setItem('no_redirect', 'sim')
    }else{
      localStorage.removeItem('no_redirect')
    }
    this.setState({loadingFavorito: false})
  }

  isFavorito = (id) => {
     return this.props.favoritos && this.props.favoritos.find(element => element.property !== undefined && element.property._id === id)
  }
}

const mapStateToProps = state => {
  return {
    imoveisproximos: state.localizacao.imoveisproximos,
    imoveisproximospredios: state.localizacao.imoveisproximospredios,
    loading: state.localizacao.loading,
    loadingimoveis: state.imoveis.loading,
    center: state.localizacao.center,
    buscou: state.localizacao.buscou,
    firstTime: state.localizacao.firstTime,
    centraliza: state.localizacao.centraliza,
    coordenadas: state.localizacao.coordenadas,
    error_geo: state.localizacao.error_geo,
    favoritos: state.localizacao.favoritos,
    favoritosPredios: state.localizacao.favoritosPredios,
    id_redirect_favorito: state.imoveis.id_redirect_favorito,
    newfavorito: state.localizacao.newfavorito,
    isAuthenticated: state.auth.token !== null,
    role: state.auth.role
  };
};

const mapDispatchToProps = dispatch => {
  return {
    criarFavorito: (imovel_id, editar) => dispatch(actions.criarFavorito(imovel_id, editar)),
    onLoadLocalizacao: (coordenadas, distancia, formatted_address) => dispatch(actions.onLoadLocalizacao(coordenadas, distancia, formatted_address)),
    onSetLocalizacao: (coordenadas) => dispatch(actions.onSetLocalizacao(coordenadas)),
    onInit: (coordenadas) => dispatch(actions.onInit(coordenadas)),
    onCenter: (centraliza) => dispatch(actions.centralizar(centraliza)),
    onSetCenter: (center) => dispatch(actions.onSetCenter(center)),
    onSetBuscou: (buscou) => dispatch(actions.onSetBuscou(buscou)),
    onSetFirstTime: (firstTime) => dispatch(actions.onSetFirstTime(firstTime)),
    // onAddFavorito: () => dispatch(actions.onAddFavorito()),
    onErrorGetLocalizacao: () => dispatch(actions.onErrorGetLocalizacao())
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(BuscaVenal);
