import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

const useStyles = makeStyles((theme) => ({
  textField: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  textLink: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  margin: {
    margin: theme.spacing(1),
  },
  button: {
    display: 'flex',
    justifyContent: 'center'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

export default function AnnouncementMessageInformation({ columns, defaultAnnouncementMessage }) {
  const classes = useStyles();

  const buildTextField = (meta, value, disabled = true) => (
    <FormControl fullWidth>
      <InputLabel>{meta}</InputLabel>
      <Input
        id={meta}
        name={meta}
        label={meta}
        value={value}
        className={classes.textField}
        disabled={disabled}
      />
    </FormControl>
  )

  return (
    <React.Fragment>
      {
        defaultAnnouncementMessage && (
          <Grid>
            <Typography component="h1" variant="body1" align="center" className={classes.textField}>
              Informações Básicas
            </Typography>
            {
              columns && columns.map(column => (
                buildTextField(column, defaultAnnouncementMessage[column])
              ))
            }
          </Grid>
        )
      }
    </React.Fragment>
  );
}
