import React, { useEffect, useState } from "react";
import { Chip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles"
import Paper from '@mui/material/Paper';

const useStyles = makeStyles({
  resultadoStyle: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#fff",
    borderRadius: "10px",
    margin: "24px 8px 0px 8px",
  },
  chip: {
    margin: "10px 5px",
  },
  container: {
    display: "inline-block",
    alignItems: "center",
  }
});

function FilterChips({ labels, handleChips, lazer = [] }) {
  const classes = useStyles();

  const labels2Chips = () => {
    const c = {};
    for (var l of labels) {
      c[l.label] = {
        icon: l.icon,
        active: lazer.includes(l.label),
      };
    }

    return c;
  };

  const [chips, setChips] = useState(labels2Chips());

  useEffect(() => {
    setChips(labels2Chips());
    // eslint-disable-next-line
}, [labels]);

  const handleChange = (label) => {
    const newChips = {
      ...chips,
      [label]: {
        ...chips[label],
        active: !chips[label].active,
      },
    };

    const activeLabels = Object.entries(newChips)
      .map(([label, value]) => {
        if (value.active) return label;
        return undefined;
      })
      .filter((f) => f);

    if (activeLabels && activeLabels.length > 0) handleChips(activeLabels);
    else handleChips([]);
  };

  return (
    <Paper elevation={1} className={classes.resultadoStyle}>
      <div className={classes.container}>
        {Object.entries(chips).map(([key, value]) => (
          <Chip
            clickable
            color={value.active ? "primary" : "default"}
            className={classes.chip}
            icon={value.icon}
            label={key}
            onClick={() => handleChange(key)}
          />
        ))}
      </div>
    </Paper>
  );
}

export default FilterChips;
