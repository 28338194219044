import React from 'react';

import './FooterButton.scss';

const footerButton = ( props ) => (
	<button
		disabled={props.disabled}
		title={props.title}
		className={["FooterButton", props.btnType].join(' ')}
    	onClick={props.clicked}
  >
    { props.children }
  </button>
);

export default footerButton;
