import { createStore, compose, applyMiddleware, combineReducers } from 'redux';
import authReducer from './reducers/auth';
import userReducer from './reducers/meusdados';
import usersReducer from './reducers/users';
import imoveisReducer from './reducers/imoveis';
import postReducer from './reducers/post';
import announcementMessageReducer from './reducers/announcementMessage';
import advertiseReducer from './reducers/advertise';
import reportsReducer from './reducers/reports';
import localizacaoReducer from './reducers/localizacao';
import localizacaoFavReducer from './reducers/localizacaofavoritos';
import thunk from 'redux-thunk';


const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : compose;

const rootReducer = combineReducers({
    auth: authReducer,
    user: userReducer,
    users: usersReducer,
    imoveis: imoveisReducer,
    post: postReducer,
    announcementMessage: announcementMessageReducer,
    advertise: advertiseReducer,
    reports: reportsReducer,
    localizacao: localizacaoReducer,
    localizacaoFavoritos: localizacaoFavReducer,
});

function configureStore() {
    const middlewares = [thunk];

    return createStore(
        rootReducer,
        composeEnhancers(applyMiddleware(...middlewares))
    );
}

export default configureStore;
