import React, {useState} from "react";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import VisibilityIcon from '@mui/icons-material/Visibility';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import api from '../../../services/api';
import configToken from "../../../utils/configToken";
import ErrorLogs from "../ErrorLogs/ErrorLogs";
import Grid from "@material-ui/core/Grid";
import { Pagination } from "@material-ui/lab";

const ErrorsList = ({errors, onPageChange, pagesCount, currentPage}) => {
    const [open, setOpen] = useState(false);
    const [uploadError, setUploadError] = useState([]);

    const handleClose = () => {
        setOpen(false)
    }

    const handleClick = (uploadId) => {
        api.post('/posts/error/info', uploadId, configToken()).then(res => {
            setUploadError(res.data);
            setOpen(true);
        }).catch(err => {
            window.alert("Não foi possivel obter os erros de upload do arquivo.")
            console.log(err)
        })
    }

    return (
        <>  
            <Grid container spacing={10} direction="column" justifyContent="center" alignItems="center">
                <p className="titulo">Histórico de erros</p>    
                <List sx={{ 
                    width: '100%', 
                    maxWidth: 600, 
                    bgcolor: 'background.paper',
                    maxHeight: 400,
                    overflow: 'auto'
                    }}>
                    {errors.map((value) => (
                        <ListItem
                            key={value.upload_id}
                            secondaryAction={
                            <IconButton 
                                aria-label="comment"
                                onClick={() => {handleClick(value.upload_id)}}
                            >
                                <VisibilityIcon/>
                            </IconButton>
                            }
                        >
                            <Typography
                                variant="h6"
                            >
                                {value.upload_id}
                            </Typography>
                        </ListItem>
                    ))}
                </List>
                <Pagination
                    count={pagesCount}
                    onChange={onPageChange}
                    page={currentPage}
                />             
            </Grid>
            {open &&
                (<ErrorLogs uploadError={uploadError} open={open} handleClose={handleClose}/>)
            }
        </>
    );
}

export default ErrorsList;