import * as actionsNames from './actionsNames';
import api from '../../services/api';
import configToken from '../../utils/configToken';

const sendAnnouncementMessageStart = () => {
  return {
    type: actionsNames.SEND_ANNOUNCEMENT_MESSAGE_START
  };
};

const sendAnnouncementMessageSuccess = data => {
  return {
    type: actionsNames.SEND_ANNOUNCEMENT_MESSAGE_SUCCESS,
    data: data
  };
};

const sendAnnouncementMessageFail = error => {
  return {
    type: actionsNames.SEND_ANNOUNCEMENT_MESSAGE_FAIL,
    data: error
  };
};

export const sendAnnouncementMessage = data => {
	return dispatch => {
		dispatch(sendAnnouncementMessageStart());
		api
			.post("/announcement-message", data, configToken())
			.then(res => {
				dispatch(
					sendAnnouncementMessageSuccess()
				);
			})
			.catch(err => {
				dispatch(sendAnnouncementMessageFail(err));
			});
	};
};

const fetchAnnouncementMessagesSuccess = data => {
	return {
	  type: actionsNames.FETCH_ANNOUNCEMENT_MESSAGES_SUCCESS,
	  data: data
	};
};
  
const fetchAnnouncementMessagesFail = error => {
	return {
	  type: actionsNames.FETCH_ANNOUNCEMENT_MESSAGES_FAIL,
	  error: error
	};
};
  
const fetchAnnouncementMessagesStart = () => {
	return {
	  type: actionsNames.FETCH_ANNOUNCEMENT_MESSAGES_START
	};
};
  
export const fetchAnnouncementMessages = () => {
	return dispatch => {
	  dispatch(fetchAnnouncementMessagesStart());
	  api
		.get(`/announcement-message/messages`)
		.then(res => {
		  dispatch(fetchAnnouncementMessagesSuccess(res.data));
		})
		.catch(res => {
		  dispatch(fetchAnnouncementMessagesFail(res.data));
		})
	};
};

const fetchAnnouncementMessageStart = () => {
	return {
	  type: actionsNames.FETCH_ANNOUNCEMENT_MESSAGE_START
	};
  };

const fetchAnnouncementMessageSuccess = data => {
	return {
		type: actionsNames.FETCH_ANNOUNCEMENT_MESSAGE_SUCCESS,
		data: data
	};
};

const fetchAnnouncementMessageFail = error => {
	return {
		type: actionsNames.FETCH_ANNOUNCEMENT_MESSAGE_FAIL,
		error: error
	};
};

export const fetchAnnouncementMessage = (id) => {
	return dispatch => {
	  dispatch(fetchAnnouncementMessageStart());
	  api
		.get(`/announcement-message/admin/search?id=${id}`, configToken())
		.then(res => {
		  dispatch(fetchAnnouncementMessageSuccess(res.data));
		})
		.catch(res => {
		  dispatch(fetchAnnouncementMessageFail(res.data));
		})
	}
};