import React from 'react';
import { Carousel } from "react-responsive-carousel";
import { serveImage } from '../../utils/serveImage';

export default function AdvertisementImages ({ advertisements, width, height }) {
    return (
        <div style={{ maxWidth: width, height: height, margin: 'auto'  }}>
            {advertisements &&
            <Carousel showThumbs={false} dynamicHeight={false} showIndicators={false} showStatus={false} autoPlay={true} infiniteLoop={true}>
                {advertisements.map((image, index) => (
                <a href={image.link} target="_blank" rel="noopener noreferrer"><div key={index} style={{ maxWidth: width, height: height, borderStyle: 'solid', borderColor: '#dcdcdc', margin: 'auto',  }}>
                    <img src={serveImage(image.image.url)} alt={image.name} />
                </div></a>
                ))}
            </Carousel>
        }
        </div>
    )
}