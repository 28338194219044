import React, { Component } from 'react';
import { connect} from 'react-redux';
import { GoogleMap, InfoWindow } from 'react-google-maps'
import MarkerWithLabel from "react-google-maps/lib/components/addons/MarkerWithLabel";
import { geocodeByAddress } from "react-places-autocomplete";
import * as actions from '../../../store/actions';
import * as constants from '../../../utils/constants';
import axios from '../../../services/api'
import axiosGeo from '../../../services/apiGoogle'
import withErrorHandler from '../../../hoc/withErrorHandler/withErrorHandler';
import withMap from '../../../components/map/withMap';
import { fetchCoordinates } from "../../../utils/geolocation";
import '../../../components/Navigation/NavigationItems/NavigationItems.scss';
import InformationCard from "../../../components/UI/InformationCard";
import { serveImage } from "../../../utils/serveImage";
import ImageDefault from "../../../assets/images/sample-image.jpg";

import './index.scss';

class BuscaMapa extends Component {
  changeCenterActive = false;
  centerMy = false;
  addressSearch = false;
  state = {
    boundCoordinates: [],
    showInfoWindow: [],
    myCoordinates: {
      latitude: sessionStorage.getItem('latitude') !== undefined ? Number(sessionStorage.getItem('latitude')) : 0,
      longitude: sessionStorage.getItem('longitude') !== undefined ? Number(sessionStorage.getItem('longitude')) : 0
    },
    address: '',
    loadingAddress: false,
    position: null,
    zoom: constants.ZOOM_DEFAULT,
  };

  handleCoordinates = (address) => {
    return geocodeByAddress(address)
  }

  handleMouseOverClick = (id) => {
    let updatedshowInfoWindow = null;
    if (this.state.showInfoWindow.length === this.props.imoveisWithinPolygon.length) {
      updatedshowInfoWindow = this.state.showInfoWindow.map((item, index) => index === id)
    } else {
      updatedshowInfoWindow = [];
      if (this.props.imoveisWithinPolygon) {
        updatedshowInfoWindow = this.props.imoveisWithinPolygon.reduce((updatedshowInfoWindow, imovel) => {
          updatedshowInfoWindow[imovel._id] = (id === imovel._id);
          return updatedshowInfoWindow;
        }, updatedshowInfoWindow);
      }
    }
    this.setState({
      showInfoWindow: updatedshowInfoWindow
    });
  };

  handleMouseExit = e => {
    let updatedshowInfoWindow = this.state.showInfoWindow.map(item => false)
    this.setState({
      showInfoWindow: updatedshowInfoWindow
    });
  };

  handleWindowBounds = (lat, lng) => {
    if(this.map){
      // let bounds = this.map.getBounds() 
      let lngne = this.map.getBounds().getNorthEast().lng();
      let latne = this.map.getBounds().getNorthEast().lat();
      let lngsw = this.map.getBounds().getSouthWest().lng(); 
      let latsw = this.map.getBounds().getSouthWest().lat();
      // let lngne = bounds.Ja['hi'];
      // let latne = bounds.Wa['hi'];
      // let lngsw = bounds.Ja['lo']; 
      // let latsw = bounds.Wa['lo'];
      let newBoundCoordinates = [lng + ((lngne - lngsw) / 2),
                                lat + ((latne - latsw) / 2),
                                lng - ((lngne - lngsw) / 2),
                                lat - ((latne - latsw) / 2)]
      this.setState({
        boundCoordinates: newBoundCoordinates
      })
      return newBoundCoordinates
    }
  }

  tryAPIGeolocation = () => {
    return axiosGeo.post("https://www.googleapis.com/geolocation/v1/geolocate?key=AIzaSyCRrwsJQfUXOsVKnI3rg3cWe5bRjWXaEIA")
      .then(response => {
        sessionStorage.removeItem('latitude')
        sessionStorage.removeItem('longitude')
        sessionStorage.setItem('latitude', response.data.location.lat)
        sessionStorage.setItem('longitude', response.data.location.lng)

        return response.data
      }).catch(error => {

        return false
      });
  }

  async componentDidMount() {
    this.centerMy = true
    this.props.onSetFirstTime(true)
    let coordMy = {}

    if (this.props.imoveisWithinPolygon && this.props.imoveisWithinPolygon.length > 0) {
      coordMy = {
        latitude: this.props.imoveisWithinPolygon[0].metas.location_coordinates[1],
        longitude: this.props.imoveisWithinPolygon[0].metas.location_coordinates[0]
      }
    } else {
      let position = await fetchCoordinates()
      if (position !== undefined) {
        coordMy = {
          latitude: position.latitude,
          longitude: position.longitude
        }
      }
    }

    this.setState({
      myCoordinates: coordMy
    })
    this.changeCenterActive = false;
  }

  componentWillUnmount() {
    this.centerMy = true
    this.props.onSetLocalizacao(this.state.myCoordinates)
    let cent = {
      lat: this.state.myCoordinates.latitude,
      lng: this.state.myCoordinates.longitude
    }
    this.props.onSetCenter(cent);
    this.changeCenterActive = true;
  }

  _onCenterChange = async () => {
    let cent = {
      lat: Number(this.map.getCenter().lat()),
      lng: Number(this.map.getCenter().lng())
    }
    this.setState({
      cent: cent
    })
    this.props.onSetCenter(cent);
    this.centerMy = false
    const boundCoordinates = this.handleWindowBounds(cent.lat, cent.lng)
    this.props.onLoadPolygonCoordinates(cent, boundCoordinates);
};


  handleReset = () => {
    this.setState({
      loadingAddress: false,
      position: null,
      address: ''
    })
  }

  onChange = (address) => {
    this.setState({ address: address })
  }

  onPlaceSelected = (fullAddress) => {
    this.addressSearch = true
    this.setState({address: fullAddress})
    this.handleCoordinates(fullAddress).then(result => {
      let place = result[0]
      let coordenadas = {
        latitude: place.geometry.location.lat(),
        longitude: place.geometry.location.lng()
      }
      this.centerMy = false;
      const boundCoordinates = this.handleWindowBounds(coordenadas.latitude, coordenadas.longitude)
      this.props.onLoadPolygonCoordinates(coordenadas, boundCoordinates)
    })
  };

  getImageSrc = (p) => (
    (p.metas && p.metas.thumbnail && serveImage(p.metas.thumbnail.url))
    || (p.metas && p.metas.banner_principal && serveImage(p.metas.banner_principal.url))
    || (p.metas && p.metas.fotos && p.metas.fotos.length && serveImage(p.metas.fotos[0].url))
    || ImageDefault
  )

  render() {
    let {
      centraliza,
      center,
      imoveisWithinPolygon,
      loading,
    } = this.props;

    let {
      showInfoWindow,
      myCoordinates
    } = this.state;

    if (this.props.error_geo) {
      this.setState({zoom: 3})
      center = {
        lat: constants.GEO_DEFAULT.latitude,
        lng: constants.GEO_DEFAULT.longitude
      };
      //TODO aviso não foi possível obter geolocalizacao
    }

    if (centraliza || this.centerMy) {
      center = {
        lat: myCoordinates.latitude,
        lng: myCoordinates.longitude
      };
    }

    if (loading) {
      return (<div />);
    } else {
      let markers = null;

      if (imoveisWithinPolygon && imoveisWithinPolygon.length > 0) {

        if (imoveisWithinPolygon) {
          markers = imoveisWithinPolygon.map(imovel => {
            const coordinates = imovel.metas.location_coordinates;
            let position = {lat: Number(coordinates[1]), lng: Number(coordinates[0])};
            let info = (
              <InformationCard
                  id={imovel._id}
                  post_title={imovel.post_title}
                  src={this.getImageSrc(imovel)}
                  tipo={imovel.metas.tipo}
                  bairro={imovel.metas.bairro}
                  cidade={imovel.metas.cidade}
                  smSize={12}
                  mdSize={12}
                />
            );

            return (
              <React.Fragment key={imovel._id}>
                {
                  showInfoWindow[imovel._id] && (
                    <InfoWindow options={{ maxWidth: "320" }}
                                position={{ lat: position.lat + 0.00001, lng: position.lng }}
                                onCloseClick={ this.handleMouseExit }
                    >
                      <div className="Infowindow">
                        { info }
                      </div>
                    </InfoWindow>
                  )
                }
                <MarkerWithLabel
                  icon={{ url: constants.SRC_MARKER_IMOVEL, scale: 2 }}
                  labelAnchor={ position }
                  labelStyle={{ backgroundColor: this.props.markerColor[imovel.metas.tipo.name] || 'rgb(170, 196, 1)', padding: "5px" }}
                  position={ position }
                  onClick={marker => {
                    this.handleMouseOverClick(imovel._id);
                  }}
                >
                  <div>
                    { imovel.post_title ? imovel.post_title : '' }
                  </div>
                </MarkerWithLabel>
              </React.Fragment>
            );
          });
        }

      }

      if (center.lat === 0) {
        center.lat = myCoordinates.latitude
        center.lng = myCoordinates.longitude
      }

      // if (firstTime) {
      //   window.scrollTo(0, 0)
      // }

      return (
        <>
          {
            <div className="Map">
            <GoogleMap
              defaultZoom={this.state.zoom}
              ref={node => {
                this.map = node
              }}
              center={center}
              onClick={e => this.handlePositionChange(e.latLng)}
              onDragEnd={() => this._onCenterChange()}
              onZoomChanged={() => this.onZoomChanged()}
            >
              {markers}
            </GoogleMap>
          </div>
          }
        </>
      )
    }
  }

  onZoomChanged = () => {
    let cent = {
      lat: parseFloat(this.map.getCenter().lat()),
      lng: parseFloat(this.map.getCenter().lng())
    }
    this.setState({
      zoom: this.map.getZoom(),
      center: cent
    })
    const boundCoordinates = this.handleWindowBounds(cent.lat, cent.lng)
    this.props.onLoadPolygonCoordinates(cent, boundCoordinates);
  }

  handleCenterMap = () => {
    this.props.onCenter()
    this.centerMy = true
  }

  handlePositionChange = location => {
    this.addressSearch = false
    let coordenadas = {
      latitude: Number(location.lat()),
      longitude: Number(location.lng())
    }
    const boundCoordinates = this.handleWindowBounds(coordenadas.latitude, coordenadas.longitude)
    this.centerMy = false
    this.props.onLoadPolygonCoordinates(coordenadas, boundCoordinates);
  }
}

const mapStateToProps = state => {
  return {
    // imoveisWithinPolygon: state.localizacao.imoveisWithinPolygon,
    loading: state.localizacao.loading,
    center: state.localizacao.center,
    buscou: state.localizacao.buscou,
    firstTime: state.localizacao.firstTime,
    centraliza: state.localizacao.centraliza,
    coordenadas: state.localizacao.coordenadas,
    error_geo: state.localizacao.error_geo,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    // onLoadPolygonCoordinates: (coordenadas, lngne, latne, lngsw, latsw) => dispatch(actions.onLoadPolygonCoordinates(coordenadas, lngne, latne, lngsw, latsw)),
    onSetLocalizacao: (coordenadas) => dispatch(actions.onSetLocalizacao(coordenadas)),
    onCenter: (centraliza) => dispatch(actions.centralizar(centraliza)),
    onSetCenter: (center) => dispatch(actions.onSetCenter(center)),
    onSetFirstTime: (firstTime) => dispatch(actions.onSetFirstTime(firstTime)),
  };
};

export default withMap(connect(mapStateToProps, mapDispatchToProps)(withErrorHandler(BuscaMapa, axios)));