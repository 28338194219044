import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import DashboardIcon from '@material-ui/icons/Dashboard';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import TimelineIcon from '@material-ui/icons/Timeline';
import PeopleIcon from '@material-ui/icons/People';

export const mainListItems = (onChangeItem, role) => (
  <div>
    <ListSubheader inset>Painel</ListSubheader>
    <ListItem button onClick={() => onChangeItem(0)}>
      <ListItemIcon>
        <DashboardIcon />
      </ListItemIcon>
      <ListItemText primary="Posts" />
    </ListItem>
    <ListItem button onClick={() => onChangeItem(1)}>
      <ListItemIcon>
        <AnnouncementIcon />
      </ListItemIcon>
      <ListItemText primary="Novos Anúncios" />
    </ListItem>
    <ListItem button onClick={() => onChangeItem(2)}>
      <ListItemIcon>
        <PeopleIcon />
      </ListItemIcon>
      <ListItemText primary="Corretores" />
    </ListItem>
    {
      role === 'Admin' &&
      <ListItem button onClick={() => onChangeItem(3)}>
        <ListItemIcon>
          <PeopleIcon />
        </ListItemIcon>
        <ListItemText primary="Admins por Cidade" />
      </ListItem>
    }
    <ListItem button onClick={() => onChangeItem(4)}>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Relatório de Acessos" />
    </ListItem>
    {
      role === 'Admin' &&
      <ListItem button onClick={() => onChangeItem(5)}>
      <ListItemIcon>
        <TimelineIcon />
      </ListItemIcon>
      <ListItemText primary="Analytics" />
    </ListItem>
    }
    {
      role === 'Admin' &&
      <ListItem button onClick={() => onChangeItem(6)}>
        <ListItemIcon>
          <PeopleIcon />
        </ListItemIcon>
        <ListItemText primary="Anúncios em Páginas" />
      </ListItem>
    }
  </div>
);

export const secondaryListItems = (
  <div>
    <ListSubheader inset>Relatórios</ListSubheader>
    <ListItem button>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Analytics" />
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Usuários" />
    </ListItem>
  </div>
);
