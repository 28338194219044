import * as actionsNames from '../actions/actionsNames';
import { updateObject } from '../../utils/utility';

const initialState = {
    loading: false,
    users: undefined,
    admins: undefined,
    user: {}
};

const actionStart = (state, action) => {
    return updateObject(state, { loading: true });
};

const actionFail = (state, action) => {
    return updateObject(state, {
        loading: false,
        error: action.error
    });
};

const fetchUserByAdminStart = (state, action) => {
    return updateObject(state, {
        loading: false,
        user: {}
    });
};

const fetchUserByAdminSuccess = (state, action) => {
    return updateObject(state, { 
        loading: false,
        user: action.data
    });
};

const fetchUsersSuccess = (state, action) => {
    return updateObject(state, { 
        loading: false,
        users: action.data
    });
};

const fetchAdminsSuccess = (state, action) => {
    return updateObject(state, { 
        loading: false,
        admins: action.data
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionsNames.FETCH_USERS_START: return actionStart(state, action);
        case actionsNames.FETCH_USERS_SUCCESS: return fetchUsersSuccess(state, action);
        case actionsNames.FETCH_USERS_FAIL: return actionFail(state, action);
        case actionsNames.FETCH_ADMINS_START: return actionStart(state, action);
        case actionsNames.FETCH_ADMINS_SUCCESS: return fetchAdminsSuccess(state, action);
        case actionsNames.FETCH_ADMINS_FAIL: return actionFail(state, action);
        case actionsNames.FETCH_USER_BY_ADMIN_START: return fetchUserByAdminStart(state, action);
        case actionsNames.FETCH_USER_BY_ADMIN_SUCCESS: return fetchUserByAdminSuccess(state, action);
        case actionsNames.FETCH_USER_BY_ADMIN_FAIL: return actionFail(state, action);
        case actionsNames.RESET_USERS_PROPS: return fetchUserByAdminStart(state, action);
        default: return state;
    }
};

export default reducer;
