import * as actionsNames from './actionsNames';
import api from '../../services/api';
import configToken from '../../utils/configToken';

const fetchReportsStart = () => {
  return {
    type: actionsNames.FETCH_REPORTS_START
  };
};

const fetchReportsSuccess = data => {
  return {
    type: actionsNames.FETCH_REPORTS_SUCCESS,
    data: data
  };
};

const fetchReportsFail = error => {
  return {
    type: actionsNames.FETCH_REPORTS_FAIL,
    data: error
  };
};

export const fetchReports = () => {
	return dispatch => {
		dispatch(fetchReportsStart());
		api
			.get("/users/admin/reports", configToken())
			.then(res => {
				dispatch(fetchReportsSuccess(res.data.map(r => ({
					...r,
					...r.user_events
				}))));
			})
			.catch(err => {
				dispatch(fetchReportsFail(err));
			});
	};
};

const fetchReportStart = () => {
	return {
	  type: actionsNames.FETCH_REPORT_START
	};
  };

const fetchReportSuccess = data => {
	return {
		type: actionsNames.FETCH_REPORT_SUCCESS,
		data: data
	};
};

const fetchReportFail = error => {
	return {
		type: actionsNames.FETCH_REPORT_FAIL,
		error: error
	};
};

export const fetchReport = (id) => {
	return dispatch => {
	  dispatch(fetchReportStart());
	  api
		.get(`/users/admin/report?id=${id}`, configToken())
		.then(res => {
		  dispatch(fetchReportSuccess(res.data));
		})
		.catch(res => {
		  dispatch(fetchReportFail(res.data));
		})
	}
};