import axios from "axios";
const SERVER_URL = process.env.REACT_APP_SERVER_URL;
// const SERVER_URL = "http://localhost:8080";
const api = axios.create({
  baseURL: SERVER_URL,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers":
      "Origin, X-Requested-With, Content-Type, Accept"
  }
});

// const UNAUTHORIZED = 401;
api.interceptors.response.use(
  response => response,
  error => {
    // const {status} = error.response;
    // console.log(error.response);
    // if (status === UNAUTHORIZED) {
    //   window.location.href = '/';
    // }
    return Promise.reject(error);
  }
);

export default api;
