export const createFormDataImages = (key, formData, images, type = "image/png") => {
    if (images) {
      if (images.length) {
        for (var x = 0; x < images.length; x++) {
          let file = new Blob([images[x].file], {type: type});
      
          if (file) {
            formData.append(key, file, file.name);
          }
        }
      } else {
        let file = new Blob([images.file], {type: type});
      
        if (file) {
          formData.append(key, file, file.name);
        }
      }
    }

    return formData;
  }