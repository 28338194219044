export const GOOGLE_API_KEY = 'AIzaSyCRrwsJQfUXOsVKnI3rg3cWe5bRjWXaEIA'
export const DISTANCIA_PROXIMA = 50;
export const DISTANCIA_MINIMA = 10;
export const ZOOM_DEFAULT = 14;
export const GEO_DEFAULT = {latitude: -15.802130175898295, longitude: -47.85172146457389};
export const SRC_MARKER_LOCALIZACAO = {url: "/icons8-street-view-50.png"};
export const SRC_MARKER_IMOVEL = 'https://res.cloudinary.com/hudsonmarinho/image/upload/v1593664313/icon-pin-map_gpgaq1.svg';
export const SECAO_FORM = {
    PROPRIETARIO: 'Dados do Proprietário',
    IMOVEL: 'Dados do Imóvel',
    CONDOMINIO:'Área Comum do Condomínio',
    VALORES: 'Valor de Venda',
    VALOR_VENDA_LOC: 'Valores de Venda e Locação',
    VALOR_LOC: 'Valor de Locação',
    PERMUTA: 'Aceita Permuta?',
    INTERESSADOS: 'Interessados',
    NODROP: 'NODROP',
}
export const SRC_IMOVEL_DEFAULTIMG = 'https://img.icons8.com/material/64/000000/cottage--v1.png';
