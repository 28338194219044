import React from 'react';
import { NavLink } from 'react-router-dom';
import './Logo.scss';

import imageLogo from '../../assets/images/slogo.png';

const logo = (props) => {
  const wrapperLogo = <>
    <img src={ imageLogo } alt="Plano Cidade" />
    {/* <span className="text">PLANO CIDADE</span> */}
  </>

  if(props.to) {
    return(
      <NavLink
        className="Logo"
        to={props.to}
        style={{ height: props.height }}
      >
        { wrapperLogo }
      </NavLink>
    )
  } else {
    return(
      <span
        className="Logo"
        style={{ height: props.height }}
      >
        { wrapperLogo }
      </span>
    )
  }
};

export default logo;
