import React, { useEffect, useState } from "react";
import { Button, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import GeneralSelect from "../../components/GeneralSelect/GeneralSelect";
import api from "../../services/api";
import backgroundImg from "../../sky-building.jpg";

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: "#265f25",
    height: "8rem",
    justifyContent: "center",
  },
  extraLargeButton: {
    width: "30rem",
    height: "8rem",
    fontSize: "3.5rem",
    fontWeight: "bold",
    padding: "1rem 2rem",
    margin: "0 10px",
    borderRadius: "30px",
    backgroundColor: "#fff",
    color: "#003a70",
    "&:focus": {
      backgroundColor: "#ffc905",
    },
    "&:hover": {
      backgroundColor: "#ffc905",
    },
    [theme.breakpoints.down("sm")]: {
      margin: "10px 0",
      display: "flex",
      justifyContent: "center",
    },
  },
  yellowExtraLargeButton: {
    width: "30rem",
    height: "8rem",
    fontSize: "3.5rem",
    fontWeight: "bold",
    padding: "1rem 2rem",
    margin: "0 10px",
    borderRadius: "30px",
    backgroundColor: "#ffc905",
    color: "#003a70",
    [theme.breakpoints.down("sm")]: {
      margin: "10px 0",
      display: "flex",
      justifyContent: "center",
    },
  },
  menuButtons: {
    marginLeft: "auto",
  },
  layoutHeight: {
    height: "calc(100vh - 90px)",
    display: "flex",
    backgroundImage: `url(${backgroundImg})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  titleConfig: {
    fontSize: "32px",
    fontWeight:"bold",
    color: "#003a70",
    margin: "20px",
    textAlign: "center",
    textShadow:"1px 1px 2px white"
  },
  logo: {
    maxWidth: "50%",
    maxHeight: "50px",
  },
  bodyLogo: {
    maxWidth: "100%",
    maxHeight: "100px",
  },
  body: {},
  buttonSearch: {
    fontSize: "1.2rem",
    padding: "1rem 2rem",
    "&:hover": {
      backgroundColor: "#003a70",
      color: "#fff",
    },
    backgroundColor: "#fff",
    display: "flex",
    justifyContent: "center",
  },
  select: {
    width: "62rem",
    marginBottom: theme.spacing(2),
    backgroundColor: "#fff",
    [theme.breakpoints.down("sm")]: {
      width: "35rem",
    },
  },
  form: {
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
  },
  search: {
    marginTop: "32px",
    width: "80%",
  },
  item: {
    width: "100%",
  },
}));

function LandingPage(props) {
  const classes = useStyles();
  const [showPurchaseOptions, setShowPurchaseOptions] = useState(false);
  const [showRentOptions, setShowRentOptions] = useState(false);
  const [filterOptions, setFilterOptions] = useState({});
  const [tipo, setTipo] = useState("");
  const [action, setAction] = useState("");
  const [city, setCity] = useState("");
  const [neighborhood, setNeighborhood] = useState("");

  const itensDeEmpreendimentos = [
    "Casa de Condominio",
    "Casa",
    "Apartamento",
    "Imóvel Comercial",
    "Terreno Comercial",
    "Terreno",
    "Fazenda / Sítio",
    "Loja",
    "Chácara",
    "Cobertura",
    "Imóvel Industrial",
    "Kitnet",
    "Escritório Comercial",
  ];

  useEffect(() => {
    loadFilter({
      city: city,
      tipo: tipo,
    });
  }, [tipo, city]);

  const loadFilter = (params) => {
    let properties = "";
    properties += `?cidade=${params.city}&tipo=${params.tipo}`;

    api
      .get("/posts/filter" + properties)
      .then((res) => {
        setFilterOptions(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleRentButton = () => {
    setAction("rent");
    setShowRentOptions(!showRentOptions);
    setShowPurchaseOptions(false);
  };

  const handlePurchaseButton = () => {
    setAction("purchase");
    setShowPurchaseOptions(!showPurchaseOptions);
    setShowRentOptions(false);
  };

  const handleSearchButton = () => {
    props.history.push(
      `/pesquisa?purchase=${action}&city=${city}&neighborhood=${neighborhood}&type=${tipo}`
    );
  };

  return (
    <div className={classes.layoutHeight}>
      <Grid container alignItems="center">
        <Grid
          item
          container
          className={classes.body}
          direction="column"
          justifyContent="flex-start"
          alignItems="center"
        >
          <img src="../../logo.png" alt="logo" className={classes.bodyLogo} />
          <Typography className={classes.titleConfig} variant="h3">
            Empreendimentos Imobiliários
          </Typography>
          <Grid item>
            <Button
              variant="outlined"
              color="secundary"
              className={
                action === "purchase"
                  ? classes.yellowExtraLargeButton
                  : classes.extraLargeButton
              }
              onClick={handlePurchaseButton}
            >
              Comprar
            </Button>
            <Button
              variant="outlined"
              color="secundary"
              className={
                action === "rent"
                  ? classes.yellowExtraLargeButton
                  : classes.extraLargeButton
              }
              onClick={handleRentButton}
            >
              Alugar
            </Button>
          </Grid>
          <Grid item className={classes.search}>
            {(showPurchaseOptions || showRentOptions) && (
              <>
                <Grid
                  item
                  container
                  className={classes.form}
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item className={classes.item}>
                    <GeneralSelect
                      value={tipo}
                      nameValue="Todos os imóveis"
                      onChange={(e) => {
                        setTipo(e.target.value);
                      }}
                      optionsArray={itensDeEmpreendimentos}
                    />
                    <GeneralSelect
                      value={city}
                      nameValue="cidade"
                      onChange={(e) => {
                        setCity(e.target.value);
                      }}
                      optionsArray={filterOptions.cidades}
                    />
                    <GeneralSelect
                      value={neighborhood}
                      nameValue="bairro"
                      onChange={(e) => {
                        setNeighborhood(e.target.value);
                      }}
                      optionsArray={filterOptions.bairros}
                    />
                  </Grid>
                  <Grid item>
                    <Button
                      variant="outlined"
                      onClick={handleSearchButton}
                      color="primary"
                      className={classes.buttonSearch}
                    >
                      Buscar
                    </Button>
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default LandingPage;
