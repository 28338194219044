import React, { Component } from "react";
import ReactGA from "react-ga";
import { Route, Switch, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";

import * as actions from "./store/actions/index";

import configToken from "./utils/configToken";
import asyncComponent from "./hoc/asyncComponent/asyncComponent";
import { isAssinaturaValida, obterUsuarioLogado } from "./store/userSession";
import api from "./services/api";

import Layout from "./hoc/Layout/Layout";
import BuscaMapa from "./containers/Busca/Mapa";
import BuscaVenal from "./containers/Busca/Venal";
import Releases from "./containers/Releases";
import Partners from "./containers/Partners";
import Advertise from "./containers/Advertise";
import WhoWeAre from "./containers/WhoWeAre";
import BuscaImoveis from "./containers/Busca/Imoveis";
import Information from "./containers/Information";
import Builders from "./containers/Builders";
import AdminPanel from "./containers/AdminPanel";
import Logout from "./containers/Auth/Logout/Logout";
import AdminPostForm from "./containers/AdminPostForm";
import AnnouncementMessageInformation from "./containers/AnnouncementMessageInformation";
import AdvertiseInformation from "./containers/AdvertiseInformation";
import ReportInformation from "./containers/ReportInformation";
import AdminCreatePost from "./containers/AdminCreatePost";
import CreateNewUser from "./containers/CreateNewUser";
import PostXml from "./containers/PostXML/PostXml";
import ImoveisAVenda from "./containers/ImoveisAVenda/ImoveisAVenda";
import LandingPage from "./containers/LandingPage";
import Search from "./containers/Search/Search";

const TRACKING_ID = "G-17DD303CEQ";
ReactGA.initialize(TRACKING_ID);

const asyncMeusDados = asyncComponent(() => {
  return import("./containers/MeusDados/MeusDados");
});

const asyncAssinatura = asyncComponent(() => {
  return import("./containers/MeusDados/Assinatura/Assinatura");
});

const asyncMeusImoveisMapa = asyncComponent(() => {
  return import("./containers/MeusImoveis/Mapa/Mapa");
});

const asyncMeusImoveisFavorito = asyncComponent(() => {
  return import("./containers/MeusImoveis/Favorito/Favorito");
});

const asyncMeusImoveisEdicao = asyncComponent(() => {
  return import("./containers/MeusImoveis/Edicao/Edicao");
});

const asyncMeusImoveisLista = asyncComponent(() => {
  return import("./containers/MeusImoveis/Lista/Lista");
});

const asyncMeusClientesLista = asyncComponent(() => {
  return import("./containers/MeusClientes/MeusClientes");
});

const asyncAuth = asyncComponent(() => {
  return import("./containers/Auth/Auth");
});

const asyncResetPassword = asyncComponent(() => {
  return import("./containers/Auth/Reset/Reset");
});

const asyncConfirmation = asyncComponent(() => {
  return import("./containers/Auth/Confirmation/Confirmation");
});

const asyncRecoverPassword = asyncComponent(() => {
  return import("./containers/Auth/Recover/Recover");
});

const asyncRemover = asyncComponent(() => {
  return import("./containers/Auth/Remover/Remover");
});

const asyncSignup = asyncComponent(() => {
  return import("./containers/Auth/Signup/Signup");
});

const useStyles = makeStyles({
  cookieAlert: {
    "& .MuiAlert-icon": {
      fontSize: 30,
    },
  },
});

const Alert = (props) => {
  return <MuiAlert className={useStyles().cookieAlert} {...props} />;
};

class App extends Component {
  state = {
    openNotes: false,
    textNotes: null,
    exibido: false,
  };

  verificarNotificacoes = () => {
    api
      .get("/users/me/daysOfPlan", configToken())
      .then((response) => {
        let user = obterUsuarioLogado();
        let days = response && response.data && response.data.daysOfPlan;
        if (user.signature.payment === "Trial") {
          if (days === 7 || days === 3) {
            this.setState({
              exibido: true,
              openNotes: true,
              textNotes: "Faltam " + days + " dias para expirar seu plano.",
            });
          }
          if (days === 1) {
            this.setState({
              exibido: true,
              openNotes: true,
              textNotes: "Falta 1 dia para expirar seu plano.",
            });
          }
          if (days === 0) {
            this.setState({
              exibido: true,
              openNotes: true,
              textNotes: "Seu plano expira hoje.",
            });
          }
          if (days < 0) {
            this.setState({
              exibido: true,
              openNotes: true,
              textNotes: "Plano expirado!",
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  componentDidMount() {
    this.props.onTryAutoSignup();
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  componentDidUpdate() {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ ...this.state, openNotes: false, textNotes: null });
  };

  render() {
    let routes = (
      <Switch>
        <Route exact path="/signup" component={asyncSignup} />
        <Route exact path="/auth" component={asyncAuth} />
        <Route
          exact
          path="/resetPassword/:token"
          component={asyncResetPassword}
        />
        <Route
          exact
          path="/confirmation/:token"
          component={asyncConfirmation}
        />
        <Route exact path="/recoverPassword" component={asyncRecoverPassword} />
        <Route exact path="/busca/mapa" component={BuscaMapa} />
        <Route exact path="/busca/venal" component={BuscaVenal} />
        <Route exact path="/busca/imoveis" component={BuscaImoveis} />
        <Route exact path="/lancamentos" component={Releases} />
        <Route exact path="/imoveis-a-venda" component={ImoveisAVenda} />
        <Route exact path="/contato" component={Advertise} />
        <Route exact path="/quem-somos" component={WhoWeAre} />
        {/* <Route exact path="/contato" component={Contact} /> */}
        <Route exact path="/imoveis/:id" component={Information} />
        <Route exact path="/parceiros" component={Partners} />
        <Route
          exact
          path="/pesquisa"
          component={(props) => <Search {...props} />}
        />
        <Route
          exact
          path="/parceiros/imoveis/:id"
          component={() => <Builders pathurl="/imoveis/" />}
        />
        <Route
          exact
          path="/parceiros/construtora"
          component={() => (
            <Builders
              partner="construtora"
              partnerLabel="Construtoras"
              pathurl="/parceiros/construtora/imoveis/"
            />
          )}
        />
        <Route
          exact
          path="/parceiros/administradora"
          component={() => (
            <Builders
              partner="administradora"
              partnerLabel="Administradoras"
              pathurl="/parceiros/administradora/imoveis/"
            />
          )}
        />
        <Route
          exact
          path="/parceiros/corretora_de_seguro"
          component={() => (
            <Builders
              partner="corretora_de_seguro"
              partnerLabel="Corretoras de Seguro"
              pathurl="/parceiros/imoveis/"
            />
          )}
        />
        <Route
          exact
          path="/parceiros/imobiliaria"
          component={() => (
            <Builders
              partner="imobiliaria"
              pathurl="/pesquisa?purchase=purchase&imobiliaria="
              partnerLabel="Imobiliárias"
            />
          )}
        />
        <Route
          exact
          path="/parceiros/imobiliaria/imoveis/:id"
          component={(props) => <ImoveisAVenda {...props} />}
        />
        <Route
          exact
          path="/parceiros/construtora/imoveis/:id"
          component={(props) => <ImoveisAVenda {...props} />}
        />
        <Route
          exact
          path="/parceiros/administradora/imoveis/:id"
          component={(props) => <ImoveisAVenda {...props} />}
        />
        <Route
          exact
          path="/parceiros/prestadora_de_servico"
          component={() => (
            <Builders
              partner="prestadora_de_servico"
              pathurl="/parceiros/imoveis/"
            />
          )}
        />
        <Route component={(props) => <LandingPage {...props} />} />
      </Switch>
    );

    if (this.props.isAuthenticated) {
      if (!this.state.exibido) {
        this.verificarNotificacoes();
      }
      routes = (
        <Switch>
          <Route path="/admin/panel" component={AdminPanel} />
          <Route path="/admin/create-post" component={AdminCreatePost} />
          <Route path="/admin/create-user/:id" component={CreateNewUser} />
          <Route path="/admin/create-master/:id" component={CreateNewUser} />
          <Route path="/admin/form/:id" component={AdminPostForm} />
          <Route path="/admin/report/:id" component={ReportInformation} />
          <Route
            path="/announcement-message/information/:id"
            component={AnnouncementMessageInformation}
          />
          <Route
            path="/advertise/information/:id"
            component={AdvertiseInformation}
          />
          <Route path="/busca/mapa" exact component={BuscaMapa} />
          <Route path="/busca/venal" exact component={BuscaVenal} />
          <Route
            path="/meusimoveis/favorito/:favorito_id"
            component={
              isAssinaturaValida() ? asyncMeusImoveisFavorito : asyncAssinatura
            }
          />
          <Route
            path="/meusimoveis/edicao/:favorito_id"
            exact
            component={
              isAssinaturaValida() ? asyncMeusImoveisEdicao : asyncAssinatura
            }
          />
          <Route
            path="/meusimoveis/mapa"
            exact
            component={
              isAssinaturaValida() ? asyncMeusImoveisMapa : asyncAssinatura
            }
          />
          <Route
            path="/meusimoveis/lista"
            component={
              isAssinaturaValida() ? asyncMeusImoveisLista : asyncAssinatura
            }
          />
          <Route
            path="/meusclientes"
            component={
              isAssinaturaValida() ? asyncMeusClientesLista : asyncAssinatura
            }
          />
          <Route path="/meusdados" component={asyncMeusDados} />
          <Route path="/assinatura" component={asyncAssinatura} />
          {/* <Route path="/assinatura" component={ asyncAssinatura } /> */}
          <Route exact path="/imoveis-a-venda" component={ImoveisAVenda} />
          <Route path="/postxml" component={PostXml} />
          <Route path="/lancamentos" component={Releases} />
          <Route path="/logout/all" component={() => <Logout all />} />
          <Route path="/logout" component={Logout} />
          {/* <Route path="/contato" component={Contato} /> */}
          <Route path="/excluirconta" component={asyncRemover} />
          <Route component={PostXml} />
        </Switch>
      );
    }

    let texto = null;
    if (this.state.textNotes === "Plano expirado!") {
      texto = (
        <React.Fragment>
          <h4>{this.state.textNotes}</h4>
          <NavLink
            onClick={this.handleClose}
            style={{ fontWeight: "bold" }}
            to="/assinatura"
          >
            <h5>Ver Assinatura</h5>
          </NavLink>
        </React.Fragment>
      );
    } else {
      texto = <h4>{this.state.textNotes}</h4>;
    }
    let avisos = (
      <Snackbar
        open={this.state.openNotes}
        autoHideDuration={15000}
        onClose={this.handleClose}
      >
        <Alert onClose={this.handleClose} severity="warning">
          {texto}
        </Alert>
      </Snackbar>
    );

    return (
      <div>
        {avisos}
        <Layout>{routes}</Layout>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.token !== null,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onTryAutoSignup: () => dispatch(actions.authCheckState()),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
