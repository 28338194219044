import React from 'react';

import './FindButton.scss';

const findButton = ( props ) => (
	<button
		disabled={props.disabled}
		title={props.title}
		className={["FindButton", props.btnType].join(' ')}
		onClick={props.clicked}
  >
    { props.children }
  </button>
);

export default findButton;
