import React from 'react';
import { NavLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import PartnerCard from '../../components/UI/PartnerCard';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center'
  },
  partnerTitle: {
    display: 'flex',
    justifyContent: 'center',
    color: '#003a70',
    fontSize: '30px'
  }
}));

export default function PartnerBanner ({ partnerTitle, partnerLink, advertisement }) {
  const classes = useStyles();

  return (
    <div>
      <NavLink to={partnerLink}>
        <span className={classes.partnerTitle}>
          {partnerTitle}
        </span>
      </NavLink>
      <br />
      <div className={classes.root}>
        <Grid container spacing={5} style={{ maxWidth: '1200px' }}>
          <Grid item xs={12}>
              <PartnerCard advertisement={advertisement} />
          </Grid>     
        </Grid>
      </div>
      <br />
    </div>
  )
}