import * as actionsNames from './actionsNames';
import api from '../../services/api';
import configToken from '../../utils/configToken';

const fetchPostStart = () => {
  return {
    type: actionsNames.FETCH_POST_START
  };
};

const fetchPostSuccess = data => {
  return {
    type: actionsNames.FETCH_POST_SUCCESS,
    data: data
  };
};

const fetchPostFail = error => {
  return {
    type: actionsNames.FETCH_POST_FAIL,
    error: error
  };
};

export const fetchPost = (id) => {
  return dispatch => {
    dispatch(fetchPostStart());
    api
      .get(`/posts/admin/search?id=${id}`, configToken())
      .then(res => {
        dispatch(fetchPostSuccess(res.data));
      })
      .catch(res => {
        dispatch(fetchPostFail(res.data));
      })
  }
};

export const fetchPostAdmin = (id) => {
  return dispatch => {
    dispatch(fetchPostStart());
    api
      .get(`/posts/admin/post?id=${id}`, configToken())
      .then(res => {
        dispatch(fetchPostSuccess(res.data));
      })
      .catch(res => {
        dispatch(fetchPostFail(res.data));
      })
  }
};

export const updatePost = (key, value) => {
  return {
    type: actionsNames.UPDATE_POST,
    data: {
      key: key,
      value: value
    }
  };
};

export const updateBasicPost = (key, value) => {
  return {
    type: actionsNames.UPDATE_BASIC_POST,
    data: {
      key: key,
      value: value
    }
  };
};
