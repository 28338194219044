import React, { Component } from 'react';

import { connect} from 'react-redux';
import { GoogleMap, Marker, InfoWindow } from 'react-google-maps'
import { NavLink, Redirect } from 'react-router-dom';
import MarkerWithLabel from "react-google-maps/lib/components/addons/MarkerWithLabel";
import { geocodeByAddress } from "react-places-autocomplete";

import * as actions from '../../../store/actions';
import * as constants from '../../../utils/constants';
import Spinner from '../../../components/UI/Spinner/Spinner';
import axios from '../../../services/api'
import axiosGeo from '../../../services/apiGoogle'
import withErrorHandler from '../../../hoc/withErrorHandler/withErrorHandler';
import withMap from '../../../components/map/withMap';
import Button from '../../../components/UI/Button/Button';
import { fetchCoordinates } from "../../../utils/geolocation";
import AddressInput from "../../../components/map/AddressInput";
import { isAssinaturaValida } from '../../../store/userSession';
import '../../../components/Navigation/NavigationItems/NavigationItems.scss';

import HeaderNav from "../../../components/UI/HeaderNav";
import MenuCard from "../../../components/UI/MenuCard";
import CardButton from "../../../components/UI/MenuCard/components/ButtonCard";

import {ButtonHolder, FloatButtons} from "../../../components/map/styles/Map.style";
import {ChildButton, FloatingMenu} from "react-floating-button-menu";
import Tooltip from '@material-ui/core/Tooltip';
import * as Icons from "@material-ui/icons";

import IconHome from '../../../assets/images/icon-home.svg';
import IconFavorites from '../../../assets/images/icon-favorites.svg';
import IconUser from '../../../assets/images/icon-user.svg';
import heartempty from '../../../assets/images/heart2.png';
import heartfull from '../../../assets/images/heart1.png';

import './index.scss';

class BuscaMapa extends Component {
  changeCenterActive = false;
  centerMy = false;
  addressSearch = false;
  state = {
    showInfoWindow: [],
    showInfoWindowUser: true,
    showWindowGroup: false,
    myCoordinates: {
      latitude: sessionStorage.getItem('latitude') !== undefined ? Number(sessionStorage.getItem('latitude')) : 0,
      longitude: sessionStorage.getItem('longitude') !== undefined ? Number(sessionStorage.getItem('longitude')) : 0
    },
    favoritou: false,
    address: '',
    loadingAddress: false,
    position: null,
    type: null,
    id: null,
  };

  handleCoordinates = (address) => {
    return geocodeByAddress(address)
  }

  handleMouseOverClick = (id) => {
    let updatedshowInfoWindow = null;

    if (this.state.showInfoWindow.length === this.props.imoveisproximos.length) {
      updatedshowInfoWindow = this.state.showInfoWindow.map((item, index) => index === id)
    } else {
      updatedshowInfoWindow = [];

      if (this.props.imoveisproximos) {
        updatedshowInfoWindow = this.props.imoveisproximos.reduce((updatedshowInfoWindow, imovel) => {
          updatedshowInfoWindow[imovel._id] = (id === imovel._id);

          return updatedshowInfoWindow;
        }, updatedshowInfoWindow);
      }
    }

    this.setState({
      showInfoWindow: updatedshowInfoWindow
    });
  };

  handleMouseOverClickPredio = (id) => {
    let updatedshowInfoWindow = null;

    if (this.state.showInfoWindow.length === this.props.imoveisproximospredios.length) {
      updatedshowInfoWindow = this.state.showInfoWindow.map((item, index) => index === id)
    } else {
      updatedshowInfoWindow = [];

      if (this.props.imoveisproximospredios) {
        updatedshowInfoWindow = this.props.imoveisproximospredios.reduce((updatedshowInfoWindow, imovel) => {
          imovel = Object.values(imovel)

          for (let i = 0; i < imovel.length; i++) {
            if (imovel[i]._id === id) {
              updatedshowInfoWindow[imovel[i]._id] = true
              break;
            }
          }

          return updatedshowInfoWindow;
        }, updatedshowInfoWindow);
      }
    }

    this.setState({
      showInfoWindow: updatedshowInfoWindow
    });
  };

  handleMouseExit = e => {
    let updatedshowInfoWindow = this.state.showInfoWindow.map(item => false)

    this.setState({
      showInfoWindow: updatedshowInfoWindow
    });
  };

  handleMouseExitMe = e => {
    let updatedshowInfoWindow = this.state.showInfoWindowUser

    this.setState({
      showInfoWindowUser: !updatedshowInfoWindow
    });
  };

  handleOpenVenal() {
    let path = `/busca/venal`;
    this.props.history.push(path);
  }

  tryAPIGeolocation = () => {
    return axiosGeo.post("https://www.googleapis.com/geolocation/v1/geolocate?key=AIzaSyCRrwsJQfUXOsVKnI3rg3cWe5bRjWXaEIA")
      .then(response => {
        sessionStorage.removeItem('latitude')
        sessionStorage.removeItem('longitude')
        sessionStorage.setItem('latitude', response.data.location.lat)
        sessionStorage.setItem('longitude', response.data.location.lng)

        return response.data
      }).catch(error => {

        return false
      });
  }

  async componentDidMount() {
    this.centerMy = true
    this.props.onSetFirstTime(true)
    let position = await fetchCoordinates()
    //await this._watchLocation()

    if (position !== undefined) {
      let coordMy = {
        latitude: position.latitude,
        longitude: position.longitude
      }

      this.setState({
        myCoordinates: coordMy
      })
      this.changeCenterActive = false;
    }
  }

  componentWillUnmount() {
    console.log('saindo')

    this.centerMy = true
    this.props.onSetLocalizacao(this.state.myCoordinates)

    let cent = {
      lat: this.state.myCoordinates.latitude,
      lng: this.state.myCoordinates.longitude
    }

    this.props.onSetCenter(cent);
    this.changeCenterActive = true;
  }

  _watchLocation = async () => {
    await navigator.geolocation.watchPosition(position => {
      this.changeCenterActive = false;

      sessionStorage.removeItem('latitude')
      sessionStorage.removeItem('longitude')
      sessionStorage.setItem('latitude', position.coords.latitude)
      sessionStorage.setItem('longitude', position.coords.longitude)

      this.setState({myCoordinates: {latitude: position.coords.latitude, longitude: position.coords.longitude}})
    })
  }

  _watchLocationSafari = async () => {
    setInterval(async function () {
      console.log('_watchLocationSafari')

      axiosGeo.post("https://www.googleapis.com/geolocation/v1/geolocate?key=AIzaSyCRrwsJQfUXOsVKnI3rg3cWe5bRjWXaEIA")
        .then(response => {
          sessionStorage.removeItem('latitude')
          sessionStorage.removeItem('longitude')
          sessionStorage.setItem('latitude', response.data.location.lat)
          sessionStorage.setItem('longitude', response.data.location.lng)

          return response.data
        }).catch(error => {
        console.log(error)
      })
    }, 5000)
  }

  _onCenterChange = () => {
    if (this.changeCenterActive) {
      let cent = {
        lat: Number(this.map.getCenter().lat()),
        lng: Number(this.map.getCenter().lng())
      }

      this.props.onSetCenter(cent);
    } else {
      //gato para evitar change centr no onload
      this.changeCenterActive = true;
    }
  };


  handleReset = () => {
    this.setState({
      loadingAddress: false,
      position: null,
      address: ''
    })
  }

  onChange = (address) => {
    this.setState({ address: address })
  }

  onPlaceSelected = (fullAddress) => {
    this.addressSearch = true
    this.setState({address: fullAddress})

    this.handleCoordinates(fullAddress).then(result => {
      let place = result[0]
      let distancia = constants.DISTANCIA_PROXIMA
      let bok = false

      for (let i = 0; i < place.address_components.length; i++) {
        let comp = place.address_components[i]

        for (let j = 0; j < comp.types.length; j++) {
          let type = comp.types[j]

          if (type === 'street_number') {
            bok = true
            break
          }
        }
      }

      if (bok) {
        distancia = constants.DISTANCIA_MINIMA
      }

      let coordenadas = {
        latitude: place.geometry.location.lat(),
        longitude: place.geometry.location.lng()
      }
      let formatted_address = place.formatted_address

      this.centerMy = false;
      this.changeCenterActive = false;
      this.props.onLoadLocalizacao(
        coordenadas,
        distancia,
        formatted_address
      );

      if (this.props.isAuthenticated)
        this.props.onInit(coordenadas)
      else {
        this.props.onSetLocalizacao(coordenadas)
      }

      this.props.onSetFirstTime(false);
    })
  };

  render() {
    if (this.props.isAuthenticated && this.props.id_redirect_favorito) {
      return <Redirect to={"/meusimoveis/edicao/" + this.props.id_redirect_favorito}/>
    }

    let {
      centraliza,
      center,
      imoveisproximos,
      imoveisproximospredios,
      buscou,
      isAuthenticated,
      favoritos,
      favoritosPredios,
      loadingimoveis,
      loading,
      firstTime
    } = this.props;

    let {
      showInfoWindow,
      showInfoWindowUser,
      myCoordinates,
      loadingAddress,
      position,
      address
    } = this.state;

    // var latC = 0;
    // var lngC = 0;

    let zoom = constants.ZOOM_DEFAULT;
    if (this.props.error_geo) {
      zoom = 3;
      center = {
        lat: constants.GEO_DEFAULT.latitude,
        lng: constants.GEO_DEFAULT.longitude
      };
      //TODO aviso não foi possível obter geolocalizacao
    }

    if (centraliza || this.centerMy) {
      center = {
        lat: myCoordinates.latitude,
        lng: myCoordinates.longitude
      };
    }

    if (loading || loadingimoveis) {
      return (<Spinner/>);
    } else {
      let markers = null;
      let markersPredios = null;

      if (imoveisproximos.length > 0 || imoveisproximospredios.length > 0) {
        if (imoveisproximos) {
          markers = imoveisproximos.map(imovel => {
            const coordinates = imovel.property_location.coordinates;
            let position = {lat: Number(coordinates[1]), lng: Number(coordinates[0])};
            let info = <h4>Valor Venal Total: R$ {imovel.total_venal_value}</h4>

            if (isAuthenticated) {
              if(!isAssinaturaValida()){
                info = (
                  <React.Fragment>
                    { info }

                    <h5>
                      <strong>Endereço:</strong> { imovel.address }
                      { imovel.property_address.block ? `, Qd. ${imovel.property_address.block}` : '' }
                      { imovel.property_address.lot ? `. Lt. ${imovel.property_address.lot}` : ''}
                    </h5>

                    <div className="boxcenter">
                      <div className="avisoimovel">
                        <h5>Assinatura expirada!</h5>
                      </div>

                      <NavLink
                        style={{fontWeight: 'bold'}}
                        to="/assinatura"
                      >
                        <Button>Ver Assinatura</Button>
                      </NavLink>
                    </div>
                  </React.Fragment>
                );
              } else {
                let controlefavorito = (
                  <Button clicked={() => this.adicionarFavorito(imovel._id, true, 0, 0)}>
                    Adicionar e Editar
                  </Button>
                );
                let favorito = favoritos && favoritos.find(element => element.property !== undefined && element.property._id === imovel._id);
                let heart = null;

                if (favorito) {
                  favorito = favorito.favorite;

                  controlefavorito = (
                    <NavLink
                      style={{ fontWeight: 'bold', marginBottom: '2px' }}
                      to={ "/meusimoveis/edicao/" + favorito._id }
                    >
                      <Button>Editar Favorito</Button>
                    </NavLink>
                  );

                  heart = (
                    <button className="btcentericon heart">
                      <img style={{ width: "30px" }} src={ heartfull } alt="Favorito"/>
                    </button>
                  );
                } else {
                  heart = (
                    <button className="btcentericon heart" onClick={() => this.adicionarFavorito(imovel._id, false, 0, 0)}>
                      <img style={{ width: "30px" }} src={ heartempty } alt="Adicionar Favorito"/>
                    </button>
                  );
                }

                info = (
                  <React.Fragment>
                    { info }

                    <h5>
                      <strong>Endereço:</strong> { imovel.address }
                      { imovel.property_address.block ? `, Qd. ${imovel.property_address.block}` : '' }
                      { imovel.property_address.lot ? `. Lt. ${imovel.property_address.lot}` : ''}
                    </h5>

                    <div style={{ display: 'flex' }}>
                      <h5>Inscrição Imobiliária: { imovel.municipal_register }</h5>
                      { heart }
                    </div>

                    { imovel.ground_area ? <h5>Área Territorial: { imovel.ground_area }</h5> : null }
                    { imovel.building_area ? <h5>Área Construída: { imovel.building_area }</h5> : null }
                    { imovel.territorial_venal_value ? <h5>Valor Venal Territorial: R$ { imovel.territorial_venal_value }</h5> : null }
                    { imovel.property_venal_value ? <h5>Valor Venal Predial: R$ { imovel.property_venal_value }</h5> : null }
                    { imovel.total_venal_value ? <h5>Valor Venal Total: R$ { imovel.total_venal_value }</h5> : null }
                    { imovel.iptu ? <h5>IPTU: R$ { imovel.iptu }</h5> : null }
                    { imovel.building_year ? <h5>Ano Construção: { imovel.building_year }</h5> : null }

                    <div className="boxcenter">
                      { controlefavorito }
                    </div>
                  </React.Fragment>
                );
              }
            } else {
              info = (
                <React.Fragment>
                  {/* { info } */}

                  <div className="CardMaps">
                    <h2>Informações do Imóvel</h2>

                    <div>
                      <p>
                        <strong>Endereço:</strong> <span>{ imovel.address }</span>
                        { imovel.property_address.block || imovel.property_address.lot ? ',' : '' }
                        <br />
                        <strong>{ imovel.property_address.block ? 'Qd.' : '' }</strong> <span>{ imovel.property_address.block ? imovel.property_address.block : '' }
                        { imovel.property_address.block ? ',' : '' }
                        </span>{ imovel.property_address.block ? <br /> : '' }
                        <strong>{ imovel.property_address.lot ? 'Lt.' : '' }</strong> <span>{ imovel.property_address.lot ? imovel.property_address.lot : '' }</span>
                      </p>

                      <p>
                        <strong>Valor Venal Total:</strong> { imovel.total_venal_value ? " R$ " + imovel.total_venal_value : null }
                      </p>
                    </div>

                    <div className="boxcenter">
                      <div className="avisoimovel">
                        <h5>Quer ver mais informações? Faça seu cadastro e comece agora mesmo a salvar seus imóveis.</h5>
                      </div>

                      {/* <NavLink
                        style={{fontWeight: 'bold'}}
                        to="/signup"
                      >
                        <Button>Cadastro</Button>
                      </NavLink> */}
                    </div>
                  </div>
                </React.Fragment>
              );
            }

            return (
              <React.Fragment key={imovel._id}>
                {
                  showInfoWindow[imovel._id] && (
                    <InfoWindow options={{ maxWidth: "320" }}
                                position={{ lat: position.lat + 0.00001, lng: position.lng }}
                                onCloseClick={ this.handleMouseExit }
                    >
                      <div className="Infowindow">
                        { info }
                      </div>
                    </InfoWindow>
                  )
                }
                <MarkerWithLabel
                  icon={{ url: constants.SRC_MARKER_IMOVEL, scale: 2 }}
                  labelAnchor={ position }
                  labelStyle={{ backgroundColor: "yellow", padding: "5px" }}
                  position={ position }
                  onClick={marker => {
                    this.handleMouseOverClick(imovel._id);
                  }}
                  onMouseOver={() => this.handleMouseOverClick(imovel._id)}
                >
                  <div>
                    { imovel.property_address.block ? `Qd. ${imovel.property_address.block}` : '' }{ imovel.property_address.number ? `${imovel.property_address.number}` : '' }
                    { imovel.property_address.lot ? `, Lt. ${imovel.property_address.lot}, ` : ''}
                  </div>
                </MarkerWithLabel>
              </React.Fragment>
            );
          });
        }

        if (imoveisproximospredios) {
          markersPredios = imoveisproximospredios.map(predio => {
            predio = Object.values(predio)

            let firstImovel = predio[0]
            let coordinatesFirst = firstImovel.property_location.coordinates;
            let positionFirts = { lat: Number(coordinatesFirst[1]), lng: Number(coordinatesFirst[0]) };
            let info = <span/>

            predio = predio.filter(function (el) {
              return el !== "undefined";
            });

            console.log(predio)
            predio.map(imovel => {
              if (isNaN(imovel)) {
                let infoR = <h4>Valor Venal Total: R$ {imovel.total_venal_value}</h4>

                if (isAuthenticated) {
                  if(isAssinaturaValida()){
                    let controlefavorito = (
                      <Button
                        clicked={() => this.adicionarFavorito(imovel._id, true, 1, firstImovel._id)}
                      >
                        Adicionar e Editar
                      </Button>
                    );
                    let favorito = undefined;

                    for(let i =0; i<favoritosPredios.length;i++) {
                      let prediofav = Object.values(favoritosPredios[i])
                      favorito = prediofav.find(imv => imv.property && imv.property._id === imovel._id);

                      if(favorito){
                        break;
                      }
                    }

                    if(!favorito){
                      favorito = this.props.favoritos.find(imv => imv.property && imv.property._id === imovel._id);
                    }

                    let heart = null;

                    if (favorito !== undefined && favorito.favorite !== undefined) {
                      favorito = favorito.favorite;
                      controlefavorito = (
                        <NavLink
                          style={{ fontWeight: 'bold', marginBottom: '2px' }}
                          to={ "/meusimoveis/edicao/" + favorito._id }>
                          <Button>Editar Favorito</Button>
                        </NavLink>
                      );

                      heart = (
                        <button className="btcentericon heart">
                          <img style={{width: "30px"}}
                               src={heartfull}
                               alt="Favorito" />
                        </button>
                      );
                    } else {
                      heart = (
                        <button className="btcentericon heart"
                                onClick={() => this.adicionarFavorito(imovel._id, false, 1, firstImovel._id)}
                        >
                          <img style={{ width: "30px" }} src={ heartempty } alt="Adicionar Favorito"/>
                        </button>
                      );
                    }

                    info = (
                      <span>
                        { info }

                        <React.Fragment>
                          { infoR }
                          <h5>
                            <strong>Endereço:</strong> { imovel.address }
                            { imovel.property_address.block ? `, Qd. ${imovel.property_address.block}` : '' }
                            { imovel.property_address.lot ? `, Lt. ${imovel.property_address.lot}` : ''}
                          </h5>

                          <div style={{ display: 'flex' }}>
                            <h5>Inscrição Imobiliária: { imovel.municipal_register }</h5>
                            { heart }
                          </div>

                          { imovel.ground_area ? <h5>Área Territorial: { imovel.ground_area }</h5> : null }
                          { imovel.building_area ? <h5>Área Construída: {imovel.building_area}</h5> : null}
                          { imovel.territorial_venal_value ? <h5>Valor Venal Territorial: R$ { imovel.territorial_venal_value }</h5> : null}
                          { imovel.property_venal_value ? <h5>Valor Venal Predial: R$ { imovel.property_venal_value }</h5> : null}
                          { imovel.total_venal_value ? <h5>Valor Venal Total: R$ { imovel.total_venal_value }</h5> : null}
                          { imovel.iptu ? <h5>IPTU: R$ { imovel.iptu }</h5> : null}
                          { imovel.building_year ? <h5>Ano Construção: { imovel.building_year }</h5> : null}

                          <div className="boxcenter">
                            { controlefavorito }
                          </div>
                        </React.Fragment>
                      </span>
                    );
                  } else {
                    info = (
                      <span>
                        { info }

                        <React.Fragment>
                          { infoR }

                          <h5>
                            <strong>Endereço:</strong> { imovel.address }
                            { imovel.property_address.block ? `, Qd. ${imovel.property_address.block}` : '' }
                            { imovel.property_address.lot ? `, Lt. ${imovel.property_address.lot}` : ''}
                          </h5>

                          <div className="boxcenter">
                            <div className="avisoimovel">
                              <h5>Assinatura Expirada.</h5>
                            </div>

                            <NavLink
                              style={{fontWeight: 'bold'}}
                              to="/assinatura">
                              <Button>Ver Assinatura</Button>
                            </NavLink>
                          </div>
                        </React.Fragment>
                      </span>
                    );
                  }
                } else {
                  info = (
                    <span>
                      { info }

                      <React.Fragment>
                        {/* { infoR } */}

                        <div className="CardMaps">
                          <h2>Informações do Imóvel</h2>

                          <div>
                            <p>
                              <strong>Endereço:</strong> <span>{ imovel.address }</span>
                              { imovel.property_address.block || imovel.property_address.lot ? ',' : '' }
                              <br />
                              <strong>{ imovel.property_address.block ? 'Qd.' : '' }</strong> <span>{ imovel.property_address.block ? imovel.property_address.block : '' }
                              { imovel.property_address.block ? ',' : '' }
                              </span>{ imovel.property_address.block ? <br /> : '' }
                              <strong>{ imovel.property_address.lot ? 'Lt.' : '' }</strong> <span>{ imovel.property_address.lot ? imovel.property_address.lot : '' }</span>
                            </p>

                            <p>
                              <strong>Valor Venal Total:</strong> { imovel.total_venal_value ? " R$ " + imovel.total_venal_value : null }
                            </p>
                          </div>

                          <div className="boxcenter">
                            <div className="avisoimovel">
                              <h5>Quer ver mais informações? Faça seu cadastro e comece agora mesmo a salvar seus imóveis.</h5>
                            </div>

                            {/* <NavLink
                              style={{fontWeight: 'bold'}}
                              to="/signup"
                            >
                              <Button>Cadastro</Button>
                            </NavLink> */}
                          </div>
                        </div>
                      </React.Fragment>
                    </span>
                  );
                }
              }

              return true
            })

            return (
              <React.Fragment key={ firstImovel._id}>
                {
                  showInfoWindow[firstImovel._id] && (
                    <InfoWindow options={{ maxWidth: "320" }}
                      position={{lat: positionFirts.lat + 0.00001, lng: positionFirts.lng}}
                      onCloseClick={this.handleMouseExit}
                    >
                      <div className="Infowindow">
                        { info }
                      </div>
                    </InfoWindow>
                  )
                }

                <MarkerWithLabel
                  icon={{url: constants.SRC_MARKER_IMOVEL, scale: 2}}
                  labelAnchor={positionFirts}
                  labelStyle={{backgroundColor: "yellow", padding: "5px"}}
                  position={positionFirts}
                  onClick={marker => {
                    this.handleMouseOverClickPredio(firstImovel._id);
                  }}
                  onMouseOver={() => this.handleMouseOverClickPredio(firstImovel._id)}
                >
                  <div>
                    { firstImovel.property_address.block ? `Qd. ${firstImovel.property_address.block}` : '' }{ firstImovel.property_address.number ? `${firstImovel.property_address.number}` : '' }
                    { firstImovel.property_address.lot ? `, Lt. ${firstImovel.property_address.lot}, ` : ''}
                  </div>
                </MarkerWithLabel>
              </React.Fragment>
            );
          });
        }
      } else {
        if (buscou && !firstTime) {
          if(this.addressSearch){
            alert('Imóvel não encontrado!\n\n' +
                  'Endereço retornado pelo Google Maps:' + address + '\n\n' +
                  'Se esse não for o endereço procurado, refaça a busca ajustando o endereço.\n')
          }else{
            alert('Nenhum imóvel encontrado para o ponto clicado')
          }

          this.props.onSetBuscou(false)
        }
      }

      if (center.lat === 0) {
        center.lat = myCoordinates.latitude
        center.lng = myCoordinates.longitude
      }

      if (firstTime) {
        window.scrollTo(0, 0)
      }

      let mostra1 = (showInfoWindowUser && myCoordinates && (centraliza || firstTime || this.centerMy))
      let mostra2 = (centraliza || firstTime || this.centerMy) && myCoordinates


      let infoMarker = <div/>
      let myMarker = <div/>
      // if (mostra1)
      console.log(mostra1);
      if (false) {
        infoMarker = <span><InfoWindow options={{maxWidth: "320"}}
                         position={{
                           lat: Number(myCoordinates.latitude) + 0.00002,
                           lng: Number(myCoordinates.longitude)
                         }}
                         onCloseClick={this.handleMouseExitMe}>
          <div className="Infowindow"><h4>Você está aqui! Clique no mapa para encontrar o(s) imovél(eis) desejado(s) ou pesquise na barra de endereço. </h4>
          </div>
        </InfoWindow></span>
      }

      if (mostra2) {
        myMarker = <Marker icon={constants.SRC_MARKER_LOCALIZACAO}
                   position={{
                     lat: myCoordinates.latitude,
                     lng: myCoordinates.longitude
                   }}/>
      }


      // let centermylocation = (center.lat === myCoordinates.latitude) && (center.lng === myCoordinates.longitude)&&(firstTime||centraliza);
      let mapa = (
        <GoogleMap
          defaultZoom={zoom}
          ref={node => {
            this.map = node
          }}
          center={center}
          onClick={e => this.handlePositionChange(e.latLng)}
          onCenterChanged={() => this._onCenterChange()}
        >
          {infoMarker}
          {myMarker}

          {markers}
          {markersPredios}
        </GoogleMap>
      );

      return (
        <>
          <HeaderNav>
            <div className="FilterSearch">
              <AddressInput
                onPlaceSelected={this.onPlaceSelected}
                onChange={this.onChange}
                onReset={this.handleReset}
                loading={loadingAddress}
                address={address}
                position={position}
              />
            </div>

            <MenuCard className="MapPage MenuCardMap">
              <CardButton to="/busca/venal" icon={ IconHome }>Buscar Imóveis</CardButton>

              {
                this.props.isAuthenticated && (
                  <>
                    <CardButton to="/meusimoveis/lista" className="MobileLinks" icon={ IconFavorites }>Meus Imóveis</CardButton>
                    {/* <CardButton to="/assinatura" className="MobileLinks" icon={ IconSignature }>Assinatura</CardButton> */}
                    {(this.props.role === 'Master' || this.props.role === 'Admin') && <CardButton to="/admin/panel" className="MobileLinks" icon={ IconUser }>Admin</CardButton>}
                    <CardButton to="/meusclientes" className="MobileLinks" icon={ IconUser }>Clientes</CardButton>
                  </>
                )
              }
            </MenuCard>
          </HeaderNav>

          <div className="Map">
            <FloatButtons>
              <ButtonHolder>
                <FloatingMenu
                  slideSpeed={500}
                  direction='up'
                  spacing={8}
                  isOpen
                >
                  <ChildButton
                    icon={
                      <Tooltip title={<h5>Centralizar</h5>} placement="left">
                        <Icons.CenterFocusStrongRounded style={{fontSize: 20, color: 'white'}} />
                      </Tooltip>
                    }
                    background='orange'
                    size={50}
                    onClick={
                      () => this.handleCenterMap()
                    }
                  />

                  <ChildButton
                    className="MapMenuVenal"
                    icon={
                      <Tooltip title={<h5>Busca Venal</h5>} placement="left">
                        <Icons.FormatListBulleted style={{ fontSize: 20, color: 'white' }} />
                      </Tooltip>
                    }
                    background='#0D2020'
                    size={50}
                    onClick={
                      () => this.handleOpenVenal()
                    }
                  />
                </FloatingMenu>
              </ButtonHolder>
            </FloatButtons>

            { mapa }
          </div>
        </>
      )
    }
  }

  handleCenterMap = () => {
    this.props.onCenter()
    this.centerMy = true
  }

  handlePositionChange = location => {
    this.addressSearch = false
    let coordenadas = {
      latitude: Number(location.lat()),
      longitude: Number(location.lng())
    }
    this.centerMy = false
    this.props.onLoadLocalizacao(coordenadas, constants.DISTANCIA_PROXIMA);
    if (this.props.isAuthenticated)
      this.props.onInit(coordenadas)
    else {
      this.props.onSetLocalizacao(coordenadas)
    }
    this.props.onSetFirstTime(false);
  }

  adicionarFavorito = (id, bok, type, idPredio) => {
    this.props.criarFavorito(id, bok);
    // this.setState({favoritou: true, type: type, id: type===0?id:idPredio})
  }

}

const mapStateToProps = state => {
  return {
    imoveisproximos: state.localizacao.imoveisproximos,
    imoveisproximospredios: state.localizacao.imoveisproximospredios,
    loading: state.localizacao.loading,
    loadingimoveis: state.imoveis.loading,
    center: state.localizacao.center,
    buscou: state.localizacao.buscou,
    firstTime: state.localizacao.firstTime,
    centraliza: state.localizacao.centraliza,
    coordenadas: state.localizacao.coordenadas,
    error_geo: state.localizacao.error_geo,
    favoritos: state.localizacao.favoritos,
    favoritosPredios: state.localizacao.favoritosPredios,
    id_redirect_favorito: state.imoveis.id_redirect_favorito,
    newfavorito: state.localizacao.newfavorito,
    isAuthenticated: state.auth.token !== null,
    role: state.auth.role
  };
};

const mapDispatchToProps = dispatch => {
  return {
    criarFavorito: (imovel_id, editar) => dispatch(actions.criarFavorito(imovel_id, editar)),
    onLoadLocalizacao: (coordenadas, distancia, formatted_address) => dispatch(actions.onLoadLocalizacao(coordenadas, distancia, formatted_address)),
    onSetLocalizacao: (coordenadas) => dispatch(actions.onSetLocalizacao(coordenadas)),
    onInit: (coordenadas) => dispatch(actions.onInit(coordenadas)),
    onCenter: (centraliza) => dispatch(actions.centralizar(centraliza)),
    onSetCenter: (center) => dispatch(actions.onSetCenter(center)),
    onSetBuscou: (buscou) => dispatch(actions.onSetBuscou(buscou)),
    onSetFirstTime: (firstTime) => dispatch(actions.onSetFirstTime(firstTime)),
    // onAddFavorito: () => dispatch(actions.onAddFavorito()),
    onErrorGetLocalizacao: () => dispatch(actions.onErrorGetLocalizacao())
  };
};

export default withMap(connect(mapStateToProps, mapDispatchToProps)(withErrorHandler(BuscaMapa, axios)));
