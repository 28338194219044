import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import {Provider} from 'react-redux';
import App from './App';
import './index.scss'
import * as serviceWorker from './serviceWorker';
import configureStore from './store';
import { staleCache } from './utils/cache'
import { initialize } from './utils/cacheControl'
import {I18nextProvider} from 'react-i18next';
import i18next from 'i18next';
import common_pt from "./translations/pt/common.json";
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import GlobalStyles from '@mui/material/GlobalStyles';
const store = configureStore();
// Config
initialize()
staleCache()

i18next.init({
    interpolation: { escapeValue: false },
    lng: 'pt',
    resources: {
        pt: {
            common: common_pt
        }
    },
});

const THEME = createMuiTheme({
    typography: {
     "fontFamily": `"Roboto", "Helvetica", "Arial", sans-serif`,
     "fontSize": 20,
     "fontWeightLight": 300,
     "fontWeightRegular": 400,
     "fontWeightMedium": 500
    }
 });

const app = (
    <MuiThemeProvider theme={THEME}>
        <GlobalStyles
            styles={{
                body: {
                  backgroundColor: "#FFFEF0",
                },
            }}
        />
        <Provider store={store}>
            <BrowserRouter>
                <I18nextProvider i18n={i18next}>
                    <App/>
                </I18nextProvider>
            </BrowserRouter>
        </Provider>
    </MuiThemeProvider>
);

ReactDOM.render(
    app,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
