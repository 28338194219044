import React from "react";
import { NavLink } from 'react-router-dom';

import './index.scss';

const ButtonCard = ({ children, ...props }) => {
  const Classes = `ButtonCard ${ props.className ? props.className : '' }`;

  return (
    <NavLink className={ Classes } to={ props.to }>
      <img src={ props.icon } alt={ children } />
      { children }
    </NavLink>
  );
}

export default ButtonCard;
