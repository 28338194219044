import React from 'react';
import NavigationItems from '../NavigationItems/NavigationItems';
import Backdrop from '../../UI/Backdrop/Backdrop';
import Logo from '../../Logo/Logo';
import './SideDrawer.scss';

const sideDrawer = ( props ) => {
    let attachedClasses = ["SideDrawer", "Close"];

    if (props.open) {
        attachedClasses = ["SideDrawer", "Open"];
    }

    return (
        <React.Fragment>
            <Backdrop show={ props.open } clicked={ props.closed }/>

            <div className={ attachedClasses.join(' ') } onClick={ props.closed }>
                <div className="Logo">
                    <Logo to="/" />
                </div>

                <nav>
                    <NavigationItems {...props} isAuthenticated={ props.isAuth } />
                </nav>
            </div>
        </React.Fragment>
    );
};

export default sideDrawer;
