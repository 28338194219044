import React, { useState, useCallback, useRef } from "react";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import { serveImage } from "../../utils/serveImage";
import { IconButton, makeStyles } from "@material-ui/core";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  fabRight: {
    position: "absolute",
    top: "40%",
    right: "5px",
    zIndex: 1,
    [theme.breakpoints.only("xs")]: {
      display: "none",
    },
  },
  fabLeft: {
    position: "absolute",
    top: "40%",
    left: "5px",
    zIndex: 1,
    [theme.breakpoints.only("xs")]: {
      display: "none",
    },
  },
  root: {
    position: "relative"
  },
  gallery: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    scrollBehavior: "smooth",
    [theme.breakpoints.only("xs")]: {
      overflow: "scroll",
    },
  }
}))

export function ImageSlider({
    photos, 
}) {
  const ref = useRef();
  const classes = useStyles();
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const handleScroll = (scroll) => {
    ref.current.scrollLeft += scroll
  }

  const photosSrc = photos.map(p => ({
    src: serveImage(p.url),
    width: 1,
    height: 1
  }))

  return (
    <div className={classes.root}>
      <IconButton className={classes.fabLeft} onClick={() => handleScroll(-768)}>
        <KeyboardArrowLeft style={{ color: "white" }} fontSize="large" />
      </IconButton>
      <IconButton className={classes.fabRight} onClick={() => handleScroll(768)}>
        <KeyboardArrowRight style={{ color: "white" }} fontSize="large" />
      </IconButton>
      <div className={classes.gallery} ref={ref}>
        <div style={{ width: 400 * photos.length }}>
          <Gallery direction={"column"} photos={photosSrc} onClick={openLightbox} columns={photos.length}/>
          <ModalGateway>
            {viewerIsOpen ? (
              <Modal onClose={closeLightbox}>
                <Carousel
                  currentIndex={currentImage}
                  views={photos.map(p => ({
                    src: serveImage(p.url),
                  }))}
                />
              </Modal>
            ) : null}
          </ModalGateway>
        </div>
      </div>
    </div>
  );
}
