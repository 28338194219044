import {SECAO_FORM} from '../utils/constants';

//CATEGORIAS
const comuns = {
    apartamento: "Apartamento", // valor_m2
    casa: "Casa",
    casa_comercial: "Casa Comercial",
    casa_condominio: "Casa em Condomínio",
    galpao: "Galpão",
    predio_comercial: "Prédio Comercial",
    sala_comercial: "Sala Comercial",// valor_m2
    terreno: "Terreno",
    cobertura: "Cobertura",
    cobertura_duplex: "Cobertura Duplex",
}

export const locacao = {
    ...comuns,
    // outros: "Outros",// valor_m2
}

export const venda = {
    ...comuns,
    gleba: "Gleba",
    terreno_condominio: "Terreno em Condomínio",
    // outros: "Outros",
}
//FIM CATEGORIAS

const elementcount = {
    elementType: 'input',
    elementConfig: {
        type: 'text'
    },
    value: null,
    validation: {
        required: false,
        maxLength: 6,
        isNumeric: true
    },
    valid: false,
    touched: false
}
//CAMPOS
const iptu_comuns = {
    // iptu_anual: {
    //     elementType: 'input',
    //     elementConfig: {
    //         type: 'text',
    //         placeholder: 'Valor IPTU Anual'
    //     },
    //     value: '',
    //     validation: {
    //         required: false,
    //         maxLength: 6,
    //         isNumeric: true
    //     },
    //     dropdown: SECAO_FORM.VALORES,
    //     label: 'IPTU Anual',
    //     valid: false,
    //     touched: false
    // },
    // iptu_mensal: {
    //     elementType: 'input',
    //     elementConfig: {
    //         type: 'text',
    //         placeholder: 'Valor IPTU Mensal'
    //     },
    //     value: '',
    //     validation: {
    //         required: false,
    //         maxLength: 6,
    //         isNumeric: true
    //     },
    //     dropdown: SECAO_FORM.VALORES,
    //     label: 'IPTU Mensal',
    //     valid: false,
    //     touched: false
    // },
}
//
// const valor_m2 ={
//     valor_m2: {
//         fvvRef: '',
//         elementType: 'input',
//         elementConfig: {
//             type: 'text',
//             placeholder: 'Valor m2'
//         },
//         value: '',
//         validation: {
//             required: false,
//             maxLength: 6,
//             isNumeric: true
//         },
//         dropdown: SECAO_FORM.VALORES,
//         label: 'Valor m²',
//         valid: false,
//         touched: false
//     },
// }

const valor_m2_area_construida = {
    valor_m2_area_construida: {
        elementType: 'input',
        elementConfig: {
            type: 'number',
            placeholder: 'Valor m2 da Área Construída'
        },
        dropdown: SECAO_FORM.VALORES,
        label: 'Valor m² da Área Construída',
        value: '',
        validation: {
            required: false,
            maxLength: 6,
            isNumeric: true
        },
        valid: false,
        touched: false
    },
}

const valor_m2_area_territorial = {
    valor_m2_area_territorial: {
        elementType: 'input',
        elementConfig: {
            type: 'number',
            placeholder: 'Valor m2 da Área Territorial'
        },
        dropdown: SECAO_FORM.VALORES,
        label: 'Valor m² da Área Territorial',
        value: '',
        validation: {
            required: false,
            maxLength: 6,
            isNumeric: true
        },
        valid: false,
        touched: false
    }
}


const valor_venda = {
    valor_venda: {
        elementType: 'input',
        elementConfig: {
            type: 'number',
            placeholder: 'Valor Venda'
        },
        dropdown: SECAO_FORM.VALORES,
        label: 'Valor Venda',
        value: '',
        resource_compra: true,
        validation: {
            required: false,
            maxLength: 8,
            isNumeric: true
        },
        valid: false,
        touched: false
    }
}

const valor_avaliado_fvv = {
    valor_avaliado_fvv: {
        fvvRef: '',
        elementType: 'input',
        elementConfig: {
            type: 'text',
            readOnly: true,
            placeholder: 'Área Territorial (FVV) x Valor m2'
        },
        value: '',
        validation: {
            required: false,
            maxLength: 8,
            isNumeric: true
        },
        dropdown: SECAO_FORM.VALORES,
        label: 'Valor avaliado',
        valid: true,
        touched: true
    },
}

const valor_avaliado = {
    valor_avaliado: {
        elementType: 'input',
        elementConfig: {
            type: 'text',
            placeholder: 'Valor Total Avaliado',
            //readOnly: true,
        },
        value: '',
        validation: {
            required: false,
            maxLength: 8,
            isNumeric: true
        },
        dropdown: SECAO_FORM.VALORES,
        label: 'Valor Total Avaliado',
        valid: true,
        touched: true
    },
}

const valor_avaliado_at = {
    valor_avaliado_at: {
        elementType: 'input',
        elementConfig: {
            type: 'text',
            readOnly: true,
            placeholder: 'Área Territorial (FVV) x Valor m2'
        },
        value: '',
        validation: {
            required: false,
            maxLength: 8,
        },
        dropdown: SECAO_FORM.VALORES,
        label: 'Valor Avaliado AT',
        valid: true,
        touched: true
    },
}

const valor_avaliado_ac = {
    valor_avaliado_ac: {
        elementType: 'input',
        elementConfig: {
            type: 'text',
            readOnly: true,
            placeholder: 'Área Construída (FVV) x Valor m2'
        },
        value: '',
        validation: {
            required: false,
            maxLength: 8,
        },
        dropdown: SECAO_FORM.VALORES,
        label: 'Valor Avaliado AC',
        valid: true,
        touched: true
    }
}

const valor_avaliado_atac = {
    valor_avaliado_atac: {
        elementType: 'input',
        elementConfig: {
            type: 'text',
            readOnly: true,
            placeholder: 'Valor Avaliado AT + Valor Avaliado AC'
        },
        value: '',
        validation: {
            required: false,
            maxLength: 6,
        },
        dropdown: SECAO_FORM.VALORES,
        label: 'Valor Total Avaliado',
        valid: true,
        touched: true
    },
}

const valores = {
    ...valor_venda,
    ...valor_m2_area_territorial,
    ...valor_m2_area_construida,
    ...valor_avaliado_at,
    ...valor_avaliado_ac,
    ...valor_avaliado_atac
}

const idade = {
    idade: {
        elementType: 'select',
        elementConfig: {
            options: [
                {value: '', displayValue: 'Não informado'},
                {value: 'nunca_habitado', displayValue: 'Nunca Habitado'},
                {value: 'novo', displayValue: 'Novo'},
                {value: 'meia_idade', displayValue: 'Meia Idade'},
                {value: 'velho', displayValue: 'Mais Velho'}
            ]
        },
        label: 'Idade',
        value: '',
        validation: {},
        valid: true
    }
}

const avaliacao_estrutural = {
    avaliacao_estrutural: {
        elementType: 'select',
        elementConfig: {
            options: [
                {value: '', displayValue: 'Não informado'},
                {value: 'excelente', displayValue: 'Excelente'},
                {value: 'otima', displayValue: 'Ótima'},
                {value: 'boa', displayValue: 'Boa'},
                {value: 'regular', displayValue: 'Regular'},
                {value: 'ruim', displayValue: 'Ruim'},
                {value: 'pessima', displayValue: 'Péssima'}
            ]
        },
        label: 'Avaliação Estrutural',
        value: '',
        validation: {},
        valid: true
    }
}

const sol = {
    sol: {
        elementType: 'select',
        elementConfig: {
            options: [
                {value: '', displayValue: 'Não informado'},
                {value: 'manha', displayValue: 'Manhã'},
                {value: 'tarde', displayValue: 'Tarde'},
            ]
        },
        label: 'Sol',
        value: '',
        validation: {},
        valid: true
    }
}

const itens_vagas = [
    {value: 'cobertas', displayValue: 'Cobertas'},
    {value: 'descobertas', displayValue: 'Descobertas'},
];

const vagas = itens_vagas.reduce((itens, item) => {
        itens[item.value] = {
            ...elementcount,
            elementConfig: {
                ...elementcount.elementConfig,
                placeholder: item.displayValue
            },
            label: item.displayValue,
            grupo: 'Vagas',
            titlegrupo: true,
        }
        return itens;
    }
    , []);

const itens_areacomum = [
    {value: 'salao_festas', displayValue: 'Salão de Festas',},
    {value: 'salao_jogos', displayValue: 'Salão de Jogos'},
    {value: 'churrasqueira', displayValue: 'Churrasqueira'},
    {value: 'academia', displayValue: 'Academia'},
    {value: 'cinema', displayValue: 'Cinema'},
    {value: 'playground', displayValue: 'Playground'},
    {value: 'piscina', displayValue: 'Piscina'},
    {value: 'piscina_raia', displayValue: 'Piscina com raia'},
    {value: 'piscina_crianca', displayValue: 'Piscina de criança'},
    {value: 'quadra', displayValue: 'Quadra'},
    {value: 'espaco_gourmet', displayValue: 'Espaço Gourmet'},
    {value: 'bicicletario', displayValue: 'Bicicletário'},
    {value: 'campo_futebol', displayValue: 'Campo de Futebol'},
    {value: 'quadra_tenis', displayValue: 'Quadra de Tênis'},
    {value: 'carwash', displayValue: 'Car Wash'},
    {value: 'petcare', displayValue: 'Pet Care'},
    {value: 'sauna', displayValue: 'Sauna'},
    {value: 'lavanderia', displayValue: 'Lavanderia'},
];

const area_comum = itens_areacomum
    .reduce((itens, item) => {
            itens[item.value] = {
                elementType: 'simnao',
                elementConfig: {
                    placeholder: item.displayValue
                },
                label: item.displayValue,
                value: "Não",
                validation: {
                    required: false,
                },
                valid: true,
                touched: false,
                dropdown: SECAO_FORM.CONDOMINIO,
                grupo: 'Área Comum do Condomínio',
                titlegrupo: true,
            }
            return itens;
        }
        , []);

const armarios = {

    possui_armarios: {
        elementType: 'simnao',
        label: 'Armários',
        elementConfig: {
            placeholder: 'Armários'
        },
        value: "Não",
        validation: {
            required: false,
        },
        valid: true,
        touched: false
    },
}

const ar_condicionado = {
    possui_ar_condicionado: {
        elementType: 'simnao',
        label: 'Ar condicionado',
        elementConfig: {
            placeholder: 'Ar condicionado'
        },
        value: "Não",
        validation: {
            required: false,
        },
        valid: true,
        touched: false
    },
}

const bloco_andar = {
    bloco: {
        elementType: 'input',
        elementConfig: {
            type: 'text',
            placeholder: 'Bloco'
        },
        value: '',
        validation: {
            required: false,
            maxLength: 5,
        },
        label: 'Bloco',
        valid: false,
        touched: false
    },
    andar: {
        elementType: 'input',
        elementConfig: {
            type: 'text',
            placeholder: 'Andar'
        },
        value: '',
        validation: {
            required: false,
            maxLength: 5,
            isNumeric: true,
        },
        label: 'Andar',
        valid: false,
        touched: false
    },
}

const nome_condominio = {
    nome_condominio: {
        elementType: 'input',
        elementConfig: {
            type: 'text',
            placeholder: 'Nome do condomínio'
        },
        dropdown: SECAO_FORM.CONDOMINIO,
        label: 'Nome do condomínio',
        value: '',
        validation: {
            required: false,
            maxLength: 50,
        },
        valid: false,
        touched: false
    },
}

const itens_dormitorio = [
    {value: 'sem_suite', displayValue: 'Sem suíte'},
    {value: 'com_suite', displayValue: 'Com suíte'},
];

const numero_banheiros = {
    numero_banheiros: {
        elementType: 'input',
        elementConfig: {
            type: 'text',
            placeholder: 'Número de banheiros'
        },
        label: 'Número de banheiros',
        value: '',
        validation: {
            required: false,
            maxLength: 4,
            isNumeric: true
        },
        valid: false,
        touched: false
    },
};

const tipo_casa = {
    tipo_casa: {
        elementType: 'select',
        elementConfig: {
            options: [
                {value: '', displayValue: 'Não informado'},
                {value: 'terrea', displayValue: 'Térrea'},
                {value: 'sobrado', displayValue: 'Sobrado'}
            ]
        },
        label: 'Tipo da Casa',
        value: '',
        validation: {},
        valid: true
    },
};

const dormitorios = itens_dormitorio.reduce((itens, item) => {
        itens[item.value] = {
            ...elementcount,
            elementConfig: {
                ...elementcount.elementConfig,
                placeholder: item.displayValue
            },
            label: item.displayValue,
            titlegrupo: true,
            grupo: 'Dormitórios/Banheiros',
        }
        return itens;
    }
    , []);

const itens_itens_casa = [
    {value: 'salao_festas', displayValue: 'Salão de Festas'},
    {value: 'sala_jantar', displayValue: 'Sala de Jantar'},
    {value: 'copa', displayValue: 'Copa'},
    {value: 'deposito', displayValue: 'Depósito'},
    {value: 'despensa', displayValue: 'Despensa'},
    {value: 'hidromassagem', displayValue: 'Hidromassagem'},
    {value: 'armario', displayValue: 'Armário'},
    {value: 'ar_condicionado', displayValue: 'Ar Condicionado'},
    {value: 'salao_jogos', displayValue: 'Salão de Jogos'},
    {value: 'churrasqueira', displayValue: 'Churrasqueira'},
    {value: 'academia', displayValue: 'Academia'},
    {value: 'playground', displayValue: 'Playground'},
    {value: 'piscina', displayValue: 'Piscina'},
    {value: 'piscina_raia', displayValue: 'Piscina com raia'},
    {value: 'piscina_crianca', displayValue: 'Piscina de criança'},
    {value: 'quadra', displayValue: 'Quadra'},
    {value: 'espaco_gourmet', displayValue: 'Espaço Gourmet'},
    {value: 'escritorio', displayValue: 'Escritório'},
    {value: 'home_theater', displayValue: 'Home Theater'},
    {value: 'bicicletario', displayValue: 'Bicicletário'},
    {value: 'campo_futebol', displayValue: 'Campo de Futebol'},
    {value: 'quadra_tenis', displayValue: 'Quadra de Tênis'},
    {value: 'sauna', displayValue: 'Sauna'},
    {value: 'lavanderia', displayValue: 'Lavanderia'},
    {value: 'lavabo', displayValue: 'Lavabo'},
];

const itens_casa = itens_itens_casa
    .reduce((itens, item) => {
        itens[item.value] = {
            elementType: 'simnao',
            elementConfig: {
                placeholder: item.displayValue
            },
            label: item.displayValue,
            value: "Não",
            validation: {
                required: false,
            },
            valid: true,
            touched: false,
            titlegrupo: true,
            grupo: 'Ítens da casa',
        };
        return itens;
    }, []);

const quadra_lote = {
    quadra: {
        elementType: 'input',
        elementConfig: {
            type: 'text',
            placeholder: 'Quadra'
        },
        label: 'Quadra',
        value: '',
        validation: {
            required: false,
            maxLength: 5,
        },
        valid: false,
        touched: false
    },
    lote: {
        elementType: 'input',
        elementConfig: {
            type: 'text',
            placeholder: 'Quadra e Lote'
        },
        label: 'Quadra e Lote',
        value: '',
        validation: {
            required: false,
            maxLength: 5,
        },
        valid: false,
        touched: false
    },
}

const valor_condominio = {
    valor_condominio: {
        elementType: 'input',
        elementConfig: {
            type: 'text',
            placeholder: 'Valor do condomínio'
        },
        label: 'Valor do condomínio',
        dropdown: SECAO_FORM.VALORES,
        value: '',
        validation: {
            required: false,
            maxLength: 6,
            isNumeric: true
        },
        valid: false,
        touched: false
    },
}

const apartamento_comuns = {
    ...valor_condominio,
    ...valor_venda,
    ...nome_condominio,
    numero_apartamento: {
        elementType: 'input',
        elementConfig: {
            type: 'text',
            placeholder: 'Número do apartamento'
        },
        label: 'Número do apartamento',
        value: '',
        validation: {
            required: false,
            maxLength: 5,
        },
        valid: false,
        touched: false
    },
    ...bloco_andar,
    ...dormitorios,
    ...numero_banheiros,
    ...armarios,
    ...ar_condicionado,
    possui_varanda_gourmet: {
        elementType: 'simnao',
        label: 'Varanda Gourmet',
        elementConfig: {
            placeholder: 'Varanda Gourmet'
        },
        // dropdown: SECAO_FORM.CONDOMINIO,
        value: "Não",
        validation: {
            required: false,
        },
        valid: true,
        touched: false
    },
    lavabo: {
        elementType: 'simnao',
        label: 'Lavabo',
        elementConfig: {
            placeholder: 'Lavabo'
        },
        // dropdown: SECAO_FORM.CONDOMINIO,
        value: "Não",
        validation: {
            required: false,
        },
        valid: true,
        touched: false
    },
    ...idade,
    ...avaliacao_estrutural,
    ...sol,
    ...vagas,
    ...area_comum,
}

export const apartamento = {
    venda: {
        ...apartamento_comuns,
        ...valor_avaliado,
        ...iptu_comuns
    },
    locacao: {
        ...apartamento_comuns,
    },
}

export const cobertura = {
    ...apartamento
}

export const cobertura_duplex = {
    ...apartamento
}

const casa_comuns = {
    ...tipo_casa,
    ...dormitorios,
    ...numero_banheiros,
    ...vagas,
    ...idade,
    ...avaliacao_estrutural,
    ...itens_casa,
};

export const casa = {
    venda: {
        ...casa_comuns,
        ...valores,
        ...iptu_comuns
    },
    locacao: {
        ...casa_comuns,
    },
}
const salas_banheiros = {
    numero_salas: {
        elementType: 'input',
        elementConfig: {
            type: 'text',
            placeholder: 'Número de salas'
        },
        label: 'Número de salas',
        value: '',
        validation: {
            required: false,
            maxLength: 4,
            isNumeric: true
        },
        valid: false,
        touched: false
    },
    ...numero_banheiros,
}

const casa_comercial_comuns = {
    ...armarios,
    ...ar_condicionado,
    ...idade,
    ...avaliacao_estrutural,
    ...vagas,
    ...salas_banheiros,
}

export const casa_comercial = {
    venda: {
        ...casa_comercial_comuns,
        ...valores,
        ...iptu_comuns,
    },
    locacao: {
        ...casa_comercial_comuns,
    }
}

const casa_condominio_comuns = {
    ...valor_condominio,
    ...nome_condominio,
    ...tipo_casa,
    ...quadra_lote,
    ...dormitorios,
    ...numero_banheiros,
    ...vagas,
    ...idade,
    ...avaliacao_estrutural,
    ...itens_casa,
    ...area_comum,
}

export const casa_condominio = {
    venda: {
        ...casa_condominio_comuns,
        ...valores,
        ...iptu_comuns,
    },
    locacao: {
        ...casa_condominio_comuns,
    },
}


const itens_itens_galpao_predio = [
    {value: 'escritorio', displayValue: 'Escritório'},
    {value: 'cozinha', displayValue: 'Cozinha'},
    {value: 'copa', displayValue: 'Copa'},
    {value: 'refeitorio', displayValue: 'Refeitório'},
    {value: 'guarita', displayValue: 'Guarita'},
    {value: 'mezanino', displayValue: 'Mezanino'},
];

const itens_galpao_predio = itens_itens_galpao_predio
    .reduce((itens, item) => {
        itens[item.value] = {
            elementType: 'simnao',
            elementConfig: {
                placeholder: item.displayValue
            },
            label: item.displayValue,
            value: "Não",
            validation: {
                required: false,
            },
            valid: true,
            touched: false,
            titlegrupo: true,
            grupo: 'Ítens galpão e prédio comercial',
        };
        return itens;
    }, []);

const galpao_comuns = {
    ...idade,
    ...avaliacao_estrutural,
    ...vagas,
    ...itens_galpao_predio,
    ...salas_banheiros,
}

export const galpao = {
    venda: {
        ...galpao_comuns,
        ...valores,
        ...iptu_comuns
    },
    locacao: {
        ...galpao_comuns,
    },
}

const predio_comuns = {
    ...galpao_comuns
}

export const predio_comercial = {
    venda: {
        ...predio_comuns,
        ...valores,
        ...iptu_comuns,
    },
    locacao: {
        ...predio_comuns,
    },
}

const sala_comercial_comuns = {
    ...valor_condominio,
    numero_sala: {
        elementType: 'input',
        elementConfig: {
            type: 'text',
            placeholder: 'Número da sala'
        },
        label: 'Número da sala',
        value: '',
        validation: {
            required: false,
            maxLength: 50,
        },
        valid: false,
        touched: false
    },
    ...bloco_andar,
    ...armarios,
    ...ar_condicionado,
    ...idade,
    ...avaliacao_estrutural,
    ...sol,
    ...vagas,
}

const banheiro_check = {
    banheiro: {
        elementType: 'simnao',
        elementConfig: {
            placeholder: 'Banheiro'
        },
        label: 'Banheiro',
        dropdown: SECAO_FORM.IMOVEL,
        value: 'Não',
        validation: {
            required: false,
        },
        valid: true,
        touched: false
    },
}

export const sala_comercial = {
    venda: {
        ...sala_comercial_comuns,
        ...valor_venda,
        ...banheiro_check,
        ...valor_avaliado,
        ...iptu_comuns
    },
    locacao: {
        ...sala_comercial_comuns,
        ...banheiro_check,
    },
}


const tipo_terreno = {
    tipo_terreno: {
        elementType: 'select',
        elementConfig: {
            options: [
                {value: '', displayValue: 'Não informado'},
                {value: 'plano', displayValue: 'Plano'},
                {value: 'aclive', displayValue: 'Aclive'},
                {value: 'declive', displayValue: 'Declive'},
            ]
        },
        label: 'Tipo',
        value: '',
        validation: {},
        valid: true
    }
}

const irregularidades_terreno = {
    irregularidades_terreno: {
        elementType: 'select',
        elementConfig: {
            options: [
                {value: '', displayValue: 'Não informado'},
                {value: 'pequena_caida_lateral', displayValue: 'Pequena Caída Lateral'},
                {value: 'caida_lateral', displayValue: 'Caída Lateral'},
            ]
        },
        label: 'Irregularidades',
        value: '',
        validation: {},
        valid: true
    }
}

const terreno_comuns = {
    frente: {
        elementType: 'input',
        elementConfig: {
            type: 'text',
            placeholder: 'Frente (metros)'
        },
        value: '',
        label: 'Frente (metros)',
        validation: {
            required: false,
            maxLength: 6,
            isNumeric: true
        },
        valid: false,
        touched: false
    },
    fundo: {
        elementType: 'input',
        elementConfig: {
            type: 'text',
            placeholder: 'Fundo (metros)'
        },
        label: 'Fundo (metros)',
        value: '',
        validation: {
            required: false,
            maxLength: 6,
            isNumeric: true
        },
        valid: false,
        touched: false
    },
    ...tipo_terreno,
    ...irregularidades_terreno,
}

export const terreno = {
    venda: {
        ...valor_venda,
        ...terreno_comuns,
        ...valor_m2_area_territorial,
        ...valor_avaliado_fvv,
        ...iptu_comuns,
    },
    locacao: {
        ...terreno_comuns,
    }
}

export const terreno_condominio = {
    venda: {
        ...valor_condominio,
        ...nome_condominio,
        ...valor_venda,
        ...valor_m2_area_territorial,
        ...valor_avaliado_fvv,
        ...quadra_lote,
        ...terreno_comuns,
        ...area_comum,
    }
}

export const gleba = {
    venda: {
        ...valor_venda,
        ...valor_m2_area_construida,
        ...valor_m2_area_territorial,
        ...valor_avaliado_ac,
        ...valor_avaliado_at,
        ...valor_avaliado_atac,
        ...valor_avaliado_fvv
    }
}

const outros_comum = {
    outros: {
        elementType: 'input',
        elementConfig: {
            type: 'text',
            placeholder: 'Outros'
        },
        label: 'Outros',
        value: '',
        validation: {
            required: false,
            maxLength: 600,
        },
        valid: false,
        touched: false
    }
}

export const outros = {
    venda: {
        ...outros_comum
    },
    locacao: {
        ...outros_comum
    },
}
//FIM CAMPOS
export const complementares_default = {
    nome_proprietario: {
        elementType: 'input',
        elementConfig: {
            type: 'text',
            placeholder: 'Nome'
        },
        label: 'Nome do Proprietário',
        value: '',
        dropdown: SECAO_FORM.PROPRIETARIO,
        grupo: 'Proprietário',
        titlegrupo: false,
        resource_compra: true,
        validation: {
            required: true,
        },
        valid: false,
        touched: false
    },
    email: {
        elementType: 'input',
        elementConfig: {
            type: 'email',
            placeholder: 'E-mail'
        },
        label: 'E-mail do Proprietário',
        value: '',
        dropdown: SECAO_FORM.PROPRIETARIO,
        grupo: 'Proprietário',
        titlegrupo: false,
        resource_compra: true,
        validation: {
            required: true,
            isEmail: true
        },
        valid: false,
        touched: false
    },
    telefone: {
        elementType: 'input',
        elementConfig: {
            type: 'number',
            placeholder: '11999999999',
            maxLength: "15"
        },
        dropdown: SECAO_FORM.PROPRIETARIO,
        label: 'Telefone do Proprietário',
        grupo: 'Proprietário',
        titlegrupo: false,
        resource_compra: true,
        value: '',
        validation: {
            required: true,
            isFone: true
        },
        valid: false,
        touched: false
    },
}
// CAMPOS COMPLEMENTARES
const complementares_comuns_locacao = {
    valor_aluguel: {
        elementType: 'input',
        elementConfig: {
            type: 'number',
            placeholder: 'Valor do Aluguel'
        },
        label: 'Valor do Aluguel',
        dropdown: SECAO_FORM.VALORES,
        value: '',
        resource_compra: true,
        validation: {
            required: false,
            maxLength: 8,
            isNumeric: true
        },
        valid: false,
        touched: false
    },
}
// CAMPOS COMPLEMENTARES
const complementares_comuns = {
    valor_aluguel: {
        elementType: 'input',
        elementConfig: {
            type: 'number',
            placeholder: 'Valor do Aluguel'
        },
        label: 'Valor do Aluguel',
        dropdown: SECAO_FORM.NODROP,
        value: '',
        resource_compra: true,
        validation: {
            required: false,
            maxLength: 8,
            isNumeric: true
        },
        valid: false,
        touched: false
    },
}

const itens_permuta_adicionais = [
    {value: 'carro', displayValue: 'Carro'},
    {value: 'moto', displayValue: 'Moto'},
    {value: 'lancha', displayValue: 'Lancha'},
];

const itens_permuta =
    Object.keys(venda).map((key) => {
        return {value: key, displayValue: venda[key]}
    }).concat(itens_permuta_adicionais);

const aceitapermuta = itens_permuta
    .reduce((itens, item) => {
            itens[item.value] = {
                elementType: 'simnao',
                value: "Não",
                elementConfig: {
                    placeholder: item.displayValue
                },
                label: item.displayValue,
                dropdown: SECAO_FORM.PERMUTA,
                grupo: 'Aceita Permuta?',
                titlegrupo: false,
                resource_compra: true,
                validation: {
                    required: false,
                },
                valid: true,
                touched: false,
            }
            return itens;
        }
        , []);

export const alugado = {
    elementType: 'simnao',
    elementConfig: {
        placeholder: 'Está Alugado'
    },
    label: 'Está Alugado',
    dropdown: SECAO_FORM.NODROP,
    value: 'Não',
    resource_compra: true,
    validation: {
        required: false,
    },
    valid: true,
    touched: false
};


export const complementares = {
    venda: {
        ...complementares_comuns,
        retorno_aluguel: {
            elementType: 'input',
            elementConfig: {
                type: 'number',
                readOnly: true,
                placeholder: 'Venda / Aluguel'
            },
            dropdown: SECAO_FORM.VALORES,
            label: 'Retorno do Aluguel (%)',
            value: '',
            resource_compra: true,
            validation: {
                required: false,
            },
            valid: true,
            touched: true
        },
        ...aceitapermuta,
    },
    locacao: {
        ...complementares_comuns_locacao,
        retorno_aluguel: {
            elementType: 'input',
            elementConfig: {
                type: 'number',
                readOnly: true,
                placeholder: 'Venda / Aluguel'
            },
            dropdown: SECAO_FORM.VALORES,
            label: 'Retorno do Aluguel (%)',
            value: '',
            resource_compra: true,
            validation: {
                required: false,
            },
            valid: true,
            touched: true
        },
    },
}
