import React from "react";
import './index.scss';

const MenuCard = ({ children, ...props }) => {
  return (
    <div className={`MenuCard ${ props.className }`}>
      { children }
    </div>
  );
}

export default MenuCard;
