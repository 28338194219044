import React, { Component } from "react";
import onClickOutside from "react-onclickoutside";
import NavigationItem from "./NavigationItem/NavigationItem";

import "./NavigationItems.scss";
import iconUser from "../../../assets/images/icon-user-white.svg";

class navigationItems extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      visibleVenal: false,
    };

    this.toggleMenu = this.toggleMenu.bind(this);
    this.toggleMenuVenal = this.toggleMenuVenal.bind(this);
  }

  toggleMenu() {
    this.setState({ visible: !this.state.visible });
  }

  toggleMenuVenal() {
    this.setState({ visibleVenal: !this.state.visibleVenal });
  }

  handleClickOutside = () => {
    this.setState({
      visible: false,
      visibleVenal: false,
    });
  };

  render() {
    return (
      <ul
        className={
          this.props.isAuthenticated
            ? "NavigationItems"
            : "NavigationItems MenuDisconnected"
        }
      >
        {this.props.isAuthenticated && (
          <>
            {(this.props.role === "Master" || this.props.role === "Admin") && (
              <NavigationItem className="menu-fx" link="/admin/panel">
                Admin
              </NavigationItem>
            )}

            <li className="toggle-button NavigationItem">
              <button className="buttton-icon-user" onClick={this.toggleMenu}>
                <img alt="Menu" className="icon-user" src={iconUser} />
              </button>

              <ul className="MenuMobile">
                <NavigationItem link="/meusdados">Meus Dados</NavigationItem>
                <NavigationItem link="/logout">Sair</NavigationItem>
              </ul>

              {this.state.visible && (
                <Menu>
                  <NavigationItem link="/meusdados">Meus Dados</NavigationItem>
                  <NavigationItem link="/logout">Sair</NavigationItem>
                </Menu>
              )}
            </li>
          </>
        )}
        {!this.props.isAuthenticated && (
          <>
            <NavigationItem className="menu-fx" link="/">
              Venda e Locação
            </NavigationItem>
            <NavigationItem className="menu-fx" link="/parceiros/imobiliaria">
              Imobiliárias
            </NavigationItem>
            <NavigationItem className="menu-fx" link="/contato">
              Contato
            </NavigationItem>
            <NavigationItem className="menu-fx" link="/auth">
              Entrar
            </NavigationItem>
          </>
        )}
      </ul>
    );
  }
}

class Menu extends React.Component {
  render() {
    return (
      <div className="toggleMenu">
        <ul>{this.props.children}</ul>
      </div>
    );
  }
}

// TODO: Fazer o componente do MenuItem
// class MenuItem extends React.Component {
//   render() {
//     return (
//       <NavigationItem link="/">{this.props.children}</NavigationItem>
//     )
//   }
// }

export default onClickOutside(navigationItems);
