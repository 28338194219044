import React, { useEffect, useState, useRef } from "react";
import AdvancedFilter from "../../components/Filter/AdvancedFilter";
import api from "../../services/api";
import NewCard from "../../components/UI/NewCard";
import FilterOrder from "../../components/FilterOrder/FilterOrder";
import FilterChips from "../../components/FilterChips/FilterChips";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import ImageDefault from "../../assets/images/sample-image.jpg";
import { serveImage } from "../../utils/serveImage";
import { Pagination } from "@material-ui/lab";
import { OutdoorGrill, Pool, Pets, OfflineBolt} from "@material-ui/icons";
import {Chair, Balcony} from "@mui/icons-material";

const useStyles = makeStyles({
  main: {
    display: "flex",
    justifyContent: "center",
  },
  container: {
    maxWidth: "1126px",
  },
  pagination: {
    marginTop: "8px",
    marginBottom: "8px",
    "& ul": {
      justifyContent: "center",
    },
  },
});

const Search = (props) => {
  const classes = useStyles();
  const [filterOptions, setFilterOptions] = useState(null);
  const [defaultParams, setDefaultParams] = useState({
    defaultCity: "",
    defaultType: "",
  });
  const [imgSrc, setImageSrc] = useState(null);

  const [sales, setSales] = useState([]);
  const [count, setCount] = useState(null);
  const [order, setOrder] = useState(null);
  const [page, setPage] = useState(1);
  const [chips, setChips] = useState([]);
  const [data, setData] = useState({});
  const [randomNumber, setRandomNumber] = useState(Math.random());
  const [parametersKeys, setParametersKeys] = useState([]);
  const [parametersString, setParametersString] = useState("");

  const hasExecutedOnce = useRef(false);
  const callCount = useRef(0);
  const newPageRaf = useRef([]);

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    const defaultCity = urlParams.get("city") || "";
    const defaultType = urlParams.get("type") || "";
    const defaultImobiliaria = urlParams.get("imobiliaria") || "";

    setRandomNumber(Math.random());

    setDefaultParams({
      defaultCity: defaultCity,
      defaultType: defaultType,
      defaultImobiliaria: defaultImobiliaria,
      defaultPurchase: urlParams.get("purchase") || "purchase",
      defaultNeighborhood: urlParams.get("neighborhood") || "",
    });

    console.log("Before API Call");

    let filterURL = "/posts/filter?";
    if (defaultCity) filterURL += `cidade=${defaultCity}&`;
    if (defaultType) filterURL += `tipo=${defaultType}`;
    if (defaultImobiliaria) getImobiliariaLogo(defaultImobiliaria);

    api
      .get(filterURL)
      .then((res) => {
        console.log("API Response:", res.data);
        setFilterOptions(res.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        console.log("After API Call");
      });
  }, []);

  const loadFilter = (params) => {
    let properties = "";
    properties += `?cidade=${params.city}&tipo=${params.tipo}`;

    api
      .get("/posts/filter" + properties)
      .then((res) => {
        setFilterOptions(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getImobiliariaLogo = (imobiliaria) => {
    let params = "";
    params += `?id=${imobiliaria}`;

    api
      .get("/posts/imobiliaria-logo" + params)
      .then((res) => {
        setImageSrc(res.data.url);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChangeTipo = (event) => {
    console.log("Tipo changed:", event.target.value);
    loadFilter({
      city: data.cidade,
      tipo: event.target.value,
    });
  };

  const handleChangeCity = (event) => {
    console.log("City changed:", event.target.value);
    loadFilter({
      city: event.target.value,
      tipo: data.tipo,
    });
  };

  const buildParameters = (formData) => {
    var parameters = "?";
    const keyLen = Object.keys(formData).length;
    Object.keys(formData).forEach((key, idx) => {
      if (formData[key]) {
        parameters += `${key}=${formData[key]}`;
        if (idx + 1 !== keyLen) {
          parameters += "&";
        }
      }
    });

    return parameters;
  };

  const getImageSrc = (p) =>
    (p.metas && p.metas.thumbnail && serveImage(p.metas.thumbnail.url)) ||
    (p.metas &&
      p.metas.banner_principal &&
      serveImage(p.metas.banner_principal.url)) ||
    (p.metas &&
      p.metas.fotos &&
      p.metas.fotos.length &&
      serveImage(p.metas.fotos[0].url)) ||
    ImageDefault;

  const getFilteredPosts = (parameters) => {
    api
      .get("/posts/search" + parameters)
      .then((res) => {
        console.log(res);

        let urlParams = new URLSearchParams(parameters);
        let keys = urlParams.keys();
        let paramKeys = Array.from(keys);
        let pageValue = urlParams.get('page');
        let hasOrder = paramKeys.includes('order');
        let shuffledPages = shufflePages(res.data.count);
        let pageOneHash = hashCode("1" + randomNumber.toString());
        let itensQuantity = res.data.posts.length;
        let numberOfPages = res.data.count;
        let itensPerPage = urlParams.get('itensPerPage');
        setParametersKeys(paramKeys);
        setParametersString(parameters);
        setCount(res.data.count);

        newPageRaf.current = shuffledPages;

        if(!hasExecutedOnce.current && pageValue === '1'){
          urlParams.set('page', shuffledPages[pageOneHash]);
          let updatedParam = "?" + urlParams.toString();
          hasExecutedOnce.current = true;
          getFilteredPosts(updatedParam);
        }

        if(numberOfPages > 1 && itensQuantity < itensPerPage && !hasOrder){
          
          let missingItens = itensPerPage - itensQuantity;
          urlParams.set('page', 1);
          urlParams.set('itensPerPage', missingItens)
          let newRequestParam = "?" + urlParams.toString();

          api
            .get("/posts/search" + newRequestParam)
            .then((response) => {
              const fullPage = [...res.data.posts, ...response.data.posts]
              setSales(shuffleItens(fullPage))
            })
            .catch((err) => {
              console.log(err);
            });
        }

        callCount.current += 1;
        if(callCount.current > 2){
          setSales(hasOrder ? res.data.posts : shuffleItens(res.data.posts));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleOrder = (orderData) => {
    setOrder(orderData);
    setPage(1);
    handleSearch({ orderData });
  };

  const handleChips = (chipsData) => {
    setChips(chipsData);
    setPage(1);
  };

  const handlePage = (_event, pageData) => {
    if (pageData !== page) window.scrollTo(0, 0);
    let pageHash = hashCode(pageData.toString() + randomNumber)
    let hasOrder = parametersKeys.includes('order');
    let currentPage = hasOrder ? pageData : newPageRaf.current[pageHash];
    setPage(pageData);
    setSales([]);
    handleSearch({ pageData: currentPage });
  };

  const handleSearch = ({
    formData = data,
    orderData = order,
    pageData = 1,
  }) => {
    console.log("Search performed with data:", formData);
    setData(formData);

    const searchParameters = buildParameters({
      ...formData,
      order: orderData,
      page: pageData,
    });
    getFilteredPosts(searchParameters);
    
    let keys = getParameterKeys(searchParameters);
    let hasOrder = keys.includes('order');
    let distinctParameters = compareParameters(searchParameters, parametersString);

    if(distinctParameters && !hasOrder){
      hasExecutedOnce.current = false
    }
  };

  const getParameterKeys = (searchParameters) => {
    let urlParams = new URLSearchParams(searchParameters);
    let keys = urlParams.keys();
    let paramKeys = Array.from(keys);

    return paramKeys
  }

const compareParameters = (url1, url2) => {
  const params1 = new URLSearchParams(url1);
  const params2 = new URLSearchParams(url2);
  params1.delete('page');
  params2.delete('page');

  return params1.toString() !== params2.toString();
}

  const shuffleItens = (array) => {
    return array.sort(() => Math.random() - 0.5); 
  }

  const shufflePages = (totalPages) => {
    let pageDict = {};
    let arrayOfHashs = [];
    let sortedHashes = [];

    for(let i = 0; i < totalPages; i++){
      arrayOfHashs[i] = hashCode((i + 1).toString() + randomNumber.toString());
    }
    sortedHashes = arrayOfHashs.sort();
    
    for(let i = 0; i < totalPages; i++){
      pageDict[sortedHashes[i]] = i + 1;
    }
    
    return pageDict;
  }

  const hashCode = (str) => {
    let hash = 0;
    for (let i = 0, len = str.length; i < len; i++) {
        let chr = str.charCodeAt(i);
        hash = (hash << 5) - hash + chr;
        hash |= 0;
    }
    return hash;
  }

  const labels = [
    {
      label: "Churrasqueira",
      icon: <OutdoorGrill />,
    },
    {
      label: "Piscina",
      icon: <Pool />,
    },
    {
      label: "Mobiliado",
      icon: <Chair/>
    },
    {
      label: "Aceita pets",
      icon: <Pets />
    },
    {
      label: "Varanda",
      icon: <Balcony/>
    },
    {
      label: "Sem fiador",
      icon: <OfflineBolt/>
    },
    
  ];

  return (
    <div className={classes.main}>
      <Grid
        container
        justifyContent="space-between"
        className={classes.container}
      >
        <Grid xs={12} md={4}>
          {filterOptions ? (
            <AdvancedFilter
              {...defaultParams}
              chips={chips}
              order={order}
              labels={labels.map((l) => l.label)}
              filterOptions={filterOptions}
              onChangeTipo={handleChangeTipo}
              onChangeCity={handleChangeCity}
              onSearch={handleSearch}
            />
          ) : (
            <p>Carregando...</p>
          )}
        </Grid>
        <Grid xs={12} md={8}>
          <FilterChips
            labels={labels}
            lazer={data.lazer}
            handleChips={handleChips}
          />
          <FilterOrder
            imgSrc={imgSrc}
            count={count}
            handleOrder={handleOrder}
          />
          {sales && sales.length > 0
            ? sales.map((s, idx) => (
                <NewCard
                  id={s._id}
                  src={getImageSrc(s)}
                  area={s.metas.area}
                  valor_inicial={s.metas.valor_inicial}
                  valor_locacao={s.metas.valor_locacao}
                  valor_condominio={s.metas.valor_condominio}
                  iptu={s.metas.iptu}
                  bairro={s.metas.bairro}
                  cidade={s.metas.cidade}
                  emailProprietary={s.metas.email_do_contato}
                  numberPhone={s.metas.celular_de_contato}
                  quartos={s.metas.quantidade_dormitorios}
                  garagens={s.metas.vagas_de_garagem}
                  banheiros={s.metas.quantidade_banheiros}
                  history={props.history}
                  purchase={data.purchase}
                  tipo={s.metas.tipo}
                />
              ))
            : null}
          <Pagination
            className={classes.pagination}
            count={count}
            page={page}
            onChange={handlePage}
            shape="rounded"
            size="large"
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default Search;
