import * as actionsNames from './actionsNames';
import api from '../../services/api';
import configToken from '../../utils/configToken';

export const fetchUserByAdminStart = () => {
	return {
	  type: actionsNames.FETCH_USER_BY_ADMIN_START
	};
  };

const fetchUserByAdminSuccess = data => {
	return {
		type: actionsNames.FETCH_USER_BY_ADMIN_SUCCESS,
		data: data
	};
};

const fetchUserByAdminFail = error => {
	return {
		type: actionsNames.FETCH_USER_BY_ADMIN_FAIL,
		error: error
	};
};

export const fetchUsersStart = () => {
	return {
	  type: actionsNames.FETCH_USERS_START
	};
  };

const fetchUsersSuccess = data => {
	return {
		type: actionsNames.FETCH_USERS_SUCCESS,
		data: data
	};
};

const fetchUsersFail = error => {
	return {
		type: actionsNames.FETCH_USERS_FAIL,
		error: error
	};
};

export const resetProps = error => {
	return {
		type: actionsNames.RESET_USERS_PROPS,
	};
};

export const fetchUsers = (id) => {
	return dispatch => {
	  dispatch(fetchUsersStart());
	  api
		.get(`/users/admin/users`, configToken())
		.then(res => {
		  dispatch(fetchUsersSuccess(res.data));
		})
		.catch(res => {
		  dispatch(fetchUsersFail(res.data));
		})
	}
};

export const fetchAdminsStart = () => {
	return {
	  type: actionsNames.FETCH_ADMINS_START
	};
  };

const fetchAdminsSuccess = data => {
	return {
		type: actionsNames.FETCH_ADMINS_SUCCESS,
		data: data
	};
};

const fetchAdminsFail = error => {
	return {
		type: actionsNames.FETCH_ADMINS_FAIL,
		error: error
	};
};

export const fetchAdmins = (id) => {
	return dispatch => {
	  dispatch(fetchAdminsStart());
	  api
		.get(`/users/admin/admins`, configToken())
		.then(res => {
		  dispatch(fetchAdminsSuccess(res.data));
		})
		.catch(res => {
		  dispatch(fetchAdminsFail(res.data));
		})
	}
};


export const fetchUser = (id) => {
	return dispatch => {
	  dispatch(fetchUserByAdminStart());
	  api
		.get(`/users/admin/user?id=${id}`, configToken())
		.then(res => {
		  dispatch(fetchUserByAdminSuccess(res.data));
		})
		.catch(res => {
		  dispatch(fetchUserByAdminFail(res.data));
		})
	}
};

export const fetchAdmin = (id) => {
	return dispatch => {
	  dispatch(fetchUserByAdminStart());
	  api
		.get(`/users/admin/admin?id=${id}`, configToken())
		.then(res => {
		  dispatch(fetchUserByAdminSuccess(res.data));
		})
		.catch(res => {
		  dispatch(fetchUserByAdminFail(res.data));
		})
	}
};

export const updateUserByAdmin = newuser => {
	return dispatch => {
	  dispatch(fetchUserByAdminStart());
	  api
		.post("/users/admin/user", newuser, configToken())
		.then(response => {
		  dispatch(fetchUserByAdminSuccess(newuser));
		})
		.catch(err => {
		  if (err.response) {
			dispatch(fetchUserByAdminFail(err.response.data));
		  }
		});
	};
  };

export const updateAdmin = newuser => {
	return dispatch => {
		dispatch(fetchUserByAdminStart());
		api
		.post("/users/admin/user", newuser, configToken())
		.then(response => {
			dispatch(fetchUserByAdminSuccess(newuser));
		})
		.catch(err => {
			if (err.response) {
			dispatch(fetchUserByAdminFail(err.response.data));
			}
		});
	};
};
