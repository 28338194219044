import * as actionsNames from '../actions/actionsNames';
import { updateObject } from '../../utils/utility';

const initialState = {
    coordenadas: null,
    loading: false,
    centraliza: 0,
    imoveisWithinPolygon: [],
    imoveisproximos: [],
    imoveisproximospredios: [],
    error_geo: false,
    favoritos: [],
    favoritosPredios: [],
    buscou: false,
    urlparams: null,
    firstTime: false,
    newfavorito: false,
    center: {
        lat: 0,
        lng: 0
    }
}

const loadSuccess = ( state, action ) => {
    return updateObject( state, 
        {   
            loading: false,
            center: {lat: action.data.coordenadas.latitude, lng: action.data.coordenadas.longitude },
            imoveisWithinPolygon: action.data.fetchedPostsWithinPolygon,
            urlparams: action.data.urlparams,
        }
    );
};

const loadFail = ( state, action ) => {
    return updateObject( state, { loading: false,
        error: action.error } );
};

const actionStart = ( state, action ) => {
    return updateObject( state, { loading: true, centraliza: 0, buscou: false, urlparams: null, newfavorito:false } );
};

const loadLocalizacaoSuccess = ( state, action ) => {
    return updateObject( state, 
        { loading: false,
            coordenadas: action.data.coordenadas,
        center: {lat: action.data.coordenadas.latitude, lng: action.data.coordenadas.longitude },
        imoveisproximos: action.data.imoveisproximos,
        imoveisproximospredios: action.data.imoveisproximospredios,
        centraliza: 0,
        erro_geo: false,
        buscou: true,
        urlparams: action.data.urlparams,
         }
    );
};

const loadLocalizacaoFail = ( state, action ) => {
    return updateObject( state, { loading: false,
        error: action.error } );
};

const setLocalizacaoSuccess = ( state, action ) => {
    return updateObject( state, 
        { loading: false, 
        coordenadas: action.data.coordenadas,
        center: { lat: action.data.coordenadas.latitude, lng: action.data.coordenadas.longitude },
        centraliza: 0,
        erro_geo: false,
        buscou: false,
         } 
    );
};
    
const centralizar = ( state, action ) => {
    return updateObject( state, { centraliza: state.centraliza+1, buscou: false } );
};
    
const setCenter = ( state, action ) => {
    return updateObject( state, { center: action.center, centraliza: 0, buscou: false } );
};


const setBuscou = ( state, action ) => {
    return updateObject( state, { buscou: action.buscou } );
};

const setFirstTime = ( state, action ) => {
    return updateObject( state, { firstTime: action.firstTime } );
};

const setErrorGetLocalizacao = ( state, action ) => {
    return updateObject( state, { loading: false, error_geo: true, buscou: false } );
};

// const setLocalizacao = ( state, action ) => {
//     return updateObject( state, { coordenadas: action.coordenadas } );
// };

const initSuccess = ( state, action ) => {
    action.data = {coordenadas: action.coordenadas};
    let stateLocalizacaoSetted = setLocalizacaoSuccess(state, action);
    return updateObject( stateLocalizacaoSetted, 
        {favoritos: action.favoritos, favoritosPredios: action.favoritosPredios, loading: false, buscou: false,
            newfavorito:action.data.newfavorito,}
    );
};

const initFail = ( state, action ) => {
    return updateObject( state, { loading: false,
        favoritos: null,
        favoritosPredios: null,
        newfavorito:false,
        error: action.error } );
};

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        
        case actionsNames.LOAD_SUCCESS: return loadSuccess( state, action );
        case actionsNames.LOAD_FAIL: return loadFail(state, action);
        case actionsNames.LOAD_LOCALIZACAO_START: return actionStart( state, action );
        case actionsNames.LOAD_LOCALIZACAO_FAIL: return loadLocalizacaoFail(state, action);
        case actionsNames.LOAD_LOCALIZACAO_SUCCESS: return loadLocalizacaoSuccess( state, action );
        case actionsNames.SET_LOCALIZACAO_SUCCESS: return setLocalizacaoSuccess( state, action );
        case actionsNames.MAP_CENTER: return centralizar( state, action );
        case actionsNames.SET_CENTER: return setCenter( state, action );
        case actionsNames.SET_BUSCOU: return setBuscou( state, action );
        case actionsNames.SET_FIRST_TIME: return setFirstTime( state, action );
        case actionsNames.ERROR_GET_LOCALIZACAO: return setErrorGetLocalizacao( state, action );
        case actionsNames.INIT_MAPA_START: return actionStart( state, action );
        case actionsNames.INIT_MAPA_SUCCESS: return initSuccess(state, action);
        case actionsNames.INIT_MAPA_FAIL: return initFail( state, action );
        default: return state;
    }
};

export default reducer;
