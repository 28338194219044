import React from "react";
import Button from "@material-ui/core/Button";
import MessageRoundedIcon from "@material-ui/icons/MessageRounded";
import CallRoundedIcon from "@material-ui/icons/CallRounded";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import WhatsAppMsg from "./WhatsAppMsg/WhatsAppMsg";
import { TextField } from "@material-ui/core";

import { connect } from "react-redux";
import * as actions from "../../store/actions/index";

const ContactButtons = ({
  numberPhone,
  emailProprietary,
  error,
  messagedone,
  onSubmitMessage,
  size = "small",
  buttonColor = "default",
  buttonVariant = "default",
  disableIcon = false,
  disablePhone = false,
  pageId = "",
  horizontal = false
}) => {
  const [states, setStates] = React.useState({
    pageId: pageId,
    openPhone: false,
    openMsg: false,
    openNoContact: false,
    email: "",
    name: "",
    number: "",
    message: "",
    responseMessage: "",
  });

  const handleClickPhone = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setStates({ ...states, openPhone: true });
  };

  const handleClickMsg = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setStates({ ...states, openMsg: true });
  };

  const handleClose = (e = undefined) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    setStates({
      ...states,
      openPhone: false,
      openMsg: false,
      openNoContact: false,
      responseMessage: "",
    });
  };

  const emptyForm = () => {
    setStates({ ...states, email: "", name: "", number: "", message: "" });
    handleClose();
  };

  const handleSubmit = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (emailProprietary) {
      const data = {
        id: states.pageId,
        name: states.name,
        email: states.email,
        phoneNumber: states.number,
        message: states.message,
        emailOwner: emailProprietary,
      };
      onSubmitMessage(data);
      emptyForm();
      setStates({ ...states, responseMessage: messagedone });
    } else {
      emptyForm();
      setStates({ ...states, openNoContact: true });
    }
  };

  return (
    <>
      <div style={horizontal ? { display: "flex", flexDirection: "row" } : { display: "flex", flexDirection: "column", alignItems: "center" }}>
        {disablePhone ? null : (
          <Button
            style={horizontal ? { width: "128px", marginRight: "16px", marginTop: "8px" } : { width: "128px", marginTop: "8px" }}
            color={buttonColor}
            variant={buttonVariant}
            startIcon={disableIcon ? null : <CallRoundedIcon />}
            size={size}
            onClick={handleClickPhone}
          >
            telefone
          </Button>
        )}
        <Button
          style={horizontal ? { width: "128px", marginRight: "8px", marginTop: "8px" } : { width: "128px", marginTop: "8px" }}
          color={buttonColor}
          variant={buttonVariant}
          startIcon={disableIcon ? null : <MessageRoundedIcon />}
          size={size}
          onClick={handleClickMsg}
        >
          mensagem
        </Button>
      </div>
      <Dialog
        open={states.openPhone}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Celular para contato:"}
        </DialogTitle>
        <DialogContent>
          <DialogActions>
            {numberPhone ? <WhatsAppMsg number={numberPhone} pageId={states.pageId}/> : "Contato indisponível"}
          </DialogActions>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Ok
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={states.openMsg}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Entre em contato com o proprietário!"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Preencha os campos abaixo com seus dados e envie sua mensagem!
          </DialogContentText>
          <form onSubmit={handleSubmit} id="messageForm">
            <TextField
              required
              id="name"
              label="Nome"
              variant="outlined"
              type="text"
              value={states.name}
              onChange={(e) => setStates({ ...states, name: e.target.value })}
              margin="dense"
              fullWidth={true}
            />
            <br />
            <TextField
              required
              id="email"
              label="Email"
              variant="outlined"
              type="email"
              value={states.email}
              onChange={(e) => setStates({ ...states, email: e.target.value })}
              margin="dense"
              fullWidth={true}
            />
            <br />
            <TextField
              required
              id="number"
              label="Telefone"
              variant="outlined"
              type="tel"
              value={states.number}
              onChange={(e) => setStates({ ...states, number: e.target.value })}
              margin="dense"
              fullWidth={true}
            />
            <br />
            <TextField
              multiline
              id="message"
              label="Mensagem"
              variant="outlined"
              type="text"
              minRows={6}
              value={states.message}
              onChange={(e) =>
                setStates({ ...states, message: e.target.value })
              }
              margin="dense"
              fullWidth={true}
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Fechar
          </Button>
          <Button onClick={handleSubmit}>Enviar</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={states.openNoContact}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Contato indisponível"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            No momento, não temos o contato de email do proprietário. Por favor,
            contate pelo celular.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Ok
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={states.responseMessage !== ""}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Mensagem enviada"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{messagedone}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Ok
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={error && states.responseMessage === ""}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Ocorreu um erro:"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{error && error}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    messagedone: state.imoveis.messagedone,
    error: state.imoveis.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSubmitMessage: (formdata) => dispatch(actions.sendMessage(formdata)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactButtons);
