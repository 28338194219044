import React from 'react';
import AdvertisementImages from "../../AdvertisementImages/AdvertisementImages";

export default function MediaCard({ advertisement }) {
  return (
    <AdvertisementImages
      advertisements={advertisement}
      width='1140px'
      height='256px'
    />
  );
}
