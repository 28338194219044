import React from 'react';
import { DataGrid } from '@material-ui/data-grid';

const columns = [
  {
    field: 'post_title',
    headerName: 'Post Title',
    width: 300,
    // editable: true,
  },
  {
    field: 'post_type',
    headerName: 'Post Type',
    width: 300,
    // editable: true,
  },
];

export default function PostList({ loading, history, url, posts = [] }) {
  return (
    <div style={{ height: 600, width: '100%' }}>
      {loading ? 'Carregando...' :
        (<DataGrid
          rows={posts && posts.posts ? posts.posts : []}
          columns={columns}
          pageSize={20}
          rowsPerPageOptions={[20]}
          getRowId={row => row._id}
          disableSelectionOnClick
          onRowClick={e => history.push(url + e.id)}
        />)
      }
    </div>
  );
}
