import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import * as actions from '../../../store/actions/index';

class Logout extends Component {
    componentDidMount () {
        if(this.props.all){
            this.props.onLogoutAll();
        }else{
            this.props.onLogout();
        }
    }

    render () {
        return <Redirect to="/"/>;
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLogout: () => dispatch(actions.logout()),
        onLogoutAll: () => dispatch(actions.logoutAll()),
    };
};

export default connect(null, mapDispatchToProps)(Logout);