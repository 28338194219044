import React from 'react';

import './Button.scss';

const button = ( props ) => (
	<button
		disabled={props.disabled}
		title={props.title}
		className={props.btnType ? ["Button", props.btnType].join(' ') : "Button"}
		onClick={props.clicked}
  >
    { props.children }
  </button>
);

export default button;
