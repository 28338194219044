import React from "react";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';

const WhatsAppMsg = ({number, pageId}) => {
    const message = 
      `Oi, tudo bem? Gostaria de maiores informações sobre este imóvel. Aguardo retorno, obrigado(a). 
      https://www.imobilize.com.br/imoveis/${pageId}`;

    const link = `https://api.whatsapp.com/send?phone=55${number}&text=${message}`;

    const noPropagation = (e) => e.stopPropagation();

    return (
        pageId ? (
            <span onClick={noPropagation}>
                <Link href={link} target="_blank" rel="noopener" underline="none" >
                    <Grid 
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={0.5}
                    > 
                    <Grid item>
                        <WhatsAppIcon fontSize="large"/>
                    </Grid>
                    <Grid item>
                        {number}
                    </Grid>
                    </Grid>
                </Link>
            </span>
      ) : number
    );
}

export default WhatsAppMsg;