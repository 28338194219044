import React, { useState } from "react";
import {
  Select,
  MenuItem,
  Typography,
  InputLabel,
  FormControl,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Paper from '@mui/material/Paper';

const useStyles = makeStyles({
  resultadoStyle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#fff",
    borderRadius: "10px",
    margin: "24px 8px 0px 8px",
  },
  styleTypography: {
    color: "#003a70",
    margin: "10px 20px",
  },
  styleSelect: {
    margin: "10px 20px",
    minWidth: 120,
  },
  logo: {
    maxHeight: 48,
    maxWidth: 96,
  },
  right: {
    display: "flex",
    alignItems: "center",
  },
});

function FilterOrder({ count, handleOrder, imgSrc = undefined }) {
  const classes = useStyles();
  const [selectedOption, setSelectedOption] = useState("");

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
    handleOrder(event.target.value);
  };

  return (
    <Paper elevation={1} className={classes.resultadoStyle}>
      <Typography variant="h5" className={classes.styleTypography}>
        {count ? count : "-"} página(s)
      </Typography>
      <div className={classes.right}>
        {imgSrc ? (
          <img className={classes.logo} alt="Logo" src={imgSrc} />
        ) : null}
        <FormControl variant="outlined" className={classes.styleSelect}>
          <InputLabel id="label-id">Ordenar por</InputLabel>
          <Select
            value={selectedOption}
            onChange={handleChange}
            MenuProps={{
              getContentAnchorEl: null,
            }}
            labelId="label-id"
            label="Ordenar por"
          >
            <MenuItem value="">Selecione uma das opções</MenuItem>
            <MenuItem value="area_descending">Área</MenuItem>
            <MenuItem value="price_ascending">Preço Menor para Maior</MenuItem>
            <MenuItem value="price_descending">Preço Maior para Menor</MenuItem>
          </Select>
        </FormControl>
      </div>
    </Paper>
  );
}

export default FilterOrder;
