import * as actionsNames from './actionsNames';
import api from '../../services/api';
import configToken from '../../utils/configToken';

const updateUserSuccess = () => {
    return {
        type: actionsNames.UPDATE_USER_SUCCESS
    };
};

const updateUserFail = ( error ) => {
    return {
        type: actionsNames.UPDATE_USER_FAIL,
        error: error
    };
}

const updateUserStart = () => {
    return {
        type: actionsNames.UPDATE_USER_START
    };
};

export const updateUser = ( userData ) => {
    return dispatch => {
        dispatch( updateUserStart() );
        api.patch( '/users/me', userData, {headers: {...configToken().headers, 'Content-Type': 'application/x-www-form-urlencoded'}})
            .then( response => {
                dispatch( updateUserSuccess( response ) );
            } )
            .catch( error => {
                dispatch( updateUserFail( error ) );
            } );
        // api.patch( '/users/me', userData, configToken())
        //     .then( response => {
        //         dispatch( updateUserSuccess() );
        //     } )
        //     .catch( error => {
        //         dispatch( updateUserFail( error ) );
        //     } );
    };
};

const fetchUserSuccess = ( userdata ) => {
    return {
        type: actionsNames.FETCH_USER_SUCCESS,
        userdata: userdata
    };
};

const fetchUserFail = ( error ) => {
    return {
        type: actionsNames.FETCH_USER_FAIL,
        error: error
    };
};

const fetchUserStart = () => {
    return {
        type: actionsNames.FETCH_USER_START
    };
};

export const loadUser = (token, userId) => {
    return dispatch => {
        dispatch(fetchUserStart());
        // const queryParams = '?auth=' + token + '&imovelBy="userId"&equalTo="' + userId + '"';
        api.get( '/users/me', configToken())
            .then( res => {
                dispatch(fetchUserSuccess(res.data));
            } )
            .catch( err => {
                dispatch(fetchUserFail(err));
            } );
    };
};

const deleteUserSuccess = () => {
    return {
        type: actionsNames.DELETE_USER_SUCCESS
    };
};

const deleteUserFail = ( error ) => {
    return {
        type: actionsNames.DELETE_USER_FAIL,
        error: error
    };
}

const deleteUserStart = () => {
    return {
        type: actionsNames.DELETE_USER_START
    };
};

export const removeUser = () => {
    return dispatch => {
        dispatch(deleteUserStart());
        api.delete( '/users/me', configToken())
            .then( res => {
                dispatch(deleteUserSuccess(res.data));
            } )
            .catch( err => {
                dispatch(deleteUserFail(err));
            } );
    };
};

export const changeUser = (newvalue, controlname) =>{
    return {
        type: actionsNames.CHANGE_USER,
        newvalue: newvalue,
        controlname: controlname
    }
}

export const changeSign = (newvalue, controlname) =>{
    return {
        type: actionsNames.CHANGE_ASSINATURA,
        newvalue: newvalue,
        controlname: controlname
    }
}

const deleteAvatarFail = ( error ) => {
    return {
        type: actionsNames.DELETE_AVATAR_FAIL,
        error: error
    };
}

const deleteAvatarStart = () => {
    return {
        type: actionsNames.DELETE_AVATAR_START
    };
};

const deleteAvatarSuccess = () => {
    return {
        type: actionsNames.DELETE_AVATAR_SUCCESS
    };
};

export const deleteAvatar = (keyavatar) => {
    return dispatch => {
        dispatch(deleteAvatarStart());
        api.delete('/users/me/avatar', {...configToken(), data: keyavatar})
            .then(response => {
                dispatch(deleteAvatarSuccess());
            })
            .catch(err => {
                if(err.response){
                    dispatch(deleteAvatarFail(err.response.data));
                }
            });
    };
};

const salvarCartaoFail = ( error ) => {
    return {
        type: actionsNames.SALVAR_CARTAO_FAIL,
        error: error
    };
}

const salvarCartaoStart = () => {
    return {
        type: actionsNames.SALVAR_CARTAO_START
    };
};

const salvarCartaoSuccess = () => {
    return {
        type: actionsNames.SALVAR_CARTAO_SUCCESS
    };
};

export const salvarCartao = (data) => {
    return dispatch => {
        dispatch( salvarCartaoStart() );
        api.post( '/sales/create', data, configToken())
            .then( response => {
                dispatch( salvarCartaoSuccess() );
                window.location.reload();
            } )
            .catch( error => {
                console.log(error);
                dispatch( salvarCartaoFail( error ) );
            } );
    };
};

export const alterarCartao = (data) => {
    return dispatch => {
        dispatch( salvarCartaoStart() );
        api.put( '/sales/change', data, configToken())
            .then( response => {
                dispatch( salvarCartaoSuccess() );
                window.location.reload();
            } )
            .catch( error => {
                dispatch( salvarCartaoFail( error ) );
                window.location.reload();
            } );
    };
};

const reativarAssinaturaFail = ( error ) => {
    return {
        type: actionsNames.REATIVAR_ASSINATURA_FAIL,
        error: error
    };
}

const reativarAssinaturaStart = () => {
    return {
        type: actionsNames.REATIVAR_ASSINATURA_START
    };
};

const reativarAssinaturaSuccess = () => {
    return {
        type: actionsNames.REATIVAR_ASSINATURA_SUCCESS
    };
};

export const reativarAssinatura = () => {
    return dispatch => {
        dispatch(reativarAssinaturaStart());
        // const queryParams = '?auth=' + token + '&imovelBy="userId"&equalTo="' + userId + '"';
        api.get( '/sales/reactivate', configToken())
            .then( res => {
                dispatch(reativarAssinaturaSuccess(res.data));
                window.location.reload();
            } )
            .catch( err => {
                dispatch(reativarAssinaturaFail(err));
            } );
    };
}

const cancelarAssinaturaFail = ( error ) => {
    return {
        type: actionsNames.CANCELAR_ASSINATURA_FAIL,
        error: error
    };
}

const cancelarAssinaturaStart = () => {
    return {
        type: actionsNames.CANCELAR_ASSINATURA_START
    };
};

const cancelarAssinaturaSuccess = () => {
    alert('Assinatura cancelada com sucesso!');
    return {
        type: actionsNames.CANCELAR_ASSINATURA_SUCCESS
    };
};

export const cancelarAssinatura = (data) => {
    return dispatch => {
        dispatch( cancelarAssinaturaStart() );
        // api.get( '/sales/all', c)
        api.get( '/sales/cancel?id='+data.salesId, configToken())
            .then( response => {
                dispatch( cancelarAssinaturaSuccess() );
                window.location.reload();
            } )
            .catch( error => {
                dispatch( cancelarAssinaturaFail( error ) );
                window.location.reload();
            } );
    };
};

const loadAssinaturaFail = ( error ) => {
    return {
        type: actionsNames.LOAD_ASSINATURA_FAIL,
        assinatura: null,
        error: error
    };
}

const loadAssinaturaStart = () => {
    return {
        type: actionsNames.LOAD_ASSINATURA_START
    };
};

const loadAssinaturaSuccess = (assinatura) => {
    return {
        type: actionsNames.LOAD_ASSINATURA_SUCCESS,
        assinatura: assinatura
    };
};

export const loadAssinatura = () => {
    return dispatch => {
        dispatch( loadAssinaturaStart() );
        api.get( '/sales/mysale', configToken())
            .then(response => {
                console.log(response)
                dispatch( loadAssinaturaSuccess(response.data) );
            } )
            .catch( error => {
                dispatch( loadAssinaturaFail( error ) );
            } );
    };
};