import React from 'react';
import { DataGrid } from '@material-ui/data-grid';

const defaultColumns = [
  {
    field: 'name',
    headerName: 'Nome',
    width: 300,
    // editable: true,
  },
];

export default function AnnoucementMessagesList({ loading, history, url, columns, messages = [] }) {
  return (
    <div style={{ height: 600, width: '100%' }}>
      {loading ? 'Carregando...' :
        (<DataGrid
          rows={messages ? messages : []}
          columns={columns || defaultColumns}
          pageSize={20}
          rowsPerPageOptions={[20]}
          getRowId={row => row._id || row.id}
          disableSelectionOnClick
          onRowClick={e => history.push(url + e.id)}
        />)
      }
    </div>
  );
}
