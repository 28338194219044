import * as actionsNames from './actionsNames';
import api from '../../services/api';
import configToken from '../../utils/configToken';

const centralizarMapa = () => {
    return {
        type: actionsNames.MAP_CENTER_FAV
    };
};

export const centralizarFav = () => {
    return dispatch => {
        dispatch(centralizarMapa());
    };
}

const setCenter = (center) => {
    return {
        type: actionsNames.SET_CENTER_FAV,
        center
    };
};

export const onSetCenterFav = (center) => {
    return dispatch => {
        dispatch(setCenter(center));
    };
}

const setLocalizacaoSuccess = ( data ) => {
    return {
        type: actionsNames.SET_LOCALIZACAO_SUCCESS_FAV,
        data: data
    };
};

export const onSetLocalizacaoFav = (coordenadas) => {
    return dispatch => {
        dispatch(setLocalizacaoSuccess({coordenadas: coordenadas}));
    };
};

export const onErrorGetLocalizacaoFav = () => {
    return {
        type: actionsNames.ERROR_GET_LOCALIZACAO_FAV,
    };
}


const initStart = ( ) => {
    return {
        type: actionsNames.INIT_MAPA_START_FAV
    };
};

const initSuccess = ( data ) => {
    return {
        type: actionsNames.INIT_MAPA_SUCCESS_FAV,
        favoritos: data.favoritos,
        favoritosPredios: data.favoritosPredios,
        coordenadas: data.coordenadas,
        loading: true
    };
};

const initFail = ( err ) => {
    return {
        type: actionsNames.INIT_MAPA_FAIL_FAV,
        error: err
    };
};

export const onInitFav = (coordenadas) => {
    return dispatch => {
        dispatch(initStart());
        api.get( '/favorites/all/parsed', configToken())
            .then( res => {
                let fetchedFavoritos= [];
                let fetchedFavoritosPredios = [];
                Object.entries(res.data.parsedFavorites).forEach(entry => {
                    let key = entry[0];
                    let value = entry[1];

                    if(value.length > 1){
                        fetchedFavoritosPredios.push( {
                            id: key,
                            ...value,
                        } );
                    }else{
                        fetchedFavoritos.push( {
                            id: key,
                            ...value[0],
                        } );
                    }
                });
                // let coordinates = undefined
                // if (fetchedFavoritos && fetchedFavoritos.length > 0) {
                //     coordinates = fetchedFavoritos[0].property.property_location.coordinates;
                // } else if (fetchedFavoritosPredios && fetchedFavoritosPredios[0] !== undefined && fetchedFavoritosPredios[0].length > 0) {
                //     coordinates = fetchedFavoritosPredios[0][0].property.property_location.coordinates;
                // }
                dispatch(initSuccess({favoritos: fetchedFavoritos, favoritosPredios: fetchedFavoritosPredios, coordenadas: coordenadas}));
            } )
            .catch( err => {
                dispatch(initFail(err));
            } );
    };
};
