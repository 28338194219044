import React, { Component } from 'react';
import Dashboard from '../Dashboard'

class AdminPanel extends Component {
  state = {};

  render() {
    return (
      <>
        <Dashboard history={this.props.history} />
      </>
    )
  }
}

export default AdminPanel;
