import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import { Grid } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  quantSelection: {
    maxWidth: "720px",
    margin: "0px 3px",
    width: "100%",
  },
  blockSelection: {
    marginBottom: theme.spacing(2),
    display: "flex",
    maxWidth: "720px",
    margin: "0px auto",
    justifyContent: "center",
    flexDirection: "column",
  },
  box: {
    display: "flex",
    justifyContent: "space-between",
  },
}));

export default function QuantSelect({
  minValue,
  maxValue,
  onChange,
  label,
  nameMinLabel,
  nameMaxLabel,
}) {
  const classes = useStyles();

  return (
    <div className={classes.blockSelection}>
      <h5 style={{ margin: "0 0 7px 0" }}>{label}</h5>
      <Grid container justifyContent="space-between">
        <Grid xs={5}>
          <FormControl
            size="small"
            variant="outlined"
            className={classes.quantSelection}
          >
            <InputLabel>De</InputLabel>
            <Select
              native
              value={minValue}
              onChange={onChange}
              label={nameMinLabel}
              id={nameMinLabel}
              renderValue={(selected) => <div>{selected}</div>}
            >
              <option aria-label="None" value="" />
              {[1, 2, 3].map((value) => (
                <option value={value}>{value}</option>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid xs={5}>
          <FormControl
            size="small"
            variant="outlined"
            className={classes.quantSelection}
          >
            <InputLabel>Até</InputLabel>
            <Select
              native
              value={maxValue}
              onChange={onChange}
              label={nameMaxLabel}
              id={nameMaxLabel}
            >
              <option aria-label="None" value="" />
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
              <option value={4}>4 ou +</option>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </div>
  );
}
