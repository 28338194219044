import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  list: {
    overflow: 'auto',
    maxHeight: 200
  }
}));


const VirtualizedList = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
        <List component="nav" aria-label="contacts" className={classes.list}>
            {props.options ? props.options.map(e => {
              return (
                <ListItem button onClick={() => {props.onPlaceSelected(e);}}>
                    <ListItemText primary={
                      <Typography style={{
                        fontSize: "12px"
                      }}>{e.label}</Typography>
                      }/>
                </ListItem>          
            )}) : ""}
        </List>
    </div>
  );
}

export default VirtualizedList;