import * as actionsNames from "../actions/actionsNames";
import { updateObject } from "../../utils/utility";

const initialState = {
  post_title: undefined,
  post_type: undefined,
  metas: {
    razao_social: undefined,
    cep: undefined,
    logradouro: undefined,
    nmero: undefined,
    complemento: undefined,
    bairro: undefined,
    telefone: undefined,
    email: undefined,
    nome_do_contato: undefined,
    email_do_contato: undefined,
    celular_de_contato: undefined,
    codigo_imovel: undefined,
    creci_juridico: undefined,
    site: undefined,
    estado: undefined,
    cidade: undefined,
    nome_de_contato: undefined,
    telefone_de_contato: undefined,
    email_de_contato: undefined,
    data_de_entrega: undefined,
    total_de_lotes: undefined,
    area_lote: undefined,
    area_construida: undefined,
    valor_condominio: undefined,
    condominio_atualizacao: undefined,
    area_loteamento: undefined,
    administradora_atualizacao: undefined,
    total_imoveis: undefined,
    total_andares: undefined,
    dormitorios: undefined,
    area_util: undefined,
    vagas_garagem: undefined,
    location_coordinates: undefined,
    _pronamic_google_maps_map_type: undefined,
    _pronamic_google_maps_zoom: undefined,
    _pronamic_google_maps_address: undefined,
    ibge: undefined,
    pmb_sequencia: undefined,
    valor_do_condomnio: undefined,
    data_de_atualizao_do_valor_do_condomnio: undefined,
    hotel: undefined,
    lancamento: undefined,
    anunciante: undefined,
    valor_inicial: undefined,
    aceita_minha_casa_minha_vida: undefined,
    valor_do_subsidio: undefined,
    tipo: undefined,
    construtora: undefined,
    administradora_condominio: undefined,
    prestadora_de_servico: undefined,
    imobiliaria: undefined,
    corretora_de_seguro: undefined,
    areas_de_lazer: undefined,
    banner_topo: undefined,
    banner_lateral: undefined,
    fotos: undefined,
    planta: undefined,
    _pronamic_google_maps_title: undefined,
    _pronamic_google_maps_description: undefined,
    banner_principal: undefined,
    tipos_de_apartamento: undefined,
    suites: undefined,
    valor_do_condominio: undefined,
    data_de_atualizacao_condomnio: undefined,
    vagas_de_garagem: undefined,
    duplex: undefined,
    arquivos: undefined,
    nome: undefined,
    banner_de_lancamento: undefined,
    logo_lancamento: undefined,
    padroes_de_unidade: [],
  },
  loading: false,
  post: undefined,
  error: undefined,
};

const actionStart = (state, action) => {
  return updateObject(state, initialState);
};

// const actionSuccess = ( state, action ) => {
//     return updateObject( state, { ...initialState.controlsSign } );
// };

const actionFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const fetchPostSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    ...action.data,
  });
};

const updatePost = (state, action) => {
  return updateObject(state, {
    loading: false,
    metas: {
      ...state.metas,
      [action.data.key]: action.data.value,
      padroes_de_unidade: [],
    },
  });
};

const updateBasicPost = (state, action) => {
  return updateObject(state, {
    loading: false,
    [action.data.key]: action.data.value,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionsNames.FETCH_POST_START:
      return actionStart(state, action);
    case actionsNames.FETCH_POST_SUCCESS:
      return fetchPostSuccess(state, action);
    case actionsNames.FETCH_POST_FAIL:
      return actionFail(state, action);
    case actionsNames.UPDATE_POST:
      return updatePost(state, action);
    case actionsNames.UPDATE_BASIC_POST:
      return updateBasicPost(state, action);
    default:
      return state;
  }
};

export default reducer;
