import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
  },
  iconButton: {
    padding: 10,
  }
}));

const Asynchronous = (props) => {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [value, setValue] = React.useState("");
  const loading = open && options.length === 0;
  const classes = useStyles();

  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      var properties = await props.clickAuto(value);

      // console.log(e);
      // console.log(active);
      console.log(properties)
      if (active && properties.length > 0) {
        setOptions(properties.map((el) => el));
      }
    })();    


    return () => {
      active = false;
    };
  // eslint-disable-next-line
  }, [loading]);

  // React.useEffect(() => {
  //   if (!open) {
  //     setOptions([]);
  //   }
  // }, [open]);

  const handleAuto = React.useCallback(
    async (e) => {
      let active = true;
      setOptions([]);
  
      (async () => {
        var properties = await props.clickAuto(e);

        // console.log(e);
        // console.log(active);
        console.log(properties)
        if (active && properties.length > 0) {
          setOptions(properties.map((el) => el));
        }
      })();    

      return () => {
        active = false;
      };
    // eslint-disable-next-line
    }, [loading]);


  const handleSubmit = React.useCallback(
    async (e) => {
      let active = true;

      if (e) {    
        (async () => {
          // setOpen(true);
          // setOpen(false);

          // console.log(options)
          if (active && options.length > 0) {
            await props.clickSubmit(options[0]);
          }  
          // console.log(properties)
          // if (active && properties.length > 0) {
          //   setOptions(properties.map((el) => el));
          //   setOpen(false);

          // }
        })();
      }


      return () => {
        active = false;
      };
    // eslint-disable-next-line
    }, [loading]);

  return (
    // <Autocomplete
    //   id="combo-box-demo"
    //   options={options}
    //   onInputChange={(event, newValue) => {
    //     handleAuto(newValue);
    //   }}
    //   getOptionLabel={(option) => option.label}
    //   renderInput={(params) => (
    //     <div className={classes.root}>
    //     <TextField
    //       {...params} 
    //       InputProps={{
    //         ...params.InputProps,
    //         style: {
    //           justifyContent: 'center',
    //           fontSize: "12px"
    //         }
    //       }}
    //       placeholder="Digite a inscrição municipal"
    //       variant="outlined" />
    //   </div>
    //   )}
    // />
    <Autocomplete
    id="asynchronous-demo"
    freeSolo
    value={value}
    open={open}
    onOpen={() => {
      setOpen(true);
     }
    }
    onClose={() => {
      setOpen(false);
    }}
    getOptionLabel={(option) => option.label}
    options={options}
    onInputChange={(event, newValue) => {
      setValue({
        label: newValue
      })
      handleAuto({
        label: newValue
      });
    }}
    // onSubmit={(event, newValue) => {
    //   setOptions([]);
    //   // setOpen(true);
    //   // setOpen(false);

    //   if (typeof newValue === 'string') {
    //     handleSubmit(newValue);
    //   } else if (newValue && newValue.inputValue) {
    //     handleSubmit(newValue.inputValue);
    //   } else {
    //     if (newValue && newValue.label) handleSubmit(newValue.label);
    //   }
    // }}
    onChange={(event, newValue) => {
      setOptions([]);
      setOpen(true);

      if (typeof newValue === 'string') {
        handleSubmit(newValue);
      } else if (newValue && newValue.inputValue) {
        handleSubmit(newValue.inputValue);
      } else {
        if (newValue && newValue.label) handleSubmit(newValue.label);
      }
    }}
    renderInput={(params) => (
      <div className={classes.root}>
        <TextField
          {...params}
          placeholder={props.placeholder}
          variant="outlined"
          onKeyDown={e => {
            if (e.keyCode === 13 && e.target.value) {
              setValue({label: options[0].label});
              handleSubmit(e.target.value);
              setOpen(false);
            }
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
            type: 'search',
            style: {
              justifyContent: 'center',
              fontSize: "12px"
            }
          }}
        />
      </div>
    )}
  />
  );
}

export default Asynchronous
