import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import CustomizedInputBase from "../map/searchBar";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center'
  },
  partnerTitle: {
    display: 'flex',
    justifyContent: 'center',
    color: '#003a70',
    fontSize: '30px'
  },
  searchBox: {
    display: 'flex',
    maxWidth: '728px',
    margin: 'auto',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  searchTitle: {
    display: 'flex',
    justifyContent: 'center',
    color: 'black',
    fontSize: '15px'
  },
  searchInput: {
    display: 'flex',
    justifyContent: 'center',
    color: '#003a70',
    fontSize: '30px'
  }
}));

export default function SearchPosts ({ selected, clickAuto, clickSubmit, onPlaceSelected, load, itsEmpty, loadDefault = () => {} }) {
  const classes = useStyles();

  return (
    <div className={classes.searchBox}>
      <span className={classes.searchTitle}>
        Busca por Nome
      </span>
      <CustomizedInputBase
        load={load}
        loadDefault={loadDefault}
        selected={selected}
        clickAuto={clickAuto}
        clickSubmit={clickSubmit}
        onPlaceSelected={onPlaceSelected}
        itsEmpty={itsEmpty}
        className={classes.searchInput}
      />
      <br />
    </div>
  )
}