import * as actionsNames from '../actions/actionsNames';
import { updateObject } from '../../utils/utility';

const initialState = {
    loading: false,
    announcementMessages: null,
    announcementMessage: null
};

const sendAnnouncementMessageStart = (state, action) => {
    return updateObject(state, { loading: true });
};

const sendAnnouncementMessageSuccess = (state, action) => {
    return updateObject(state, {
        loading: false
    });
};

const sendAnnouncementMessageFail = (state, action) => {
    return updateObject(state, {
        loading: false
    });
};

const actionFail = (state, action) => {
    return updateObject(state, {
        loading: false,
        error: action.error
    });
};

const actionStart = (state, action) => {
    return updateObject(state, { loading: true });
};

const fetchAnnouncementMessagesSuccess = (state, action) => {
    return updateObject(state, { 
        loading: false,
        announcementMessages: action.data
    });
};

const fetchAnnouncementMessageSuccess = (state, action) => {
    return updateObject(state, { 
        loading: false,
        announcementMessage: action.data
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionsNames.SEND_ANNOUNCEMENT_MESSAGE_START: return sendAnnouncementMessageStart(state, action);
        case actionsNames.SEND_ANNOUNCEMENT_MESSAGE_SUCCESS: return sendAnnouncementMessageSuccess(state, action)
        case actionsNames.SEND_ANNOUNCEMENT_MESSAGE_FAIL: return sendAnnouncementMessageFail(state, action);
        case actionsNames.FETCH_ANNOUNCEMENT_MESSAGES_START: return actionStart(state, action);
        case actionsNames.FETCH_ANNOUNCEMENT_MESSAGES_SUCCESS: return fetchAnnouncementMessagesSuccess(state, action);
        case actionsNames.FETCH_ANNOUNCEMENT_MESSAGES_FAIL: return actionFail(state, action);
        case actionsNames.FETCH_ANNOUNCEMENT_MESSAGE_START: return actionStart(state, action);
        case actionsNames.FETCH_ANNOUNCEMENT_MESSAGE_SUCCESS: return fetchAnnouncementMessageSuccess(state, action);
        case actionsNames.FETCH_ANNOUNCEMENT_MESSAGE_FAIL: return actionFail(state, action);
        default: return state;
    }
};

export default reducer;
