import api from "../../services/api";

import * as actionNames from "./actionsNames";
import * as userSession from "../userSession";
import configToken from "../../utils/configToken";
//import { withInfo } from "antd/lib/modal/confirm";

export const showErrors = (show) => {
  return {
    type: actionNames.SHOW_ERRORS,
    show: show
  };
};

export const authStart = () => {
  return {
    type: actionNames.AUTH_START
  };
};

export const authSuccess = (token, userId, role) => {
  return {
    type: actionNames.AUTH_SUCCESS,
    idToken: token,
    userId: userId,
    role: role
  };
};

export const authFail = error => {
  return {
    type: actionNames.AUTH_FAIL,
    error: error
  };
};

export const logout = () => {
  api.get("/users/me/logout", configToken());
  localStorage.removeItem("token");
  localStorage.removeItem("expirationDate");
  localStorage.removeItem("userId");
  userSession.clearSessionUser();
  return {
    type: actionNames.AUTH_LOGOUT
  };
};

export const logoutAll = () => {
  api.get("/users/me/logout/all");
  localStorage.removeItem("token");
  localStorage.removeItem("expirationDate");
  localStorage.removeItem("userId");
  userSession.clearSessionUser();
  return {
    type: actionNames.AUTH_LOGOUT
  };
};

export const checkAuthTimeout = expirationDate => {
  let expirationTime = expirationDate.getTime() - new Date().getTime();
  return dispatch => {
    setTimeout(() => {
      dispatch(logout());
    }, expirationTime);
  };
};

const logar = (dispatch, response) => {
  const expirationDate = new Date(response.data.exp);
  localStorage.setItem("token", response.data.token);
  localStorage.setItem("expirationDate", expirationDate);
  localStorage.setItem("userId", response.data.user.id);
  localStorage.setItem("role", response.data.user.role);
  userSession.setUsuarioLogado(response.data.user);
  dispatch(authSuccess(response.data.authtoken, response.data.user.id, response.data.user.role));
  dispatch(checkAuthTimeout(expirationDate));
};

export const login = (login, password) => {
  return dispatch => {
    dispatch(authStart());
    const authData = {
      login: login,
      password: password
    };
    api
      .post("/users/signin", authData)
      .then(response => {
        logar(dispatch, response);
        window.location.href = "/";
      })
      .catch(err => {
        if (err.response) {
          dispatch(authFail(err.response.data));
        }
      });
  };
};

export const signupSuccess = userData => {
  return {
    type: actionNames.SIGNUP_SUCCESS,
    message: userData.message,
    user: userData.user
  };
};

export const signup = newuser => {
  return dispatch => {
    dispatch(authStart());
    api
      .post("/users/signup", newuser)
      .then(response => {
        dispatch(signupSuccess({message: response.data.message, user: newuser}));
      })
      .catch(err => {
        if (err.response) {
          dispatch(authFail(err.response.data));
        }
      });
  };
};

export const resetPassSuccess = message => {
  return {
    type: actionNames.RESET_PASS_SUCCESS,
    message: message
  };
};

export const resetPassword = (datareset, token) => {
  return dispatch => {
    dispatch(authStart());
    api
      .post("/auth/reset/" + token, datareset)
      .then(response => {
        dispatch(resetPassSuccess(response.data.message));
      })
      .catch(err => {
        if (err.response) {
          dispatch(authFail(err.response.data));
        }
      });
  };
};

export const recoverPassSuccess = message => {
  return {
    type: actionNames.RECOVER_PASS_SUCCESS,
    message: message
  };
};

export const recoverPassword = dataemail => {
  return dispatch => {
    dispatch(authStart());
    api
      .post("/auth/recover", dataemail)
      .then(response => {
        dispatch(recoverPassSuccess(response.data.message));
      })
      .catch(err => {
        if (err.response) {
          dispatch(authFail(err.response.data));
        }
      });
  };
};

export const authCheckState = () => {
  // console.log('action authCheckState');
  return dispatch => {
    const token = localStorage.getItem("token");
    if (!token) {
      // console.log('sem token logout');
      // dispatch(logout());
    } else {
      const expirationDate = new Date(localStorage.getItem("expirationDate"));
      // console.log('com token',token, expirationDate);
      if (expirationDate <= new Date()) {
        // console.log('token expirado logout');
        dispatch(logout());
      } else {
        // console.log('tentando success');
        const userId = localStorage.getItem("userId");
        const role = localStorage.getItem("role");
        dispatch(authSuccess(token, userId, role));
        dispatch(checkAuthTimeout(expirationDate));
      }
    }
  };
};

export const loginWithToken = (token) => {

}

export const confirmation = token => {
  return dispatch => {
    dispatch(authStart());
    api
      .get(`/confirmation/${token}`)
      .then(response => {
        logar(dispatch, response);
        window.location.href = "/";
      })
      .catch(err => {
        if (err.response) {
          dispatch(authFail(err.response.data));
        }
      });
  };
};
