import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import imageTop from "../../../assets/images/bg-top@2x.png";
import { serveImage } from "../../../utils/serveImage";

import "./index.scss";

class HeaderNav extends Component {
  cleanValues = (value) => {
    if (value && value !== "0.00") {
      return value;
    } else {
      return "---";
    }
  };

  render() {
    return (
      <div
        style={{
          display: "flex",
          backgroundPosition: "center",
          backgroundSize: "cover",
          padding: "",
          minHeight: this.props.height || "",
          justifyContent: "center",
          backgroundImage: `url(${
            this.props.imageUrl
              ? serveImage(this.props.imageUrl)
              : this.props.atSearch
              ? ""
              : imageTop
          })`,
        }}
      >
        {/* <div style={{ display: "flex", alignItems: "end", width: "100%" }}>
          <div className="container">
            <Grid container spacing={0}>
              <Grid item xs={12}>
                À PARTIR DE{" "}
              </Grid>
              <Grid item xs={12}>
                <span className="initialValue">
                  {this.props.purchase === "purchase"
                    ? `Venda: R$ ${this.cleanValues(this.props.initialValue)}`
                    : `Locação: R$ ${this.cleanValues(this.props.secondValue)}`}
                </span>
              </Grid>
              <Grid item xs={12}>
                <span className="secondValue">
                  {this.props.purchase === "purchase"
                    ? `Locação: R$ ${this.cleanValues(this.props.secondValue)}`
                    : `Venda: R$ ${this.cleanValues(this.props.initialValue)}`}
                </span>
              </Grid>
            </Grid>
          </div>
        </div> */}
        <Grid style={this.props.padd ? { ...this.props.padd } : {}}>
          {this.props.children}
        </Grid>
      </div>
    );
  }
}

export default HeaderNav;
