import React, { Component } from 'react';
import { connect } from "react-redux";
import { Helmet } from 'react-helmet';
import * as actions from "../../store/actions/index";

import Partners from '../../components/Partners/Partners';
import SearchPosts from "../../components/SearchPosts/SearchPosts";
import CitySelect from "../../components/CitySelect/CitySelect";

import api from "../../services/api";

class Release extends Component {
  state = {
    value: '',
    show: false,
    options: [],
    showList: false,
    postsSelected: [],
    city: window.localStorage.getItem('city') ? window.localStorage.getItem('city') : ""
  }

  componentDidMount = () => {
    this.props.onFetchReleases('/lancamentos', this.state.city);
    this.props.onFetchAdvertisements('/lancamentos');
  }

  itsEmpty = () => {
    this.setState({ postsSelected: []})
  }

  onInputChange = async (e) => {
    let options = []
    if (e !== this.state.value && typeof e === 'string') {
      await api.get('/posts/autocomplete?post_title=' + e + '&post_type=empreendimento&lancamento=true')
      .then(res => {
        for (const element of res.data) {
          options.push({value: element, label: element.post_title})
        }
        this.setState({show: true, options: options, value: e, showList: false})
      })
      .catch(err => {
        console.log(err)
      })
    }

    return options
  }

  onInputSubmit = async (e) => {
    let options = []
    this.setState({show: false, options: []})
    if (typeof e === 'string') {
      await api.get('/posts/search?post_title=' + e + '&post_type=empreendimento&itensPerPage=18&lancamento=true')
      .then(res => {
        for (const element of res.data.posts) {
          options.push({value: element, label: element.post_title})
        }
        if (options.length === 0) this.setState({showList: false})
        else this.setState({showList: true})
        this.setState({show: true, postsSelected: res.data.posts, value: e, options: options, showList: true})
      })
      .catch(err => {
        console.log(err)
      })
    }

    return options
  }
  promiseOptions = () =>
    new Promise(resolve => {
      setTimeout(() => {
        resolve(this.state.options);
      }, 10000);
  });

  onChange = (e) => {
    const city = e.target.value;
    if (city) window.localStorage.setItem('city', city);

    this.setState({
      city: city,
      postsSelected: []
    });

    this.props.onFetchReleases('/lancamentos', city);
  }

  render() {
    const search = (
      <SearchPosts
        load={this.promiseOptions}
        loadDefault={this.props.onFetchReleases}
        selected={this.props.posts}
        clickAuto={this.onInputChange}
        clickSubmit={this.onInputSubmit}
        onPlaceSelected={this.onInputSubmit}
        itsEmpty={this.itsEmpty}
      />
    );

    const citySearch = (
      <CitySelect city={this.state.city} onChange={this.onChange} />
    );

    return (
      <>
        <Helmet>
          <title> Lançamentos — Plano Cidade</title>
        </Helmet>
      {
        this.props.posts && <Partners
          title={'Destaque'}
          subtitle={'Lançamentos'}
          search={search}
          citySearch={citySearch}
          posts={this.props.posts}
          postSelected={this.state.postsSelected}
          advertisements={this.props.advertisements}
        />
      }
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    posts: state.imoveis.posts,
    advertisements: state.advertise.advertisements
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFetchReleases: (page, city) => dispatch(actions.fetchReleases(page, city)),
    onFetchAdvertisements: (page) => dispatch(actions.fetchAdvertisements(page))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Release);
