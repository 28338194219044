import React, { Component } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import Footer from "../../components/UI/Footer";


class Release extends Component {

  classes = makeStyles((theme) => ({
    root: {
      flexGrow: 1
    },
    root2: {
      maxWidth: 345,
    },
    media: {
      height: 140,
    },
    paper: {
      textAlign: "center",
      color: theme.palette.text.secondary
    }
  }));

  state = {
    showInfoWindow: [],
    showInfoWindowUser: true,
    showWindowGroup: false,
    myCoordinates: {
      latitude: sessionStorage.getItem('latitude') !== undefined ? Number(sessionStorage.getItem('latitude')) : 0,
      longitude: sessionStorage.getItem('longitude') !== undefined ? Number(sessionStorage.getItem('longitude')) : 0
    },
    favoritou: false,
    address: '',
    loadingAddress: false,
    position: null,
    id: null,
    imovel: null,
    quadrinha: null,
    mostrarPainel: false,
    mostrarQuadrinha: false,
    loadingFavorito: false,
    scroll: false,
    city: '',
    type: '',
    elevator: '',
    lazer: '',
    find: false,
    name: '',
    email: '',
    cellPhoneNumber: '',
    mesage: ''
  };

  handleChangeCity = (event) => {
    this.setState({
      city: event.target.value,
    });
  }

  handleChangeType = (event) => {
    this.setState({
      type: event.target.value,
    });
  }

  handleChangeName = (event) => {
    this.setState({
      name: event.target.value,
    });
  }

  handleChangeEmail = (event) => {
    this.setState({
      email: event.target.value,
    });
  }

  handleChangeCellPhoneNumber = (event) => {
    this.setState({
      cellPhoneNumber: event.target.value,
    });
  }

  handleChangeMesage = (event) => {
    this.setState({
      mesage: event.target.value,
    });
  }

  render() {
    return (
      <>
        {/* <div style={{ backgroundColor: '#ededed', padding: '30px' }}> */}
        <div style={{ backgroundColor: '#ffffff', padding: '30px' }}>
          <div className={this.classes.root} style={{ display: 'flex', justifyContent: 'center', width: '1170px' }}>
            <div style={{ width: '500px' }}>
              <span style={{ display: 'flex', justifyContent: 'flex-start', color: '#003a70', fontSize: '36px' }}>Quem Somos</span><br /><br />
              <article style={{ display: 'flex', justifyContent: 'flex-start', color: '#57574d', fontSize: '20px' }}><span>O objetivo do&nbsp;<b>Plano Cidade</b>&nbsp;é facilitar a vida das pessoas que estão procurando informações sobre imóveis comerciais e residenciais em todas as cidades e também facilitar o trabalho de corretoras de imóveis, síndicos, administradores de condomínios e todos os demais profissionais que trabalham com imóveis.</span></article><br /><br />             
            </div>
          </div>
        </div>
        <section position='fixed' bottom={0}>
          <div position='absolute' bottom={0} style={{ left: '0', right: '0' }} padding-bottom='60px' width='100%'>
            <Footer />
          </div>
        </section>
      </>
    )
  }
}

export default Release;
