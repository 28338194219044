import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles((theme) => ({
  citySelection: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    maxWidth: '720px',
    margin: 'auto',
    justifyContent: 'center',
    flexDirection: 'column'
  }
}));

export default function CitySelect ({ city, onChange }) {
  const classes = useStyles();

  return (
    <div className={classes.citySelection}>
      <Box sx={{ minWidth: 120 }}>
        <FormControl size='small' variant="outlined" style={{ width: '100%' }}>
          <InputLabel>Cidade</InputLabel>
          <Select
            native
            value={city}
            onChange={onChange}
            label="Cidade"
            inputProps={{
              name: 'cidade',
              id: 'outlined-age-native-simple',
            }}
          >
            <option aria-label="None" value="" />
            <option value={'Bauru'}>Bauru</option>
            <option value={'Piratininga'}>Piratininga</option>
            <option value={'Agudos'}>Agudos</option>
            <option value={'Pederneiras'}>Pederneiras</option>
            <option value={'Lençóis Paulista'}>Lençóis Paulista</option>
            <option value={'Londrina'}>Londrina</option>
            <option value={'Jaú'}>Jaú</option>
            <option value={'Santos'}>Santos</option>
            <option value={'Praia Grande'}>Praia Grande</option>
          </Select>
        </FormControl>
      </Box>
    </div>
  )
}