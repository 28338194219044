import React, { Component } from "react";
import { connect } from "react-redux";
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import { mainListItems } from './listItems';
import PostList from './PostList';
import AnnoucementMessagesList from "./AnnouncementMessagesList";
import DataTable from './DataTable';

import Button from '../../components/UI/Button/Button'
import * as actions from "../../store/actions/index";

const drawerWidth = 240;

const useStyles = (theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    zIndex: 0,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(7),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: drawerWidth,
  }
});

class Dashboard extends Component {
  state = {
    item: 0,
    open: true
  };

  componentDidMount () {
    this.props.onFetchPosts();
    this.props.onFetchMessages();
    this.props.onFetchAdvertises();
    this.props.onFetchReports();
    this.props.onFetchUsers();
    this.props.onFetchAdmins();
  }

  handleDrawerOpen = () => {
    this.setState({
      open: true
    });
  };

  handleDrawerClose = () => {
    this.setState({
      open: false
    });
  };

  handleOpenCreatePost = () => {
    this.props.history.push('/admin/create-post')
  };

  handleOpenCreateNewUser = () => {
    if(this.props.role === 'Master' || this.props.role === 'Admin'){
      this.props.history.push('/admin/create-user/undefined')
    } else {
      this.props.history.push('/')
    }
  };

  handleOpenCreateAdvertise = () => {
    if(this.props.role === 'Admin'){
      this.props.history.push('/advertise/information/undefined')
    } else {
      this.props.history.push('/')
    }
  };

  handleOpenCreateNewAdmin = () => {
    this.props.history.push('/admin/create-master/undefined')
  };

  onChangeItem = (i) => this.setState({item: i})

  render () {
    const { classes } = this.props;

    const switchMenu = (item) => {
      switch (item) {
        case 0:
          return (
            <Grid item xs={12} md={12} lg={12}>
              <Paper className={classes.paper}>
                <PostList
                  url={'/admin/form/'}
                  history={this.props.history}
                  loading={this.props.loading}
                  posts={this.props.posts}
                />
              </Paper>
            </Grid>
          )
        case 1:
          return (
            <Grid item xs={12} md={12} lg={12}>
                <Paper className={classes.paper}>
                    <AnnoucementMessagesList
                      columns={[
                        {
                          field: 'name',
                          headerName: 'Nome',
                          width: 300
                        },
                        {
                          field: 'city',
                          headerName: 'Cidade',
                          width: 300
                        },
                        {
                          field: 'message',
                          headerName: 'Mensagem',
                          width: 300
                        },
                      ]}
                      url={'/announcement-message/information/'}
                      history={this.props.history}
                      loading={this.props.loadingMessages}
                      messages={this.props.announcementMessages}
                    />
                </Paper>
            </Grid>
          )
        case 2:
          return (
            <Grid item xs={12} md={12} lg={12}>
                <Paper className={classes.paper}>
                  <Button
                    clicked={this.handleOpenCreateNewUser}
                  >
                    Criar Novo Corretor
                  </Button>
                  <AnnoucementMessagesList
                    columns={[
                      {
                        field: 'name',
                        headerName: 'Nome',
                        width: 300
                      },
                      {
                        field: 'email',
                        headerName: 'Email',
                        width: 300
                      },
                      {
                        field: 'realState',
                        headerName: 'Imobiliária',
                        width: 300
                      },
                    ]}
                    url={'/admin/create-user/'}
                    history={this.props.history}
                    loading={this.props.loadingMessages}
                    messages={this.props.users}
                  />
                </Paper>
            </Grid>
          )
        case 3:
          return (
            <Grid item xs={12} md={12} lg={12}>
                <Paper className={classes.paper}>
                  <Button
                    clicked={this.handleOpenCreateNewAdmin}
                  >
                    Criar Novo Admin de Cidade
                  </Button>
                  <AnnoucementMessagesList
                    columns={[
                      {
                        field: 'name',
                        headerName: 'Nome',
                        width: 300
                      },
                      {
                        field: 'email',
                        headerName: 'Email',
                        width: 300
                      },
                    ]}
                    url={'/admin/create-master/'}
                    history={this.props.history}
                    loading={this.props.loadingMessages}
                    messages={this.props.admins}
                  />
                </Paper>
            </Grid>
          )
        case 4:
          return (
            <Grid item xs={12} md={12} lg={12}>
                <Paper className={classes.paper}>
                    <DataTable
                      url={'/admin/report/'}
                      data={this.props.reports}
                      history={this.props.history}
                      loading={this.props.loadingReports}
                      sortModel={[{
                        field: 'date',
                        sort: 'desc'
                      }]}
                      columns={[
                        {
                          field: 'event',
                          headerName: 'Evento',
                          width: 300
                        },
                        {
                          field: 'name',
                          headerName: 'Name',
                          width: 300
                        },
                        {
                          field: 'date',
                          headerName: 'Date',
                          width: 300
                        },
                        {
                          field: 'realState',
                          headerName: 'Imobiliária',
                          width: 300
                        },
                      ]}
                    />
                </Paper>
            </Grid>
          )
        case 5:
          window.open("https://analytics.google.com", "_blank");
          break;
        case 6:
          return (
            <Grid item xs={12} md={12} lg={12}>
                <Paper className={classes.paper}>
                  <Button
                    clicked={this.handleOpenCreateAdvertise}
                  >
                    Criar Novo Anúncio
                  </Button>
                  <br />
                  <AnnoucementMessagesList
                    columns={[
                      {
                        field: 'name',
                        headerName: 'Nome',
                        width: 300
                      },
                      {
                        field: 'page',
                        headerName: 'Página',
                        width: 300
                      },
                    ]}
                    url={'/advertise/information/'}
                    history={this.props.history}
                    loading={this.props.loadingMessages}
                    messages={this.props.advertises}
                  />
                </Paper>
            </Grid>
          )
        default:
      }
    }

    return (
      <div className={classes.root}>
        <CssBaseline />
        <Drawer
          variant="permanent"
          classes={{
            paper: clsx(classes.drawerPaper, !this.state.open && classes.drawerPaperClose),
          }}
          open={this.state.open}
        >
          {this.state.open ? (
            <div className={classes.toolbarIcon}>
              <IconButton onClick={this.handleDrawerClose}>
                <ChevronLeftIcon />
              </IconButton>
            </div>
          ) : (
            <div className={classes.toolbarIcon}>
              <IconButton onClick={this.handleDrawerOpen}>
                <ChevronRightIcon />
              </IconButton>
            </div>
          )
          }
          <Divider />
          <List>{mainListItems(this.onChangeItem, this.props.role)}</List>
          <Divider />
          {/* <List>{secondaryListItems}</List> */}
        </Drawer>
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          {
            this.state.item === 0 && <Button
              clicked={this.handleOpenCreatePost}
            >
              Criar Novo Post
            </Button>
          }
          <Container maxWidth="lg" className={classes.container}>
            {switchMenu(this.state.item)}
          </Container>
        </main>
      </div>
    );      
  }
}

const mapStateToProps = state => {
  return {
    loading: state.imoveis.loading,
    loadingMessages: state.announcementMessage.loading,
    loadingReports: state.reports.loading,
    posts: state.imoveis.posts,
    announcementMessages: state.announcementMessage.announcementMessages,
    advertises: state.advertise.advertises,
    users: state.users.users,
    admins: state.users.admins,
    reports: state.reports.reports,
    role: state.auth.role
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFetchPosts: () => dispatch(actions.fetchPosts({
      adminUrl: 'admin/',
      postType: ''
    })),
    onFetchReports: () => dispatch(actions.fetchReports()),
    onFetchMessages: () => dispatch(actions.fetchAnnouncementMessages()),
    onFetchAdvertises: () => dispatch(actions.fetchAdvertises()),
    onFetchUsers: () => dispatch(actions.fetchUsers()),
    onFetchAdmins: () => dispatch(actions.fetchAdmins())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(useStyles)(Dashboard));
