import React, { Component } from "react";
import { Map, Marker, GoogleApiWrapper } from "google-maps-react";
import { Helmet } from "react-helmet";
import Footer from "../../components/UI/Footer";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import api from "../../services/api";

import "./index.scss";
import { postMetasFieldSchemas } from "../../utils/fields";
import ContactButtons from "../../components/ContactButtons/ContactButtons";
import IconsPost from "../../components/IconsPost/IconsPost";
import { moneyMask } from "../../utils/utility";
import { Card, CardMedia } from "@material-ui/core";
import { ImageSlider } from "../../components/ImageSlider/ImageSlider";

const useStyles = (theme) => ({
  tableRow: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  table: {
    maxWidth: 700,
  },
  menu: {
    display: "flex",
    justifyContent: "space-evenly",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "center",
    },
  },
  columns: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  folders: {
    display: "flex",
  },
  textMeta: {
    marginTop: "2px",
  },
  h3: {
    marginTop: "0px",
  },
  carouselImg: {
    maxHeight: "450px",
    objectFit: "contain",
    [theme.breakpoints.down(450)]: {
      maxHeight: "245px",
    },
  },
});

class Information extends Component {
  state = {
    rows: [],
    post: [],
    fotos: [],
    planta: [],
    bairro: "",
    estado: "",
    purchase: "purchase",
    cidade: undefined,
    tipo: undefined,
    position: undefined,
    map_type: undefined,
    zoom: undefined,
    banner_topo: undefined,
    banner_lateral: undefined,
  };

  cleanValues = (value) => {
    if (value && value !== "0.00") {
      return moneyMask(value);
    } else {
      return "---";
    }
  };

  componentDidMount() {
    var pageId;
    if (window.location.href.includes("?")) {
      pageId = window.location.href.substring(
        window.location.href.lastIndexOf("/") + 1,
        window.location.href.lastIndexOf("?")
      );
    } else {
      pageId = window.location.href.substring(
        window.location.href.lastIndexOf("/") + 1
      );
    }

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    const defaultPurchase = urlParams.get("purchase") || "purchase";
    this.setState({ purchase: defaultPurchase });

    api
      .get(`/posts/post?id=` + pageId)
      .then((res) => {
        console.log(res.data);
        this.setState({
          ...this.state,
          page_id: pageId,
          created_from:
            res.data.created_from !== undefined ? res.data.created_from : "",
          post_title: res.data.post_title,
          descricao: res.data.descricao,
          thumbnail: res.data.metas.thumbnail,
          banner_topo: res.data.metas.banner_topo,
          banner_principal: res.data.metas.banner_principal,
          banner_lateral: res.data.metas.banner_lateral,
          fotos: res.data.metas.fotos,
          planta: res.data.metas.planta,
          area_lote: res.data.metas.area_lote,
          area_construida: res.data.metas.area_construida,
          area_loteamento: res.data.metas.area_loteamento,
          arquivos: res.data.metas.arquivos,
          cep: res.data.metas.cep,
          logradouro: res.data.metas.logradouro,
          nmero: res.data.metas.nmero,
          cidade: res.data.metas.cidade,
          codigo_imovel: res.data.metas.codigo_imovel,
          creci_juridico: res.data.metas.creci_juridico,
          complemento: res.data.metas.complemento,
          bairro: res.data.metas.bairro,
          data_de_atualizao_do_valor_do_condomnio:
            res.data.metas.data_de_atualizao_do_valor_do_condomnio,
          estado: res.data.metas.estado,
          ibge: res.data.metas.ibge,
          site: res.data.metas.site,
          total_andares: res.data.metas.total_andares,
          total_de_lotes: res.data.metas.total_de_lotes,
          total_imoveis: res.data.metas.total_imoveis,
          vagas_garagem: res.data.metas.vagas_garagem,
          tipo: res.data.metas.tipo,
          valor_do_condomnio: res.data.metas.valor_do_condomnio,
          valor_inicial: res.data.metas.valor_inicial,
          valor_locacao: res.data.metas.valor_locacao,
          valor_condominio: res.data.metas.valor_condominio,
          iptu: res.data.metas.iptu,
          areas_de_lazer: res.data.metas.areas_de_lazer,
          _pronamic_google_maps_address:
            res.data.metas._pronamic_google_maps_address,
          position: {
            lat:
              res.data.metas.location_coordinates[1] &&
              parseFloat(res.data.metas.location_coordinates[1]),
            lng:
              res.data.metas.location_coordinates[0] &&
              parseFloat(res.data.metas.location_coordinates[0]),
          },
          zoom:
            res.data.metas._pronamic_google_maps_zoom &&
            parseInt(res.data.metas._pronamic_google_maps_zoom),
          construtora: res.data.metas.construtora,
          administradora_condominio: res.data.metas.administradora_condominio,
          prestadora_de_servico: res.data.metas.prestadora_de_servico,
          imobiliaria: res.data.metas.imobiliaria,
          corretora_de_seguro: res.data.metas.corretora_de_seguro,
          data_de_entrega: res.data.metas.data_de_entrega,
          total_elevadores: res.data.metas.total_elevadores,
          total_torres: res.data.metas.total_torres,
          total_blocos: res.data.metas.total_blocos,
          pmb_sequencia: res.data.metas.pmb_sequencia,
          email_do_contato: res.data.metas.email_do_contato,
          celular_de_contato: res.data.metas.celular_de_contato,
          quantidade_dormitorios: res.data.metas.quantidade_dormitorios,
          quantidade_banheiros: res.data.metas.quantidade_banheiros,
          vagas_de_garagem: res.data.metas.vagas_de_garagem,
          quantidade_suites: res.data.metas.quantidade_suites,
          quantidade_salas: res.data.metas.quantidade_salas,
          area: res.data.metas.area,
          padroes_de_unidade: res.data.metas.padroes_de_unidade,
          google_maps_street_name: res.data.metas.google_maps_street_name,
          google_maps_street_number: res.data.metas.google_maps_street_number,
          google_maps_neighborhood: res.data.metas.google_maps_neighborhood,
          google_maps_zipcode: res.data.metas.google_maps_zipcode,
          google_maps_city: res.data.metas.google_maps_city,
          google_maps_state: res.data.metas.google_maps_state,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  createData(name, description) {
    return { name, description };
  }

  render() {
    const { classes } = this.props;

    // const buildBanner = (banner, width, height) => (
    //   <div style={{ display: 'flex', justifyContent: 'center', margin: '8px' }}>
    //     {
    //       banner
    //       && banner.url
    //       && banner.link
    //       && <a href={banner.link} target="_blank" rel="noopener noreferrer"><img alt={banner.link} src={serveImage(banner.url)} style={{ maxWidth: width, maxHeight: height, width: '100%' }} /></a>
    //     }
    //   </div>
    // )

    // const buildListBanner = (banners, width, height) => banners && banners.length > 0 && banners.map(banner => buildBanner(banner, width, height));

    const imagesToCarousel = (title, images) => (
      <div>
        <h3 id="fotos" class="green-title" style={{ color: "#003a70" }}>
          {title}
        </h3>
        <ImageSlider photos={images} />
      </div>
    );

    const formatValueToCell = (key, value, join = false, isUrl = false) => {
      if (Array.isArray(value) && value.length === 0) return null;
      if (Array.isArray(value) && join) {
        return value.map((v) => v.name || v).join(", ");
      }

      if (Array.isArray(value) && isUrl) {
        return value.map((v, idx) => (
          <a href={v.url} target="_blank" rel="noopener noreferrer">
            Arquivo {idx + 1};{" "}
          </a>
        ));
      }

      if (
        ["valor_do_condomnio", "valor_locacao", "valor_inicial"].includes(key)
      ) {
        return `R$ ${moneyMask(value)}`;
      }

      if (
        ["area", "area_lote", "area_construida", "area_loteamento"].includes(
          key
        )
      ) {
        return `${value}m² `;
      }

      if (
        value.post_type === "construtora" ||
        value.post_type === "administradora" ||
        value.post_type === "imobiliaria"
      ) {
        return (
          <a
            href={
              "/pesquisa?purchase=" +
              this.state.purchase +
              "&imobiliaria=" +
              value.slug
            }
          >
            {value.name}
          </a>
        );
      } else {
        return value.name || value;
      }
    };

    const addressRules = (type) => {
      const group1 = new Set(["Casa de Condominio", "Casa"]);
      const group2 = new Set([
        "Apartamento",
        "Kitnet",
        "Cobertura",
        "Imóvel Comercial",
        "Terreno Comercial",
        "Loja",
        "Escritório Comercial",
      ]);
      const components = [
        "google_maps_street_name",
        "google_maps_street_number",
        "google_maps_neighborhood",
        "google_maps_city",
        "google_maps_state",
        "google_maps_zipcode",
      ];
      if (group1.has(type)) {
        return components.slice(2);
      } else if (group2.has(type)) {
        return [components[0]].concat(components.slice(2));
      } else {
        return components;
      }
    };

    const buildTextMeta = (key, isPadroesUnidade = false, index = null) => {
      let keyValue = this.state[key];
      if (isPadroesUnidade) {
        keyValue = this.state.padroes_de_unidade[index][key];
      }

      let join = false;
      let isUrl = false;
      if (key === "areas_de_lazer") join = true;
      if (key === "arquivos") isUrl = true;

      if (key === "_pronamic_google_maps_address") {
        const keys = addressRules(this.state.tipo);
        let newValue = "";
        keys.forEach((gkey) => {
          if (this.state[gkey] !== null && this.state[gkey] !== "")
            newValue = newValue + this.state[gkey] + ", ";
        });
        keyValue = newValue + "Brasil";
      }

      const cellValue =
        keyValue && formatValueToCell(key, keyValue, join, isUrl);

      return cellValue && cellValue !== "0" && postMetasFieldSchemas[key] ? (
        <Typography
          className={classes.textMeta}
          color="textSecondary"
          variant="body1"
        >
          {`${postMetasFieldSchemas[key].label}: `}
          {cellValue}
        </Typography>
      ) : null;
    };

    return (
      <div>
        <Helmet>
          <title>
            {" "}
            {this.state.post_title ? this.state.post_title : ""} — Imobilize
          </title>
        </Helmet>
        <ImageSlider photos={this.state.fotos} />
        <br />
        <Grid container className={classes.columns}>
          <Grid item xs={12} md={2} />
          <Grid item xs={12} md={8}>
            <div className={classes.folders}>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: "8px",
                  marginRight: "8px",
                }}
              >
                <Grid container>
                  <Grid item xs={12} sm={12} md={9}>
                    <h3 className={classes.h3}>{this.state.post_title}</h3>
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="h2"
                      style={{ color: "#003a70" }}
                    >
                      {this.state.tipo && this.state.tipo.name}
                    </Typography>
                    {this.state.descricao && (
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                      >
                        <span style={{ fontSize: "16px" }}>
                          {this.state.descricao}
                        </span>
                        <br />
                        <br />
                      </Typography>
                    )}
                    <Typography variant="body1" color="textSecondary">
                      {this.state.bairro}
                      <br />
                      {this.state.cidade && this.state.cidade.name}
                      {this.state.estado &&
                        this.state.estado !== "" &&
                        "/" + this.state.estado}
                      <br />
                      <br />
                    </Typography>
                    <Grid container spacing={0}>
                      <Grid item xs={12}>
                        <h3 className={classes.h3}>
                          {this.state.purchase === "purchase"
                            ? `R$ ${this.cleanValues(this.state.valor_inicial)}`
                            : `R$ ${this.cleanValues(
                                this.state.valor_locacao
                              )}`}
                        </h3>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          gutterBottom
                          variant="body1"
                          color="textSecondary"
                        >
                          {this.state.purchase === "purchase"
                            ? `Locação: R$ ${this.cleanValues(
                                this.state.valor_locacao
                              )}`
                            : `Venda: R$ ${this.cleanValues(
                                this.state.valor_inicial
                              )}`}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          color="textSecondary"
                          component="div"
                          className={classes.price}
                          style={{ marginBottom: "12px" }}
                        >
                          {`${
                            this.state.valor_condominio
                              ? "Condomínio R$ " +
                                this.cleanValues(this.state.valor_condominio)
                              : ""
                          }${
                            this.state.valor_condominio &&
                            this.cleanValues(this.state.iptu)
                              ? " - "
                              : ""
                          }${
                            this.state.iptu
                              ? "IPTU R$ " + this.cleanValues(this.state.iptu)
                              : ""
                          }`}
                        </Typography>
                      </Grid>

                      <IconsPost
                        area={this.state["area"]}
                        quartos={this.state["quantidade_dormitorios"]}
                        garagens={this.state["vagas_de_garagem"]}
                        banheiros={this.state["quantidade_banheiros"]}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={3}
                    style={{ paddingLeft: "16px", paddingRight: "16px" }}
                  >
                    {this.state.created_from === "xml" ? (
                      <Card
                        variant="outlined"
                        style={{
                          marginTop: "32px",
                          padding: "12px 12px 12px 12px",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        {this.state.imobiliaria.banner_principal ? (
                          <CardMedia
                            style={{
                              height: 140,
                              width: "100%",
                              marginBottom: "4px",
                            }}
                            image={this.state.imobiliaria.banner_principal}
                            title="green iguana"
                          />
                        ) : null}
                        <h4>Anunciante</h4>
                        <a
                          href={
                            "/pesquisa?purchase=" +
                            this.state.purchase +
                            "&imobiliaria=" +
                            this.state.imobiliaria.slug
                          }
                        >
                          {this.state.imobiliaria.name}{" "}
                        </a>
                        {this.state.imobiliaria.creci_juridico ? (
                          <p>CRECI {this.state.imobiliaria.creci_juridico}</p>
                        ) : null}
                        <ContactButtons
                          pageId={this.state.page_id}
                          size="small"
                          buttonColor="primary"
                          buttonVariant="contained"
                          numberPhone={this.state.celular_de_contato || (this.state.imobiliaria && this.state.imobiliaria.celular_de_contato)}
                          emailProprietary={this.state.email_do_contato}
                        />
                      </Card>
                    ) : (
                      ""
                    )}
                  </Grid>
                </Grid>
                <br />
                {this.state.planta.length > 0 &&
                  imagesToCarousel("Plantas", this.state.planta)}
                <h3
                  id="ficha-tecnica"
                  class="green-title"
                  style={{ color: "#003a70" }}
                >
                  {this.state.created_from === "xml"
                    ? "Características do Imóvel"
                    : "Ficha Técnica"}
                </h3>
                <Grid container>
                  <Grid item xs={12} md={4}>
                    {buildTextMeta("codigo_imovel")}
                    {buildTextMeta("pmb_sequencia")}
                    {buildTextMeta("area_lote")}
                    {buildTextMeta("area_construida")}
                    {buildTextMeta("area_loteamento")}
                    {buildTextMeta("arquivos")}
                    {buildTextMeta("vagas_garagem")}
                    {buildTextMeta("valor_do_condomnio")}
                    {buildTextMeta("data_de_atualizao_do_valor_do_condomnio")}
                    {buildTextMeta("quantidade_dormitorios")}
                    {buildTextMeta("quantidade_banheiros")}
                    {buildTextMeta("vagas_de_garagem")}
                    {buildTextMeta("quantidade_suites")}
                    {buildTextMeta("quantidade_salas")}
                    {buildTextMeta("valor_locacao")}
                    {buildTextMeta("valor_inicial")}
                    {buildTextMeta("area")}
                  </Grid>
                  <Grid item xs={12} md={4}>
                    {buildTextMeta("tipo")}
                    {buildTextMeta("_pronamic_google_maps_address")}
                    {buildTextMeta("construtora")}
                    {buildTextMeta("administradora_condominio")}
                    {buildTextMeta("total_andares")}
                    {buildTextMeta("total_imoveis")}
                    {buildTextMeta("total_blocos")}
                    {buildTextMeta("total_torres")}
                    {buildTextMeta("total_elevadores")}
                    {buildTextMeta("total_de_lotes")}
                    {buildTextMeta("areas_de_lazer")}
                    {buildTextMeta("data_de_entrega")}
                    {buildTextMeta("site")}
                  </Grid>
                </Grid>
                {/* 
                <TableContainer component={Paper}>
                  <Table
                    className={classes.table}
                    aria-label="customized table"
                  >
                    <TableBody>
                      {buildTableRow("tipo")}
                      {buildTableRow("_pronamic_google_maps_address")}
                      {buildTableRow("construtora")}
                      {buildTableRow("administradora_condominio")}
                      {buildTableRow("imobiliaria")}
                      {buildTableRow("total_andares")}
                      {buildTableRow("total_imoveis")}
                      {buildTableRow("total_blocos")}
                      {buildTableRow("total_torres")}
                      {buildTableRow("total_elevadores")}
                      {buildTableRow("total_de_lotes")}
                      {buildTableRow("areas_de_lazer")}
                      {buildTableRow("data_de_entrega")}
                      {buildTableRow("site")}
                      {buildTableRow("pmb_sequencia")}
                      {buildTableRow("area_lote")}
                      {buildTableRow("area_construida")}
                      {buildTableRow("area_loteamento")}
                      {buildTableRow("arquivos")}
                      {buildTableRow("vagas_garagem")}
                      {buildTableRow("valor_do_condomnio")}
                      {buildTableRow("data_de_atualizao_do_valor_do_condomnio")}
                      {buildTableRow("quantidade_dormitorios")}
                      {buildTableRow("quantidade_banheiros")}
                      {buildTableRow("vagas_de_garagem")}
                      {buildTableRow("quantidade_suites")}
                      {buildTableRow("quantidade_salas")}
                      {buildTableRow("valor_locacao")}
                      {buildTableRow("valor_inicial")}
                      {buildTableRow("area")}
                    </TableBody>
                  </Table>
                </TableContainer>

                {Array.isArray(this.state.padroes_de_unidade) &&
                  this.state.padroes_de_unidade.length > 0 &&
                  this.state.padroes_de_unidade.map((tipo, index) => (
                    <div>
                      <br />
                      <h3
                        id="ficha-tecnica"
                        class="green-title"
                        style={{ color: "#003a70" }}
                      >{`Tipo ${index + 1}`}</h3>
                      <TableContainer component={Paper}>
                        <Table
                          className={classes.table}
                          aria-label="customized table"
                        >
                          <TableBody>
                            {Object.keys(tipo).map((key) =>
                              buildTableRow(key, true, index)
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  ))} */}
                <br />
                {this.state.position && this.state.zoom && (
                  <div>
                    <h3
                      id="localizacao"
                      class="green-title"
                      style={{ color: "#003a70" }}
                    >
                      Localização
                    </h3>
                    <div style={{ height: "583px" }}>
                      <Map
                        containerStyle={{
                          height: "583px",
                          maxWidth: "700px",
                          width: "100%",
                        }}
                        google={this.props.google}
                        initialCenter={this.state.position}
                        zoom={this.state.zoom}
                      >
                        <Marker
                          name={"Current location"}
                          position={this.state.position}
                        />
                      </Map>
                    </div>
                  </div>
                )}
                <br />
                {/* {
                  buildBanner(this.state.banner_meio, '720px', '300px')
                } */}
                <br />
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={2} />
        </Grid>
        <Footer />
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyCRrwsJQfUXOsVKnI3rg3cWe5bRjWXaEIA",
})(withStyles(useStyles)(Information));
