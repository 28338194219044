export const fetchCoordinates = async () => {
    try {
        const {coords} = await getCurrentPosition();
        console.log('coords', coords)
        sessionStorage.removeItem('latitude')
        sessionStorage.removeItem('longitude')
        sessionStorage.setItem('latitude', coords.latitude)
        sessionStorage.setItem('longitude', coords.longitude)
        return coords
    } catch (error) {
        // Handle error
        console.error(error);
    }
};

function errorHandler(err) {
    if (err.code === 1) {
        alert("Erro: Acesso negado para o Safari!");
    } else if (err.code === 2) {
        alert("Erro: Posição não está disponível para o Safari!");
    }
}

function getCurrentPosition(options = {}) {
    return new Promise((resolve, reject) => {
        console.log('getCurrentPosition')
        let userAgentString = navigator.userAgent;
        let chromeAgent = userAgentString.indexOf("Chrome") > -1;
        let safariAgent = userAgentString.indexOf("Safari") > -1;
        if ((chromeAgent) && (safariAgent)) safariAgent = false;
        if (safariAgent) {
            let options2 = {timeout: 60000};
            return navigator.geolocation.getCurrentPosition(resolve, errorHandler, options2);
        } else {
            return navigator.geolocation.getCurrentPosition(resolve, reject, options);
        }


    });
}
