import React from 'react';
import './Toolbar.scss';
import NavigationItems from '../NavigationItems/NavigationItems';
import DrawerToggle from '../SideDrawer/DrawerToggle/DrawerToggle';
import Logo from '../../Logo/Logo';

const Toolbar = ( props ) => {
  let Menu = null;

  if (props.isAuth) {
    Menu = <React.Fragment>
      <DrawerToggle clicked={ props.drawerToggleClicked } />

      <nav className="DesktopOnly">
        <NavigationItems {...props} isAuthenticated={ props.isAuth } />
      </nav>
    </React.Fragment>;
  } else {
    Menu = <React.Fragment>
    <DrawerToggle clicked={ props.drawerToggleClicked } />

    <nav className="DesktopOnly">
      <NavigationItems {...props} isAuthenticated={ props.isAuth } />
    </nav>
  </React.Fragment>;
  }

  return (
    <header className="toolbar">
      <div className="wrapper">
        <Logo to="/" height='60px' />

        <div className="menu">
          { Menu }
        </div>
      </div>
    </header>
  );
};

export default Toolbar;
