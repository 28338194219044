import React, { Component } from "react";
import { connect } from "react-redux";

import "./Layout.css";
import Toolbar from "../../components/Navigation/Toolbar/Toolbar";
import SideDrawer from "../../components/Navigation/SideDrawer/SideDrawer";
import {
  ThemeProvider,
  createTheme,
  responsiveFontSizes,
} from "@material-ui/core";

class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSideDrawer: false,
    };
  }

  sideDrawerClosedHandler = () => {
    this.setState({ showSideDrawer: false });
  };

  sideDrawerToggleHandler = () => {
    this.setState((prevState) => {
      return { showSideDrawer: !prevState.showSideDrawer };
    });
  };

  render() {
    let theme = createTheme({
      palette: {
        primary: {
          main: "#003a70",
        },
      },
      typography: {
        fontSize: 18,
      },
    });

    theme = responsiveFontSizes(theme, {
      factor: 10,
    });

    return (
      <React.Fragment>
        {
          // eslint-disable-next-line no-restricted-globals
          <Toolbar
            {...this.props}
            isAuth={this.props.isAuthenticated}
            drawerToggleClicked={this.sideDrawerToggleHandler}
          />
        }

        <SideDrawer
          {...this.props}
          isAuth={this.props.isAuthenticated}
          open={this.state.showSideDrawer}
          closed={this.sideDrawerClosedHandler}
        />
        <ThemeProvider theme={theme}>
          <main className="Content">{this.props.children}</main>
        </ThemeProvider>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.token !== null,
    role: state.auth.role,
  };
};

export default connect(mapStateToProps)(Layout);
