import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const filter = createFilterOptions({
  stringify: (option) => option.label || option
});

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  iconButton: {
    padding: 10,
  },
  autocomplete: {
    display: 'flex',
    justifyContent: 'center'
  }
}));

const Asynchronous = (props) => {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [value] = React.useState(null);
  const loading = open && options.length === 0;
  const classes = useStyles();

  // React.useEffect(() => {
  //   let active = true;

  //   if (!loading) {
  //     return undefined;
  //   }

  //   (async () => {
  //       const prop = await props.clickAuto(value);

  //       if (active) {
  //         setOptions(prop.map((el) => el));
  //       }
  //   })();

  //   return () => {
  //     active = false;
  //   };
  // }, [loading]);

  // React.useEffect(() => {
  //   if (!open) {
  //     setOptions([]);
  //   }
  // }, [open]);

  const handleAuto = React.useCallback(
    async (e) => {
      let active = true;
      if (e) {
        // setOptions([]);
    
        (async () => {
          var properties = await props.clickAuto(e);
 
          if (active && properties.length > 0) {
            setOptions(properties.map((el) => el));
          }
        })();    
      } else {
        setOpen(false);
      }

      return () => {
        active = false;
      };
    // eslint-disable-next-line
    }, [loading]);

  const handleSubmit = React.useCallback(
    async (e) => {
      let active = true;

      if (e) {
        (async () => {
          setOpen(true);

          var properties = await props.clickSubmit(e);      
          if (active && properties.length > 0) {
            setOptions(properties.map((el) => el));
            setOpen(false);

            const selected =  properties.filter(el => el.label === e)
            if (selected && selected.length > 0) {
              props.onPlaceSelected(selected[0]);
            }
          }
        })();
      }


      return () => {
        active = false;
      };
    // eslint-disable-next-line
    }, [loading]);

  return (
    <Autocomplete
      id="asynchronous-demo"
      freeSolo
      className={classes.autocomplete}
      value={value}
      open={open}
      onOpen={() => {
        setOpen(true);
       }
      }
      onClose={() => {
        setOpen(false);
      }}
      getOptionSelected={(option, value) => option === undefined ? option.label === value.label : ""}
      // getOptionLabel={(option) => option ? option.label : ""}
      options={options}
      loading={loading}
      renderOption={(option) => (
        <Typography style={{ fontSize: "12px" }}>{option.label}</Typography>
      )}
      onChange={(event, newValue) => {
        setOptions([]);
        setOpen(true);

        if (typeof newValue === 'string') {
          handleSubmit(newValue);
        } else if (newValue && newValue.inputValue) {
          handleSubmit(newValue.inputValue);
        } else {
          if (newValue && newValue.label) handleSubmit(newValue.label);
        }
      }}
      onInputChange={(event, newValue) => {
        if (newValue === ""){
          props.loadDefault("", window.localStorage.getItem("city"))
          props.itsEmpty()
        } else {
          handleAuto(newValue);
        }
      }}
      filterOptions={(options, params) => {
        if (params) {
          const filtered = filter(options, params);

          // Suggest the creation of a new value
          if (typeof params.inputValue === 'string') {
            filtered.push({
              inputValue: params.inputValue,
              label: `Pesquisar "${params.inputValue}"`,
            });
          } else {
            setOpen(false);
          }
  
          return filtered;
        }
        return [];
      }}
      handleHomeEndKeys
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
          return option;
        }
        // // Add "xxx" option created dynamically
        if (typeof option.inputValue === 'string') {
          return option.inputValue;
        }
        // Regular option
        return option.label;
      }}
      renderInput={(params) => (
        <div className={classes.root}>
          <TextField
            {...params}
            placeholder=""
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {/* {loading ? <CircularProgress color="inherit" size={20} /> : null} */}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
              type: 'search',
              style: {
                justifyContent: 'center',
                fontSize: "12px"
              }
            }}
          />
        </div>
      )}
    />
  );
}

export default Asynchronous
