import * as actionNames from '../actions/actionsNames';

const initialState = {
    token: null,
    userId: null,
    error: null,
    loading: false,
    authRedirectPath: '/',
    actionDone: false,
    user: null,
    messagedone: ''
};

const authStart = ( state, action ) => {
    return {...state, error: null, loading: true, actionDone: false };
};

const authSuccess = (state, action) => {
    return {...state,
        token: action.idToken,
        userId: action.userId,
        role: action.role,
        error: null,
        loading: false
     };
};

const doneSuccessMessage = (state, action) => {
    return {...state,
        error: null,
        loading: false,
        messagedone: action.message,
        user: action.user,
        actionDone: true
     };
};

const authFail = (state, action) => {
    return {...state,
        error: action.error,
        loading: false
    };
};

const showErrors = (state, action) => {
    return {
        ...state,
        show: action.show
    }
}

const authLogout = (state, action) => {
    return {...state, token: null, userId: null };
};

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionNames.AUTH_START: return authStart(state, action);
        case actionNames.AUTH_SUCCESS: return authSuccess(state, action);
        case actionNames.AUTH_FAIL: return authFail(state, action);
        case actionNames.SHOW_ERRORS: return showErrors(state, action);
        case actionNames.AUTH_LOGOUT: return authLogout(state, action);
        case actionNames.SIGNUP_SUCCESS: return doneSuccessMessage(state, action);
        case actionNames.RESET_PASS_SUCCESS: return doneSuccessMessage(state, action);
        case actionNames.RECOVER_PASS_SUCCESS: return doneSuccessMessage(state, action);
        default:
            return state;
    }
};

export default reducer;