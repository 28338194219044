import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import ContactButtons from "../../ContactButtons/ContactButtons";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import { Grid } from "@material-ui/core";
import IconsPost from "../../IconsPost/IconsPost";

import { moneyMask } from "../../../utils/utility";

const useStyles = makeStyles({
  root: {
    display: "flex",
    maxWidth: "100%",
    margin: "24px 8px 0px 8px",
  },
  media: {
    height: 256,
    objectFit: "cover",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    flex: 1,
    paddingLeft: 10,
    width: "100%",
  },
  text: {
    color: "black",
  },
  areaIcon: {
    marginRight: "4px",
    color: "#003a70",
  },
  icon: {
    fontSize: "16px",
    marginRight: "4px",
    color: "#003a70",
  },
  price: {
    color: "#003a70",
  },
  cardArea: {
    display: "flex",
    alignItems: "center",
  },
  button: {
    marginTop: "24px",
  },
});

const NewCard = ({
  id,
  src,
  history,
  area = undefined,
  purchase = undefined,
  valor_inicial = undefined,
  valor_locacao = undefined,
  valor_condominio = undefined,
  iptu = undefined,
  quartos = undefined,
  garagens = undefined,
  banheiros = undefined,
  bairro = undefined,
  cidade = undefined,
  pathurl = "/imoveis/",
  numberPhone = undefined,
  tipo = undefined,
  emailProprietary,
  created_from = "",
  mdSize = 4,
  smSize = 6,
}) => {
  const classes = useStyles();

  const showIf = (param) => param && param !== "0";

  const formatText = (text) =>
    text.includes("/") ? text :
    text[0].toUpperCase() + text.slice(1).toLowerCase();

  return (
    <Card className={classes.root}>
      <CardActionArea
        onClick={() =>
          window.open(`${pathurl}${id}?purchase=${purchase}`, "_blank")
        }
      >
        <Grid container className={classes.cardArea}>
          <Grid xs={4}>
            <CardMedia className={classes.media} image={src} />
          </Grid>
          <Grid xs={8} className={classes.content}>
            {/* <NavLink to={pathurl + id} className={classes.navlink}> */}
            <CardContent>
              <div>
              <Typography
                  variant="body2"
                  component="div"
                  className={classes.price}
                  style={{ marginBottom: "8px" }}
                >{ purchase === "purchase" ? (
                    <span style={{ fontSize: "26px", color: "#000" }}>
                      {area 
                          ? formatText(tipo) + " à venda, " + area +"m²"
                          : formatText(tipo) + " à venda"
                        }         
                    </span>               
                  ) : (
                    <span style={{ fontSize: "26px", color: "#000" }}>
                        {area 
                          ? formatText(tipo) + " para alugar, " + area +"m²"
                          : formatText(tipo) + " para alugar"
                        }       
                    </span>
                  )}
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  component="div"
                  className={classes.price}
                  style={{ marginBottom: "8px" }}
                >
                  {purchase === "purchase" ? (
                    <span style={{ fontSize: "20px" }}>
                      {showIf(valor_inicial)
                        ? "R$ " + moneyMask(valor_inicial)
                        : "R$ ---"}
                    </span>
                  ) : (
                    <span style={{ fontSize: "20px" }}>
                      {showIf(valor_locacao)
                        ? "R$ " + moneyMask(valor_locacao)
                        : "R$ ---"}
                    </span>
                  )}
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  component="div"
                  className={classes.price}
                  style={{ marginBottom: "12px" }}
                >
                  {purchase === "rent" ? (
                    <span style={{ fontSize: "14px" }}>
                      {showIf(valor_inicial)
                        ? "R$ " + moneyMask(valor_inicial)
                        : null}
                    </span>
                  ) : (
                    <span style={{ fontSize: "14px" }}>
                      {showIf(valor_locacao)
                        ? "R$ " + moneyMask(valor_locacao)
                        : null}
                    </span>
                  )}
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  component="div"
                  className={classes.price}
                  style={{ marginBottom: "12px" }}
                >
                  {`${
                    showIf(valor_condominio)
                      ? "Condomínio R$ " + moneyMask(valor_condominio)
                      : ""
                  }${showIf(valor_condominio) && showIf(iptu) ? " - " : ""}${
                    showIf(iptu) ? "IPTU R$ " + moneyMask(iptu) : ""
                  }`}
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  component="div"
                  className={classes.text}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      height: "24px",
                    }}
                  >
                    <LocationOnIcon className={classes.icon} />
                    <span style={{ fontSize: "12px", color: "#000" }}>
                      {bairro ? bairro : null} -{" "}
                      {cidade && cidade.name ? cidade.name : null}
                    </span>
                  </div>
                </Typography>
              </div>
              <Grid
                container
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "10px",
                }}
              >
                <IconsPost
                  area={area}
                  quartos={quartos}
                  garagens={garagens}
                  banheiros={banheiros}
                />
                <Grid xs={12} md={5} className={classes.button}>
                  <ContactButtons
                    disableIcon
                    horizontal
                    size="medium"
                    buttonColor="primary"
                    buttonVariant="text"
                    numberPhone={numberPhone}
                    emailProprietary={emailProprietary}
                    pageId={id}
                  />
                </Grid>
              </Grid>
            </CardContent>
            {created_from === "xml" ? (
              <div
                style={{
                  display: "flex",
                  marginTop: "7px",
                  justifyContent: "center",
                }}
              >
                <ContactButtons
                  numberPhone={numberPhone}
                  emailProprietary={emailProprietary}
                  pageId={id}
                />
              </div>
            ) : null}
          </Grid>
        </Grid>
      </CardActionArea>
    </Card>
  );
};

export default NewCard;
