export const obterUsuarioLogado = () =>{
    if(localStorage.getItem('user')){
        return JSON.parse(localStorage.getItem('user'));
    }
    return null;
}

export const setUsuarioLogado = (user) => {
    localStorage.setItem('user', JSON.stringify(user));
}

export const clearSessionUser = () => {
    localStorage.removeItem('user');
}

export const obterLocalizacao = () => {
    if(localStorage.getItem('current_location')){
        return JSON.parse(localStorage.getItem('current_location'));
    }else{

    }
}

export const isAssinaturaValida = function() {
    let user = obterUsuarioLogado();
    return (user.signature.active ? user.signature.active : false);
}
