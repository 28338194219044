import React from 'react';
import { DataGrid } from '@material-ui/data-grid';

export default function DataTable({ loading, history, url, columns, sortModel, data = [] }) {
  return (
    <div style={{ height: 600, width: '100%' }}>
      {loading ? 'Carregando...' :
        (<DataGrid
          rows={data ? data : []}
          columns={columns}
          sortModel={sortModel}
          pageSize={20}
          rowsPerPageOptions={[20]}
          getRowId={row => row.date}
          onRowClick={e => history.push(url + e.row.userId)}
          disableSelectionOnClick
        />)
      }
    </div>
  );
}
