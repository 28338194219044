import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Checkbox from "@material-ui/core/Checkbox";
import GeneralSelect from "../GeneralSelect/GeneralSelect";
import {
  Button,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Input,
  Chip,
} from "@material-ui/core";
import { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import QuantSelect from "../QuantSelect/QuantSelect";

const useStyles = makeStyles((theme) => ({
  buttonSearch: {
    marginBottom: "50px",
    marginTop: "30px",
    display: "flex",
    maxWidth: "720px",
    margin: "auto",
    justifyContent: "space-around",
  },
  formControl: {
    display: "flex",
    maxWidth: "720px",
    justifyContent: "space-around"
  },
  checkboxConfig: {
    display: "flex",
    maxWidth: "720px",
    margin: "40px auto",
    justifyContent: "center",
    flexDirection: "column",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
    margin: "2px",
  },
}));

export default function FilterSearch({
  city,
  id,
  page,
  neighborhood,
  onSearch,
  filterOptions,
  onChangeCity,
}) {
  const classes = useStyles();
  const [moreFilters, setMoreFilters] = useState(false);
  const [filterState, setFilterState] = useState({
    city: city,
    neighborhood: neighborhood,
    type: "",
    roomsMin: "",
    roomsMax: "",
    suitesMin: "",
    suitesMax: "",
    garageMin: "",
    garageMax: "",
    priceMin: "",
    priceMax: "",
    areaMax: "",
    areaMin: "",
    livRoomsMax: "",
    livRoomsMin: "",
    bathroomsMax: "",
    bathroomsMin: "",
    lazer: [],
  });

  const handleMoreFilters = () => {
    setMoreFilters(true);
  };

  const handleClose = () => {
    setMoreFilters(false);
  };

  const handleSubmit = () => {
    const initialFormData = {
      cidade: filterState.city,
      tipo: filterState.type,
      lazer: filterState.lazer.join(","),
      bairro: filterState.neighborhood,
      quartosMin: filterState.roomsMin,
      quartosMax: filterState.roomsMax,
      banheirosMin: filterState.bathroomsMin,
      banheirosMax: filterState.bathroomsMax,
      garagensMin: filterState.garageMin,
      garagensMax: filterState.garageMax,
      suitesMin: filterState.suitesMin,
      suitesMax: filterState.suitesMax,
      salasMin: filterState.livRoomsMin,
      salasMax: filterState.livRoomsMax,
      precoMin: filterState.priceMin,
      precoMax: filterState.priceMax,
      areaMin: filterState.areaMin,
      areaMax: filterState.areaMax,
      post_type: "empreendimento",
      created_from: "xml",
    };

    if (id) initialFormData.imobiliaria = id;
    if (page) initialFormData.page = page;

    let formData = {};
    Object.keys(initialFormData).forEach((key, idx) => {
      if (initialFormData[key]) {
        formData[key] = initialFormData[key];
      }
    });

    onSearch(formData);
    handleClose();
  };

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 48 * 4.5 + 8,
        width: 250,
      },
    },
    getContentAnchorEl: () => null,
  };

  const itensDeEmpreendimentos = [
    "Casa de Condominio",
    "Casa",
    "Apartamento",
    "Imóvel Comercial",
    "Terreno Comercial",
    "Terreno",
    "Fazenda / Sítio",
    "Loja",
    "Chácara",
    "Cobertura",
    "Imóvel Industrial",
    "Kitnet",
    "Escritório Comercial"
  ];

  return (
    <div>
      <GeneralSelect
        value={filterState.city}
        nameValue="cidade"
        onChange={(e) => {
          setFilterState({ ...filterState, city: e.target.value });
          onChangeCity(e);
        }}
        optionsArray={filterOptions && filterOptions.cidades}
      />
      <GeneralSelect
        value={filterState.neighborhood}
        nameValue="bairro"
        onChange={(e) =>
          setFilterState({ ...filterState, neighborhood: e.target.value })
        }
        optionsArray={
          filterOptions && filterOptions.bairros && filterOptions.bairros
        }
      />
      <div className={classes.buttonSearch}>
        <span>
          <Button variant="contained" type="submit" onClick={handleSubmit}>
            Pesquisar
          </Button>
        </span>
        <span>
          <Button variant="contained" onClick={handleMoreFilters}>
            Mais filtros
          </Button>
        </span>
      </div>
      <Dialog
        fullWidth="true"
        maxWidth="md"
        open={moreFilters}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Filtro avançado</DialogTitle>
        <DialogContent>
          <Grid
            justifyContent="space-around"
            alignContent="center"
            container
            spacing={2}
          >
            <Grid item xs={12} md={6}>
              <GeneralSelect
                value={filterState.city}
                nameValue="cidade"
                updateWidth="80%"
                onChange={(e) => {
                  setFilterState({ ...filterState, city: e.target.value });
                  onChangeCity(e);
                }}
                optionsArray={filterOptions && filterOptions.cidades}
              />
              <GeneralSelect
                value={filterState.neighborhood}
                nameValue="bairro"
                updateWidth="80%"
                onChange={(e) => {
                  setFilterState({
                    ...filterState,
                    neighborhood: e.target.value,
                  });
                }}
                optionsArray={
                  filterOptions &&
                  filterOptions.bairros &&
                  filterOptions.bairros
                }
              />
              <GeneralSelect
                value={filterState.type}
                nameValue="tipo"
                updateWidth="80%"
                onChange={(e) => {
                  setFilterState({ ...filterState, type: e.target.value });
                }}
                optionsArray={itensDeEmpreendimentos}
              />
              <QuantSelect
                minValue={filterState.roomsMin}
                maxValue={filterState.roomsMax}
                label="Quartos"
                nameMinLabel="roomsMin"
                nameMaxLabel="roomsMax"
                onChange={(e) => {
                  setFilterState({
                    ...filterState,
                    [e.target.id]: e.target.value,
                  });
                }}
              />
              <QuantSelect
                minValue={filterState.suitesMin}
                maxValue={filterState.suitesMax}
                label="Suítes"
                nameMinLabel="suitesMin"
                nameMaxLabel="suitesMax"
                onChange={(e) => {
                  setFilterState({
                    ...filterState,
                    [e.target.id]: e.target.value,
                  });
                }}
              />
              <QuantSelect
                minValue={filterState.garageMin}
                maxValue={filterState.garageMax}
                label="Vagas de Garagem"
                nameMinLabel="garageMin"
                nameMaxLabel="garageMax"
                onChange={(e) => {
                  setFilterState({
                    ...filterState,
                    [e.target.id]: e.target.value,
                  });
                }}
              />
              <QuantSelect
                minValue={filterState.livRoomsMin}
                maxValue={filterState.livRoomsMax}
                label="Salas"
                nameMinLabel="livRoomsMin"
                nameMaxLabel="livRoomsMax"
                onChange={(e) => {
                  setFilterState({
                    ...filterState,
                    [e.target.id]: e.target.value,
                  });
                }}
              />
              <QuantSelect
                minValue={filterState.bathroomsMin}
                maxValue={filterState.bathroomsMax}
                label="Banheiros"
                nameMinLabel="bathroomsMin"
                nameMaxLabel="bathroomsMax"
                onChange={(e) => {
                  setFilterState({
                    ...filterState,
                    [e.target.id]: e.target.value,
                  });
                }}
              />
            </Grid>
            <Grid container spacing={2} item xs={12} md={6} style={{ height: '50%' }}>
              <Grid item xs={12} md={6}>
                <h5 style={{ margin: "0 0 8px 0" }}>Área útil de</h5>
                <TextField
                  id="areaMin"
                  label="m²"
                  type="text"
                  variant="outlined"
                  size="small"
                  onChange={(e) => {
                    setFilterState({ ...filterState, areaMin: e.target.value });
                  }}
                  value={filterState.areaMin}
                />
                <h5 style={{ margin: "8px 0 8px 0" }}>Preço Venda de</h5>
                <TextField
                  id="priceMin"
                  label="R$"
                  type="text"
                  variant="outlined"
                  size="small"
                  onChange={(e) => {
                    setFilterState({
                      ...filterState,
                      priceMin: e.target.value,
                    });
                  }}
                  value={filterState.priceMin}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <h5 style={{ margin: "0 0 8px 0" }}>até</h5>
                <TextField
                  id="areaMax"
                  label="m²"
                  type="text"
                  variant="outlined"
                  size="small"
                  onChange={(e) => {
                    setFilterState({ ...filterState, areaMax: e.target.value });
                  }}
                  value={filterState.areaMax}
                />
                <h5 style={{ margin: "8px 0 8px 0" }}>até</h5>
                <TextField
                  id="priceMax"
                  label="R$"
                  type="text"
                  variant="outlined"
                  size="small"
                  onChange={(e) => {
                    setFilterState({
                      ...filterState,
                      priceMax: e.target.value,
                    });
                  }}
                  value={filterState.priceMax}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <FormControl className={classes.formControl}>
                  <InputLabel id="demo-mutiple-checkbox-label">
                    Descrição do imóvel
                  </InputLabel>
                  <Select
                    labelId="demo-mutiple-checkbox-label"
                    multiple
                    value={filterState.lazer}
                    onChange={(e) => {
                      setFilterState({ ...filterState, lazer: e.target.value });
                    }}
                    input={<Input />}
                    renderValue={(selected) => (
                      <div className={classes.chips}>
                        {selected.map((value) => (
                          <Chip
                            key={value}
                            label={value}
                            className={classes.chip}
                          />
                        ))}
                      </div>
                    )}
                    MenuProps={MenuProps}
                  >
                    {filterOptions &&
                      filterOptions.lazer &&
                      filterOptions.lazer.map((name) => (
                        <MenuItem key={name} value={name}>
                          <Checkbox
                            color="secundary"
                            checked={filterState.lazer.indexOf(name) > -1}
                          />
                          <ListItemText primary={name} />
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button onClick={handleSubmit}>Pesquisar</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
