let data;
// eslint-disable-next-line no-unused-vars
let length;
let type;

//let map;

export const initialize = () => {
    data = getData();
    length = 0;
    type = 'session';
    // initMap();
    // watchLocation();
}

export const clear = () => {
    data = {};
    length = 0;
    clearData();
}

export const getItem = (key) => {
    if(data !== undefined){
        if(key in data)
            return data[key] === undefined ? null : data[key];
        else
            return ''
    }
    else
        return ''
}

export const key = (i) => {
    var ctr = 0;
    for (var k in data) {
        if (ctr === i) return k;
        else ctr++;
    }
    return null;
};

export const removeItem = (key) => {
    delete data[key];
    length--;
    setData(data);
};

export const setItem = (key, value) => {
    data[key] = value + ''; // forces the value to a string
    length++;
    setData(data);
};

// const initMap = () => {
//
//     if (navigator.geolocation) {
//         navigator.geolocation.getCurrentPosition(function(position) {
//             console.log('initMap', position)
//             removeItem('latitude')
//             removeItem('longitude')
//             setItem('latitude', position.coords.latitude)
//             setItem('longitude', position.coords.longitude)
//
//         }, function() {
//
//         });
//
//     } else {
//
//     }
// }
//
// const watchLocation = () => {
//
//     // if (navigator.geolocation) {
//     //     navigator.geolocation.watchPosition(function(position) {
//     //         console.log('watchLocation', position)
//     //         removeItem('latitude')
//     //         removeItem('longitude')
//     //         setItem('latitude', position.coords.latitude)
//     //         setItem('longitude', position.coords.longitude)
//     //
//     //     }, function() {
//     //         handleLocationError(true, infoWindow, map.getCenter());
//     //     });
//     //
//     // } else {
//     //     // Browser doesn't support Geolocation
//     //     handleLocationError(false, infoWindow, map.getCenter());
//     // }
// }
//
// const handleLocationError = (browserHasGeolocation, infoWindow, pos) => {
//     infoWindow.setPosition(pos);
//     infoWindow.setContent(browserHasGeolocation ?
//         'Error: The Geolocation service failed.' :
//         'Error: Your browser doesn\'t support geolocation.');
//     infoWindow.open(map);
// }

const readCookie = (name) => {
    var nameEQ = name + "=",
        ca = document.cookie.split(';'),
        i, c;

    for (i = 0; i < ca.length; i++) {
        c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1, c.length);
        }

        if (c.indexOf(nameEQ) === 0) {
            return c.substring(nameEQ.length, c.length);
        }
    }
    return null;
};

const createCookie = (name, value, days) => {
    var date, expires;

    if (days) {
        date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toGMTString();
    } else {
        expires = "";
    }
    document.cookie = name + "=" + value + expires + "; path=/";
};

const setData = (data) => {
    data = encodeURIComponent(JSON.stringify(data));
    // Create cookie.
    if (type === 'session') {
        createCookie(getSessionName(), data, 365);
    } else {
        createCookie('localStorage', data, 365);
    }
};

const clearData = () => {
    if (type === 'session') {
        createCookie(getSessionName(), '', 365);
    } else {
        createCookie('localStorage', '', 365);
    }
};

const getData = () => {
    // Get cookie data.
    var data = type === 'session' ? readCookie(getSessionName()) : readCookie('localStorage');
    // If we have some data decode, parse and return it.
    return data ? JSON.parse(decodeURIComponent(data)) : {};
};

const getSessionName = () => {
    if(!window.name) {
        window.name = new Date().getTime();
    }
    return 'sessionStorage' + window.name;
};
