import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {compose} from 'redux'
import withScript from '../map/withScript'
import {List, Spin} from 'antd'
import PlacesAutocomplete from 'react-places-autocomplete'
import {AddressInputContainer} from './styles/Input.style'


class AddressInput extends Component {
    _isMounted = false

    static propTypes = {
        onPlaceSelected: PropTypes.func.isRequired,
        onChange: PropTypes.func.isRequired,
        onReset: PropTypes.func.isRequired,
        loading: PropTypes.bool,
        address: PropTypes.string,
        position: PropTypes.any,
    }

    componentDidMount() {
        this._isMounted = true
        if (this._isMounted) {
            let addressStorage = window.localStorage.getItem('address')
            if (addressStorage !== null && addressStorage !== undefined) {
                this.setState({address: addressStorage})
                window.localStorage.removeItem('address')
            }
        }
    }

    componentWillUnmount() {
        this._isMounted = false
    }

    render() {
        const {
            onPlaceSelected,
            onChange,
            address,
            loading
        } = this.props

        return (
            <Spin spinning={loading}>
                <PlacesAutocomplete
                    value={address}
                    onChange={onChange}
                    onSelect={onPlaceSelected}
                    highlightFirstSuggestion
                    searchOptions={{componentRestrictions: {country: 'br'}}}
                >
                    {this.renderInput}
                </PlacesAutocomplete>
            </Spin>
        )
    }

    renderInput = ({getInputProps, suggestions, getSuggestionItemProps, loading}) => (
        <AddressInputContainer>
            <input type='text'
                   ref={node => {
                       this.input = node
                   }}
                   title="Barra de endereço"
                   className='form-control'
                   {...getInputProps({placeholder: 'Digite o endereço e a cidade'})}
            />
            {(loading || suggestions.length !== 0) && this.renderList(suggestions, getSuggestionItemProps, loading)}
        </AddressInputContainer>
    )

    renderList = (suggestions, getSuggestionItemProps, loading) => (
        <List
            size='small'
            loading={loading}
            dataSource={suggestions}
            renderItem={suggestion => this.renderItem(suggestion, getSuggestionItemProps)}
        />
    )

    renderItem = (suggestion, getSuggestionItemProps) => {
        const className = suggestion.active
            ? 'suggestion-item--active'
            : 'suggestion-item'

        return (
            <List.Item
                {...getSuggestionItemProps(suggestion, {className})}
            >
                {suggestion.description}
            </List.Item>
        )
    }

}

const enhance = compose(
    withScript
)

export default enhance(AddressInput)
