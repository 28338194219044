import React from 'react'
import { withScriptjs } from 'react-google-maps'
import { MapElement } from './styles/Map.style'
import { GOOGLE_API_KEY } from '../../utils/constants'

const withScript = Component => props =>
  <Component
    {...props}
    googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&language=pt&region=BR&libraries=geometry,drawing,places,visualization&key=${GOOGLE_API_KEY}`}
    loadingElement={<MapElement className='map-loading' />}
  />

export default Component => withScript(withScriptjs(Component))
