import * as actionsNames from './actionsNames';
import api from '../../services/api';
import configToken from '../../utils/configToken';

import {criarFavoritoSuccess} from './imoveis';

export const loadSuccess = ( data ) => {
    return {
        type: actionsNames.LOAD_SUCCESS,
        data: data
    };
};

const loadFail = ( error ) => {
    return {
        type: actionsNames.LOAD_FAIL,
        error: error
    };
}

const loadLocalizacaoSuccess = ( data ) => {
    return {
        type: actionsNames.LOAD_LOCALIZACAO_SUCCESS,
        data: data
    };
};

const loadLocalizacaoFail = ( error ) => {
    return {
        type: actionsNames.LOAD_LOCALIZACAO_FAIL,
        error: error
    };
}

const loadLocalizacaoStart = () => {
    return {
        type: actionsNames.LOAD_LOCALIZACAO_START
    };
};

const centralizarMapa = () => {
    return {
        type: actionsNames.MAP_CENTER
    };
};

export const centralizar = () => {
    return dispatch => {
        dispatch(centralizarMapa());
    };
}

const setCenter = (center) => {
    return {
        type: actionsNames.SET_CENTER,
        center
    };
};

export const onSetCenter = (center) => {
    return dispatch => {
        dispatch(setCenter(center));
    };
}

const setBuscou = (buscou) => {
    return {
        type: actionsNames.SET_BUSCOU,
        buscou
    };
};

export const onSetBuscou = (buscou) => {
    return dispatch => {
        dispatch(setBuscou(buscou));
    };
}


const setFirstTime = (firstTime) => {
    return {
        type: actionsNames.SET_FIRST_TIME,
        firstTime
    };
};

export const onSetFirstTime = (firstTime) => {
    return dispatch => {
        dispatch(setFirstTime(firstTime));
    };
}

export const onLoadPolygonCoordinates = (coordenadas, boundCoordinates) => {
    return dispatch => {
        let lngne = boundCoordinates[0];
        let latne = boundCoordinates[1];
        let lngsw = boundCoordinates[2];
        let latsw = boundCoordinates[3];
        let urlparams = lngne + '/' + latne + '/' + lngsw + '/' + latsw;
        api.get('/posts/withinpolygon/' + urlparams, configToken())
        .then( res => {
            let fetchedPostsWithinPolygon = [];
            if (res.data.postsWithinPolygon.length > 0) {
                fetchedPostsWithinPolygon = res.data.postsWithinPolygon;
            }
            dispatch(loadSuccess({lngne: lngne, latne: latne, lngsw: lngsw, latsw: latsw, coordenadas: coordenadas, fetchedPostsWithinPolygon: fetchedPostsWithinPolygon, urlparams: urlparams }))
        } )
        .catch(err => {
            dispatch(loadFail(err));
        });
    };
};

export const onLoadLocalizacao = (coordenadas, distancia, formatted_address) => {
    return dispatch => {
        dispatch(loadLocalizacaoStart());
        let urlparams = coordenadas.latitude + '/' + coordenadas.longitude + '/' + distancia + '?formatted_address=' + formatted_address;
        api.get( '/properties/me/' + urlparams, configToken())
        .then( res => {
            const fetchedImoveis = [];
            const fetchedImoveisPredios = [];
            for ( let key in res.data.parsedProperties ) {
                if(res.data.parsedProperties[key].length > 1){
                    fetchedImoveisPredios.push( {
                        id: key,
                        ...res.data.parsedProperties[key],
                    } );
                }else{
                    fetchedImoveis.push( {
                        id: key,
                        ...res.data.parsedProperties[key][0],
                    } );
                }
            }
            dispatch(loadLocalizacaoSuccess({coordenadas:coordenadas, imoveisproximos: fetchedImoveis, imoveisproximospredios: fetchedImoveisPredios, buscou: true, centraliza: false, urlparams: urlparams}));
            
        } )
        .catch( err => {
            dispatch(loadLocalizacaoFail(err));
        } );
    };
};

export const setLocalizacaoSuccess = ( data ) => {
    return {
        type: actionsNames.SET_LOCALIZACAO_SUCCESS,
        data: data
    };
};

export const onSetLocalizacao = (coordenadas) => {
    return dispatch => {
        dispatch(setLocalizacaoSuccess({coordenadas: coordenadas, centraliza: true}));
    };
};

export const onErrorGetLocalizacao = () => {
    return {
        type: actionsNames.ERROR_GET_LOCALIZACAO,
    };
}


const initStart = ( ) => {
    return {
        type: actionsNames.INIT_MAPA_START
    };
};

const initSuccess = ( data ) => {
    return {
        type: actionsNames.INIT_MAPA_SUCCESS,
        favoritos: data.favoritos,
        favoritosPredios: data.favoritosPredios,
        coordenadas: data.coordenadas,
        loading: true
    };
};

const initFail = ( err) => {
    return {
        type: actionsNames.INIT_MAPA_FAIL,
        error: err
    };
};

export const onInit = (coordenadas, newfavorito) => {
    return dispatch => {
        dispatch(initStart());
        api.get( '/favorites/all/parsed', configToken())
            .then( res => {
                let fetchedFavoritos= [];
                let fetchedFavoritosPredios = [];
                Object.entries(res.data.parsedFavorites).forEach(entry => {
                    let key = entry[0];
                    let value = entry[1];

                    if(value.length > 1){
                        fetchedFavoritosPredios.push( {
                            id: key,
                            ...value,
                        } );
                    }else{
                        fetchedFavoritos.push( {
                            id: key,
                            ...value[0],
                        } );
                    }
                });
                // let coordinates = undefined
                // if (fetchedFavoritos && fetchedFavoritos.length > 0) {
                //     coordinates = fetchedFavoritos[0].property.property_location.coordinates;
                // } else if (fetchedFavoritosPredios && fetchedFavoritosPredios[0] !== undefined && fetchedFavoritosPredios[0].length > 0) {
                //     coordinates = fetchedFavoritosPredios[0][0].property.property_location.coordinates;
                // }
                dispatch(initSuccess({favoritos: fetchedFavoritos, favoritosPredios: fetchedFavoritosPredios, coordenadas: coordenadas, centraliza: true, newfavorito: newfavorito}));
                if(newfavorito){
                    dispatch(criarFavoritoSuccess());
                }
            } )
            .catch( err => {
                dispatch(initFail(err));
            } );
    };
};

export const onAddFavorito = () => {
    console.log('ONADDFAVORITO');
    return (dispatch, getState) => {
        dispatch(onInit(getState().localizacao.coordenadas, true));
    };
}