import * as actionsNames from '../actions/actionsNames';
import { updateObject, checkValidity } from '../../utils/utility';
import * as categorias from '../categorias';
import {SECAO_FORM} from '../../utils/constants';

const initialState = {
    favoritosAll: [],
    favoritos: [],
    favoritosPredios: [],
    loading: false,
    error: null,
    favorito: null,
    posts: null,
    images: [],
    file_exported: "",
    xmlUrl: "",
    property: null,
    id_redirect_favorito: null,
    justsaved: false,
    messagedone: null,
    controls: {
        negotiation: {
            elementType: 'select',
            elementConfig: {
                options: [
                    { value: '', displayValue: 'Não informado' },
                    { value: 'venda', displayValue: 'Venda' },
                    { value: 'locacao', displayValue: 'Locação' },
                    { value: 'venda_locacao', displayValue: 'Venda e Locação' }
                ]
            },
            label: 'Tipo',
            dropdown: SECAO_FORM.NODROP,
            value: '',
            validation: {
                required: true
            },
            valid: false,
            touched: false
        },
        categories: {
            elementType: 'select',
            elementConfig: {
                options: [{ value: '', displayValue: 'Não informado' }]
            },
            label: 'Categoria',
            dropdown: SECAO_FORM.NODROP,
            value: '',
            validation: {
                required: true
            },
            valid: false,
            touched: false
        },
        ...categorias.complementares_default
    },
    countInteressados: 0,
    search: {
        categories: '',
        cliente : '',
    },
    favoritosSearched: [],
};


const criarFavoritoStart = (state, action) => {
    return updateObject(state, { loading: true, id_redirect_favorito: null, messagedone: null });
};

const criarFavoritoSuccess = (state, action) => {
    if (action.editar) {
        return updateObject(state, {
            loading: false,
            id_redirect_favorito: action.favorito._id
        });
    } else {
        return updateObject(state, {
            loading: false
        });
    }
};

const sendMessageSuccess = (state, action) => {
    return updateObject(state, {
        loading: false, 
        messagedone: action.message
    });
};

const actionSucess = (state, action) => {
    return updateObject(state, {
        loading: false
    });
};

const actionFail = (state, action) => {
    return updateObject(state, {
        loading: false,
        error: action.error
    });
};

const actionStart = (state, action) => {
    return updateObject(state, { loading: true, id_redirect_favorito: null, justsaved: false, messagedone: null });
};

const fetchPostsSuccess = (state, action) => {
    return updateObject(state, { 
        loading: false,
        posts: action.data
    });
};

const salvarFavoritoSuccess = (state, action) => {
    return updateObject(state, {
        loading: false,
        favorito: action.favorito,
        justsaved: true
    });
};

const fetchFavoritosSuccess = (state, action) => {
    return updateObject(state, {
        favoritos: action.data.favoritos,
        favoritosPredios: action.data.favoritosPredios,
        loading: false
    });
};

const fetchFavoritosListSuccess = (state, action) => {
    return updateObject(state, {
        favoritosAll: action.data.favoritosAll,
        favoritosSearched: action.data.favoritosAll,
        search: { categories: '',
        cliente : '',},
        loading: false
    });
};

const exportFavoriteFail = (state, action) => {
    return updateObject(state, {
        loading: false,
        error: action.error
    });
};

const exportFavoriteStart = (state, action) => {
    return updateObject(state, { loading: true, file_exported: "" });
};

const exportFavoriteSuccess = (state, action) => {
    return updateObject(state, {
        file_exported: action,
        loading: false
    });
};

const deleteFavoritosSuccess = (state, action) => {
    let novalista = state.favoritosAll.filter(fav => (fav.favorite._id !== action._id));
    return updateObject(state, {
        favoritos: [],
        favoritosAll: novalista,
        loading: false,
        messagedone: action.message
    });
};

const fetchXMLUrlSuccess = (state, action) => {
    return updateObject(state, {
        loading: false,
        xmlUrl: action.data.url
    })
}

const fetchKeyImobziSuccess = (state, action) => {
    return updateObject(state, {
        loading: false,
        keyImobzi: action.data.key
    })
}

const setFavorito = (state, action) => {
    return updateObject(state, { loading: false, favorito: action.favorito });
};

const loadFavorito = (state, action) => {
    let updatedControls = initialState.controls;
    let favorito = action.favorito.favorite;
    if(favorito.negotiation){
        updatedControls = changeControls(updatedControls, { controlname: 'negotiation', newvalue: favorito.negotiation, onload: true });
        if(favorito.categories){
            updatedControls = changeControls(updatedControls, { controlname: 'categories', newvalue: favorito.categories, onload: true });
        }
    }
    let resources = [];
    if(favorito.resources && favorito.resources.length>0){
        resources = favorito.resources;
    }
    if(favorito.resourcesComp && favorito.resourcesComp.length>0){
        let resourcesComp = favorito.resourcesComp;
        resources = [...resources, ...resourcesComp];
    }
    if (resources.length > 0) {
        updatedControls = updateObject(updatedControls,
            resources
                .filter(resource => (resource.resourceValue in updatedControls))
                .reduce((controls, resource) => {
                    // if (resource.resourceAmount) {
                        controls[resource.resourceValue] = updateObject(updatedControls[resource.resourceValue], {
                            value: resource.resourceAmount,
                            touched: false,
                            _id: resource._id,
                        });
                    // } else {
                    //     controls[resource.resourceName] = updateObject(updatedControls[resource.resourceName], {
                    //         value: resource.resourceValue,
                    //         touched: false,
                    //         _id: resource._id,
                    //     });
                    // }
                    return controls;
                }, updatedControls)
        );
    }
    let countInteressados = resources.find(resource => resource.resourceName === 'countInteressados');
    countInteressados = countInteressados?countInteressados.resourceValue:0;
    return updateObject(state, { loading: false, favorito: favorito, images: favorito.images, property: action.favorito.property, controls: updatedControls, justsaved: false, countInteressados });
};

const changeControls = (currentcontrols, action) => {
    let updatedControls = null;
    if (action.controlname === "negotiation" || action.controlname === "categories") {
        if (action.controlname === "negotiation") {
            let categorianaoinformada = { value: '', displayValue: 'Não informada' };
            let optionscategories = null;
            if (action.newvalue) {
                localStorage.removeItem('negotiation')
                localStorage.setItem('negotiation', action.newvalue)
                let valor = action.newvalue;
                if(action.newvalue==='venda_locacao'){
                    //para venda / locação os campos são iguais aos de venda
                    valor = 'venda';
                }
                optionscategories = [
                    categorianaoinformada,
                    ...Object.keys(categorias[valor]).map((key) => {
                        return { value: key, displayValue: categorias[valor][key] }
                    })
                ];
            } else {
                optionscategories = [categorianaoinformada];
            }
            updatedControls = {
                negotiation: currentcontrols.negotiation,
                categories: updateObject(currentcontrols.categories, {
                    value: '',
                    elementConfig: updateObject(currentcontrols.categories.elementConfig,
                        {
                            options: optionscategories
                        })
                }),
                ...categorias.complementares_default
            }
        } else {
            let negotiationselecionada = currentcontrols.negotiation.value;
            if(negotiationselecionada ==='venda_locacao'){
                negotiationselecionada = 'venda';
            }
            let categoriaselecionada = action.newvalue;

            let categoriasdisponiveis = [];
            let camposcomplementares = [];
            if (negotiationselecionada) {
                categoriasdisponiveis = categorias[categoriaselecionada][negotiationselecionada];
                camposcomplementares = categorias.complementares[negotiationselecionada];
            }
            if(currentcontrols.negotiation.value === 'venda'){
                updatedControls = {
                    negotiation: currentcontrols.negotiation,
                    categories: currentcontrols.categories,
                    nome_proprietario: currentcontrols.nome_proprietario,
                    email: currentcontrols.email,
                    telefone: currentcontrols.telefone,
                    alugado: categorias.alugado,
                    ...categoriasdisponiveis,
                    ...camposcomplementares,
                }
            }else{
                updatedControls = {
                    negotiation: currentcontrols.negotiation,
                    categories: currentcontrols.categories,
                    nome_proprietario: currentcontrols.nome_proprietario,
                    email: currentcontrols.email,
                    telefone: currentcontrols.telefone,
                    ...categoriasdisponiveis,
                    ...camposcomplementares,
                }
            }
        }
        let control = updateObject(currentcontrols[action.controlname], {
            value: action.newvalue,
            valid: checkValidity(action.newvalue, currentcontrols[action.controlname].validation),
            touched: !action.onload
        });
        updatedControls[action.controlname] = control;
    } else {
        let control = updateObject(currentcontrols[action.controlname], {
            value: action.newvalue,
            valid: checkValidity(action.newvalue, currentcontrols[action.controlname].validation),
            touched: true
        });
        updatedControls = {...currentcontrols};
        updatedControls[action.controlname] = control;
    }
    return updatedControls;
}

const calculoValorAvaliado = (area, valorm2) =>{
    if(!area || !valorm2){
        return null;
    }
    let result = Number.parseFloat(valorm2.replace(',', '.')) * Number.parseFloat(area.replace(',', '.'));
    let partes = result.toString().split('.');
    if(partes.length>1 && partes[1].length>2){
        return result.toFixed(2);
    }
    return result;
}

const somaValorTotal = (valor1, valor2) =>{
    if(!valor1){
        if(!valor2){
            return null;
        }else{
            return valor2;
        }
    }
    if(!valor2){
        return valor1;
    }
    let result = Number.parseFloat(valor1.toString().replace(',', '.')) + Number.parseFloat(valor2.toString().replace(',', '.'));
    let partes = result.toString().split('.');
    if(partes.length>1 && partes[1].length>2){
        return result.toFixed(2);
    }
    return result;
}

const changeFavorito = (state, action) => {
    let property = state.property
    let updatedControls = changeControls(state.controls, action);

    if (action.controlname === 'valor_m2_area_construida' && (updatedControls.negotiation.value === 'venda'||updatedControls.negotiation.value === 'venda_locacao')) {
       
        if (updatedControls.categories.value !== 'apartamento'
            && updatedControls.categories.value !== 'sala_comercial'
            && updatedControls.categories.value !== 'outros') {
                updatedControls.valor_avaliado_ac.touched = true
                updatedControls.valor_avaliado_ac.value = calculoValorAvaliado(action.newvalue, property.building_area);
                updatedControls.valor_avaliado_atac.touched = true
                updatedControls.valor_avaliado_at.touched = true
                updatedControls.valor_avaliado_at.value = calculoValorAvaliado(action.newvalue, property.building_area);
                updatedControls.valor_avaliado_atac.value = somaValorTotal(updatedControls.valor_avaliado_ac.value,updatedControls.valor_avaliado_at.value);
        }

    }

    if (action.controlname === 'valor_m2_area_territorial' && (updatedControls.negotiation.value === 'venda'||updatedControls.negotiation.value === 'venda_locacao')) {
       
        if (updatedControls.categories.value === 'terreno'
            || updatedControls.categories.value === 'terreno_condominio'
            || updatedControls.categories.value === 'gleba') {
            updatedControls.valor_avaliado_fvv.touched = true
            updatedControls.valor_avaliado_fvv.value = calculoValorAvaliado(action.newvalue, property.ground_area);

        }
        else if (updatedControls.categories.value !== 'apartamento'
            && updatedControls.categories.value !== 'sala_comercial'
            && updatedControls.categories.value !== 'outros') {
            updatedControls.valor_avaliado_at.touched = true
            updatedControls.valor_avaliado_at.value = calculoValorAvaliado(action.newvalue, property.ground_area);
            updatedControls.valor_avaliado_ac.touched = true
            updatedControls.valor_avaliado_at.value = calculoValorAvaliado(action.newvalue, property.building_area);
            updatedControls.valor_avaliado_atac.value = somaValorTotal(updatedControls.valor_avaliado_ac.value,updatedControls.valor_avaliado_at.value);
            updatedControls.valor_avaliado_atac.touched = true
        }

    }


    if (action.controlname === 'valor_venda' || action.controlname === 'valor_aluguel') {
        if (updatedControls.valor_venda && updatedControls.valor_venda.value > 0 &&
            updatedControls.valor_aluguel && updatedControls.valor_aluguel.value > 0) {
            if (updatedControls.retorno_aluguel) {
                updatedControls.retorno_aluguel.touched = true
                updatedControls.retorno_aluguel.value = (((updatedControls.valor_aluguel.value / updatedControls.valor_venda.value) * 100).toFixed(2));
            }
        }
    }

    return updateObject(state, { controls: updatedControls });
}

const changeInteressado = (state, action) => {
    let favorito = {...state.favorito};
    let resource = favorito.resources.find(resource => (resource.resourceName ===action.data.id && resource.resourceValue === action.data.field));
    resource.resourceAmount = action.data.newval;
    return updateObject(state, {favorito: favorito});
}

const addInteressado = (state, action) => {
    let favorito = {...state.favorito};
    favorito.resources.push({resourceName: 'Interessado['+state.countInteressados+']', resourceValue: 'nome', resourceAmount: ''});
    favorito.resources.push({resourceName: 'Interessado['+state.countInteressados+']', resourceValue: 'email', resourceAmount: ''});
    favorito.resources.push({resourceName: 'Interessado['+state.countInteressados+']', resourceValue: 'telefone', resourceAmount: ''});
    return updateObject(state, {favorito: favorito, countInteressados: state.countInteressados+1});
}

const removeInteressado = (state, action) => {
    let favorito = {...state.favorito};
    favorito.resources = favorito.resources.filter(element => element.resourceName !== action.id);
    return updateObject(state, {favorito: favorito});
}

const filterFavoritos = (state, action) => {
    let newsearch = {...state.search};
    newsearch[action.data.field] = action.data.newvalue;
    if(action.data.newvalue === ''){
        return updateObject(state, {search: newsearch, favoritosSearched: state.favoritosAll});
    }
    if(!state.favoritosAll || state.favoritosAll.length===0){
        return updateObject(state, {search: newsearch})
    }else{
        let functionFilter = null;
        if(action.data.field === 'categories'){
            functionFilter = favorito => favorito.favorite.categories === action.data.newvalue;
        }else{
            //atualmente só filtra por categories ou cliente
            functionFilter = favorito => (
                (favorito.favorite.resourcesComp
                    && favorito.favorite.resourcesComp.length>0
                    && (
                        favorito.favorite.resourcesComp.filter(resource => (
                            (resource.resourceValue ==='nome_proprietario'
                                && resource.resourceAmount
                                && resource.resourceAmount.toLowerCase().includes(action.data.newvalue.toLowerCase()))
                                ||
                            (resource.resourceName && resource.resourceName.startsWith('Interessado')
                                && resource.resourceValue === 'nome'
                                && resource.resourceAmount.includes(action.data.newvalue))
                        )).length>0
                    )
                ));
        }
        let filtragem = state.favoritosAll
            .filter(functionFilter);

        return updateObject(state, {search: newsearch, favoritosSearched: filtragem});
    }
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionsNames.CRIAR_FAVORITO_START: return criarFavoritoStart(state, action);
        case actionsNames.CRIAR_FAVORITO_SUCCESS: return criarFavoritoSuccess(state, action)
        case actionsNames.CRIAR_FAVORITO_FAIL: return actionFail(state, action);
        case actionsNames.SALVAR_FAVORITO_START: return actionStart(state, action);
        case actionsNames.SALVAR_FAVORITO_SUCCESS: return salvarFavoritoSuccess(state, action)
        case actionsNames.SALVAR_FAVORITO_FAIL: return actionFail(state, action);
        case actionsNames.FETCH_FAVORITOS_START: return actionStart(state, action);
        case actionsNames.FETCH_FAVORITOS_SUCCESS: return fetchFavoritosSuccess(state, action);
        case actionsNames.FETCH_FAVORITOS_ALL_SUCCESS: return fetchFavoritosListSuccess(state, action);
        case actionsNames.FETCH_FAVORITOS_FAIL: return actionFail(state, action);
        case actionsNames.DELETE_FAVORITO_START: return actionStart(state, action);
        case actionsNames.DELETE_FAVORITO_SUCCESS: return deleteFavoritosSuccess(state, action);
        case actionsNames.FETCH_XML_URL_SUCCESS: return fetchXMLUrlSuccess(state, action);
        case actionsNames.FETCH_KEY_IMOBZI_SUCCESS: return fetchKeyImobziSuccess(state, action);
        case actionsNames.DELETE_FAVORITO_FAIL: return actionFail(state, action);
        case actionsNames.DELETE_IMAGE_FAVORITO_START: return actionStart(state, action);
        case actionsNames.DELETE_IMAGE_FAVORITO_SUCCESS: return salvarFavoritoSuccess(state, action);
        case actionsNames.DELETE_IMAGE_FAVORITO_FAIL: return actionFail(state, action);
        case actionsNames.SET_FAVORITO: return setFavorito(state, action);
        case actionsNames.LOAD_FAVORITO: return loadFavorito(state, action);
        case actionsNames.CHANGE_FAVORITO: return changeFavorito(state, action);
        case actionsNames.EXPORT_FAVORITO_START: return exportFavoriteStart(state, action);
        case actionsNames.EXPORT_FAVORITO_SUCCESS: return exportFavoriteSuccess(state, action);
        case actionsNames.EXPORT_FAVORITO_FAIL: return exportFavoriteFail(state, action);
        case actionsNames.CHANGE_INTERESSADO: return changeInteressado(state, action);
        case actionsNames.ADD_INTERESSADO: return addInteressado(state, action);
        case actionsNames.REMOVE_INTERESSADO: return removeInteressado(state, action);
        case actionsNames.FILTER_FAVORITOS: return filterFavoritos(state, action);
        case actionsNames.FETCH_POSTS_START: return actionStart(state, action);
        case actionsNames.FETCH_POSTS_SUCCESS: return fetchPostsSuccess(state, action);
        case actionsNames.FETCH_POSTS_FAIL: return actionFail(state, action);
        case actionsNames.SAVE_POST_START: return actionStart(state, action);
        case actionsNames.SAVE_POST_SUCCESS: return actionSucess(state, action);
        case actionsNames.SAVE_POST_FAIL: return actionFail(state, action);
        case actionsNames.DELETE_POST_START: return actionStart(state, action);
        case actionsNames.DELETE_POST_SUCCESS: return actionSucess(state, action);
        case actionsNames.DELETE_POST_FAIL: return actionFail(state, action);
        case actionsNames.SEND_CLIENT_MESSAGE_FAILURE: return actionFail(state, action);
        case actionsNames.SEND_CLIENT_MESSAGE_START: return actionStart(state, action);
        case actionsNames.SEND_CLIENT_MESSAGE_SUCCESS: return sendMessageSuccess(state, action);
        default: return state;
    }
};

export default reducer;
