import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Box,
  Input,
  InputLabel,
  Chip,
  Grid,
  DialogActions,
  ListItemText,
  DialogContent,
  DialogTitle,
  FormControl,
  MenuItem,
  Select,
  TextField,
  Card,
  ButtonGroup,
  Hidden,
} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import QuantSelect from "../QuantSelect/QuantSelect";
import GeneralSelect from "../GeneralSelect/GeneralSelect";
import DebounceInputField from "./DebounceField/DebounceField";

const useStyles = makeStyles((theme) => ({
  card: {
    margin: "24px 8px 0px 8px",
  },
  formControl: {
    display: "flex",
    justifyContent: "space-around",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
    margin: "2px",
  },
  box: {
    display: "flex",
    justifyContent: "space-between",
  },
  text: {
    width: "100%",
  },
  buttonGroup: {
    marginBottom: "16px",
  },
}));

const AdvancedFilter = ({
  filterOptions,
  onChangeCity,
  onChangeTipo,
  onSearch,
  order = null,
  chips = [],
  labels = [],
  page = 1,
  defaultCity = "",
  defaultNeighborhood = "",
  defaultType = "",
  defaultPurchase = "",
  defaultImobiliaria = "",
}) => {
  const classes = useStyles();
  const [filterState, setFilterState] = useState({
    city: defaultCity,
    imobiliaria: defaultImobiliaria,
    neighborhood: defaultNeighborhood,
    type: defaultType,
    purchase: defaultPurchase,
    order: order,
    page: page,
    roomsMin: "",
    roomsMax: "",
    suitesMin: "",
    suitesMax: "",
    garageMin: "",
    garageMax: "",
    priceMin: "",
    priceMax: "",
    areaMax: "",
    areaMin: "",
    livRoomsMax: "",
    livRoomsMin: "",
    bathroomsMax: "",
    bathroomsMin: "",
    lazer: [],
  });

  const [hide, setHide] = useState(false);

  useEffect(() => {
    // Perform the search whenever filterState changes
    const performSearch = () => {
      // Collect and filter the form data
      const formData = {
        cidade: filterState.city,
        imobiliaria: filterState.imobiliaria,
        tipo: filterState.type,
        lazer: filterState.lazer.join(","),
        bairro: filterState.neighborhood,
        purchase: filterState.purchase,
        quartosMin: filterState.roomsMin,
        quartosMax: filterState.roomsMax,
        banheirosMin: filterState.bathroomsMin,
        banheirosMax: filterState.bathroomsMax,
        garagensMin: filterState.garageMin,
        garagensMax: filterState.garageMax,
        suitesMin: filterState.suitesMin,
        suitesMax: filterState.suitesMax,
        salasMin: filterState.livRoomsMin,
        salasMax: filterState.livRoomsMax,
        precoMin: filterState.priceMin,
        precoMax: filterState.priceMax,
        areaMin: filterState.areaMin,
        areaMax: filterState.areaMax,
        order: filterState.order,
        page: filterState.page,
        post_type: "empreendimento",
        created_from: "xml",
        itensPerPage: 10,
      };
      // Call the onSearch function with the form data
      onSearch({
        formData,
      });
    };

    performSearch();

    // Important eslint disable
    // eslint-disable-next-line
  }, [filterState]);

  const mergeChips = (lazer, chips) => {
    const filteredLazer = lazer.filter((item) => !labels.includes(item));
    return filteredLazer.concat(chips);
  };

  useEffect(() => {
    setFilterState({
      ...filterState,
      lazer: mergeChips(filterState.lazer, chips),
    });
    // eslint-disable-next-line
  }, [chips]);

  const handleHide = () => {
    setHide(true);
  };

  const handleShow = () => {
    setHide(false);
  };

  const handleClear = () => {
    setFilterState({
      city: "",
      imobiliaria: "",
      neighborhood: "",
      type: "",
      roomsMin: "",
      roomsMax: "",
      suitesMin: "",
      suitesMax: "",
      garageMin: "",
      garageMax: "",
      priceMin: "",
      priceMax: "",
      areaMax: "",
      areaMin: "",
      livRoomsMax: "",
      livRoomsMin: "",
      bathroomsMax: "",
      bathroomsMin: "",
      lazer: [],
      purchase: filterState.purchase,
      order: filterState.order,
      page: 1,
    });
  };

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 48 * 4.5 + 8,
        width: 250,
      },
    },
    getContentAnchorEl: () => null,
  };

  const itensDeEmpreendimentos = [
    "Casa de Condominio",
    "Casa",
    "Apartamento",
    "Imóvel Comercial",
    "Terreno Comercial",
    "Terreno",
    "Fazenda / Sítio",
    "Loja",
    "Chácara",
    "Cobertura",
    "Imóvel Industrial",
    "Kitnet",
    "Escritório Comercial",
  ];

  return (
    <Card className={classes.card}>
      {hide ? (
        <>
          <DialogActions>
            <Button onClick={handleShow} color="primary">
              Exibir Filtros
            </Button>
          </DialogActions>
        </>
      ) : (
        <>
          <DialogTitle>Filtro</DialogTitle>
          <DialogContent>
            {/* Comprar / Alugar */}
            <ButtonGroup className={classes.buttonGroup}>
              <Button
                color="primary"
                variant={
                  filterState.purchase === "purchase" ? "contained" : "outlined"
                }
                onClick={(e) => {
                  setFilterState({ ...filterState, purchase: "purchase" });
                }}
              >
                Comprar
              </Button>
              <Button
                color="primary"
                variant={
                  filterState.purchase === "rent" ? "contained" : "outlined"
                }
                onClick={(e) => {
                  setFilterState({ ...filterState, purchase: "rent" });
                }}
              >
                Alugar
              </Button>
            </ButtonGroup>

            {/* City */}
            <GeneralSelect
              value={filterState.city}
              nameValue="cidade"
              onChange={(e) => {
                setFilterState({
                  ...filterState,
                  neighborhood: "",
                  city: e.target.value,
                });
                onChangeCity(e);
              }}
              optionsArray={filterOptions && filterOptions.cidades}
            />

            {/* Neighborhood */}
            {filterOptions && filterOptions.bairros && (
              <GeneralSelect
                value={filterState.neighborhood}
                nameValue="bairro"
                onChange={(e) => {
                  setFilterState({
                    ...filterState,
                    neighborhood: e.target.value,
                  });
                }}
                optionsArray={filterOptions && filterOptions.bairros}
              />
            )}

            {/* Property Type */}
            <GeneralSelect
              value={filterState.type}
              nameValue="tipo"
              onChange={(e) => {
                setFilterState({ ...filterState, type: e.target.value });
                onChangeTipo(e);
              }}
              optionsArray={itensDeEmpreendimentos}
            />

            <Box mt={2}>
              {" "}
              {/* Add margin-top to create space */}
              {/* Number of Rooms */}
              <QuantSelect
                minValue={filterState.roomsMin}
                maxValue={filterState.roomsMax}
                label="Quartos"
                nameMinLabel="roomsMin"
                nameMaxLabel="roomsMax"
                onChange={(e) =>
                  setFilterState({
                    ...filterState,
                    [e.target.id]: e.target.value,
                  })
                }
              />
            </Box>

            {/* Number of Suites */}
            <QuantSelect
              minValue={filterState.suitesMin}
              maxValue={filterState.suitesMax}
              label="Suítes"
              nameMinLabel="suitesMin"
              nameMaxLabel="suitesMax"
              onChange={(e) =>
                setFilterState({
                  ...filterState,
                  [e.target.id]: e.target.value,
                })
              }
            />

            {/* Number of Garages */}
            <QuantSelect
              minValue={filterState.garageMin}
              maxValue={filterState.garageMax}
              label="Garagens"
              nameMinLabel="garageMin"
              nameMaxLabel="garageMax"
              onChange={(e) =>
                setFilterState({
                  ...filterState,
                  [e.target.id]: e.target.value,
                })
              }
            />

            {/* Number of Bathrooms */}
            <QuantSelect
              minValue={filterState.bathroomsMin}
              maxValue={filterState.bathroomsMax}
              label="Banheiros"
              nameMinLabel="bathroomsMin"
              nameMaxLabel="bathroomsMax"
              onChange={(e) =>
                setFilterState({
                  ...filterState,
                  [e.target.id]: e.target.value,
                })
              }
            />

            {/* Number of Living Rooms */}
            <QuantSelect
              minValue={filterState.livRoomsMin}
              maxValue={filterState.livRoomsMax}
              label="Salas"
              nameMinLabel="livRoomsMin"
              nameMaxLabel="livRoomsMax"
              onChange={(e) =>
                setFilterState({
                  ...filterState,
                  [e.target.id]: e.target.value,
                })
              }
            />

            <Grid
              container
              justifyContent="space-between"
              alignItems="flex-end"
            >
              <Grid xs={5}>
                <h5 style={{ margin: "0 0 8px 0" }}>Área útil de</h5>
                <TextField
                  className={classes.text}
                  id="areaMin"
                  label="m²"
                  type="text"
                  variant="outlined"
                  size="small"
                  InputProps={{
                    inputComponent: DebounceInputField,
                  }}
                  onChange={(e) => {
                    setFilterState({ ...filterState, areaMin: e.target.value });
                  }}
                  value={filterState.areaMin}
                />
              </Grid>
              <Grid xs={5}>
                <h5 style={{ margin: "0 0 8px 0" }}>até</h5>
                <TextField
                  className={classes.text}
                  id="areaMax"
                  label="m²"
                  type="text"
                  variant="outlined"
                  size="small"
                  InputProps={{
                    inputComponent: DebounceInputField,
                  }}
                  onChange={(e) => {
                    setFilterState({ ...filterState, areaMax: e.target.value });
                  }}
                  value={filterState.areaMax}
                />
              </Grid>
            </Grid>

            <Grid
              container
              justifyContent="space-between"
              alignItems="flex-end"
            >
              <Grid xs={5}>
                <h5 style={{ margin: "8px 0 8px 0" }}>Preço Venda de</h5>
                <TextField
                  className={classes.text}
                  id="priceMin"
                  label="R$"
                  type="text"
                  variant="outlined"
                  size="small"
                  InputProps={{
                    inputComponent: DebounceInputField,
                  }}
                  onChange={(e) => {
                    setFilterState({
                      ...filterState,
                      priceMin: e.target.value,
                    });
                  }}
                  value={filterState.priceMin}
                />
              </Grid>
              <Grid xs={5}>
                <h5 style={{ margin: "8px 0 8px 0" }}>até</h5>
                <TextField
                  className={classes.text}
                  id="priceMax"
                  label="R$"
                  type="text"
                  variant="outlined"
                  size="small"
                  InputProps={{
                    inputComponent: DebounceInputField,
                  }}
                  onChange={(e) => {
                    setFilterState({
                      ...filterState,
                      priceMax: e.target.value,
                    });
                  }}
                  value={filterState.priceMax}
                />
              </Grid>
            </Grid>

            {/* Descrição do imóvel */}
            <FormControl className={classes.formControl}>
              <InputLabel id="demo-mutiple-checkbox-label">
                Descrição do imóvel
              </InputLabel>
              <Select
                labelId="demo-mutiple-checkbox-label"
                multiple
                value={filterState.lazer}
                onChange={(e) => {
                  setFilterState({ ...filterState, lazer: e.target.value });
                }}
                input={<Input />}
                renderValue={(selected) => (
                  <div className={classes.chips}>
                    {selected.map((value) => (
                      <Chip
                        key={value}
                        label={value}
                        className={classes.chip}
                      />
                    ))}
                  </div>
                )}
                MenuProps={MenuProps}
              >
                {filterOptions &&
                  filterOptions.lazer &&
                  filterOptions.lazer.map((name) => (
                    <MenuItem key={name} value={name}>
                      <Checkbox
                        color="secondary"
                        checked={filterState.lazer.indexOf(name) > -1}
                      />
                      <ListItemText primary={name} />
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Hidden smUp>
              <Button onClick={handleHide} color="primary">
                Ocultar
              </Button>
            </Hidden>
            <Button onClick={handleClear} color="secondary">
              Limpar
            </Button>
          </DialogActions>
        </>
      )}
    </Card>
  );
};

export default AdvancedFilter;
