import * as actionsNames from '../actions/actionsNames';
import { updateObject, checkValidity } from '../../utils/utility';
import * as userSession from "../userSession";

const initialState = {
    controls: {
        nome: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: 'Nome'
            },
            label: 'Nome',
            value: '',
            validation: {
                required: true
            },
            valid: false,
            touched: false
        },
        email: {
            elementType: 'input',
            elementConfig: {
                type: 'email',
                placeholder: 'E-mail'
            },
            label: 'E-mail',
            value: '',
            validation: {
                required: true,
                isEmail: true
            },
            valid: false,
            touched: false
        },
        telefone: {
            elementType: 'input',
            elementConfig: {
                type: 'tel',
                placeholder: '11999999999',
                maxLength:"15",
            },
            label: 'Telefone',
            value: '',
            validation: {
                required: true,
                isFone: true
            },
            valid: false,
            touched: false
        }
    },
    controlsSign: {
        nome: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: 'Nome conforme cartão'
            },
            label: 'Nome',
            value: '',
            validation: {
                required: true
            },
            valid: false,
            touched: false
        },
        numero: {
            elementType: 'input',
            elementConfig: {
                type: 'number',
                placeholder: '9999999999999999'
            },
            label: 'Número',
            value: '',
            validation: {
                required: true,
                maxLength:"16",
                minLength:"16",
            },
            valid: false,
            touched: false
        },
        validade: {
            elementType: 'text',
            elementConfig: {
                type: 'text',
                placeholder: '99/9999',
                maxLength:"7",
                minLength:"7",
            },
            label: 'Validade',
            value: '',
            validation: {
                required: true,
            },
            valid: false,
            touched: false
        },
        codigo: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: 'xxx',
                maxLength:"3",
                minLength:"3",
            },
            label: 'Código',
            value: '',
            validation: {
                required: true,
            },
            valid: false,
            touched: false
        },
        bandeira: {
            elementType: 'select',
            elementConfig: {
                options: [
                    {value: '', displayValue: 'Bandeira'},
                    {value: 'visa', displayValue: 'Visa'},
                    {value: 'mastercard', displayValue: 'Master'},
                    {value: 'amex', displayValue: 'Amex'},
                    {value: 'elo', displayValue: 'Elo'},
                    {value: 'aura', displayValue: 'Aura'},
                    {value: 'jcb', displayValue: 'Jcb'},
                    {value: 'dinersclub', displayValue: 'Diners'},
                    {value: 'discover', displayValue: 'Discover'},
                    {value: 'hipercard', displayValue: 'Hipercard'},
                    {value: 'hiper', displayValue: 'Hiper'},
                ]
            },
            label: 'Bandeira',
            value: '',
            validation: {
                required: true,
            },
            valid: false,
            touched: false
        }
    },
    userloaded: false,
    loading: false,
    error: null,
    assinatura: null,
}

const actionStart = ( state, action ) => {
    return updateObject( state, { loading: true, error: null } );
};

const actionSuccess = ( state, action ) => {
    return updateObject( state, { loading: false, controlsSign: {...initialState.controlsSign} } );
};

const fetchUserSuccess = ( state, action ) => {
    let updatedControls = updateObject( initialState.controls, {
        nome: updateObject( initialState.controls.nome, {
            value: action.userdata.name,
            touched: false
        }),
        email: updateObject( initialState.controls.email, {
            value: action.userdata.email,
            touched: false
        }),
        telefone: updateObject( initialState.controls.telefone, {
            value: action.userdata.phone,
            touched: false
        })
    } );

    userSession.setUsuarioLogado(action.userdata);
    return updateObject( state, { loading: false, controls: updatedControls, userloaded: true } );
};

const changeUser = (state, action) => {
    let control = updateObject( state.controls[action.controlname], {
        value: action.newvalue,
        valid: checkValidity( action.newvalue, state.controls[action.controlname].validation ),
        touched: true
    } );
    const updatedControls = {...state.controls};
    updatedControls[action.controlname]= control;
    return { controls: updatedControls };
}

const changeSign = (state, action) => {
    let control = updateObject( state.controlsSign[action.controlname], {
        value: action.newvalue,
        valid: checkValidity( action.newvalue, state.controlsSign[action.controlname].validation ),
        touched: true
    } );
    const updatedControls = {...state.controlsSign};
    updatedControls[action.controlname]= control;
    return updateObject(state, { controlsSign: updatedControls });
}

const actionFail = ( state, action ) => {
    let errormessage = action.error;
    if(action.error.response && action.error.response.data&& (action.error.response.data.error||action.error.response.data.message)){
        errormessage = action.error.response.data.error?action.error.response.data.error:action.error.response.data.message;
    }else{
        if(action.error.message){
            errormessage = action.error.message;
        }
    }
    return updateObject( state, { loading: false,
        error: errormessage } );
};

const loadAssinaturaSuccess = ( state, action ) => {
    console.log(action)
    let updatedControlsSign = updateObject( initialState.controlsSign, {
        // nome: updateObject( initialState.controlsSign.nome, {
        //     value: action.userdata.name,
        //     touched: false
        // }),
        numero: updateObject( initialState.controlsSign.numero, {
            value: action.assinatura.creditCard,
            touched: true
        }),
        // validade: updateObject( initialState.controls.validade, {
        //     value: action.userdata.validade,
        //     touched: false
        // }),
        // codigo: updateObject( initialState.controls.telefone, {
        //     value: action.userdata.phone,
        //     touched: false
        // }),
        bandeira: updateObject( initialState.controlsSign.bandeira, {
            value: action.assinatura.brand,
            touched: false
        })
    } );
    return updateObject( state, { loading: false,
        error: null,
        controlsSign: updatedControlsSign,
        assinatura: action.assinatura } );
};


const loadAssinaturaFail = ( state, action ) => {
    return updateObject( state, { loading: false,
        error: null,
        controlsSign: initialState.controlsSign,
        assinatura: false } );
};

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionsNames.FETCH_USER_START: return actionStart( state, action );
        case actionsNames.FETCH_USER_SUCCESS: return fetchUserSuccess( state, action )
        case actionsNames.FETCH_USER_FAIL: return actionFail( state, action );
        case actionsNames.DELETE_USER_START: return actionStart( state, action );
        case actionsNames.DELETE_USER_SUCCESS: return actionSuccess( state, action )
        case actionsNames.DELETE_USER_FAIL: return actionFail( state, action );
        case actionsNames.UPDATE_USER_START: return actionStart( state, action );
        case actionsNames.UPDATE_USER_SUCCESS: return actionSuccess( state, action )
        case actionsNames.UPDATE_USER_FAIL: return actionFail( state, action );
        case actionsNames.CHANGE_USER: return changeUser(state, action);
        case actionsNames.CHANGE_ASSINATURA: return changeSign(state, action);
        case actionsNames.DELETE_AVATAR_START: return actionStart( state, action );
        case actionsNames.DELETE_AVATAR_SUCCESS: return actionSuccess( state, action )
        case actionsNames.DELETE_AVATAR_FAIL: return actionFail( state, action );
        case actionsNames.SALVAR_CARTAO_START: return actionStart( state, action );
        case actionsNames.SALVAR_CARTAO_SUCCESS: return actionSuccess( state, action )
        case actionsNames.SALVAR_CARTAO_FAIL: return actionFail( state, action );
        case actionsNames.CANCELAR_ASSINATURA_START: return actionStart( state, action );
        case actionsNames.CANCELAR_ASSINATURA_SUCCESS: return actionSuccess( state, action )
        case actionsNames.CANCELAR_ASSINATURA_FAIL: return actionFail( state, action );
        case actionsNames.REATIVAR_ASSINATURA_START: return actionStart( state, action );
        case actionsNames.REATIVAR_ASSINATURA_SUCCESS: return actionSuccess( state, action )
        case actionsNames.REATIVAR_ASSINATURA_FAIL: return actionFail( state, action );
        case actionsNames.LOAD_ASSINATURA_START: return actionStart( state, action );
        case actionsNames.LOAD_ASSINATURA_SUCCESS: return loadAssinaturaSuccess( state, action )
        case actionsNames.LOAD_ASSINATURA_FAIL: return loadAssinaturaFail( state, action );
        default: return state;
    }
};

export default reducer;
