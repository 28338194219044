import React, { Component } from 'react';
import api from '../../services/api';
import Button from '../../components/UI/Button/Button';
import xml2js from "xml2js";
import configToken from "../../utils/configToken";
import ErrorsList from './ErrorList/ErrorList';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { 
    FormControl, 
    Grid, InputLabel, 
    MenuItem, Select, 
    TextField, 
    Typography, 
    Box 
} from '@material-ui/core';

import * as actions from '../../store/actions/index';

class PostXml extends Component {
    constructor(props) {
        super(props);
        this.fileInput = React.createRef();

        this.state = {
            url: "",
            keyImobzi: "",
            selected: "",
            uploadIds: [],
            pagesCount: 1,
            currentPage: 1,
            openList: false
        }
    }

    componentDidMount() {
        this.props.onFetchXMLUrl();
        this.props.onFetchKeyImobzi();
    }

    componentDidUpdate(prevProps) {
        if (this.props.url !== prevProps.url || this.props.keyImobzi !== prevProps.keyImobzi) {
            this.setState({
                url: this.props.url,
                keyImobzi: this.props.keyImobzi,
            })
        }
    }

    checkUploadError = ({ page = 1 }) => {
        console.log(page)
        api.post('/posts/error/list', { page: page }, configToken()).then(res => {
            console.log(res);
            this.setState({
                uploadIds: res.data.errors, 
                openList: true,
                pagesCount: res.data.totalPages,
                currentPage: res.data.currentPage
            });
        }).catch(err => {
            console.log(err)
        }) 
    }

    dateNow = () =>{
        let currentdate = new Date();
        let options = { 
          timeZone: 'America/Sao_Paulo',
          hour: 'numeric', 
          minute: 'numeric', 
          second: 'numeric' 
        };
        let dateFormated = 
          currentdate.getDate() + "/" +
          (currentdate.getMonth() + 1) + "/" +
          currentdate.getFullYear() + " - " +
          currentdate.toLocaleTimeString('pt-BR', options) 
        
        return dateFormated
      }

    submitForm = (event) => {
        event.preventDefault();
        const file = this.fileInput.current.files[0]
        const read = new FileReader();
        const uploadId = file.name  +" - "+ this.dateNow();

        read.readAsText(file);

        read.onloadend = function () {
            try {
                const parser = new xml2js.Parser({ explicitArray: false });
                parser.parseString(read.result, function (err, result) {
                    result.uploadId = uploadId
                    api.post('/posts/postxml', result, configToken()).then(res => {
                        window.alert("XML Enviado!")
                        console.log(res)
                    }).catch(err => {
                        window.alert("Erro ao enviar XML")
                        console.log(err)
                    })
                })
            } catch (error) {
                console.log(error)
            }
        } 

    };

    submitUrl = (event) => {
        event.preventDefault();
        this.props.onSetXMLUrl(this.state.url);
        window.alert("Url Atualizada!")
    };

    submitKeyImobzi = (event) => {
        event.preventDefault();
        this.props.onSetKeyImobzi(this.state.keyImobzi);
        window.alert("Key Imobzi Atualizada!")
    };

    handlePageChange = (_event, page) => {
        this.setState({page: page})
        this.checkUploadError({page});
    };

    handleText = (event) => {
        this.setState({
            url: event.target.value
        })
    }

    handleKeyImobzi = (event) => {
        this.setState({
            keyImobzi: event.target.value
        })
    }

    handleSelect = (event) => {
        this.setState({
            selected: event.target.value
        })
    }

    render() {
        return (
            <div className="WrapperDiv">
                <Helmet>
                    <title> Upload XML — Plano Cidade</title>
                </Helmet>
                <div className="formularios Form-center">
                    <p className="titulo">Upload</p>

                    <Typography component="div">
                        <div className="inputDiv" style={{ padding: "30px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <Grid component="label" container justifyContent="center" alignItems="center" spacing={1}>
                                <FormControl fullWidth>
                                    <InputLabel>Selecione a forma de upload</InputLabel>
                                    <Select
                                        value={this.state.selected}
                                        onChange={this.handleSelect}
                                    >
                                        <MenuItem value={0}>Arquivo XML</MenuItem>
                                        <MenuItem value={1}>URL do XML</MenuItem>
                                        <MenuItem value={2}>Imobzi API</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </div>
                    </Typography>
                    <br />
                    {this.state.selected === 0 ? (
                        <Box>
                            <form onSubmit={this.submitForm}>
                                <div className="inputDiv" style={{ padding: "30px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <input type="file" style={{ position: "relative" }} ref={this.fileInput} />
                                </div>
                                <Button>Enviar</Button>
                            </form>
                            <div className="inputDiv" style={{ padding: "30px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <Button clicked={this.checkUploadError}>Lista de erros</Button>
                            </div>
                        </Box>
                    ) : null}
                    {this.state.selected === 1 ? (
                        <Box>
                            <form onSubmit={this.submitUrl}>
                                <div className="inputDiv" style={{ padding: "30px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <TextField label="Url" value={this.state.url} onChange={this.handleText} fullWidth />
                                </div>
                                <Button>Enviar</Button>
                            </form>
                            <div className="inputDiv" style={{ padding: "30px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <Button clicked={this.checkUploadError}>Lista de erros</Button>
                            </div>
                        </Box>
                    ) : null}
                    {this.state.selected === 2 ? (
                        <Box>
                            <form onSubmit={this.submitKeyImobzi}>
                                <div className="inputDiv" style={{ padding: "30px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <TextField label="Key Imobzi" value={this.state.keyImobzi} onChange={this.handleKeyImobzi} fullWidth />
                                </div>
                                <Button>Enviar</Button>
                            </form>
                            <div className="inputDiv" style={{ padding: "30px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <Button clicked={this.checkUploadError}>Lista de erros</Button>
                            </div>
                        </Box>
                    ) : null}
                    {this.state.openList ? (
                    <div className="inputDiv" style={{ padding: "30px", marginTop: "40px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <ErrorsList 
                            errors={this.state.uploadIds} 
                            onPageChange={this.handlePageChange} 
                            pagesCount={this.state.pagesCount} 
                            currentPage={this.state.currentPage}
                        />
                    </div>): null}
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        url: state.imoveis.xmlUrl,
        keyImobzi: state.imoveis.keyImobzi,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onFetchKeyImobzi: () => dispatch(actions.fetchKeyImobzi()),
        onSetKeyImobzi: (key) => dispatch(actions.setKeyImobzi(key)),
        onFetchXMLUrl: () => dispatch(actions.fetchXMLUrl()),
        onSetXMLUrl: (url) => dispatch(actions.setXMLUrl(url))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PostXml);
